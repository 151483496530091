<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary fixed-top">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#">Menú</a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation"
      aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <div class="col-sm-12 col-md-12 col-lg-5">
          <input
            type="text"
            placeholder="Buscar Paciente"
            class="form-control"
            [(ngModel)]="f_paciente"
            style="background: white; font-size: 15px;"
            matInput
            (keyup)="obtenerPacientes($event)"
            [matAutocomplete]="autoFPacientes"
            >
          <mat-autocomplete #autoFPacientes="matAutocomplete" [displayWith]="displayFn" (optionSelected)="navToProd($event)">
            <mat-option *ngFor="let paciente of pacientes" [value]="paciente">
              {{ paciente.texto }}
            </mat-option>
          </mat-autocomplete>
      </div>

      <ul class="navbar-nav">

        <!-- MODULO DE AGENDA-->
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/agenda']">
            <i class="now-ui-icons ui-1_calendar-60"></i>
            <p>
              <span class="d-md-block"> AGENDA <span id='notificacionCitas'
                  class="badge badge-info d-none">0</span></span>
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/agenda/citas/crearcita']">
            <i class="now-ui-icons education_agenda-bookmark"></i>
            <p>
              <span class="d-md-block">CREAR CITA</span>
            </p>
          </a>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="now-ui-icons users_single-02"></i>
            <p>
              <span class="d-md-block">{{identity.nombre + " " + identity.ape_pat}}</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">

            <a class="dropdown-item" [routerLink]="['/miperfil']"><i class="fas fa-user"></i> Mi Perfil</a>

            <a class="dropdown-item" (click)="salir()"><i class="fas fa-sign-out-alt"></i> Cerrar Sesión</a>

          </div>
        </li>

      </ul>
    </div>
  </div>
</nav>
<!-- End Navbar -->

<div id="sound"></div>

<div class="panel-header panel-header-sm"></div>
