import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";

@Component({
  selector: 'imprimir-documentos',
  templateUrl: './imprimir-documentos.component.html',
})

export class ImprimirdocumentosComponent {

  public pacientes = null;
  public id_paciente;
  public contCPac = 0;
  public safari = 0;

  public config = {
    todo : 0,
    generales : 0,
    heredofamiliares : 0,
    nopatologicos : 0,
    gineco : 0,
    personalizado : 0,
    exploracion : 0,
    odontogramas : 0,
    notas : 0,
    presupuesto : 0,
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _pacientesService: PacientesService,
    private _expedienteService: ExpedienteService,
    private modalService: NgbModal,
  ){

  }

  ngOnInit(){

    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      this.safari = 1;
    }
  }

  obtenerPacientes(event: any) {
    let texto = '';
    texto += event.target.value;

    if ( texto.length >= 3 ) {

      let respuesta = this._pacientesService.obtenerPacientes(texto);
      respuesta.then( val =>{
        if( val.status == "success" ){
          if(val.pacientes != null ){
            this.pacientes = val.pacientes;
          }
        }
      });

      this.contCPac = 1;

    }else{
      this.pacientes = null;
      this.contCPac = 0;
    }
  }

  usuarioSeleccionado(e){
    console.log(e);
  }

  displayFn(elemento): string {
    return elemento ? elemento.texto : elemento;
  }

  configHistoria(e){
    if( e == 1 ){
      let estado = this.config.todo
      this.config = {
        todo : estado,
        generales : estado,
        heredofamiliares : estado,
        nopatologicos : estado,
        gineco : estado,
        personalizado : estado,
        exploracion : estado,
        odontogramas : estado,
        notas : estado,
        presupuesto : estado,
      };
    }else{
      this.config.todo = 0;
    }

  }

  historiaConfig( contentModal ){
    this.modalService.open( contentModal );
  }

  generarHistoria(){
    this.spinner.show('spnr-s');
    let respuesta = null;

    if(this.id_paciente != null){
      this.id_paciente = this.id_paciente.id;
      respuesta = this._expedienteService.imprimirHistoria( this.id_paciente, this.config );
    }else{
      respuesta = this._expedienteService.imprimirHistoriavacia( this.config );
    }

    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');

          var html = htmlToPdfmake(val.historia,
            {
              tableAutoSize:true
            }
            );
          let textofolio = ""
          if(this.id_paciente != null){
            textofolio = "Folio Paciente: "+this.id_paciente;
          }

          const documentDefinition = {
            pageSize: 'A4',
            header:{
              columns:[
                {
                  text: textofolio,
                  alignment: 'right',
                  color: '#4f4c4c',
                }
              ],
              margin: [40,20]
            },
            content: html,

          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }
          this.id_paciente = null;
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

  consentimiento( contentModal ){
    this.modalService.open( contentModal );
  }

  generarConcentimiento(){

    this.spinner.show('spnr-s');
    this.id_paciente = this.id_paciente.id;
    let respuesta = this._expedienteService.imprimirConcentimiento( this.id_paciente );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');

          var html = htmlToPdfmake(val.concentimiento,
            {
              tableAutoSize:true
            }
            );
          const documentDefinition = {
            pageSize: 'A4',
            header:{
              columns:[
                {
                  text: "Folio Paciente: "+this.id_paciente,
                  alignment: 'right',
                  color: '#4f4c4c',
                }
              ],
              margin: [40,20]
            },
            content: html,

          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }
          this.id_paciente = null;
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

  formatoTx(){

    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.imprimirTx( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');

          var html = htmlToPdfmake(val.tx,
            {
              tableAutoSize:true
            }
            );
          const documentDefinition = {
            pageSize: 'A4',
            content: html,
          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;

          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

}
