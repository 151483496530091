<div *ngIf="nombre != null">

  <div class="h3"> Tratamientos/Adeudos del Paciente: <span class="font-weight-bold">{{nombre}}</span> </div>

  <h3 class="pt-3">Tratamientos Registrados</h3>

  <table class="table table-sm">
      <thead class="thead-light">
          <tr>
              <th scope="col">F. Trat.</th>
              <th scope="col">Trat/Concepto</th>
              <th scope="col">Monto Gen</th>
              <th scope="col">Adeudo</th>
              <th scope="col">Pagos Relacionados</th>
          </tr>
      </thead>
      <tbody>
              <tr
                *ngFor="let adeudo of adeudos"
                [ngStyle]="{'background-color': (adeudo.estatus == 'Pendiente') ? '#ff00002e' : '#00ff0c2e'}"
              >
                  <th scope="row" style="width: 5%;">{{adeudo.id_tratamiento}}</th>
                  <td style="width: 15%;">{{adeudo.descripcion}}</td>
                  <td style="width: 10%;">${{adeudo.monto_general}}</td>
                  <td style="width: 10%;">${{adeudo.adeudo}}</td>
                  <td>
                    <div *ngIf="adeudo.pagos.length > 0" >
                      <button [attr.data-target]="'#deuda-'+adeudo.id_tratamiento" type="button" class="btn btn-sm btn-info" data-toggle="collapse" style="border: 1px solid black;">Ver Pagos</button>
                      <div id="deuda-{{adeudo.id_tratamiento}}" class="collapse">

                          <table style="font-size: 75%;">
                              <thead>
                                  <tr>
                                      <th scope="col">Folio</th>
                                      <th scope="col">Fecha</th>
                                      <th scope="col">Concepto</th>
                                      <th scope="col">Monto</th>
                                      <th scope="col">Cobró</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let pago of adeudo.pagos">
                                    <th scope="row" style="width: 5%;">{{pago.id_mov_caja}}</th>
                                    <td style="width: 15%;">{{pago.fecha}}</td>
                                    <td style="width: 30%;">{{pago.concepto}}</td>
                                    <td style="width: 15%;">{{pago.monto}}</td>
                                    <td>{{pago.persona}}</td>
                                </tr>
                              </tbody>
                          </table>

                      </div>
                    </div>
                    <div *ngIf="adeudo.pagos.length == 0">
                      SIN ACCIONES / PAGOS
                    </div>
                  </td>
              </tr>

      </tbody>
  </table>

  <h3 class="pt-3">Anotaciones / Comentarios</h3>

  <table class="table table-sm" *ngIf="anotaciones.length > 0">
      <thead class="thead-light">
          <tr>
              <th scope="col">Fecha</th>
              <th scope="col">Anotacion / Comentario</th>
              <th scope="col">Registró</th>
          </tr>
      </thead>
      <tbody>
        <tr *ngFor="let anotacion of anotaciones">
          <td style="width: 15%;">{{anotacion.fecha}}</td>
          <td style="width: 50%;">{{anotacion.anotaciones}}</td>
          <td>{{anotacion.persona}}</td>
        </tr>
      </tbody>
  </table>

  <div *ngIf="anotaciones.length == 0">
    SIN ANOTACIONES
  </div>

</div>
