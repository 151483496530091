<div class="pull-right">
  <p>
      <a class="btn btn-success" [routerLink]="['/caja/entrada']" target="_blank">
        <i class="fa fa-donate"></i> Cobrar
      </a>
  </p>
</div>

<h1>Historial de citas</h1>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc" width="13%">Folio</th>
      <th class="disabled-sorting sorting_desc">Concepto</th>
      <th class="disabled-sorting sorting_desc" width="15%">Monto</th>
      <th class="disabled-sorting sorting_desc" width="15%">Tipo</th>
      <th class="disabled-sorting sorting_desc" width="15%">Fecha Hora</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let pago of pagos"
    [ngStyle]="{'background-color': (pago.estado == '1') ? '#ff00002e' : null }">
      <td width="13%">{{ pago.id_mov_caja }}</td>
      <td>{{ pago.concepto }}</td>
      <td width="15%">${{ pago.monto }}</td>
      <td width="15%">{{ pago.tipo }}</td>
      <td width="15%">{{ pago.fecha }}</td>
    </tr>
  </tbody>
</table>
