import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { Global } from 'src/app/services/global';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'expediente',
  templateUrl: './expediente.component.html',
})

export class ExpedienteComponent {

  public id_paciente = null;
  public urlImg = null;
  public configApp = null;
  public nombre = "ADClinik";
  public logo = '';
  public imagen = null;
  public activeModal = null;

  public expand = true;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
    private _pacienteService: PacientesService,
    private modalService: NgbModal,
    private _expedienteService: ExpedienteService,
  ){
    this.configApp = this._configuracionesService.getConfig();
    this.urlImg = Global.urlImg;
  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_paciente = params['id'];
      this.logo = "logos/"+this.configApp.logo_small;
      this.obtenerPaciente();
      this._pacienteService.setPaciente(this.id_paciente);
    });
  }

  ngAfterViewInit() {
    $('body').addClass('sidebar-mini');
  }

  ngOnDestroy(): void {
    $('body').removeClass('sidebar-mini');
  }

  extender(){

    this.expand = !this.expand;

    var item = $('.divm');
    var nombre = $('.divmt');

    if(this.expand){

      item.addClass('is-visible');
      setTimeout(function () {
        item.addClass('is-open');

        nombre.removeClass('is-open');
        nombre.one('transitionend', function(e) {
          nombre.removeClass('is-visible');
        });

      }, 20);


    }else{

      item.removeClass('is-open');
      item.one('transitionend', function(e) {
        item.removeClass('is-visible');

        nombre.addClass('is-visible');
        setTimeout(function () {
          nombre.addClass('is-open');
        }, 20);

      });


    }

  }

  obtenerPaciente(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._pacienteService.obtenerPaciente( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            if(val.paciente != null ){
              this.spinner.hide('spnr-s');
              this.nombre = val.paciente.nombre + " " + ((val.paciente.ape_pat != null)? val.paciente.ape_pat : "") + " " + ((val.paciente.ape_mat != null)? val.paciente.ape_mat : "");
              if(val.paciente.foto != '0'){
                this.logo = "expedientes/"+val.paciente.foto+"?rev="+ new Date().getTime();
              }
            }else{
              this.mensajeError("Ha ocurrido un problema al cargar los datos.");
            }
          }else{
            this.mensajeError(val.message);
          }
        },
        error =>{
          this.mensajeError("Ha ocurrido un problema al cargar los datos.");
        }
      );
    }
  }

  mensajeError(mensaje){
    this.spinner.hide('spnr-s');
    this._router.navigate(['/pacientes']);
    Swal.fire({
      title: 'Oopss...',
      text: mensaje,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#1beb11',
    });
  }

  cambiarFoto( contentModal ){
    this.activeModal = this.modalService.open(contentModal);
  }

  cambioArchivo(fileInput) {
    this.spinner.show('spnr-s');
    this.imagen = <Array<File>>fileInput.target.files;
    this.spinner.hide('spnr-s');
  }

  registrarArchivo( ){
    this.spinner.show('spnr-s');
    let archivo = this.imagen;
    this._expedienteService.subirFoto( archivo, this.id_paciente ).then(
      (result) =>{
        if( result.status == "success" ){

          this.activeModal.close();
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Fotografía Cargada Exitosamente",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this.logo = "expedientes/"+result.img+"?rev="+ new Date().getTime();

        }else{

          this.spinner.hide('spnr-s');

          Swal.fire({
            title: 'Oopss...',
            text: result.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      (error) =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar este elemento.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

}
