<div class="pull-right">
  <p>
    <button class="btn btn-success" (click)="registroArchivo(contentModal)">
      <span class="glyphicon glyphicon-copy icono"></span>  Subir Archivo
    </button>
  </p>
</div>

<h3>Docs. & Archivos</h3>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc" width="20%">Fecha</th>
      <th class="disabled-sorting sorting_desc">Descripción</th>
      <th class="disabled-sorting sorting_desc text-center" width="20%" data-priority="1">Ver</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let archivo of archivos">
      <td width="20%">{{ archivo.fecha }}</td>
      <td>{{ archivo.descripcion }}</td>
      <td width="20%" class="text-center">
        <button (click)="verArchivo( archivo.id_archivo, viewModal )" class="btn btn-info btn-icon btn-sm mr-1" title="Ver" aria-label="Ver" data-pjax="0">
          <i class="far fa-eye"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #contentModal let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h5 class="modal-title" id="modal-primary-title">Archivos Clínicos</h5>
  </div>
  <div class="modal-body centered formulario">
    <form [formGroup]="formArchivo" (ngSubmit)="registrarArchivo(formArchivo.value)">

      <div class="container">
          <input type="hidden" formControlName="id_tratamiento">

          <div class="row">
              <div class="col-sm-12 col-md">
                <div class="form-group">
                  <label class="control-label">Tipo de Archivo</label>
                    <mat-select formControlName="tipo"  class="form-control">
                      <mat-option [value]="'Fotografia'">Fotografia</mat-option>
                      <mat-option [value]="'Radiografia'">Radiografia</mat-option>
                      <mat-option [value]="'Documento'">Documento</mat-option>
                    </mat-select>
                </div>
              </div>
          </div>

          <div class="row">
              <div class="col-sm-12 col-md">
                <div class="form-group">
                  <label class="control-label">Descripcion</label>
                  <input
                    class="form-control"
                    formControlName="descripcion">
                </div>
              </div>
          </div>

          <div class="row justify-content-center text-center">
              <div class="col-12">
                <label class="control-label">Archivo</label><br>
                <div class="fileinput fileinput-new text-center" data-provides="fileinput">
                  <div class="fileinput-preview fileinput-exists thumbnail img-raised logos_upload"></div>
                  <div>
                    <span class="btn btn-raised btn-round btn-info btn-file">
                        <span class="fileinput-new"><i class="fas fa-file-image mr-1"></i> Seleccionar Imagen</span>
                        <span class="fileinput-exists"><i class="fas fa-sync-alt mr-1"></i> Cambiar</span>
                        <input type="file" (change)="cambioArchivo($event)"/>
                    </span>
                    <a href="#" (click)="imagen = null"class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput">
                      <i class="fa fa-times"></i> Remover
                    </a>
                  </div>
                </div>
              </div>
          </div>

          <div class="row justify-content-center">
              <div class="col-sm-7">
                  <div class="form-group">
                    <button
                      [disabled]="imagen == null"
                      type="submit"
                      class="btn btn-success btn-block">
                      <i class="fas fa-cloud-upload-alt"></i> Subir Archivo
                    </button>
                  </div>
              </div>
          </div>

      </div>

    </form>
  </div>


</ng-template>

<ng-template #viewModal let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h5 class="modal-title" id="modal-primary-title">Archivos Clínicos</h5>
  </div>

  <div class="modal-body centered formulario" *ngIf="archivo_view != null">

    <div class="container">

        <table class="table table-striped table-bordered detail-view table-sm">
          <tbody>
            <tr>
              <th>TIPO</th>
              <td> {{archivo_view.tipo}}</td>
            </tr>
            <tr>
              <th>FECHA</th>
              <td> {{archivo_view.fecha}}</td>
            </tr>
            <tr>
              <th>DESCRIPCION</th>
              <td>{{archivo_view.descripcion}}</td>
            </tr>
          </tbody>
        </table>

        <div *ngFor="let image of imagenes; let i=index">
          <img [src]="image.thumb" (click)="open(i)" loading="lazy"/>
        </div>

        <div class="row align-items-center mt-3">
            <div class="col-sm">
              <button class="btn btn-danger" (click)="eliminarArchivo(archivo_view.id_archivo)">
                <span class="glyphicon glyphicon-trash icono"></span> Eliminar
              </button>
            </div>
        </div>
    </div>

  </div>


</ng-template>

