<div class="row">
  <div class="col-12 col-sm-6">
    <h1>Técnicos/Laboratorios</h1>
  </div>
  <div class="col-12 col-sm-6">
    <div class="pull-right">
      <button
        *ngIf=" cuenta_tecnicos < limite_tecnicos "
        type="button"
        class="btn btn-success"
        (click)="registrarTecnico(contentModal)">
        <span class="glyphicon glyphicon-copy icono"></span>
        Nuevo Técnico
      </button>
      <div class="alert alert-warning" role="alert" *ngIf=" cuenta_tecnicos >= limite_tecnicos ">
        Ha registrado el número máximo de técnicos.
      </div>
    </div>
  </div>
</div>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc">Nombre</th>
      <th class="disabled-sorting sorting_desc">Dirección</th>
      <th class="disabled-sorting sorting_desc">Teléfono</th>
      <th class="disabled-sorting sorting_desc text-center" data-priority="1" >Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tecnico of tecnicos">
      <td>{{ tecnico.nombre }}</td>
      <td>{{ tecnico.direccion }}</td>
      <td>{{ tecnico.telefono }}</td>

      <td class="text-center">
        <!-- <a class="btn btn-info btn-icon btn-sm mr-1" [routerLink]="['/tecnico', tecnico.id ]" title="Ver" aria-label="Ver" data-pjax="0">
          <i class="far fa-eye"></i>
        </a> -->
        <button
          class="btn btn-warning btn-icon btn-sm mr-1"
          (click)="actualizarTecnico(tecnico.id_tecnico, contentModal)"
          title="Actualizar"
          aria-label="Actualizar"
          data-pjax="0">
          <i class="far fa-edit"></i>
        </button>
        <button
          class="btn btn-danger btn-icon btn-sm"
          (click)="eliminarTecnico(tecnico.id_tecnico)"
          title="Eliminar"
          aria-label="Eliminar">
          <i class="far fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #contentModal let-c="close" let-d="dismiss">

  <div class="container">
    <div class="row">
      <div class="col-12">
        <h4>Registrar/Editar Técnico</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="formTecnico" (ngSubmit)="registrar(formTecnico.value)">

          <div class="container">
              <input type="hidden" formControlName="id_tecnico">
              <div class="row">
                  <div class="col-sm-12 col-md">
                    <div class="form-group">
                      <label class="control-label" for="nombre">NOMBRE</label>
                      <input
                        type="text"
                        id="nombre"
                        class="form-control"
                        formControlName="nombre">
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md">
                    <div class="form-group">
                      <label class="control-label" for="direccion">DIRECCION</label>
                      <input
                        type="text"
                        id="direccion"
                        class="form-control"
                        formControlName="direccion">
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md">
                    <div class="form-group">
                      <label class="control-label" for="telefono">TELEFONO</label>
                      <input
                        type="text"
                        id="telefono"
                        class="form-control"
                        formControlName="telefono">
                    </div>
                  </div>
              </div>
              <div class="row justify-content-center">
                  <div class="col-sm-7">
                      <div class="form-group">
                        <button
                          [disabled]="formTecnico.invalid"
                          type="submit"
                          class="btn btn-success btn-block">
                          GUARDAR
                        </button>
                      </div>
                  </div>
              </div>
          </div>

        </form>
      </div>
    </div>

  </div>

</ng-template>
