<div class="pull-right">
  <p>
      <a class="btn btn-success" [routerLink]="['/agenda']" target="_blank">
        <span class="glyphicon glyphicon-copy icono"></span> Crear Nueva Cita
      </a>
  </p>
</div>

<h1>Historial de citas</h1>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc">Fecha y Hora Cita</th>
      <th class="disabled-sorting sorting_desc">Motivo</th>
      <th class="disabled-sorting sorting_desc">Estado</th>
      <th class="disabled-sorting sorting_desc text-center" data-priority="1">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let cita of citas" >
      <td>{{ cita.fecha_programada_inicial }}</td>
      <td>{{ cita.motivo }}</td>
      <td>{{ cita.status }}</td>
      <td class="text-center">
        <button *ngIf="cita.status == 'Por Atender' " class="btn btn-success btn-icon btn-sm mr-1"  (click)="cambiarEstado(cita.id_cita)" title="Antendida" aria-label="Antendida" data-pjax="0">
          <i class="fa fa-check"></i>
        </button>
        <a class="btn btn-info btn-icon btn-sm" [routerLink]="['/agenda/cita', cita.id_cita]" target="_blank" title="Cancelar" aria-label="Cancelar" data-pjax="0">
          <i class="far fa-eye"></i>
        </a>
      </td>
    </tr>
  </tbody>
</table>
