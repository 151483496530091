  <ngx-spinner
    name="spnr-l"
    bdColor = "rgba(0, 0, 0, 0.9)"
    size = "large"
    color = "#ffffff"
    type = "ball-elastic-dots"
    [fullScreen] = "true">
    <p style="color: white">
      <b>Conectando</b>
    </p>
  </ngx-spinner>

  <ngx-spinner
    name="spnr-g"
    bdColor = "rgba(0, 0, 0, 0.8)"
    size = "large"
    color = "#ffffff"
    type = "ball-clip-rotate-multiple"
    [fullScreen] = "true">
    <p style="color: white">
      <b>Cargando</b>
    </p>
  </ngx-spinner>

  <ngx-spinner
    name="spnr-s"
    bdColor = "rgba(0, 0, 0, 0.8)"
    size = "medium"
    color = "#ffffff"
    type = "ball-fall"
    [fullScreen] = "true">
    <p style="color: white;">
      <b>Cargando</b>
    </p>
  </ngx-spinner>

  <div class="wrapper animate-bottom" id="contenido" >

    <div class="wrapper" *ngIf="identity">

      <perfect-scrollbar [config]="config">

      <barra-tag></barra-tag>

      <div class="main-panel">

        <header-tag></header-tag>

        <notifier-container></notifier-container>

        <div class="content">

            <div class="row">

                <div class="col-md-12">

                   <div [ngClass]="{ 'card': expediente == '0' }">

                      <div [ngClass]="{ 'card-body': expediente == '0' }">

                        <router-outlet></router-outlet>

                      </div>

                  </div>

                </div>

            </div>

        </div>

        <chat></chat>

        <footer-tag></footer-tag>

      </div>

      </perfect-scrollbar>

    </div>

    <login *ngIf="identity == null" ></login>

  </div>


