<div class="card-calendar">
    <div class="card-header">
      <div class="row">
          <div class="col-md-8">
            <h1>Agenda Clínica</h1>
          </div>
          <div class="col-md-4">
            <div class="pull-right">
              <button class="btn btn-purple btn-sm" (click)="recargarAgenda()"><i class="fas fa-sync-alt"></i></button>
            </div>
          </div>
          <div *ngIf="buscando" style="position: absolute; width: 95%; padding-top: 38px;">
            <p class="text-center"> <b>Buscando Citas</b> </p>
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
      </div>
    </div>

    <div class="card-body ">
      <div id="fullCalendar" class="fc fc-unthemed fc-ltr">
      </div>
    </div>
</div>

<ng-template #contentModal let-c="close" let-d="dismiss">
</ng-template>



