import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AgendaService } from 'src/app/services/agenda.service';

@Component({
  selector: 'anotacioncita',
  templateUrl: './anotacioncita.component.html',
})

export class AnotacioncitaComponent {

  anotacionForm: FormGroup;
  @Input() public id_cita;

  constructor(
    private _bilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private _agendaService: AgendaService,
    private spinner: NgxSpinnerService,
  ){
    this.anotacionForm = this._bilder.group({
      anotaciones: [ "" , Validators.required ]
    });
  }

  ngOnInit(){

    this.spinner.show('spnr-s');

    let respuesta = this._agendaService.anotacionesCita( this.id_cita, 0 );
    respuesta.then( val =>{
      if( val.status == "success" ){
        this.anotacionForm = this._bilder.group({
          anotaciones: [ val.anotaciones , Validators.required ]
        });
        this.spinner.hide('spnr-s');
      }else{
        this.spinner.hide('spnr-s');
        this.activeModal.close();
      }
    });

  }

  registrarAnotacion( datos ){

    this.spinner.show('spnr-s');

    let respuesta = this._agendaService.anotacionesCita( this.id_cita, datos.anotaciones );
    respuesta.then( val =>{
      if( val.status == "success" ){
        this.activeModal.close();
        this.spinner.hide('spnr-s');

        Swal.fire({
          title: 'Anotación registrada exitosamente',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });

      }else{
        Swal.fire({
          title: 'Oopss...',
          text: val.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
        this.spinner.hide('spnr-s');
      }
    });

  }


}
