import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";
import { ConfiguracionesService } from "./configuraciones.service";


@Injectable()

export class AgendaService{
  public url: string;
  public token = null;
  public config = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.url = Global.url;
    this.config = this._configuracionesService.getConfig( );
    this.token = this._usuarioService.getToken();
  }

  obtenerAgenda(  ):Observable<any>{

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta = this._http.post(this.url + "agenda/agenda", params, {headers: headers});

    return respuesta;

  }

  obtenerCitas(  ):Observable<any>{

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta = this._http.post(this.url + "agenda/citas", params, {headers: headers});

    return respuesta;

  }

  obtenerCitasFiltradas( id_ultima_cita_local ):Observable<any>{

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "uc": id_ultima_cita_local
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = this._http.post(this.url + "agenda/citasfiltradas", params, {headers: headers});

    return respuesta;

  }

  obtenerCantFiltro( f_inicial, f_final, contador_elementos_locales, citas_encontradas ):Observable<any>{

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "fi":f_inicial,
      "ff":f_final,
      'cont_local':contador_elementos_locales,
      'c_encontradas':citas_encontradas
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = this._http.post(this.url + "agenda/cantfiltro", params, {headers: headers});

    return respuesta;

  }

  async obtenerCita( id_cita ){

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "ic": id_cita
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "agenda/obtenercita", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async anotacionesCita( id_cita, anotaciones ){

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "ic": id_cita,
      "anotacion": anotaciones
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "agenda/anotacion", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async cancelarCita( id_cita, motivo ){

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "ic": id_cita,
      "motivo": motivo
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "agenda/cancelarcita", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async inasistenciaCita( id_cita, motivo ){

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "ic": id_cita,
      "motivo": motivo
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "agenda/inasistencia", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async eliminarDeshabilitada( id_cita ){

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "ic": id_cita,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "agenda/eliminardeshabilitado", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarCita( datos ){

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "datos": datos,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "agenda/registrarcita", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async actualizarCita( datos ){

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "datos": datos,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "agenda/actualizarcita", params, {headers: headers}).toPromise();

    return respuesta;

  }

}
