<div class="container">
  <div class="site-error">

      <h1>Error</h1>

      <div class="alert alert-danger">
        La Pagina a la que intentas ingresar no existe o no cuentas con los permisos necesarios.
      </div>

  </div>
</div>
