<div class="row">
  <div class="col-sm-5 mr-auto">
      <h1>Apertura de Caja</h1>
  </div>
</div>

<div class="dropdown-divider mb-5" style='border-top: 3px solid #919a91a6;'></div>

<div class="row justify-content-center text-center">
  <div class="col-sm-8 col-md-6">
    <h6>ANOTACIONES</h6>
    <div class="form-group">
      <textarea [(ngModel)]="concepto" class="form-control" maxlength="255" aria-required="true" aria-invalid="false"></textarea>
    </div>
  </div>
  <div class="col-sm-4 col-md-3">
    <h6>MONTO DE APERTURA</h6>
    <div class="form-group">
      <input id="monto-input" class="form-control" currencyMask [(ngModel)]="monto" />
    </div>
  </div>
</div>

<div class="row justify-content-center mt-5">
  <div class="col-12 col-sm-8 col-md-5">
      <div class="form-group">
          <button
            [disabled]=" concepto =='' "
            (click)="registrarApertura()"
            class="btn btn-success btn-block">
            Registrar Movimiento
          </button>
      </div>
  </div>
</div>
