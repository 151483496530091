<div class="pull-right">
  <p>
    <button class="btn btn-success" (click)="registroNota(contentModal)">
      <span class="glyphicon glyphicon-copy icono"></span>  Nueva Nota
    </button>
  </p>
</div>

<h3>Notas de Evolución</h3>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc" width="20%">Fecha</th>
      <th class="disabled-sorting sorting_desc">Nota</th>
      <th class="disabled-sorting sorting_desc text-center" width="20%" data-priority="1">Ver</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let nota of notas">
      <td width="20%">{{ nota.fecha }}</td>
      <td>{{ nota.descripcion }}</td>
      <td width="20%" class="text-center">
        <button (click)="verNota( nota.id_notas_evolucion, viewModal )" class="btn btn-info btn-icon btn-sm mr-1" title="Ver" aria-label="Ver" data-pjax="0">
          <i class="far fa-eye"></i>
        </button>
        <button (click)="editarNota( nota.id_notas_evolucion, contentModal )" class="btn btn-warning btn-icon btn-sm mr-1" title="Editar" aria-label="Editar" data-pjax="0">
          <i class="far fa-edit"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #contentModal let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h5 class="modal-title" id="modal-primary-title">Registrar/Actualizar Nota de Evolución</h5>
  </div>
  <div class="modal-body centered formulario">
    <form [formGroup]="formNota" (ngSubmit)="registrarNota(formNota.value)">

      <div class="container">
          <input type="hidden" formControlName="id_nota">
          <input type="hidden" formControlName="id_tratamiento">

          <div class="row">
              <div class="col-sm-12 col-md">
                <div class="form-group">
                  <label class="control-label">Nota</label>

                  <textarea
                    class="form-control"
                    formControlName="nota">
                  </textarea>

                </div>
              </div>
          </div>

          <div class="row justify-content-center">
              <div class="col-sm-7">
                  <div class="form-group">
                    <button
                      [disabled]="formNota.invalid"
                      type="submit"
                      class="btn btn-success btn-block">
                      Registrar
                    </button>
                  </div>
              </div>
          </div>

      </div>

    </form>
  </div>


</ng-template>

<ng-template #viewModal let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h5 class="modal-title" id="modal-primary-title">Detalle Nota Evolución</h5>
  </div>

  <div class="modal-body centered formulario" *ngIf="nota_view != null">

    <div class="container">
        <div class="row text-center">
            <div class="col-sm">
                <h4>Descripción</h4>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-sm border border-info">
                {{nota_view.descripcion}}
            </div>
        </div>
        <div class="row align-items-center mt-3">
            <div class="col-sm">
              <button class="btn btn-danger" (click)="eliminarNota(nota_view.id_notas_evolucion)">
                <span class="glyphicon glyphicon-trash icono"></span> Eliminar
              </button>
            </div>
        </div>
    </div>

  </div>


</ng-template>

