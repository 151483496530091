<div class="row">
  <div class="col-md-8">
    <h1>Clínicas</h1>
  </div>
  <div class="col-md-4">
    <div class="pull-right">
      <button type="button" class="btn btn-success"
        (click)="registroModal()" *ngIf="cuenta_clinicas < limite_clinicas && permisos.c">
        <span class="glyphicon glyphicon-copy icono"></span>
        Nueva Clínica
      </button>
      <div class="alert alert-warning" role="alert" *ngIf="cuenta_clinicas >= limite_clinicas">
        Ha registrado el número máximo de clínicas.
      </div>
    </div>
  </div>
</div>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc">Razón Social</th>
      <th class="disabled-sorting sorting_desc">RFC</th>
      <th class="disabled-sorting sorting_desc">Dirección</th>
      <th class="disabled-sorting sorting_desc">Teléfono</th>
      <th class="disabled-sorting sorting_desc text-center" data-priority="1">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let clinica of clinicas">
      <td>{{ clinica.razon_social }}</td>
      <td>{{ clinica.rfc }}</td>
      <td>{{ clinica.direccion }}</td>
      <td>{{ clinica.telefono }}</td>
      <td class="text-center">
        <button class="btn btn-warning btn-icon btn-sm mr-1"  (click)="actualizarModal(clinica.id_clinica)" *ngIf="permisos.u" title="Actualizar" aria-label="Actualizar" data-pjax="0">
          <i class="far fa-edit"></i>
        </button>
        <button class="btn btn-danger btn-icon btn-sm"  (click)="eliminarClinica(clinica.id_clinica)" *ngIf="permisos.d" title="Eliminar" aria-label="Eliminar">
          <i class="far fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #contentModal let-c="close" let-d="dismiss">
</ng-template>
