import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Global } from 'src/app/services/global';

@Component({
  selector: 'footer-tag',
  templateUrl: './footer.component.html',
})

export class FooterComponent {
  public urlImg;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ){
    this.urlImg = Global.urlImg;
  }

}
