<div class="row" *ngIf="usuario">
  <div class="col-md-6 ml-auto">
      <h1>{{usuario.persona.nombre + " " + usuario.persona.ape_pat + " " + usuario.persona.ape_mat}}</h1>
  </div>
  <div
      class="col-md-5 mr-auto"
      *ngIf="identity.tipo == 'Administrativo' || identity.tipo == 'Soporte Técnico' || identity.id_persona == id_usuario">
      <div class="pull-right">

        <a class="btn btn-primary" [routerLink]="['/usuario/actualizar/', id_usuario]">
          <i class="far fa-edit"></i>
          Actualizar
        </a>

        <a
          *ngIf="identity.tipo == 'Administrativo' || identity.tipo == 'Soporte Técnico'"
          class="btn btn-info"
          [routerLink]="['/usuario/permisos/', id_usuario]">
          <i class="fas fa-key"></i>
          Editar Permisos
        </a>

      </div>
  </div>
</div>

<div class="row justify-content-center" *ngIf="usuario">
  <div class="col-12 col-sm-6 col-md-4">
    <h4 class="text-center">Datos de Personales</h4>
    <table class="table table-bordered table-striped detail-view">
      <tbody>
        <tr><th>Nombre</th><td>{{usuario.persona.nombre + " " + usuario.persona.ape_pat + " " + usuario.persona.ape_mat}}</td></tr>
        <tr><th>Teléfono</th><td>{{usuario.persona.tel}}</td></tr>
        <tr><th>Dirección</th><td>{{usuario.persona.direccion}}</td></tr>
        <tr><th>Estado Civil</th><td>{{usuario.persona.estado_civil}}</td></tr>
        <tr><th>Tipo Sangre</th><td>{{usuario.persona.tipo_sangre}}</td></tr>
        <tr><th>Tipo Usuario</th><td>{{usuario.usuario.tipo}}</td></tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 col-sm-6 col-md-4" *ngIf="usuario.usuario.medico == '1'">
    <h4 class="text-center">Datos de Médico</h4>
    <table class="table table-bordered table-striped detail-view">
      <tbody>
        <tr><th>Nombre Estudio</th><td>{{usuario.estudio.nombre}}</td></tr>
        <tr><th>Tipo Estudio</th><td>{{usuario.estudio.tipo_estudio}}</td></tr>
        <tr><th>Cedula Prof</th><td>{{usuario.estudio.cedula}}</td></tr>
      </tbody>
    </table>
  </div>
</div>
