<form [formGroup]="formGenerales" (ngSubmit)="registrarGenerales(formGenerales.value)">
  <div class="container ">

  <div class="row pt-3 pb-3 justify-content-center">
    <div class="col-sm col-md-8 col-lg-8">
      <div class="form-group">
        <label class="control-label" >OBSERVACIONES GENERALES</label>
        <textarea
          formControlName="general"
          placeholder="Observaciones Generales"
          class="form-control">
        </textarea>
      </div>
    </div>
  </div>

    <div class="row pb-3">
        <div class="col-sm col-md-4 col-lg-4">

          <div class="form-group">
            <label class="control-label">CLASIFICACION ASA</label>
            <button class="btn btn-fab btn-icon btn-info btn-round btn-sm btn-neutral" style="margin: 0px 0px!important;" (click)="mostrarAsa(contentModal)">
              <i class="fas fa-info-circle"></i>
            </button>
            <select
              formControlName="asa"
              placeholder="Elige una opción"
              class="form-control"
              title="Elige una opción"
              data-style="btn btn-info btn-round">

              <option value="Elige" selected="" disabled="">Elige una opción</option>
              <option value="CLASE I">CLASE I</option>
              <option value="CLASE II">CLASE II</option>
              <option value="CLASE III">CLASE III</option>
              <option value="CLASE IV">CLASE IV</option>
              <option value="CLASE V">CLASE V</option>
              <option value="CLASE VI">CLASE VI</option>

            </select>
          </div>

        </div>
        <div class="col-sm-6 col-md-4 col-lg">
          <div class="form-group">
            <label class="control-label">PESO (Kg)</label>
            <input
              type="number"
              class="form-control"
              formControlName="peso"
              (change)="calcularIMC()"
              >
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg">
          <div class="form-group">
            <label class="control-label">TALLA (Mtrs)</label>
            <input
              type="number"
              class="form-control"
              formControlName="talla"
              (change)="calcularIMC()"
              >
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg">
          <div class="form-group">
            <label class="control-label">F. CARDIACA (lpm)</label>
            <input
              type="number"
              class="form-control"
              formControlName="f_cardiaca">
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg">
          <div class="form-group">
            <label class="control-label">F. RESPIRATORIA (rpm)</label>
            <input
              type="number"
              class="form-control"
              formControlName="f_respiratoria">
          </div>
        </div>
    </div>

    <div class="row pb-3">

        <div class="col-sm-6 col-md-3 col-lg">
          <div class="form-group">
            <label class="control-label">TEMP. (°C)</label>
            <input
              type="number"
              class="form-control"
              formControlName="temperatura">
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg ">
          <div class="form-group">
            <label class="control-label">PRESION (mm/Hg)</label>
            <input
              type="text"
              class="form-control"
              formControlName="presion">
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg">
          <div class="form-group">
            <label class="control-label">GLUCEMIA (mg/dl)</label>
            <input
              type="text"
              class="form-control"
              formControlName="glucemia">
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg">
          <div class="form-group">
            <label class="control-label">IMC</label>
            <input
              type="text"
              class="form-control"
              formControlName="imc"
              readOnly>
          </div>
        </div>
    </div>

    <div class="row justify-content-center pb-3">
        <div class="col-sm-6 col-md-4 col-lg-3">

          <div class="form-group">
            <label class="control-label">ANESTESICO</label>
            <select
              formControlName="anestesico"
              placeholder="Tipo de Anestésico"
              class="form-control"
              title="Tipo de Anestésico"
              data-style="btn btn-info btn-round"
              (change)="calculoAnestesico()">
              <option value="Elige" selected="" disabled="">Elige un Anestésico</option>
              <option value="LIDOCAINA">LIDOCAINA (4.4mg)</option>
              <option value="LIDOCAINA+EPINEFRINA">LIDOCAINA+EPINEFRINA (7mg)</option>
              <option value="MEPIVACAINA">MEPIVACAINA (4.4mg)</option>
              <option value="ARTICAINA+EPINEFRINA">ARTICAINA+EPINEFRINA (7mg)</option>
            </select>
          </div>

        </div>
        <div class="col-sm-6 col-md-4 col-lg" >
          <div class="form-group">
            <label class="control-label">DOSIS PONDERAL</label>
            <input
              type="text"
              class="form-control"
              formControlName="dosis_ponderal"
              readOnly>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg">
          <div class="form-group">
            <label class="control-label">CARTUCHOS</label>
            <input
              type="text"
              class="form-control"
              formControlName="cartuchos"
              readOnly>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg" >
          <div class="form-group">
            <label class="control-label">PULSIOXIMETRO (%SpO2)</label>
            <input
              type="text"
              class="form-control"
              formControlName="pulsioximetro">
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg">
          <div class="form-group">
            <label class="control-label">TIPO DE SANGRE</label>
            <select
              formControlName="sangre"
              placeholder="Tipo de Sangre"
              class="form-control"
              title="Tipo de Sangre"
              data-style="btn btn-info btn-round">

              <option value="O-P">O+</option>
              <option value="O-N">O-</option>
              <option value="A-P">A+</option>
              <option value="A-N">A-</option>
              <option value="B-P">B+</option>
              <option value="B-N">B-</option>
              <option value="AB-P">AB+</option>
              <option value="AB-N">AB-</option>

            </select>
          </div>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-sm-8 col-md-6 ">
            <div class="form-group">
                <button class="btn btn-success btn-block">
                  Guardar
                </button>
            </div>
        </div>
    </div>

  </div>
</form>

<ng-template #contentModal let-c="close" let-d="dismiss">
  <div class='container mb-3'>
    <div class="row justify-content-center">
      <h4>Clasificación ASA</h4>
    </div>
    <div class='row'>
        <div class='col-sm-3'>
            <b>CLASE I</b>
        </div>
        <div class='col-sm'>
            Paciente sano.
        </div>
    </div>

    <div class='row'>
        <div class='col-sm-3'>
            <b>CLASE II</b>
        </div>
        <div class='col-sm'>
            Paciente con enfermedad sistémica leve.
        </div>
    </div>

    <div class='row'>
        <div class='col-sm-3'>
            <b>CLASE III</b>
        </div>
        <div class='col-sm'>
            Paciente con enfermedad sistémica grave.
        </div>
    </div>

    <div class='row'>
        <div class='col-sm-3'>
            <b>CLASE IV</b>
        </div>
        <div class='col-sm'>
            Paciente con enfermedad sistémica grave que es una amenaza constante para la vida.
        </div>
    </div>

    <div class='row'>
        <div class='col-sm-3'>
            <b>CLASE V</b>
        </div>
        <div class='col-sm'>
            Paciente moribundo que no se espera que sobreviva en las siguientes 24 horas con o sin cirugía
        </div>
    </div>

    <div class='row'>
        <div class='col-sm-3'>
            <b>CLASE VI</b>
        </div>
        <div class='col-sm'>
            Paciente declarado con muerte cerebral cuyos órganos serán removidos para donación
        </div>
    </div>

    <i class='fuente'>Sistema de clasificación del estado físico de la American Society of Anesthesiologists (ASA-PS).
    (Última aprobación por la Casa de Delegados de la ASA el 15 de octubre de 2014).</i>
  </div>
</ng-template>

