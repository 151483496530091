import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { CajaService } from 'src/app/services/caja.service';
import { MedicosService } from 'src/app/services/medicos.service';
import { PacientesService } from 'src/app/services/pacientes.service';
import { TratamientosService } from 'src/app/services/tratamientos.service';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";

@Component({
  selector: 'caja-entradas',
  templateUrl: './caja-entradas.component.html',
})

export class CajaentradasComponent {

  public total = '$ 0.00';
  public tarjeta = false;
  public lista_tabla;
  public lista_elementos;
  public concepto = "";
  public monto = "";
  public pacientes = null;
  public contCPac = 1;
  public tratamientos = null;
  public doctores = null;
  public procedimientos = null;
  public tratamiento_paciente = null;
  public procedimiento = null;
  public id_paciente = null;
  public id_tratamiento = null;
  public id_doctor = null;
  public medico = null;
  public id_procedimiento = null;
  public safari = 0;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _pacientesService: PacientesService,
    private _medicosService: MedicosService,
    private _tratamientosService: TratamientosService,
    private _cajaService: CajaService,
  ){

  }

  ngOnInit(){
    this.spinner.show('spnr-s');

    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      this.safari = 1;
    }

    let respuesta = this._cajaService.validarApertura( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          if(val.apertura == 0){
            this._router.navigate(['/caja/apertura']);
          }else{
            this.obtenerMedicos();
          }
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        this._router.navigate(['/error']);
      }
    );
  }

  ngAfterViewInit() {
    $('body').addClass('sidebar-mini');
    // const doc = new jsPDF('p', 'mm', [80,160]);
    // doc.html(document.getElementById('#tabla-elementos'));
    // doc.autoPrint();
    // doc.output("dataurlnewwindow");
  }

  ngOnDestroy(): void {
    $('body').removeClass('sidebar-mini');
  }

  obtenerPacientes(event: any) {
    let texto = '';
    texto += event.target.value;
    this.id_paciente = null;
    this.tratamientos = null;

    if ( texto.length >= 3 ) {
      let respuesta = this._pacientesService.obtenerPacientes(texto);
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            if(val.pacientes != null ){
              this.pacientes = val.pacientes;
            }
          }else{
            this.spinner.hide('spnr-s');
            this._router.navigate(['/error']);
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          this._router.navigate(['/error']);
        }
      );

      this.contCPac = 1;

    }else{
      this.pacientes = null;
      this.contCPac = 0;
      this.cambioTratamiento(0);
    }
  }

  obtenerMedicos(){
    let respuesta = this._medicosService.obtenerMedicos();
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.meds != null ){
          this.doctores = val.meds;
        }
      }
    });
  }

  obtenerTratamientos(id_paciente){
    this.spinner.show('spnr-s');
    let respuesta = this._tratamientosService.obtenerTratamientospaciente(id_paciente);
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.tratamientos != null ){
          this.tratamientos = val.tratamientos;
          this.spinner.hide('spnr-s');
        }
      }
    });
  }

  obtenerTratamiento(id_tratamiento){
    this.spinner.show('spnr-s');
    let respuesta = this._tratamientosService.obtenerTratamientopaciente(id_tratamiento);
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          if(val.datos != null ){
            this.tratamiento_paciente = val.datos;
            this.concepto = "Pago Tratamiento: "+this.tratamiento_paciente.descripcion;
            $( '#contenedor_tratamiento' ).slideDown( 'slow' );
          }else{
            $('#datos_tratamiento').html('<p class="h3" style="margin-top: 0px;">Tratamiento sin adeudos.</p>');
          }
        }else{
          this.spinner.hide('spnr-s');
          this.mostrarError(val.message);
        }
      },
      error => {
        this.spinner.hide('spnr-s');
        this.mostrarError("Ha ocurrido un problema al cargar los datos");
      }
    );
  }

  obtenerProcedimientos(id_medico){
    this.spinner.show('spnr-s');
    let respuesta = this._tratamientosService.obtenerProcedimientos(id_medico);
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.procedimientos != null ){
          this.procedimientos = val.procedimientos;
          this.spinner.hide('spnr-s');
        }
      }
    });
  }

  displayFn(elemento): string {
    return elemento ? elemento.texto : elemento;
  }

  quitarSeleccion(tipo){

    switch(tipo){
      case 'tratamiento':
        this.id_tratamiento = null;
        break;
      case 'medico':
        this.id_doctor = null;
        this.medico = null;
        this.procedimientos = null;
        this.procedimiento = null;
        this.id_procedimiento = null;
        break;
      case 'procedimiento':
        this.procedimiento = null;
        this.id_procedimiento = null;
        break;
    }
  }

  seleccionPaciente(elemento){
    let id_paciente = parseInt( elemento.option.value.id );
    this.id_paciente = id_paciente;
    this.obtenerTratamientos(this.id_paciente);
  }

  cambioTratamiento(id_tratamiento){
    if(id_tratamiento == 0){
      this.quitarSeleccion('tratamiento');
      this.tratamiento_paciente = null;
      $( "#sin_tratamiento" ).slideDown( "slow" );
      $("#contratamiento").addClass("col-md-6");
      $("#contratamiento").removeClass("col-md-8");
      $( '#contenedor_tratamiento' ).slideUp( 'slow' );
      this.concepto = "";
    }else{
      $( "#con_tratamiento" ).slideDown( "slow" );
      $( "#sin_tratamiento" ).slideUp( "slow" );
      $("#contratamiento").addClass("col-md-8");
      $("#contratamiento").removeClass("col-md-6");
      this.id_tratamiento = id_tratamiento;
      this.obtenerTratamiento(id_tratamiento);
    }
  }

  cambioMedico(id_medico){
    this.tratamiento_paciente = null;
    if(id_medico == 0){
      $("#sintratamiento").addClass("col-md-6");
      $("#sintratamiento").removeClass("col-md-8");
      $("#con_tratamiento").slideDown( "slow" );
      this.procedimiento = null;
      this.id_doctor = null;
      this.medico = null;
      this.quitarSeleccion('medico');
    }else{
      $("#sin_tratamiento").slideDown( "slow" );
      $("#con_tratamiento").slideUp( "slow" );
      $("#sintratamiento").removeClass("col-md-6");
      $("#sintratamiento").addClass("col-md-8");
      this.tratamientos == null;
      this.id_doctor = id_medico;
      let docs = this.doctores;
      for( let i=0; i < docs.length; i++){
        if( docs[i].id == id_medico ){
          this.medico = docs[i];
          break;
        }
      }
      this.obtenerProcedimientos(id_medico);
    }
  }

  cambioProcedimiento(id_tratamiento){
    if(id_tratamiento == 0){
      this.procedimiento = null;
      this.quitarSeleccion('procedimiento');
    }else{
      let proc = this.procedimientos;
      for( let i=0; i < proc.length; i++){
        if( proc[i].id == id_tratamiento ){
          this.procedimiento = proc[i];
          this.monto = this.procedimiento['precio'];
          let concepto =  this.procedimiento['texto'].split('--');
          this.concepto = concepto[0];
          break;
        }
      }
    }
  }

  mostrarError(mensaje){
    Swal.fire({
      title: 'Oopss...',
      text: mensaje,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#1beb11',
    });
  }

  agregarElemento(){

      let fila = 1;
      let concepto = this.concepto;
      let medico = null;
      let id_medico = 0;
      let elementos = 1;
      let precio_unitario = parseFloat(this.monto);
      let total_elemento = precio_unitario * elementos;
      let total_general = "0";
      let pago_tratamiento = "sin";
      let id_paciente_con = null;
      let id_paciente_sin = null;
      let id_tratamiento = this.id_tratamiento;

      if( concepto === "" ){
          Swal.fire({
            title: 'Cuidado',
            text: 'No es posible agregar un elemento sin Concepto.',
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          return;
      }

      if( precio_unitario <= 0  ){
          Swal.fire({
            title: 'Cuidado',
            text: 'No es posible agregar un elemento sin Monto.',
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          return;
      }

      if( id_tratamiento != null ){
          let saldo_restante_tratamieto = this.tratamiento_paciente.adeudo;
          pago_tratamiento = 'con';
          id_paciente_con = this.id_paciente;
          let diferencia = saldo_restante_tratamieto - precio_unitario;
          if( diferencia < 0 ){
              Swal.fire({
                title: 'Cuidado',
                text: 'El monto ingresado supera el Adeudo.',
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
              return;
          }
      }else{
          pago_tratamiento = 'sin';
          id_paciente_sin = this.id_paciente;
          let medico_seleccionado = this.medico;
          if ( medico_seleccionado != null ){
              medico = medico_seleccionado.texto;
              id_medico =  medico_seleccionado.id;
          }
      }

      let cantidad_lista = 0;
      let lista_elem = this.lista_elementos;

      if( lista_elem == null ){
        lista_elem = [];
      }else{
        cantidad_lista = lista_elem.length;
        if( cantidad_lista > 0 ){
            let id_ultimo_elemento = lista_elem[ cantidad_lista - 1]['list_fila'];
            fila = id_ultimo_elemento + 1;
        }
      }

      let nuevo_elemento =   {
          list_fila: fila,
          list_medico: id_medico,
          list_pago_tratamiento: pago_tratamiento,
          list_id_paciente_con: id_paciente_con,
          list_id_paciente_sin: id_paciente_sin,
          list_tratamiento: id_tratamiento,
          list_concepto: concepto,
          list_precio_unitario: precio_unitario,
          list_elementos: elementos,
          list_total_elementos: total_elemento
      };

      lista_elem.push(nuevo_elemento);

      this.lista_elementos = lista_elem;

      let nueva_f = {
        fila: fila,
        medico: medico,
        concepto: concepto,
        tratamiento: id_tratamiento,
        precio_unitario: precio_unitario,
        elementos: elementos,
        total_elemento: total_elemento,
        total_elementos: total_elemento
      };
      let lista_tbl = this.lista_tabla
      if( lista_tbl == null ){ lista_tbl = []; }
      lista_tbl.push(nueva_f);
      this.lista_tabla = lista_tbl;

      this.calcularTotal();
      this.cambioMedico(0);
      this.cambioTratamiento(0);

      this.concepto = "";
      this.monto = ""
  }

  calcularTotal() {
    let list_elem = this.lista_elementos;
    let total_movimiento = 0;
    $.each(list_elem, function(index, value) {
        total_movimiento = total_movimiento + value['list_total_elementos'];
    });
    const formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2
    })
    this.total = formatter.format(total_movimiento);
  }

  eliminarElemento( fila ) {

    let id_fila = fila;
    let lista_elem = this.lista_elementos;
    let lista_tnl = this.lista_tabla;
    let lista_elementos_clon = [];
    let lista_tnl_clon = [];

    $.each(lista_elem, function(index, value) {
        fila = value['list_fila'];
        if( fila != id_fila ){
          lista_elementos_clon.push(value);
        }
    });

    $.each(lista_tnl, function(index, value) {
        fila = value['fila'];
        if( fila != id_fila ){
          lista_tnl_clon.push(value);
        }
    });
    this.lista_elementos = lista_elementos_clon;
    this.lista_tabla = lista_tnl_clon;
    this.calcularTotal();

  }

  restarCant(fila){
    let id_fila = fila;
    let lista_elem = this.lista_elementos;
    let lista_tnl = this.lista_tabla;
    let lista_elementos_clon = [];
    let lista_tnl_clon = [];

    $.each(lista_elem, function(index, value) {
        fila = value['list_fila'];
        if( fila == id_fila ){
          let precio_e = value['list_precio_unitario'];
          let cantidad_e = value['list_elementos'];

          if(cantidad_e-1 > 0){
            value['list_elementos'] = cantidad_e - 1;
            value['list_total_elementos'] = value['list_elementos'] * precio_e;
          }else{
            Swal.fire({
              title: 'Cuidado',
              text: 'No es posible quitar mas elementos. El minimo es 1.',
              icon: 'info',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }

        }
        lista_elementos_clon.push(value);
    });

    $.each(lista_tnl, function(index, value) {
      fila = value['fila'];
      if( fila == id_fila ){
        let precio_e = value['precio_unitario'];
        let cantidad_e = value['elementos'];
        if(cantidad_e-1 > 0){
          value['elementos'] = cantidad_e - 1;
          value['total_elementos'] = value['elementos'] * precio_e;
        }else{
          Swal.fire({
            title: 'Cuidado',
            text: 'No es posible quitar mas elementos. El minimo es 1.',
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      }
      lista_tnl_clon.push(value);
    });

    this.lista_elementos = lista_elementos_clon;
    this.lista_tabla = lista_tnl_clon;
    this.calcularTotal();
  }

  sumarCant(fila){
    let id_fila = fila;
    let lista_elem = this.lista_elementos;
    let lista_tnl = this.lista_tabla;
    let lista_elementos_clon = [];
    let lista_tnl_clon = [];

    $.each(lista_elem, function(index, value) {
        fila = value['list_fila'];
        if( fila == id_fila ){
          let precio_e = value['list_precio_unitario'];
          let cantidad_e = value['list_elementos'];
          value['list_elementos'] = cantidad_e + 1;
          value['list_total_elementos'] = value['list_elementos'] * precio_e;
        }
        lista_elementos_clon.push(value);
    });

    $.each(lista_tnl, function(index, value) {
      fila = value['fila'];
      if( fila == id_fila ){
        let precio_e = value['precio_unitario'];
        let cantidad_e = value['elementos'];
        value['elementos'] = cantidad_e + 1;
        value['total_elementos'] = value['elementos'] * precio_e;
      }
      lista_tnl_clon.push(value);
    });

    this.lista_elementos = lista_elementos_clon;
    this.lista_tabla = lista_tnl_clon;
    this.calcularTotal();
  }

  registrarMovimientos(){

      $("#registrarMovimiento").addClass('disabled');

      let monto = this.total;
      let lista_elementos = this.lista_elementos;

      if(lista_elementos != null){
          if( lista_elementos.length > 0 ){

            Swal.fire({
              title: "Se registrará un movimiento de Entrada por: " + monto,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',

            }).then((result) => {
              if (result.isConfirmed) {

                this.spinner.show('spnr-s');

                let data = {
                    tarjeta: (this.tarjeta)?1:0,
                    elementos: lista_elementos
                };

                let respuesta = this._cajaService.registrarEntrada( data );
                respuesta.then( val =>{
                  if( val.status == "success" ){

                    this.spinner.hide('spnr-s');
                    Swal.fire({
                      title: "Registrado",
                      icon: 'success',
                      showCancelButton: false,
                      confirmButtonColor: '#1beb11',
                    });

                    let estado_ticket = val.ticket.ticket;
                    let ticket_id = val.ticket.ticket_id;
                    let tipo = val.ticket.tipo;

                    this.imprimirTicket(ticket_id);

                  }else{

                    this.spinner.hide('spnr-s');
                    Swal.fire({
                      title: 'No se ha registrado.',
                      text: val.message,
                      icon: 'warning',
                      showCancelButton: false,
                      confirmButtonColor: '#1beb11',
                    });
                    $("#registrarMovimiento").removeClass('disabled');

                  }

                });
              }
            })

          }else{
              $("#registrarMovimiento").removeClass('disabled');
              Swal.fire({
                title: 'Faltan Elementos',
                text: 'Es necesario agregar por lo menos un elemento.',
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
          }
      }else{
          $("#registrarMovimiento").removeClass('disabled');
          Swal.fire({
            title: 'Faltan Elementos',
            text: 'Es necesario agregar por lo menos un elemento.',
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
      }
  }

  imprimirTicket(id_ticket){
    var ancho = 8 / 2.54 * 72
    let respuesta = this._cajaService.obtenerTicket( id_ticket );
    respuesta.then(
      val =>{
        if( val.status == "success" ){

          var html = htmlToPdfmake(val.ticket, {tableAutoSize:true});
          const documentDefinition = {
            pageSize: {
              width: ancho,
              height: 'auto'
            },
            pageMargins: [12, 12, 12, 12 ],
            content: html
          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }
          this._router.navigate(['/caja/movimientos']);

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Ticket.',
            text: 'Por favor intentalo desde el listado de movimientos.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this._router.navigate(['/caja/movimientos']);
        }

      },
      error =>{
        this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Ticket.',
            text: 'Por favor intentalo desde el listado de movimientos.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this._router.navigate(['/caja/movimientos']);
      }
    );
  }

}
