import { Component } from '@angular/core';

@Component({
  selector: 'clinica',
  templateUrl: './clinica.component.html',
})

export class ClinicaComponent {

  constructor(

  ){

  }

  ngOnInit(){

  }

}
