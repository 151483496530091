import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpedienteService } from 'src/app/services/expediente.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'patologicos',
  templateUrl: './patologicos.component.html',
})

export class PatologicosComponent {

  formPatologicos: FormGroup;

  @Input() public id_paciente = null;

  public datos_patologicos = {
    "id_paciente" : "",
    "infeccionas" : "",
    "infancia" : "",
    "traumatologicas" : "",
    "endocrinas" : "",
    "ematologicas" : "",
    "neurologicas" : "",
    "mentales" : "",
    "neoplasias" : "",
    "alergias" : "",
    "quirurgicas" : "",
    "transfusiones" : "",
    "cardiovasculares" : "",
    "reumatologicas" : "",
    "respiratorias" : ""
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _expedienteService: ExpedienteService,
  ){

  }

  ngOnInit(){
    this.colocarValores();
    this.obtenerPatologicos();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  colocarValores( patologicos = null ){
    let datos = null;
    if( patologicos != null ){
      datos = patologicos;
    }else{
      datos = this.datos_patologicos;
      datos.id_paciente = this.id_paciente;
    }

    this.formPatologicos = this._bilder.group({
      id_paciente : [datos.id_paciente],
      infeccionas : [ datos.infeccionas ],
      infancia : [ datos.infancia ],
      traumatologicas : [ datos.traumatologicas ],
      endocrinas : [ datos.endocrinas ],
      ematologicas : [ datos.ematologicas ],
      neurologicas : [ datos.neurologicas ],
      mentales : [ datos.mentales ],
      neoplasias : [ datos.neoplasias ],
      alergias : [ datos.alergias ],
      quirurgicas : [ datos.quirurgicas ],
      transfusiones : [ datos.transfusiones ],
      cardiovasculares : [ datos.cardiovasculares ],
      reumatologicas : [ datos.reumatologicas ],
      respiratorias : [ datos.respiratorias ]
    });
  }

  obtenerPatologicos(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerPatologicos( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            if( val.patologicos != null ){
              this.colocarValores( val.patologicos );
            }
          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  registrarPatologicos( datos ){
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.registrarPatologicos( datos );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Registro Exitoso",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al registrar los datos, por favor intentalo nuevamente.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

}
