  <h1 *ngIf="id_tratamiento == null">Registro de Tratamiento</h1>
  <h1 *ngIf="id_tratamiento != null">Actualización de Tratamiento</h1>

  <div class="container" *ngIf="id_tratamiento == null">
    <div class="row">
      <div class="col-sm">
        <div class="form-group">
          <label class="control-label">MÉDICO</label>
            <mat-select class="form-control" (selectionChange)="cambioDoctor($event.value)">
              <mat-option *ngFor="let doctor of doctores" [value]="doctor.id">{{doctor.texto}}</mat-option>
            </mat-select>
        </div>
      </div>
      <div class="col-sm">
        <div class="form-group">
          <label class="control-label">TRATAMIENTO</label>
            <mat-select class="form-control" (selectionChange)="cambioTratamiento($event.value)">
              <mat-option *ngFor="let tratamiento of tratamientos" [value]="tratamiento.id">{{tratamiento.texto}}</mat-option>
            </mat-select>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="formTratamiento" (ngSubmit)="registrarTratamiento(formTratamiento.value)">

    <div class="container">
      <input type="hidden" formControlName="id_bas_tratamiento">
        <div class="row justify-content-center">

          <div class="col-12 col-sm-6 col-md-5">
            <div class="form-group">
              <label class="control-label">NOMBRE/IDENTIFICADOR</label>
              <input
                type="text"
                id="descripcion"
                class="form-control"
                formControlName="descripcion">
            </div>
          </div>

        </div>

        <div class="row rounded" style='border: 2px solid #6c7edb !important;'>
          <div class="col-12">
            <div class="panel panel-default">
              <div class="panel-body">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4">
                        <h4 class="mt-1"><i class="fas fa-calculator"></i> Presupuesto</h4>
                      </div>
                      <div class="col-12 col-sm-6 col-md-8">
                        <button type="button" class="pull-right btn btn-success btn-xs" (click)="addItem()">
                          <i class="fa fa-plus"></i> Agregar Detalles
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="panel-body container-items">
                    <div
                    formArrayName="detalle_tratamiento"
                    *ngFor="let item of formTratamiento.get('detalle_tratamiento')['controls']; let i = index;">
                      <div [formGroupName]="i">
                        <div class="row">
                            <input type="hidden" formControlName="id_bas_dettratamientos">
                            <div class="col-sm-12 col-md-5">
                              <label>Descripción</label>
                              <input formControlName="descripcion" class="form-control" >
                            </div>
                            <div class="col-sm-8 col-md-3">
                              <label>Monto</label>
                              <input formControlName="monto" class="form-control monto" (change)="sumar();">
                            </div>
                            <div class="col-sm-2 col-md-2 text-center">
                              <label>Servicio Técnico</label>
                              <div class="form-group">
                                <mat-slide-toggle
                                  formControlName="tecnico"
                                  color="primary">
                                </mat-slide-toggle>
                              </div>
                            </div>
                            <div class="col-sm-2 col-md-2 text-center">
                              <button type="button" class="btn btn-danger btn-xs mt-4" (click)="deleteItem(i)">
                                <i class="fa fa-minus"></i>
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 col-sm-5 pt-2">
            <h3><b>Total:</b>${{total}}</h3>
          </div>
          <div class="col-12 col-sm-7">
            <div class="form-group">
              <button
                [disabled]="formTratamiento.invalid"
                type="submit"
                class="btn btn-success btn-block">
                Registrar
              </button>
            </div>
          </div>
        </div>

    </div>

  </form>
