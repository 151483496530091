import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpedienteService } from 'src/app/services/expediente.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'triage',
  templateUrl: './triage.component.html',
})

export class TriageComponent {

  formTriage: FormGroup;
  @Input() public id_paciente = null;
  public color_resultado = '#5fd367';

  public datos_triage = {
    "id_paciente" : "",
    "temperatura" : "",
    "fiebre" : 0,
    "respiratoria" : 0,
    "viaje" : 0,
    "contacto" : 0,
    "estrecho" : 0,
    "tos" : 0,
    "fiebre2" : 0,
    "cefalea" : 0,
    "disena" : 0,
    "artralgias" : 0,
    "mialgias" : 0,
    "odinofagia" : 0,
    "rinorrea" : 0,
    "conjuntivitis" : 0,
    "toraico" : 0,
    "olfato" : 0,
    "resultado" : ""
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _expedienteService: ExpedienteService,
  ){

  }

  ngOnInit(){
    this.colocarValores();
    this.obtenerTriage();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  colocarValores( triage = null ){

    let datos = null;
    if( triage != null ){
      datos = triage;
    }else{
      datos = this.datos_triage;
      datos.id_paciente = this.id_paciente;
    }

    this.formTriage = this._bilder.group({
      id_paciente : [ datos.id_paciente ],
      temperatura : [ datos.temperatura ],
      fiebre : [ datos.fiebre ],
      respiratoria : [ datos.respiratoria ],
      viaje : [ datos.viaje ],
      contacto : [ datos.contacto ],
      estrecho : [ datos.estrecho ],
      tos : [ datos.tos ],
      fiebre2 : [ datos.fiebre2 ],
      cefalea : [ datos.cefalea ],
      disena : [ datos.disena ],
      artralgias : [ datos.artralgias ],
      mialgias : [ datos.mialgias ],
      odinofagia : [ datos.odinofagia ],
      rinorrea : [ datos.rinorrea ],
      conjuntivitis : [ datos.conjuntivitis ],
      toraico : [ datos.toraico ],
      olfato : [ datos.olfato ],
      resultado : [ datos.resultado ]
    });
    this.validaciones( );
  }

  obtenerTriage(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerTriage( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            if( val.triage != null ){
              //this.datos_triage = val.triage;
              this.colocarValores( val.triage );
            }
          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  validaciones( ){

    let temperatura =  this.formTriage.controls.temperatura.value;
    let fiebre =  this.formTriage.controls.fiebre.value;
    let respiratoria =  this.formTriage.controls.respiratoria.value;
    let viaje =  this.formTriage.controls.viaje.value;
    let contacto =  this.formTriage.controls.contacto.value;
    let estrecho =  this.formTriage.controls.estrecho.value;
    let tos =  this.formTriage.controls.tos.value;
    let fiebre2 =  this.formTriage.controls.fiebre2.value;
    let cefalea =  this.formTriage.controls.cefalea.value;
    let disena =  this.formTriage.controls.disena.value;
    let artralgias =  this.formTriage.controls.artralgias.value;
    let mialgias =  this.formTriage.controls.mialgias.value;
    let odinofagia =  this.formTriage.controls.odinofagia.value;
    let rinorrea =  this.formTriage.controls.rinorrea.value;
    let conjuntivitis =  this.formTriage.controls.conjuntivitis.value;
    let toraico =  this.formTriage.controls.toraico.value;
    let olfato =  this.formTriage.controls.olfato.value;

    let resultado = "Verde";
    let puntaje = 0;
    let puntaje2 = 0;
    let puntaje3 = 0;
    let puntaje4 = 0;

    if( parseFloat(temperatura) >= 37.5 ){
        puntaje = 2;
    }
    if( parseFloat(temperatura) >= 37 &&  parseFloat(temperatura) <= 37.4 ){
        puntaje = 1; //amarillo
    }
    if( parseFloat(temperatura) <= 36.9 ){
        puntaje = 0;
    }

    if( fiebre == 1 ){ puntaje2 += 2; }
    if( respiratoria == 1){ puntaje2 += 2; } //roja
    if( viaje == 1){ puntaje2 += 1; }
    if( contacto == 1){ puntaje2 += 2; } //rojo
    if( estrecho == 1){ puntaje2 += 1; }

    if( tos == 1){ puntaje3 += 1; }
    if( fiebre2 == 1){ puntaje3 += 1; }
    if( cefalea == 1){ puntaje3 += 1; }

    if( disena == 1){ puntaje4 += 1; }
    if( artralgias == 1){ puntaje4 += 1; }
    if( mialgias == 1){ puntaje4 += 1; }
    if( odinofagia == 1){ puntaje4 += 1; }
    if( rinorrea == 1){ puntaje4 += 1; }
    if( conjuntivitis == 1){ puntaje4 += 1; }
    if( toraico == 1){ puntaje4 += 1; }
    if( olfato == 1){ puntaje4 += 1; }

    if( puntaje == 2 ){
        resultado = 'Rojo';
    }else{
        if (puntaje == 0 ){
            resultado = 'Verde';
        }else{
            resultado = 'Amarillo';
        }

        if( puntaje2 >= 2 ){
            resultado = 'Rojo';
        }else{
            resultado = 'Amarillo';
        }

        if( resultado == 'Amarillo' || resultado == 'Verde'){
            if( puntaje3 == 1 ){
                resultado = 'Amarillo';
                if( puntaje4 > 0 ){
                    resultado = 'Rojo';
                }
            }else{
                if(puntaje3 > 1){
                    resultado = 'Rojo';
                }

                if(puntaje3 == 0 && (puntaje == 1 || puntaje2 == 1) ){
                    if( puntaje4 == 1  ){
                        resultado = 'Amarillo';
                    }
                    if( puntaje4 >= 2  ){
                        resultado = 'Rojo';
                    }
                    if( puntaje4 == 0  ){
                        resultado = 'Amarillo';
                    }
                }

                if(puntaje3 == 0 && puntaje == 0 && puntaje2 == 0){
                    if( puntaje4 == 1  ){
                        resultado = 'Amarillo';
                    }
                    if( puntaje4 >= 2  ){
                        resultado = 'Rojo';
                    }
                    if( puntaje4 == 0  ){
                        resultado = 'Verde';
                    }
                }
            }
        }

    }

    //console.log( resultado );
    switch( resultado ){
        case "Verde":
            resultado += ': La valoración es favorable, el médico puede atenderlo.';
            this.color_resultado = "#5fd367";
            break;
        case "Amarillo":
            resultado += ': Es posible su atención de acuerdo a la valoración del médico.';
            this.color_resultado = "#d3e226";
            break;
        case "Rojo":
            resultado += ': No es posible su atención. Si lo creen necesario, el médico deberá hacer valoración.';
            this.color_resultado = "#dd887b";
            break;
    }

    this.formTriage.controls['resultado'].setValue(resultado);

  }

  registrarTriage( datos ){
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.registrarTriage( datos );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Registro Exitoso",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al registrar los datos, por favor intentalo nuevamente.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

}
