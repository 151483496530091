import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'paciente-actualizar',
  templateUrl: './paciente-actualizar.component.html',
})

export class PacienteactualizarComponent {

  public id_paciente = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService
  ){

  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_paciente = params['id'];
    });
  }

}
