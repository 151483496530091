<div class="row justify-content-center" id="vencimiento" *ngIf="validacion != null">
  <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="validacion.estado == 1 && validacion.fecha_vencimiento != null">
      <div class="card">
          <div class="card-header text-center">
              <h4 class="card-subtitle mb-2 text-muted">Vencimiento del Servicio</h4>
          </div>
          <div class="card-body text-center">
              <div class="alert alert-warning" role="alert">
                  Su servicio vencio/vencerá el dia: <b>{{validacion.fecha_vencimiento}}</b> y será suspendido el día <b>{{validacion.dia_corte}}</b><br>
                  Le recomendamos mantener al corriente sus pagos para evitar la pausa de su servicio.
              </div>
          </div>
      </div>
  </div>

  <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="validacion.estado == 11">
      <div class="card">
          <div class="card-header text-center">
              <h4 class="card-subtitle mb-2 text-muted">Servicio en Proceso de Activación</h4>
          </div>
          <div class="card-body text-center">
              <div class="alert alert-warning" role="alert">
                  Su servicio se encuentra en proceso de activación, se le notificará por medio telefónico una vez
                  se encuentre listo el servicio.
              </div>
          </div>
      </div>
  </div>

  <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="validacion.estado == 4 || validacion.estado == 0 ">
    <div class="card">
        <div class="card-header text-center">
            <h4 class="card-subtitle mb-2 text-muted">Servicio Inactivo</h4>
        </div>
        <div class="card-body text-center">
            <div class="alert alert-warning" role="alert">
                Su servicio se encuentra inactivo, si necesita ayuda por favor contáctenos por medio de nuetras redes sociales o a los teléfonos de contacto.
            </div>
        </div>
    </div>
</div>

</div>
