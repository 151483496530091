<div class="row">
  <div class="col-12 col-sm-6">
    <h1>Usuarios/Personal</h1>
  </div>
  <div class="col-12 col-sm-6">
    <div class="pull-right">
      <button
        *ngIf=" cuenta_usuarios < limite_usuarios "
        type="button"
        class="btn btn-success"
        (click)="registrarUsuario()">
        <span class="glyphicon glyphicon-copy icono"></span>
        Nuevo Usuario
      </button>
      <div class="alert alert-warning" role="alert" *ngIf=" cuenta_usuarios >= limite_usuarios ">
        Ha registrado el número máximo de usuarios.
      </div>
    </div>
  </div>
</div>


<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc">Nombre</th>
      <th class="disabled-sorting sorting_desc">Teléfono</th>
      <th class="disabled-sorting sorting_desc">Dirección</th>
      <th class="disabled-sorting sorting_desc text-center" data-priority="1">Acciones</th>
      <th class="disabled-sorting sorting_desc text-center" data-priority="2">Eliminar</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let usuario of usuarios">
      <td>{{ usuario.nombre }}</td>
      <td>{{ usuario.tel }}</td>
      <td>{{ usuario.direccion }}</td>

      <td class="text-center">
        <a class="btn btn-info btn-icon btn-sm mr-1" [routerLink]="['/usuario', usuario.id ]" title="Ver" aria-label="Ver" data-pjax="0">
          <i class="far fa-eye"></i>
        </a>
        <a class="btn btn-warning btn-icon btn-sm mr-1" [routerLink]="['/usuario/actualizar/', usuario.id ]" title="Actualizar" aria-label="Actualizar" data-pjax="0">
          <i class="far fa-edit"></i>
        </a>
      </td>

      <td class="text-center">
        <button
          *ngIf="identity.tipo == 'Administrativo' || identity.tipo == 'Soporte Técnico'"
          class="btn btn-danger btn-icon btn-sm"
          (click)="eliminarUsuario(usuario.id)"
          title="Deshabilitar"
          aria-label="Deshabilitar">
          <i class="fa fa-times"></i>
        </button>
      </td>

    </tr>
  </tbody>
</table>

<ng-template #contentModal let-c="close" let-d="dismiss">
</ng-template>
