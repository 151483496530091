<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="modal-title" id="modal-primary-title">Registro/Edición de Paciente</h3>
</div>

<div class="modal-body centered formulario">
  <paciente-registro [cModal]=true [id_paciente]="id_paciente" [nuevoPaciente]="nuevoPaciente" [activeModal]="activeModal"></paciente-registro>
</div>
