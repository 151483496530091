<div class="container">
  <div class="row">
    <div class="col-6">
      <h1 *ngIf="datos_usuario.persona.id_persona == ''">
        Registrar Usuarios
      </h1>
      <h1 *ngIf="datos_usuario.persona.id_persona != ''">
        Actualizar Usuario
      </h1>
    </div>
  </div>
</div>

<form [formGroup]="formUsuario" (ngSubmit)="registrarUsuario(formUsuario.value)">

  <div class="container">
      <input type="hidden" formControlName="id_persona">
      <div class="row">
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="nombre">NOMBRE</label>
              <input
                type="text"
                id="nombre"
                class="form-control"
                formControlName="nombre">
            </div>
          </div>
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="ape_pat">A PATERNO</label>
              <input
                type="text"
                id="ape_pat"
                class="form-control"
                formControlName="ape_pat">
            </div>
          </div>
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="ape_mat">A MATERNO</label>
              <input
                type="text"
                id="ape_mat"
                class="form-control"
                formControlName="ape_mat">
            </div>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <label class="control-label" for="tel">TELEFONO</label>
              <input
                type="tel"
                id="tel"
                class="form-control"
                formControlName="tel">
            </div>
          </div>
          <div class="col-sm-12 col-md-8">
            <div class="form-group">
              <label class="control-label" for="domicilio">DOMICILIO</label>
              <input
                type="text"
                id="direccion"
                class="form-control"
                formControlName="direccion">
            </div>
          </div>
      </div>
      <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="control-label" for="correo">CORREO ELECTRONICO</label>
              <input
                type="text"
                id="correo"
                class="form-control"
                formControlName="correo">
            </div>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-12 col-md">
            <div class="form-group field-estado_civil">
              <label class="control-label" for="estado_civil">ESTADO CIVIL</label>
              <select
                formControlName="estado_civil"
                id="estado_civil"
                class="form-control"
                title="Estado Civil"
                data-style="btn btn-info btn-round">

                <option value="Soltero(a)">Soltero(a)</option>
                <option value="Casado(a)">Casado(a)</option>
                <option value="Comprometido(a)">Comprometido(a)</option>
                <option value="Divorciado(a)">Divorciado(a)</option>
                <option value="Viudo(a)">Viudo(a)</option>

              </select>
              <div class="help-block"></div>
            </div>
          </div>
          <div class="col-sm-12 col-md">
            <div class="form-group field-tipo_sangre">
              <label class="control-label" for="tipo_sangre">TIPO DE SANGRE</label>
              <select
                formControlName="tipo_sangre"
                id="tipo_sangre"
                class="form-control"
                title="Tipo de Sangre"
                data-style="btn btn-info btn-round">

                <option value="O-P">O+</option>
                <option value="O-N">O-</option>
                <option value="A-P">A+</option>
                <option value="A-N">A-</option>
                <option value="B-P">B+</option>
                <option value="B-N">B-</option>
                <option value="AB-P">AB+</option>
                <option value="AB-N">AB-</option>

              </select>
              <div class="help-block"></div>
            </div>
          </div>
          <div class="col-sm-12 col-md" *ngIf="identity.tipo == 'Administrativo' || identity.tipo == 'Soporte Técnico'">

            <div class="form-group field-tipo">
              <label class="control-label" for="tipo">TIPO USUARIO</label>
              <select
                formControlName="tipo"
                id="tipo"
                class="form-control"
                title="Tipo Usuario"
                data-style="btn btn-info btn-round">

                <option value="Administrativo">Administrativo</option>
                <option value="Médico">Médico</option>
                <option value="Asistente">Asistente</option>
                <option value="Interno">Interno</option>
                <option value="Recepcionista">Recepcionista</option>
                <option value="Empleado">Empleado</option>

              </select>

              <div class="help-block"></div>
            </div>

          </div>
          <div class="col-sm-12 col-md text-center" *ngIf="identity.tipo == 'Administrativo' || identity.tipo == 'Soporte Técnico'">

            <div class="form-group">
              <label class="control-label" for="medico">MEDICO</label>
              <div class="form-group">
                <mat-slide-toggle
                  formControlName="medico"
                  color="primary"
                  (change)="estadoMedico($event)"
                >
                </mat-slide-toggle>
                <div class="help-block"></div>
              </div>
            </div>

          </div>
      </div>
      <div class="mt-5" id="datosBasEstudios" [style.display]=" estado_medico ? '' : 'none'">
        <h3 class="text-center">Datos del Médico</h3>
        <div class="row">
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="nombre_estudio">NOMBRE ESTUDIO</label>
              <input
                type="text"
                id="nombre_estudio"
                class="form-control"
                formControlName="nombre_estudio">
            </div>
          </div>
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="tipo_estudio">TIPO DE ESTUDIO</label>
              <input
                type="text"
                id="tipo_estudio"
                class="form-control"
                formControlName="tipo_estudio">
            </div>
          </div>
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="cedula">CEDULA PROF</label>
              <input
                type="text"
                id="cedula"
                class="form-control"
                formControlName="cedula">
            </div>
          </div>
        </div>
        <div class="row justify-content-center" *ngIf="identity.tipo == 'Administrativo' || identity.tipo == 'Soporte Técnico'">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="control-label" for="comision">COMISIÓN</label>
              <input
                type="text"
                id="comision"
                class="form-control"
                formControlName="comision"
                readonly>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label class="control-label" for="estado_comision">ACTIVAR COMISIÓN</label>
              <div class="form-group">
                <mat-slide-toggle
                  formControlName="estado_comision"
                  color="primary"
                >
                </mat-slide-toggle>
                <div class="help-block"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center" *ngIf="identity.tipo == 'Administrativo' || identity.tipo == 'Soporte Técnico'">
          <div class="col-12 col-sm-8 col-md-6">
            <mat-slider
                (input)="onInputChange($event)"
                style="width: 100%;"
                [invert]="false"
                [max]="100"
                [min]="0"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="tickInterval"
                [vertical]="false"
                [value]="datos_usuario.doctor.comision">
            </mat-slider>
          </div>
        </div>
      </div>
      <div class="mt-5"  *ngIf="identity.tipo == 'Administrativo' || identity.tipo == 'Soporte Técnico' || identity.id_persona == datos_usuario.persona.id_persona">
        <h3 class="text-center">Datos de Usuario</h3>
        <div class="alert alert-warning" role="alert" *ngIf="identity.id_persona == datos_usuario.persona.id_persona">
            Al momento de hacer efectivo el cambio de <b> USUARIO </b> o <b> CONTRASEÑA </b> se cerrará atuomaticamente la sesión actual.
        </div>
        <div class="row">
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="login">USUARIO</label>
              <input
                type="text"
                id="login"
                class="form-control"
                formControlName="login">
            </div>
          </div>
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="pass">CONTRASEÑA</label>
              <input
                type="password"
                id="pass"
                class="form-control"
                formControlName="pass">
            </div>
          </div>
          <div class="col-sm-12 col-md" *ngIf="identity.tipo == 'Administrativo' || identity.tipo == 'Soporte Técnico'">

            <div class="form-group field-tipo">
              <label class="control-label" for="tipo">CLINICA</label>
              <select
                formControlName="id_clinica"
                id="id_clinica"
                class="form-control"
                title="Clinica"
                data-style="btn btn-info btn-round">

                <option *ngFor="let clinica of clinicas" value="{{clinica.id}}">{{clinica.texto}}</option>

              </select>

              <div class="help-block"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
          <div class="col-sm-7">
              <div class="form-group">
                <button
                  [disabled]="formUsuario.invalid"
                  type="submit"
                  class="btn btn-success btn-block">
                  Registrar
                </button>
              </div>
          </div>
      </div>

  </div>

</form>
