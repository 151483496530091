import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CajaService } from 'src/app/services/caja.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'caja-apertura',
  templateUrl: './caja-apertura.component.html',
})

export class CajaaperturaComponent {

  public concepto = "";
  public monto = "0.00";

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService,
  ){

  }

  ngOnInit(){
    this.spinner.show('spnr-s');
    this.concepto = "APERTURA DE CAJA: "+this.obtenerFecha( );
    let respuesta = this._cajaService.validarApertura( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          if(val.apertura == 1){
            this._router.navigate(['/caja']);
          }
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        this._router.navigate(['/error']);
      }
    );
  }

  obtenerFecha( ){
    var d = new Date();

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-') ;
  }

  registrarApertura(){

    Swal.fire({
      title: "La apertura de caja será con un monto de: $" + this.monto,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let datos = {
          monto: this.monto,
          concepto: this.concepto
        };

        let respuesta = this._cajaService.registrarApertura( datos );
        respuesta.then(
          val =>{
            if( val.status == "success" ){

              this.spinner.hide('spnr-s');
              Swal.fire({
                title: "Registrado",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
              this._router.navigate(['/caja']);

            }else{

              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'No se ha registrado.',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
              this._router.navigate(['/caja']);
            }

          },
          error => {
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'No se ha registrado.',
              text: 'Ha ocurrido un problema, por favor vuelve a intentarlo.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
            this._router.navigate(['/caja']);
          }
        );

      }
    })

  }

}
