<div class="modal-header">
  <h5 class="modal-title" id="modal-primary-title">Registrar/Actualizar Clínica</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body centered formulario">
  <form [formGroup]="formClinica" (ngSubmit)="registrarClinica(formClinica.value)">

    <div class="container">
        <input type="hidden" formControlName="id_clinica">

        <div class="row">
            <div class="col-sm-12 col-md">
              <div class="form-group">
                <label class="control-label" for="razon_social">RAZON SOCIAL</label>
                <input
                  type="text"
                  id="razon_social"
                  class="form-control"
                  formControlName="razon_social">
              </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md">
              <div class="form-group">
                <label class="control-label" for="rfc">RFC</label>
                <input
                  type="text"
                  id="rfc"
                  class="form-control"
                  formControlName="rfc">
              </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md">
              <div class="form-group">
                <label class="control-label" for="direccion">DIRECCION</label>
                <input
                  type="text"
                  id="direccion"
                  class="form-control"
                  formControlName="direccion">
              </div>
            </div>
        </div>

        <div class="row">
          <div class="col-6 col-sm-6">
            <div class="form-group">
                <label class="control-label" for="id_estado">ESTADO</label>
                <select
                  #id_estado
                  (change)='cambioEstado(id_estado.value)'
                  formControlName="id_estado"
                  id="id_estado"
                  class="form-control"
                  title="Estado"
                  data-style="btn btn-info btn-round">

                  <option *ngFor="let estado of estados" value="{{estado.id}}">{{estado.texto}}</option>

                </select>

                <div class="help-block"></div>
            </div>
          </div>
          <div class="col-6 col-sm-6">
            <div class="form-group">
              <label class="control-label" for="id_municipio">MUNICIPIO</label>
              <select
                formControlName="id_municipio"
                id="id_municipio"
                class="form-control"
                title="Municipio"
                data-style="btn btn-info btn-round">

                <option *ngFor="let municipio of municipios" value="{{municipio.id}}">{{municipio.texto}}</option>

              </select>

              <div class="help-block"></div>
          </div>
          </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md">
              <div class="form-group">
                <label class="control-label" for="telefono">TELEFONO</label>
                <input
                  type="text"
                  id="telefono"
                  class="form-control"
                  formControlName="telefono">
              </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-7">
                <div class="form-group">
                  <button
                    [disabled]="formClinica.invalid"
                    type="submit"
                    class="btn btn-success btn-block">
                    Registrar
                  </button>
                </div>
            </div>
        </div>

    </div>

  </form>
</div>
