<div *ngIf="cita">
  <div class="pull-right">

      <div *ngIf="cita.id_paciente != 0" >

        <button
          type="button"
          class="btn"
          (click)="anotacion(cita.id_cita)">
          <span class="fas fa-comment-alt icono"></span>
          Anotaciones
        </button>

        <button
          *ngIf=" cita_pasada == 0 && permisos.u "
          type="button"
          class="btn btn-primary"
          (click)="editarCita(cita.id_cita)">
          <span class="fas fa-pen icono"></span>
          Editar
        </button>

        <button
          *ngIf="cita.status == 'Por Atender' && cita_pasada == 0 && permisos.d"
          type="button"
          class="btn btn-danger"
          (click)="cancelarCita(cita.id_cita)">
          <span class="fa fa-times icono"></span>
          Cancelar
        </button>

        <span *ngIf="cita.status != 'Inasistencia' " >
          <button
            *ngIf=" (cita_pasada == 1 || cita.status == 'Atendida') && permisos.u "
            type="button"
            class="btn btn-danger"
            (click)="inasistenciaCita(cita.id_cita)">
            <span class="fa fa-times icono"></span>
            No Asistió
          </button>
        </span>

        <a
          class="btn btn-info"
          [routerLink]="['/paciente/expediente/'+cita.id_paciente]">
          <span class="fas fa-file-medical-alt icono"></span>
          Documentos Clinicos
        </a>

      </div>

      <button
        *ngIf="cita.id_paciente == 0 && permisos.d"
        class="btn btn-danger"
        (click)="eliminarDeshabilitado(cita.id_cita)"
        >
        <span class="fa fa-times icono"></span>
        Eliminar
      </button>

  </div>

  <h1>{{ cita.motivo }}</h1>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-8">

        <table class="table border">
          <tbody class="thead-light">

            <tr>
              <th scope="row">FECHA REGISTRO</th>
              <td> {{cita.fecha_registro}} </td>
            </tr>

            <tr>
              <th scope="row">FECHA/HORA PROGRAMADA</th>
              <td> {{cita.fecha_programada_inicial}} </td>
            </tr>

            <tr *ngIf="cita.id_paciente == 0">
              <th scope="row">FECHA/HORA PROGRAMADA FIN</th>
              <td> {{cita.fecha_programada_final}} </td>
            </tr>

            <tr *ngIf="cita.id_paciente != 0" >
              <th scope="row">PACIENTE</th>
              <td> {{cita.paciente}} </td>
            </tr>

            <tr *ngIf="cita.id_paciente != 0" >
              <th scope="row">TELEFONO</th>
              <td> {{cita.telefono}} </td>
            </tr>

            <tr *ngIf="cita.id_paciente != 0" >
              <th scope="row">MEDICO</th>
              <td> {{cita.medico}} </td>
            </tr>

            <tr>
              <th scope="row">REGISTRÓ</th>
              <td> {{cita.registro}} </td>
            </tr>

            <tr  *ngIf="cita.id_paciente != 0" >
              <th scope="row">ESTATUS</th>
              <td> {{cita.status}} </td>
            </tr>

            <tr  *ngIf="cita.status == 'Cancelada' || cita.status == 'Inasistencia'" >
              <th scope="row">CANCELÓ</th>
              <td> {{cita.cancelo}} </td>
            </tr>

            <tr  *ngIf="cita.status == 'Cancelada' || cita.status == 'Inasistencia'" >
              <th scope="row">MOTIVO CANCELACIÓN</th>
              <td> {{cita.motivoc}} </td>
            </tr>


          </tbody>
        </table>

      </div>
    </div>
  </div>

</div>

<ng-template #contentModal let-c="close" let-d="dismiss">
</ng-template>

