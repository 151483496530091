import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UsuarioService } from './services/usuario.service';
import { PusherService } from './services/pusher.service';
import { ConfiguracionesService } from './services/configuraciones.service';
import { config } from 'process';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  title = 'ADClinik';
  public identity;
  public expediente = '0';
  public perfectScroll;
  public config: PerfectScrollbarConfigInterface = {};
  public subIniciada = 0;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _titleService: Title,
    private spinner: NgxSpinnerService,
    public _usuarioService: UsuarioService,
    private _pusherService: PusherService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.identity = this._usuarioService.getIdentity();
    this.expediente = this._usuarioService.getExpediente();
    this.perfectScroll = this.checkWindows();

    this._usuarioService.identity$.subscribe( identity =>{
      this.identity = identity;
      if( this.identity ){
        this.iniciarPusher();
      }
    });

    this._usuarioService.expediente$.subscribe( expediente =>{
      this.expediente = expediente;
    });

    //this.iniciarSW();
  }

  iniciarPusher(){
    let configuracion = this._configuracionesService.getConfig();
    if( configuracion && this.subIniciada == 0){
      this.subIniciada = 1;
      this._pusherService.subscribirCitas( configuracion.dom + configuracion.id_clinica );
      this._pusherService.subscribirChat( configuracion.dom + configuracion.id_clinica );
      this._pusherService.subscribirCerrarsesion( configuracion.dom + this.identity.sub );
    }
  }

  ngOnInit() {
    this.spinner.show('spnr-g');
    // setTimeout(() => {
    //   this.spinner.hide('spnr-g');
    // }, 3000);

    const appTitle = this._titleService.getTitle();
    this._router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this._route.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this._titleService.setTitle(ttl);
      });

  }

  checkWindows(){
    let isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

    if (isWindows) {
      //console.log("es windows");
      return true;
    } else {
      //console.log("no es windows");
      return false;
    }
  }

  ngAfterViewInit() {
    this.spinner.hide('spnr-g');
  }

  iniciarSW(){
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js').then(function (reg) {

        if (reg.installing) {
          console.log('Service worker installing');
        } else if (reg.waiting) {
          console.log('Service worker installed');
        } else if (reg.active) {
          console.log('Service worker active');
        }

      }).catch(function (error) {
        console.log('Registration failed with ' + error);
      });
    }
  }

}
