import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";
import { ConfiguracionesService } from "./configuraciones.service";

@Injectable()

export class CuestionariosService{
  public url: string;
  public token = null;
  public config = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.url = Global.url;
    this.token = this._usuarioService.getToken();
    this.config = this._configuracionesService.getConfig( );
  }

  obtenerCuestionarios(  ):Observable<any>{

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = this._http.get(this.url + "cuestionarios/listado/"+this.config.id_clinica, {headers: headers});

    return respuesta;

  }

  async obtenerCuestionario( id_cuestionario ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "cuestionarios/obtenercuestionario/"+id_cuestionario, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarCuestionario( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "cuestionarios/registrar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async actualizarCuestionario( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "cuestionarios/actualizar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async deshabiitarCuestionario( id_cuestionario ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.delete(this.url + "cuestionarios/deshabilitar/"+id_cuestionario, {headers: headers}).toPromise();

    return respuesta;

  }

  async eliminarCuestionario( id_cuestionario ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.delete(this.url + "cuestionarios/eliminar/"+id_cuestionario, {headers: headers}).toPromise();

    return respuesta;

  }

}
