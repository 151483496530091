<div class="pull-right">
  <p>
      <a class="btn btn-info" [routerLink]="['/paciente/expediente/'+id_paciente+'/notasevolucion']" >
        <span class="glyphicon glyphicon-copy icono"></span> Notas Evolucion
      </a>
      <a class="btn btn-success" [routerLink]="['/paciente/expediente/'+id_paciente+'/nuevotratamiento']" >
        <span class="glyphicon glyphicon-copy icono"></span> Nuevo Tratamiento
      </a>
  </p>
</div>

<h1>Tratamientos</h1>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc" width="8%">#</th>
      <th class="disabled-sorting sorting_desc">Tratamiento</th>
      <th class="disabled-sorting sorting_desc">Doctor</th>
      <th class="disabled-sorting sorting_desc" width="10%">Estado</th>
      <th class="disabled-sorting sorting_desc" width="12%">Precio</th>
      <th class="disabled-sorting sorting_desc" width="15%">Fecha I.</th>
      <th class="disabled-sorting sorting_desc text-center" width="10%" data-priority="1">Ver</th>
      <th class="disabled-sorting sorting_desc text-center" width="10%" data-priority="2">Elim.</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tratamiento of tratamientos">
      <td width="8%">{{ tratamiento.id_tratamiento }}</td>
      <td>{{ tratamiento.descripcion }}</td>
      <td>{{ tratamiento.medico }}</td>
      <td width="10%">{{ tratamiento.estado }}</td>
      <td width="12%">${{ tratamiento.costo }}</td>
      <td width="15%">{{ tratamiento.fechainicio }}</td>
      <td width="10%" class="text-center">
        <button (click)="verTratamiento( tratamiento.id_tratamiento )" class="btn btn-info btn-icon btn-sm mr-1" title="Ver" aria-label="Ver" data-pjax="0">
          <i class="far fa-eye"></i>
        </button>
      </td>
      <td width="10%" class="text-center">
        <button *ngIf="tratamiento.estado == 'Pendiente'" (click)="cancelarTratamiento( tratamiento.id_tratamiento )" class="btn btn-danger btn-icon btn-sm" title="Eliminar Tratamiento" aria-label="Eliminar Tratamiento" data-pjax="0">
          <i class="far fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
