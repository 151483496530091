import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Global } from 'src/app/services/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { PacientesService } from 'src/app/services/pacientes.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
};

@Component({
  selector: 'pacientes',
  templateUrl: './pacientes.component.html',
})

export class PacientesComponent {

  public pacientes = null;
  public limite_pacientes = 9999;
  public cuenta_pacientes = 0;
  public permisos = null;

  closeResult: string;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
    private _pacienteService: PacientesService,
  ){
    this.permisos = this._configuracionesService.getPermisos('pacientes');
  }

  ngOnInit(){
    this.spinner.show('spnr-s');
    this.limitePacientes();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje,
      processing: true,
      serverSide: true,
      ajax: (dataTablesParameters: any, callback) => {

        dataTablesParameters = JSON.stringify(dataTablesParameters);
        let params = 'data='+dataTablesParameters;

        let headers = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization',  this._usuarioService.getToken());

        this.http.post<DataTablesResponse>( Global.url + "pacientes/listado", params, {headers: headers} ).subscribe(resp => {
          this.pacientes = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });

      },
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  limitePacientes(){
    let respuesta = this._configuracionesService.obtenerLimites();
    respuesta.then( val =>{
      if( val.status == "success" ){
        this.limite_pacientes = val.limites.pacientes.limite;
        this.cuenta_pacientes = val.limites.pacientes.cuenta;
      }
      this.spinner.hide('spnr-s');
    });
  }

  registrarPaciente(){
    this._router.navigate(['/pacientes/registro']);
  }

  eliminarPaciente( id_paciente ){

    Swal.fire({
      title: '¿Está seguro de eliminar este paciente? ',
      text: 'Esta acción no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let respuesta = this._pacienteService.eliminarPaciente( id_paciente );
        respuesta.then( val =>{
          if( val.status == "success" ){

            this.spinner.hide('spnr-s');

            Swal.fire({
              title: "Paciente eliminado exitosamente",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

            const currentRoute = this._router.url;
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this._router.navigate([currentRoute]);
            });

          }else{

            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

          }
        });

      }
    })

  }

}
