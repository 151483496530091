<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="modal-title" id="modal-primary-title">Anotaciones de Cita</h3>
</div>

<div class="modal-body centered">

  <form [formGroup]="anotacionForm" (ngSubmit)="registrarAnotacion(anotacionForm.value)">
    <div class="container">
      <div class="row justify-content-center">
          <div class="col-sm-12 col-md-12 col-lg-8">
              <div class="form-group">
                  <label class="control-label" for="anotaciones">ANOTACIONES / COMENTARIOS</label>
                  <textarea
                    id="anotaciones"
                    class="form-control"
                    formControlName="anotaciones"
                    >
                  </textarea>

                  <div class="help-block"></div>
              </div>
          </div>
      </div>

      <div class="row justify-content-center">
          <div class="col-sm-12 col-md-4 col-lg-4">
              <div class="form-group">
                  <button
                    [disabled]="anotacionForm.invalid"
                    type="submit"
                    class="btn btn-success btn-block">
                    Registrar
                  </button>
              </div>
          </div>
      </div>
    </div>
  </form>

</div>
