<div class="form-container" *ngIf="mostrar">
  <ngx-spinner
    name="spnr-chat"
    bdColor = "rgba(255, 255, 255, 0.5)"
    size = "large"
    color = "#0d95e8"
    type = "ball-clip-rotate-multiple"
    [fullScreen] = "false">
    <p style="color:#0d95e8">
      <b>Cargando</b>
    </p>
  </ngx-spinner>
  <div class="container">
    <div class="row justify-content-center" style="border-bottom: 1px solid #ddd;">
      <div class="col-10 col-sm-10">
        <h5>Chat Global</h5>
      </div>
      <div class="col-2 col-sm-2">
        <button
          (click)="mostrarChat()"
          class="btn btn-fab btn-icon btn-danger btn-round btn-sm">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 contenedor-mensajes">
        <perfect-scrollbar [config]="config" #chatPS>

        <div
          *ngFor="let mensaje of mensajes"
          class="d-flex align-items-end flex-column"
          [ngClass]=" {'d-flex align-items-end flex-column': mensaje.iu == identity.sub} ">
          <div
            [ngClass]=" {
              'mensaje-saliente': mensaje.iu == identity.sub,
              'mensaje': mensaje.iu != identity.sub
            } ">
            <span class="detalle-mensaje"><b>{{mensaje.nombre}}</b> {{mensaje.fecha}}</span>
            <p>{{mensaje.mensaje}}</p>
          </div>
        </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="row" style="border-top: 1px solid #ddd;">
      <div class="col-10 col-sm-10">
        <textarea
          (keydown)="keyDownFunction($event)"
          placeholder="Escribe tu mensaje"
          [(ngModel)]="msg_text" >
        </textarea>
      </div>
      <div class="col-2 col-sm-2 d-flex ">
        <button
          [ngClass]=" {'disabled': msg_text == ''} "
          (click)="enviarMensaje()"
          class="btn btn-fab btn-icon btn-success btn-round align-self-center">
          <i class="fa fa-paper-plane"></i>
        </button>
      </div>
    </div>
  </div>

</div>
<div class="chat-div">
  <span
    *ngIf="pendientes"
    class="badge badge-pill badge-primary cantidad"
    >
    <i class="fas fa-bell"></i>
  </span>
  <button
    (click)="mostrarChat()"
    [ngClass]="{'d-none' : mostrar}"
    class="btn btn-fab btn-icon btn-info btn-round btn-lg ">
    <span>
      <i class="fas fa-comment-alt"></i>
    </span>
  </button>
</div>
