import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { CajaService } from 'src/app/services/caja.service';
import Swal from 'sweetalert2';
import { Global } from 'src/app/services/global';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';

@Component({
  selector: 'caja-movimientos',
  templateUrl: './caja-movimientos.component.html',
})

export class CajamovimientosComponent {

  public movimientos = null;
  public tipo_filtro = 'D';
  public iniciado = 0;
  public modalRef = null;
  public saldos = null;
  public permisos = null;
  public safari = 0;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();


  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService,
    private modalService: NgbModal,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.permisos = this._configuracionesService.getPermisos('caja');
  }

  ngOnInit(){

    this.spinner.show('spnr-s');

    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      this.safari = 1;
    }

    let respuesta = this._cajaService.validarApertura( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          if(val.apertura == 0){
            this._router.navigate(['/caja/apertura']);
          }else{
            this.obtenerMovimientos();
            this.dtOptions = {
              pagingType: 'full_numbers',
              responsive: true,
              language: Global.lenguaje,
              order:[[0, 'desc']]
            };
          }
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        this._router.navigate(['/error']);
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  obtenerMovimientos( filtro = null ){
    this.spinner.show('spnr-s');
    let tipo = (filtro != null)? filtro : 'D';
    let respuesta = this._cajaService.obtenerMovimientos( tipo );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.movimientos = val.movimientos;
          if(this.iniciado == 0){
            this.iniciado = 1;
            this.dtTrigger.next();
          }else{
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          }
          this.spinner.hide('spnr-s');
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        this._router.navigate(['/error']);
      }
    );
  }

  cambioFiltro(tipo){
    //console.log(tipo);
    this.tipo_filtro = tipo;
    this.obtenerMovimientos(tipo);
  }

  ejecutarRetiro(id_mov){
    Swal.fire({
      title: '¿Está seguro del Retiro Bancario? ',
      text: 'Esta acción no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let respuesta = this._cajaService.retiroBancario( id_mov );
        respuesta.then(
          val =>{
            if( val.status == "success" ){

              this.cambioFiltro(this.tipo_filtro);
              this.spinner.hide('spnr-s');
              Swal.fire({
                title: "Retiro Exitoso",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });

            }else{

              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'Oopss...',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });

            }
          },
          error =>{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: 'Ha ocurrido un problema al realizar esta acción.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }

        );

      }
    })
  }

  cancelarMovimiento(id_mov){

    Swal.fire({
      title: 'Menciona el motivo de Cancelación',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      inputAttributes: {
        autocapitalize: 'off'
      },
      input: 'text',
      preConfirm: (concepto) => {
        this.spinner.show('spnr-s');
        let respuesta = this._cajaService.cancelarMovimiento(id_mov, concepto);
        respuesta.then(
          val =>{
            if( val.status == "success" ){
              this.cambioFiltro(this.tipo_filtro);
              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'Movimiento Cancelado Exitosamente',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
            }else{
              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'Oopss...',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
            }
          },
          error =>{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: 'Ha ocurrido un problema al cargar los datos.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        );

      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }

  consultarSaldo(contentModal){
    this.spinner.show('spnr-s');
    let respuesta = this._cajaService.obtenerSaldos();
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.saldos = val.saldos;
          this.modalRef = this.modalService.open(contentModal, { size: 'lg' });
          this.spinner.hide('spnr-s');
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar los datos.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

  imprimirTicket(id_ticket){
    var ancho = 8 / 2.54 * 72
    let respuesta = this._cajaService.obtenerTicket( id_ticket, 're' );
    respuesta.then(
      val =>{
        if( val.status == "success" ){

          var html = htmlToPdfmake(val.ticket, {tableAutoSize:true});
          const documentDefinition = {
            pageSize: {
              width: ancho,
              height: 'auto'
            },
            pageMargins: [12, 12, 12, 12 ],
            content: html
          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Ticket.',
            text: 'Por favor intentalo nuevamente.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }

      },
      error =>{
        this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Ticket.',
            text: 'Por favor intentalo nuevamente.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
      }
    );
  }

}
