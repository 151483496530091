<div class="caj-movimientos-caja-index">

  <h1>Acciones de Caja</h1>

  <br>
  <div class="dropdown-divider"></div>
  <br>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-6 col-sm-4">
        <a class="btn btn-sq-lg btn-success d-flex justify-content-center "
          [routerLink]="['/caja/entrada']">
          <div class="align-self-center">
            <p><i class="fa fa-donate fa-5x"></i></p>
            <p>REGISTRAR ENTRADA</p>
          </div>
        </a>
      </div>
      <div class="col-6 col-sm-4">
        <a class="btn btn-sq-lg btn-danger d-flex justify-content-center"
          [routerLink]="['/caja/salida']">
          <div class="align-self-center">
            <p><i class="fa fa-hand-holding-usd fa-5x"></i></p>
            <p>REGISTRAR SALIDA</p>
          </div>
        </a>
      </div>
      <div class="col-12 col-sm-4">
        <a class="btn btn-info btn-sq-lg d-flex justify-content-center"
          [routerLink]="['/caja/movimientos']">
          <div class="align-self-center">
            <p><i class="fas fa-wallet fa-5x"></i></p>
            <p>MOVIMIENTOS</p>
          </div>
        </a>
      </div>
    </div>
    <div class="row justify-content-center">

      <div class="col-6 col-sm-6 col-md-3">
        <a class="btn btn-purple btn-sq-lg d-flex justify-content-center"
          [routerLink]="['/caja/cortes']">
          <div class="align-self-center">
            <p><i class="fas fa-cash-register fa-5x"></i></p>
            <p>CORTES</p>
          </div>
        </a>
      </div>

      <div class="col-6 col-sm-6 col-md-3">
        <a class="btn btn-sq-lg btn-primary d-flex justify-content-center"
          [routerLink]="['/caja/medicos']">
          <div class="align-self-center">
            <p><i class="fas fa-money-bill-wave fa-5x"></i></p>
            <p>PAGO A MÉDICOS</p>
          </div>
        </a>
      </div>

      <div class="col-6 col-sm-6 col-md-3">
        <a class="btn btn-warning btn-sq-lg d-flex justify-content-center"
          [routerLink]="['/caja/reportes']">
          <div class="align-self-center">
            <p><i class="fas fa-file-invoice-dollar fa-5x"></i></p>
            <p>REPORTES</p>
          </div>
        </a>
      </div>

      <div class="col-6 col-sm-6 col-md-3">
        <a class="btn btn-sq-lg d-flex justify-content-center"
          [routerLink]="['/caja/adeudos']">
          <div class="align-self-center">
            <p><i class="fas fa-file-invoice fa-5x"></i></p>
            <p>ADEUDOS</p>
          </div>
        </a>
      </div>

    </div>
  </div>

</div>
