<div class="row">
  <div class="col-md-12">
    <div class="card cardexpand">
      <div class="card-body">
        <div class="row texto justify-content-center" id="url">

          <div
            class="col-12 col-md col-lg"
            [ngClass]="{'col-md': expand, 'col-sm-12 col-md-3 col-lg-2': expand == false }" >
            <div class="card-user">
              <div class="author">


                <a class="text-info contenedor-foto">
                  <div class="divcontainer" type="file">
                    <img class="avatar border-gray" src="{{urlImg}}{{logo}}" >
                    <span  (click)="cambiarFoto(contentModal)">
                        <i class="fas fa-camera-retro"></i>
                    </span>
                  </div>

                    <!-- <img src="{{urlImg}}{{logo}}" alt="Fotografia Paciente" class="avatar border-gray"> -->

                    <h5 class="title divm is-visible is-open">{{nombre}}</h5>
                </a>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-md col-lg divmt text-center"
            [ngClass]="{'col-md': expand, 'col-sm-12 col-md-6 col-lg-5': expand == false }">
            <h5 class="title text-info">{{nombre}}</h5>
          </div>

          <div class="col-6 col-sm-6 col-md col-lg divm is-visible is-open">
            <ul style="list-style: none;">
              <li>
                <p>
                  <a class="text-info" [routerLink]="['/paciente/expediente/'+id_paciente+'/personales']">
                    <i class="fas fa-user-edit"></i>
                    Datos Personales
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a class="text-info" [routerLink]="['/paciente/expediente/'+id_paciente+'/historia']">
                    <i class="fas fa-file-medical-alt"></i>
                    Historia Clínica
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a class="text-info" [routerLink]="['/paciente/expediente/'+id_paciente+'/citas']" >
                    <i class="fas fa-clipboard-list"></i>
                    Citas
                  </a>
                </p>
              </li>
            </ul>
          </div>

          <div class="col-6 col-sm-6 col-md col-lg divm is-visible is-open">
            <ul style="list-style: none;">
              <li>
                <p>
                  <a class="text-info" [routerLink]="['/paciente/expediente/'+id_paciente+'/tratamientos']">
                    <i class="fas fa-notes-medical"></i>
                    Tratamientos
                  </a>
                </p>
              </li>
              <!-- MODULO DE ODONTOGRAMAS-->
              <li>
                <p>
                  <a class="text-info" [routerLink]="['/paciente/expediente/'+id_paciente+'/odontograma/i']">
                    <i class="fas fa-teeth-open"></i>
                    Odontograma Inicial
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a class="text-info" [routerLink]="['/paciente/expediente/'+id_paciente+'/odontograma/f']">
                    <i class="fas fa-teeth"></i>
                    Odontograma Final
                  </a>
                </p>
              </li>
            </ul>
          </div>

          <div class="col-6 col-sm-6 col-md col-lg divm is-visible is-open">
            <ul style="list-style: none;">
              <li>
                <p>
                  <a class="text-info" [routerLink]="['/paciente/expediente/'+id_paciente+'/pagos']">
                    <i class="fas fa-money-bill-wave"></i>
                    Pagos
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a class="text-info"  [routerLink]="['/paciente/expediente/'+id_paciente+'/archivos']">
                    <i class="fas fa-file-medical"></i>
                    Imágenes & Archivos
                  </a>
                </p>
              </li>
              <!-- MODULO DE ODONTOGRAMAS-->
              <li>
                <p>
                  <a class="text-info" [routerLink]="['/paciente/expediente/'+id_paciente+'/documentos']">
                    <i class="fas fa-print"></i>
                    Imprimir Doc. Clínicos
                  </a>
                </p>
              </li>
            </ul>
          </div>

        </div>
        <button
          (click)="extender()"
          class="btn btn-icon btn-neutral btn-info btn-lg btn-tamano">
          <i class="fas fa-expand-alt" ></i>
        </button>

      </div>
    </div>
  </div>
</div>


<div class="row justify-content-center" id="codonto">
  <div class="col-md-11">
      <div class="card">
          <div class="card-body">
            <router-outlet></router-outlet>
          </div>
      </div>
  </div>
</div>

<ng-template #contentModal let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h5 class="modal-title" id="modal-primary-title">Fotografía Paciente</h5>
  </div>
  <div class="modal-body centered formulario">

      <div class="container">
          <div class="row justify-content-center text-center">
              <div class="col-12">
                <label class="control-label">Archivo</label><br>
                <div class="fileinput fileinput-new text-center" data-provides="fileinput">
                  <div class="fileinput-preview fileinput-exists thumbnail img-raised logos_upload"></div>
                  <div>
                    <span class="btn btn-raised btn-round btn-info btn-file">
                        <span class="fileinput-new"><i class="fas fa-file-image mr-1"></i> Seleccionar Imagen</span>
                        <span class="fileinput-exists"><i class="fas fa-sync-alt mr-1"></i> Cambiar</span>
                        <input type="file" (change)="cambioArchivo($event)"/>
                    </span>
                    <a href="#" (click)="imagen = null"class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput">
                      <i class="fa fa-times"></i> Remover
                    </a>
                  </div>
                </div>
              </div>
          </div>

          <div class="row justify-content-center">
              <div class="col-sm-7">
                  <div class="form-group">
                    <button
                      (click)="registrarArchivo()"
                      [disabled]="imagen == null"
                      type="submit"
                      class="btn btn-success btn-block">
                      <i class="fas fa-cloud-upload-alt"></i> Subir Foto
                    </button>
                  </div>
              </div>
          </div>

      </div>
  </div>


</ng-template>
