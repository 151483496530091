import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";
import { ConfiguracionesService } from "./configuraciones.service";

@Injectable()

export class ChatService{
  public url: string;
  public token = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.url = Global.url;
    this.token = this._usuarioService.getToken();
  }

  async obtenerMensajes( ){

    let config = this._configuracionesService.getConfig( );

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "chat/mensajes/"+config.id_clinica, {headers: headers}).toPromise();

    return respuesta;

  }

  async enviarMensaje( mensaje ){

    let config = this._configuracionesService.getConfig( );

    let data = JSON.stringify( {
      "mensaje": mensaje,
      "ic":  config.id_clinica,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "chat/enviar", params, {headers: headers}).toPromise();

    return respuesta;

  }


}
