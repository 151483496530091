<form [formGroup]="formNopatologicos" (ngSubmit)="registrarNopatologicos(formNopatologicos.value)">

  <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6  text-center">
          <h5>CONCEPTO</h5>
        </div>
        <div class="col-sm  text-center">
          <h5>OBSERVACIONES</h5>
        </div>
      </div>
      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Higiene</b> (cepillado dental, frecuencia)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="higiene">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Alcoholismo</b> (fecha de inicio, frecuencia de consumo, tiempo sin consumirlo)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="alcoholismo">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Tabaquismo</b> (fecha de inicio, frecuencia de consumo, tiempo sin consumirlo)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="tabaquismo">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Toxicomanias</b> (fecha de inicio, frecuencia de consumo, tiempo sin consumirlo)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="drogas">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Enf. Previas</b> (hígado, riñon)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="enfprevias">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Padece actualmente alguna enfermedad</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="enfactual">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Toma actualmente algún medicamento</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="medicamento">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row justify-content-center">
        <div class="col-sm-8 col-md-6 ">
            <div class="form-group">
                <button class="btn btn-success btn-block">
                  Guardar No Patológicos
                </button>
            </div>
        </div>
      </div>

  </div>

</form>
