<h1>Consumos</h1>
<div class="container" *ngIf="info_servicio != null">
    <div class="row justify-content-center">

        <div class="col-12 col-sm-12 col-md-6">
            <table class="table table-sm table-bordered">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Inf. Plan Contratado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <b>Plan Contratado: </b>{{info_servicio.servicio}}<br><br>
                            <b>Precio Base del Plan: </b>${{info_servicio.precio}} MXN<br><br>
                            <b>Saldo a la fecha: </b>
                            ${{total}} MXN<br><br>
                            <b>Fecha del Corte: </b>Día {{info_servicio.fechacorte}} de cada mes
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-12 col-sm-12 col-md-6">

          <table class="table table-sm table-bordered" *ngIf="consumo != null">
              <thead class="thead-light">
                  <tr>
                  <th scope="col">Tipo</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col">Precio (MXN)</th>
                  <th scope="col">Total (MXN)</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <th scope="row">Citas</th>
                      <td>{{consumo.citas.cantidad}}</td>
                      <td>${{consumo.citas.precio}}</td>
                      <td>${{consumo.citas.total}}</td>
                  </tr>
                  <tr>
                      <th scope="row">Cobros</th>
                      <td>{{consumo.mcajas.cantidad}}</td>
                      <td>${{consumo.mcajas.precio}}</td>
                      <td>${{consumo.mcajas.total}}</td>
                  </tr>
                  <tr>
                      <th scope="row">Historias Clínicas</th>
                      <td>{{consumo.historias.cantidad}}</td>
                      <td>${{consumo.historias.precio}}</td>
                      <td>${{consumo.historias.total}}</td>
                  </tr>
                  <tr>
                      <th scope="row">Consentimientos Informados</th>
                      <td>{{consumo.consentimientos.cantidad}}</td>
                      <td>${{consumo.consentimientos.precio}}</td>
                      <td>${{consumo.consentimientos.total}}</td>
                  </tr>
                  <tr>
                      <th scope="row" colspan="3" class="text-right">Total</th>
                      <td>
                        ${{consumo.citas.total+consumo.mcajas.total+consumo.historias.total+consumo.consentimientos.total}}
                      </td>
                  </tr>
              </tbody>
          </table>
          <p *ngIf="consumo == null">{{mensaje}}</p>

        </div>
    </div>

    <div class="row justify-content-center" *ngIf="movimientos != null">
      <div class="col-12 col-sm-12 text-center">
        <h4>Movimientos Realizados</h4>

        <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered dataTable no-footer dtr-inline">
          <thead>
            <tr>
              <th class="disabled-sorting sorting_desc">Folio</th>
              <th class="disabled-sorting sorting_desc">Tipo</th>
              <th class="disabled-sorting sorting_desc">Usuario Registro</th>
              <th class="disabled-sorting sorting_desc">F Mov</th>
              <th class="disabled-sorting sorting_desc">Fecha</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let movimiento of movimientos">
                <th scope="row">{{ movimiento['id_consumo'] }}</th>

                <td *ngIf="movimiento['tipo'] == 'cita'">Cita</td>
                <td *ngIf="movimiento['tipo'] == 'mcaja'">Movimiento de Caja</td>
                <td *ngIf="movimiento['tipo'] == 'historia'">Historia Clínica</td>
                <td *ngIf="movimiento['tipo'] == 'consentimiento'">Consentimiento Informado</td>

                <td>{{ movimiento['id_usuario'] }}</td>

                <td *ngIf="movimiento['tipo'] == 'cita'">
                    <a [routerLink]="['/agenda/cita/'+movimiento['f_mov']]" target="_blank">{{movimiento['f_mov']}}</a>
                </td>
                <td *ngIf="movimiento['tipo'] == 'mcaja'">
                    <a [routerLink]="['/caja/movimiento/'+movimiento['f_mov']]" target="_blank">{{movimiento['f_mov']}}</a>
                </td>
                <td *ngIf="movimiento['tipo'] == 'historia'">
                    <a [routerLink]="['/paciente/expediente/'+movimiento['f_mov']]" target="_blank">{{movimiento['f_mov']}}</a>
                </td>
                <td *ngIf="movimiento['tipo'] == 'consentimiento'">
                    <a [routerLink]="['/paciente/expediente/'+movimiento['f_mov']]" target="_blank">{{movimiento['f_mov']}}</a>
                </td>
                <td>{{movimiento['fecha'] }}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>

</div>
