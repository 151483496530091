import { Component, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';

@Component({
  selector: 'consumo',
  templateUrl: './consumo.component.html',
})

export class ConsumoComponent{

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  public consumo = null;
  public info_servicio = null;
  public movimientos = null;
  public total = 0;
  constructor(
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
  ){

  }

  ngOnInit(){
    this.obtenerComsumos();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje
    };
  }

  obtenerComsumos( ){

    this.spinner.show('spnr-s');

    let respuesta = this._configuracionesService.obtenerConsumos( );

    respuesta.then( val =>{
      if( val.status == "success" ){

        this.consumo = val.consumo;
        this.info_servicio = val.info_servicio;
        this.movimientos = val.movimientos;

        this.total = parseFloat(this.info_servicio.precio) +  parseFloat(this.consumo.citas.total) +  parseFloat(this.consumo.mcajas.total) +  parseFloat(this.consumo.historias.total) +  parseFloat(this.consumo.consentimientos.total);

        //this.dtTrigger.next();
        this.spinner.hide('spnr-s');

      }
    });
  }

}
