<div class="container" *ngIf="cModal != true">
  <div class="row">
    <div class="col-6">
      <h1>Registrar Paciente</h1>
    </div>
  </div>
</div>

<form [formGroup]="formPaciente" (ngSubmit)="registrarPaciente(formPaciente.value)">

  <div class="container">
      <input type="hidden" formControlName="id_paciente">
      <div class="row">
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="nombre">NOMBRE</label>
              <input
                type="text"
                id="nombre"
                class="form-control"
                formControlName="nombre">
            </div>
          </div>
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="ape_pat">APE PAT</label>
              <input
                type="text"
                id="ape_pat"
                class="form-control"
                formControlName="ape_pat">
            </div>
          </div>
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="ape_mat">APE MAT</label>
              <input
                type="text"
                id="ape_mat"
                class="form-control"
                formControlName="ape_mat">
            </div>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="fecha_nac">FECHA DE NACIMIETO</label>
              <div class="input-group">
                <span class="input-group-prepend" style="display: block;">
                  <div class="input-group-text" >
                    <i class="glyphicon glyphicon-calendar"></i>
                  </div>
                </span>
                <input
                  placeholder="Fecha de Nacimiento"
                  class="form-control"
                  matInput
                  [matDatepicker]="picker"
                  formControlName="fecha_nac"
                  (change)="cambioFecha($event.target.value, 'input')"
                  (dateChange)="cambioFecha($event.value, 'date')"
                  (click)="picker.open(); $event.stopPropagation();">
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md">
            <div class="form-group">
              <label class="control-label" for="edad">EDAD</label>
              <input
                type="text"
                id="edad"
                class="form-control"
                formControlName="edad"
                readonly>
            </div>
          </div>
          <div class="col-sm-12 col-md">

            <div class="form-group field-sexo">
              <label class="control-label" for="sexo">SEXO</label>
              <select
                formControlName="sexo"
                id="sexo"
                class="form-control"
                title="Sexo"
                data-style="btn btn-info btn-round"
                required=""
                aria-invalid="false">

                <option value="Sexo" disabled="">Sexo</option>
                <option value="Femenino">Femenino</option>
                <option value="Masculino">Masculino</option>

              </select>
              <div class="help-block"></div>
            </div>

          </div>
      </div>
      <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <label class="control-label" for="tel">TELEFONO</label>
              <input
                type="tel"
                id="tel"
                class="form-control"
                formControlName="tel">
            </div>
          </div>
          <div class="col-sm-12 col-md-8">
            <div class="form-group">
              <label class="control-label" for="domicilio">DOMICILIO</label>
              <input
                type="text"
                id="direccion"
                class="form-control"
                formControlName="direccion">
            </div>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-8 col-md-6">
            <div class="form-group">
              <label class="control-label" for="correo">CORREO ELECTRONICO</label>
              <input
                type="text"
                id="correo"
                class="form-control"
                formControlName="correo">
            </div>
          </div>
          <div class="col-sm-4 col-md-2 text-center">
            <div class="form-group">
              <label class="control-label" for="mensajes">MENSAJES</label>
              <div class="form-group">
                <mat-slide-toggle
                  formControlName="mensajes"
                  color="primary"
                >
                </mat-slide-toggle>
                <div class="help-block"></div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <label class="control-label" for="correo">RFC</label>
              <input
                type="text"
                id="rfc"
                class="form-control"
                formControlName="rfc">
            </div>
          </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md">

          <div class="form-group field-ocupacion">
            <label class="control-label" for="ocupacion">OCUPACION</label>
            <select
              formControlName="ocupacion"
              id="ocupacion"
              class="form-control"
              title="Ocupación"
              data-style="btn btn-info btn-round"
              aria-invalid="false">

              <option value="Ocupacion" disabled="">Ocupación</option>
              <option value="Empleado">Empleado</option>
              <option value="Estudiante">Estudiante</option>
              <option value="Hogar">Hogar</option>
              <option value="Comerciante">Comerciante</option>
              <option value="Empresario">Empresario</option>
              <option value="Otro">Otro</option>

            </select>
            <div class="help-block"></div>
          </div>

        </div>
        <div class="col-sm-12 col-md">

          <div class="form-group field-escoloridad">
            <label class="control-label" for="escoloridad">ESCOLARIDAD</label>
            <select
              formControlName="escoloridad"
              id="escoloridad"
              class="form-control"
              title="Escolaridad"
              data-style="btn btn-info btn-round"
              aria-invalid="false">
              <option value="Escolaridad" selected="" disabled="">Escolaridad</option>
              <option value="Preescolar">Preescolar</option>
              <option value="Primaria">Primaria</option>
              <option value="Secundaria">Secundaria</option>
              <option value="Bachillerato">Bachillerato</option>
              <option value="Licenciatura">Licenciatura o posterior</option>
              <option value="Otro">Otro</option>
            </select>

            <div class="help-block"></div>
          </div>

        </div>
        <div class="col-sm-12 col-md">

          <div class="form-group field-grado">
            <label class="control-label" for="grado">GRADO</label>
            <select
              formControlName="grado"
              id="grado"
              class="form-control"
              title="Escolaridad"
              data-style="btn btn-info btn-round"
              aria-invalid="false">
              <option value="Grado" selected="" disabled="">Grado</option>
              <option value="Concluido">Concluido</option>
              <option value="Curso">En Curso</option>
              <option value="Trunco">Trunco</option>
              <option value="Otro">Otro</option>
            </select>

            <div class="help-block"></div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
          <div class="col-sm-7">
              <div class="form-group">
                <button
                  [disabled]="formPaciente.invalid"
                  type="submit"
                  class="btn btn-success btn-block">
                  Registrar
                </button>
              </div>
          </div>
      </div>
  </div>

</form>
