import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing, appRoutingProviders } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { ValidateEqualModule } from 'ng-validate-equal';
import { NgxSpinnerModule } from "ngx-spinner";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { MatRadioModule } from '@angular/material/radio';
import { LightboxModule } from 'ngx-lightbox';

// import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
// import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
// import interactionPlugin from '@fullcalendar/interaction'; // a plugin

import { ConversorPipe } from './pipes/conversor.pipe';
import { HeaderService } from './services/header.service';
import { UsuarioService } from './services/usuario.service';
import { AgendaService } from './services/agenda.service';
import { ClinicasService } from './services/clinicas.service';
import { ConfiguracionesService } from './services/configuraciones.service';
import { PacientesService } from './services/pacientes.service';
import { MedicosService } from './services/medicos.service';
import { UsuariosService } from './services/usuarios.service';
import { TecnicosService } from './services/tecnicos.service';
import { TratamientosService } from './services/tratamientos.service';
import { CuestionariosService } from './services/cuestionarios.service';
import { CajaService } from './services/caja.service';
import { ExpedienteService } from './services/expediente.service';
import { CookieService } from 'ngx-cookie-service';
import { PusherService } from './services/pusher.service';
import { ChatService } from './services/chat.service';

import { AppComponent } from './app.component';

import { BarraComponent } from './components/layouts/barra/barra.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NotificacionesComponent } from './components/layouts/notificaciones/notificaciones.component';
import { VencimientoComponent } from './components/layouts/vencimiento/vencimiento.component';

import { LoginComponent } from './components/login/login.component';
import { NoencontradaComponent } from './components/layouts/noencontrada/noencontrada.component';

import { AgendaComponent } from './components/sistema/agenda/agenda/agenda.component';
import { CitascreateComponent } from './components/sistema/agenda/create/citas-create.component';
import { CreatecitasComponent } from './components/sistema/agenda/createcitas/createcitas.component';
import { VercitaComponent } from './components/sistema/agenda/vercita/vercita.component';
import { AnotacioncitaComponent } from './components/sistema/agenda/anotacioncita/anotacioncita.component';

import { ClinicasComponent } from './components/sistema/administracion/clinicas/clinicas/clinicas.component';
import { ClinicaregistroComponent } from './components/sistema/administracion/clinicas/clinica-registro/clinica-registro.component';
import { ClinicaComponent } from './components/sistema/administracion/clinicas/clinica/clinica.component';

import { CajaComponent } from './components/sistema/caja/caja/caja.component';
import { CajaaperturaComponent } from './components/sistema/caja/apertura/caja-apertura.component';
import { CajaentradasComponent } from './components/sistema/caja/entradas/caja-entradas.component';
import { CajasalidasComponent } from './components/sistema/caja/salidas/caja-salidas.component';
import { CajamovimientosComponent } from './components/sistema/caja/movimientos/caja-movimientos.component';
import { CajacortesComponent } from './components/sistema/caja/cortes/caja-cortes.component';
import { CajacorteComponent } from './components/sistema/caja/cortes/corte/caja-corte.component';
import { CreatecorteComponent } from './components/sistema/caja/cortes/create-corte/create-corte.component';
import { CajamedicosComponent } from './components/sistema/caja/medicos/caja-medicos.component';
import { CajamedicoComponent } from './components/sistema/caja/medicos/medico/caja-medico.component';
import { CajaadeudosComponent } from './components/sistema/caja/adeudos/caja-adeudos.component';
import { CajaadeudoComponent } from './components/sistema/caja/adeudos/adeudo/caja-adeudo.component';
import { CajareportesComponent } from './components/sistema/caja/reportes/caja-reportes.component';

import { PacientesComponent } from './components/sistema/pacientes/pacientes/pacientes.component';
import { PacienteregistroComponent } from './components/sistema/pacientes/parciente-registro/paciente-registro.component';
import { PacientecreateComponent } from './components/sistema/pacientes/create/paciente-create.component';
import { PacienteactualizarComponent } from './components/sistema/pacientes/paciente-actualizar/paciente-actualizar.component';
import { ExpedienteComponent } from './components/sistema/pacientes/expediente/expediente/expediente.component';
import { HistoriaComponent } from './components/sistema/pacientes/expediente/historia/historia/historia.component';
import { GeneralesComponent } from './components/sistema/pacientes/expediente/historia/generales/generales.component';
import { ExploracionComponent } from './components/sistema/pacientes/expediente/historia/exp-fisica/exploracion.component';
import { PatologicosComponent } from './components/sistema/pacientes/expediente/historia/antecedentes/patologicos/patologicos.component';
import { NopatologicosComponent } from './components/sistema/pacientes/expediente/historia/antecedentes/nopatologicos/nopatologicos.component';
import { GinecoComponent } from './components/sistema/pacientes/expediente/historia/antecedentes/gineco/gineco.component';
import { PersonalizadoComponent } from './components/sistema/pacientes/expediente/historia/personalizado/personalizado.component';
import { TriageComponent } from './components/sistema/pacientes/expediente/historia/triage/triage.component';
import { PacienteComponent } from './components/sistema/pacientes/expediente/paciente/paciente.component';
import { CitaspacienteComponent } from './components/sistema/pacientes/expediente/citas/citas_paciente.component';
import { PagospacienteComponent } from './components/sistema/pacientes/expediente/pagos/pagos_paciente.component';
import { TratamientospacienteComponent } from './components/sistema/pacientes/expediente/tratamientos/tratamientos_paciente.component';
import { VertratamientopacienteComponent } from './components/sistema/pacientes/expediente/tratamientos/vertratamiento_paciente/vertratamiento_paciente.component';
import { NotasevolucionComponent } from './components/sistema/pacientes/expediente/tratamientos/notas_evolucion/notas_evolucion.component';
import { NotaspacienteComponent } from './components/sistema/pacientes/expediente/tratamientos/notas_paciente/notas_paciente.component';
import { ArchivostratamientoComponent } from './components/sistema/pacientes/expediente/tratamientos/archivos_tratamiento/archivos_tratamiento.component';
import { TratamientoregistroComponent } from './components/sistema/pacientes/expediente/tratamientos/tratamiento_registro/tratamiento_registro.component';
import { FacialComponent } from './components/sistema/pacientes/expediente/historia/facial-funcional/facial.component';
import { OdontogramaComponent } from './components/sistema/pacientes/expediente/odontograma/odontograma.component';
import { ArchivosComponent } from './components/sistema/pacientes/expediente/archivos/archivos.component';
import { DocumentosComponent } from './components/sistema/pacientes/expediente/documentos/documentos.component';
import { ImprimirdocumentosComponent } from './components/sistema/pacientes/documentos/imprimir-documentos.component';

import { UsuariosComponent } from './components/sistema/administracion/usuarios/usuarios/usuarios.component';
import { UsuarioComponent } from './components/sistema/administracion/usuarios/usuario/usuario.component';
import { UsuarioregistroComponent } from './components/sistema/administracion/usuarios/usuario-registro/usuario-registro.component';
import { PerfilComponent } from './components/sistema/administracion/usuarios/perfil/perfil.component';
import { PermisosComponent } from './components/sistema/administracion/usuarios/permisos/permisos.component';

import { TecnicosComponent } from './components/sistema/administracion/tecnicos/tecnicos/tecnicos.component';
import { ConfiguracionComponent } from './components/sistema/administracion/configuracion/configuracion/configuracion.component';
import { ConsumoComponent } from './components/sistema/administracion/consumo/consumo/consumo.component';
import { TratamientosComponent } from './components/sistema/administracion/tratamientos/tratamientos/tratamientos.component';
import { TratamientosregistroComponent } from './components/sistema/administracion/tratamientos/tratamientos-registro/tratamientos-registro.component';
import { TratamientoComponent } from './components/sistema/administracion/tratamientos/tratamiento/tratamiento.component';
import { CuestionariosComponent } from './components/sistema/administracion/cuestionarios/cuestionarios/cuestionarios.component';
import { CuestionariosregistroComponent } from './components/sistema/administracion/cuestionarios/cuestionarios-registro/cuestionarios-registro.component';
import { ChatComponent } from './components/sistema/chat/chat.component';

export const customCurrencyMaskConfig = {
    align: "left",
    allowNegative: false,
    allowZero: false,
    decimal: ".",
    precision: 2,
    prefix: "$ ",
    suffix: "",
    thousands: ",",
    nullable: false,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.FINANCIAL
};

const customNotifierOptions: NotifierOptions = {
  position: {
      horizontal: {
          position: 'right',
          distance: 12
      },
      vertical: {
          position: 'top',
          distance: 80,
          gap: 10
      }
  },
  theme: 'material',
  behaviour: {
      autoHide: 5000,
      onClick: 'hide',
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
  },
  animations: {
      enabled: true,
      show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
      },
      hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
      },
      shift: {
          speed: 300,
          easing: 'ease'
      },
      overlap: 150
  }
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  //suppressScrollX: true
};

// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//   dayGridPlugin,
//   interactionPlugin
// ]);

@NgModule({
  declarations: [
    AppComponent,
    BarraComponent,
    HeaderComponent,
    FooterComponent,
    ConversorPipe,
    NotificacionesComponent,
    VencimientoComponent,

    LoginComponent,
    NoencontradaComponent,

    AgendaComponent,
    CreatecitasComponent,
    CitascreateComponent,
    VercitaComponent,
    AnotacioncitaComponent,

    ClinicasComponent,
    ClinicaComponent,
    ClinicaregistroComponent,

    CajaComponent,
    CajaaperturaComponent,
    CajaentradasComponent,
    CajasalidasComponent,
    CajamovimientosComponent,
    CajacortesComponent,
    CajacorteComponent,
    CreatecorteComponent,
    CajamedicosComponent,
    CajamedicoComponent,
    CajaadeudosComponent,
    CajaadeudoComponent,
    CajareportesComponent,

    PacientesComponent,
    PacienteregistroComponent,
    PacientecreateComponent,
    PacienteactualizarComponent,
    ExpedienteComponent,
    HistoriaComponent,
    GeneralesComponent,
    ExploracionComponent,
    FacialComponent,
    PatologicosComponent,
    NopatologicosComponent,
    GinecoComponent,
    PersonalizadoComponent,
    TriageComponent,
    PacienteComponent,
    CitaspacienteComponent,
    PagospacienteComponent,
    TratamientospacienteComponent,
    VertratamientopacienteComponent,
    NotasevolucionComponent,
    NotaspacienteComponent,
    ArchivostratamientoComponent,
    TratamientoregistroComponent,
    OdontogramaComponent,
    ArchivosComponent,
    DocumentosComponent,
    ImprimirdocumentosComponent,

    UsuariosComponent,
    UsuarioComponent,
    UsuarioregistroComponent,
    PerfilComponent,
    PermisosComponent,

    TecnicosComponent,
    ConfiguracionComponent,
    ConsumoComponent,
    TratamientosComponent,
    TratamientosregistroComponent,
    TratamientoComponent,
    CuestionariosComponent,
    CuestionariosregistroComponent,

    ChatComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    NgxMatMomentModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatSelectModule,
    MatSliderModule,
    MatProgressBarModule,
    NgxMatFileInputModule,
    NgxCurrencyModule,
    MatRadioModule,
    LightboxModule,
    routing,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    ValidateEqualModule,
    NgxSpinnerModule,
    PerfectScrollbarModule,
    NgbModule,
    DataTablesModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // FullCalendarModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CookieService,
    appRoutingProviders,
    Title,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'es-MX'
    },
    MatDatepickerModule,
    HeaderService,
    ConfiguracionesService,
    UsuarioService,
    AgendaService,
    ClinicasService,
    PacientesService,
    MedicosService,
    UsuariosService,
    TecnicosService,
    TratamientosService,
    CuestionariosService,
    CajaService,
    ExpedienteService,
    PusherService,
    ChatService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
