import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChatService } from 'src/app/services/chat.service';
import { PusherService } from 'src/app/services/pusher.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
})

export class ChatComponent {

  public mostrar = false;
  public pendientes = false;
  public config: PerfectScrollbarConfigInterface = {};
  public mensajes = null;
  public mensaje = null;
  public msg_text = "";
  public identity = null;
  @ ViewChild ( 'chatPS' ) chatPS: PerfectScrollbarComponent ;

  constructor(
    private spinner: NgxSpinnerService,
    private _chatService: ChatService,
    private _pusherService: PusherService,
    private _usuarioService: UsuarioService,
    private cd: ChangeDetectorRef
  ){

    this.identity = this._usuarioService.getIdentity();
    this._usuarioService.identity$.subscribe( identity =>{
      this.identity = identity;
    });

    this._pusherService.mensaje$.subscribe( msg =>{

      if( msg ){
        if(msg.iu !=  this.identity.sub){
          this.playAudio();
        }
        this.mensajes.push(msg);
        if( this.mostrar ){
          this.cd.detectChanges();
          this.chatPS.directiveRef.scrollToBottom();
        }else{
          this.pendientes = true;
        }
      }

    });
  }

  ngOnInit(){
    this.obtenerMensajes();
  }

  ngOnDestroy(): void {

  }

  mostrarChat(){
    this.pendientes = false;
    this.mostrar = ( this.mostrar )? false : true;
    if( this.mostrar ){
      this.cd.detectChanges();
      this.chatPS.directiveRef.scrollToBottom();
    }
  }

  obtenerMensajes(){
    this.spinner.show('spnr-chat');
    let respuesta = this._chatService.obtenerMensajes( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-chat');
          this.mensajes = val.mensajes;
          if( this.mensajes.length > 0 ){
            if ( this.mensajes[this.mensajes.length - 1].iu != this.identity.sub) {
              this.pendientes = true;
            }
          }

        }else{
          this.spinner.hide('spnr-chat');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-chat');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar el chat.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

  enviarMensaje(){
    this.spinner.show('spnr-chat');
    let respuesta = this._chatService.enviarMensaje( this.msg_text );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-chat');
          this.msg_text = "";
        }else{
          this.spinner.hide('spnr-chat');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-chat');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar el chat.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

  playAudio(){
    let audio = new Audio();
    audio.src = "../../../../assets/sound/sonido.mp3";
    audio.load();
    audio.play();
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.enviarMensaje();
    }
  }

}
