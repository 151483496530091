<div class="row">
  <div class="col-md-8">
    <h1>Tratamientos Pre-establecidos</h1>
  </div>
  <div class="col-md-4">
    <div class="pull-right">
      <button type="button" class="btn btn-success"
        (click)="registroModal()">
        <span class="glyphicon glyphicon-copy icono"></span>
        Registrar Tratamiento
      </button>
    </div>
  </div>
</div>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc">Folio</th>
      <th class="disabled-sorting sorting_desc">Descripción</th>
      <th class="disabled-sorting sorting_desc">Precio</th>
      <th class="disabled-sorting sorting_desc">Serv. Médico</th>
      <th class="disabled-sorting sorting_desc">Serv. Técnico</th>
      <th class="disabled-sorting sorting_desc text-center" data-priority="1">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tratamiento of tratamientos">
      <td>{{ tratamiento.id_bas_tratamiento }}</td>
      <td>{{ tratamiento.descripcion }}</td>
      <td>${{ tratamiento.costo }}</td>
      <td>${{ tratamiento.tratamiento }}</td>
      <td>${{ tratamiento.tecnico }}</td>
      <td class="text-center">
        <a class="btn btn-info btn-icon btn-sm mr-1" [routerLink]="['/tratamiento', tratamiento.id_bas_tratamiento ]" title="Ver" aria-label="Ver" data-pjax="0">
          <i class="far fa-eye"></i>
        </a>
        <button class="btn btn-warning btn-icon btn-sm mr-1"  (click)="actualizarModal(tratamiento.id_bas_tratamiento)" title="Actualizar" aria-label="Actualizar" data-pjax="0">
          <i class="far fa-edit"></i>
        </button>
        <button class="btn btn-danger btn-icon btn-sm"  (click)="eliminarTratamiento(tratamiento.id_bas_tratamiento)" title="Eliminar" aria-label="Eliminar">
          <i class="far fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #contentModal let-c="close" let-d="dismiss">
</ng-template>
