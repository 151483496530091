import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { CajaService } from 'src/app/services/caja.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'caja-adeudo',
  templateUrl: './caja-adeudo.component.html',
})

export class CajaadeudoComponent {

  public id_paciente = null;
  public nombre = null;
  public adeudos = null;
  public anotaciones = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService,
  ){

  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_paciente = params['id'];
    });
    this.obtenerDatosadeudo();

  }

  obtenerDatosadeudo( ){
    this.spinner.show('spnr-s');
    let respuesta = this._cajaService.obtenerAdeudo( this.id_paciente );
    respuesta.then(
      val =>{
        if( val.status == "success" ){

          this.nombre = val.nombre;
          this.adeudos = val.adeudos;
          this.anotaciones = val.anotaciones;

          this.spinner.hide('spnr-s');
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Oops...",
            text: "Ha ocurrido un problema al cargar los datos. Vuelve a recargar la pagina por favor.",
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: "Oops...",
          text: "Ha ocurrido un problema al cargar los datos. Vuelve a recargar la pagina por favor.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
        this._router.navigate(['/caja/adeudos']);
      }
    );
  }

}
