<div class="modal-header">
  <h5 class="modal-title" id="modal-primary-title">Registrar/Actualizar Tratamiento</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body centered formulario">
  <form [formGroup]="formTratamiento" (ngSubmit)="registrarTratamiento(formTratamiento.value)">

    <div class="container">
      <input type="hidden" formControlName="id_bas_tratamiento">
        <div class="row justify-content-center">

          <div class="col-12 col-sm-6 col-md-5">
            <div class="form-group">
              <label class="control-label">NOMBRE/IDENTIFICADOR</label>
              <input
                type="text"
                id="descripcion"
                class="form-control"
                formControlName="descripcion">
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-7">
            <div class="form-group">
              <label class="control-label">MÉDICOS</label>
                <mat-select formControlName="id_doctores" multiple class="form-control">
                  <mat-option *ngFor="let doctor of doctores" [value]="doctor.id">{{doctor.texto}}</mat-option>
                </mat-select>
            </div>
          </div>
        </div>

        <div class="row rounded" style='border: 2px solid #6c7edb !important;'>
          <div class="col-12">
            <div class="panel panel-default">
              <div class="panel-body">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4">
                        <h4 class="mt-1"><i class="fas fa-calculator"></i> Presupuesto</h4>
                      </div>
                      <div class="col-12 col-sm-6 col-md-8">
                        <button type="button" class="pull-right btn btn-success btn-xs" (click)="addItem()">
                          <i class="fa fa-plus"></i> Agregar Detalles
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="panel-body container-items">
                    <div
                    formArrayName="detalle_tratamiento"
                    *ngFor="let item of formTratamiento.get('detalle_tratamiento')['controls']; let i = index;">
                      <div [formGroupName]="i">
                        <div class="row">
                            <input type="hidden" formControlName="id_bas_dettratamientos">
                            <div class="col-sm-12 col-md-5">
                              <label>Descripción</label>
                              <input formControlName="descripcion" class="form-control" >
                            </div>
                            <div class="col-sm-8 col-md-3">
                              <label>Monto</label>
                              <input formControlName="monto" class="form-control monto" (change)="sumar();">
                            </div>
                            <div class="col-sm-2 col-md-2 text-center">
                              <label>Servicio Técnico</label>
                              <div class="form-group">
                                <mat-slide-toggle
                                  formControlName="tecnico"
                                  color="primary">
                                </mat-slide-toggle>
                              </div>
                            </div>
                            <div class="col-sm-2 col-md-2 text-center">
                              <button type="button" class="btn btn-danger btn-xs mt-4" (click)="deleteItem(i)">
                                <i class="fa fa-minus"></i>
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 col-sm-5 pt-2">
            <h3><b>Total:</b>${{total}}</h3>
          </div>
          <div class="col-12 col-sm-7">
            <div class="form-group">
              <button
                [disabled]="formTratamiento.invalid"
                type="submit"
                class="btn btn-success btn-block">
                Registrar
              </button>
            </div>
          </div>
        </div>

    </div>

  </form>
</div>
