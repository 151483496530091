import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { UsuarioService } from "./usuario.service";
import { ConfiguracionesService } from "./configuraciones.service";
import Pusher from 'pusher-js';
declare var jQuery:any;
declare var moment: any;
declare var $:any;

@Injectable()

export class PusherService{
  public url: string;
  public token = null;
  public config = null;
  pusher: any
  private PUSHER_API_KEY = "172e9ab312919d33b1fb";
  private PUSHER_API_CLUSTER = "us2";

  mensaje$ = new EventEmitter<any>();

  constructor(
    public _http: HttpClient,
    private _configuracionesService: ConfiguracionesService,
    private _usuarioService: UsuarioService,
  ){
    this.url = Global.url;
    this.pusher = new Pusher(
      this.PUSHER_API_KEY, {
        cluster: this.PUSHER_API_CLUSTER
      }
    );
  }

  subScribeToChannel( channelName: String, events: String[], cb: Function ) {
    var channel = this.pusher.subscribe(channelName);

    events.forEach( event => {
      channel.bind(event, function(data) {
        cb(data)
      });
    })

  }

  subscribirCitas( canal ) {

    var channel = this.pusher.subscribe( canal );
    let cargarCita = this.cargarCita;
    this.config = this._configuracionesService.getConfig( );
    let tamano_cita = this.config.duracion_espacios
    channel.bind( 'actualizarcitas', function(data) {

      let id_ultima_cita = data['id_cita'];
      let cant_citas = data['cantidad_citas'];
      let id_ultima_cita_local = localStorage.getItem('id_ultima_cita_local');
      let cant_citas_local = localStorage.getItem('cant_citas_local');
      let citas_locales = JSON.parse( localStorage.getItem("citas") );
      let location_agenda = localStorage.getItem( "ubicacion" );

      let nuevos_cambios = 0;
      let obj = [];

      if( citas_locales.length > 0 ){

        if(id_ultima_cita != id_ultima_cita_local){
          let diferencia_citas = parseInt(cant_citas) - parseInt(cant_citas_local);
          if( diferencia_citas > 1 ){ //si la cantidad de citas es mayor a 1 quiere decir que faltan citas por cargar
            //console.log('faltanDatos');
          }
          localStorage.setItem("id_ultima_cita_local", id_ultima_cita);
        }

        if(data['accion'] == 'registro'){
            citas_locales.push(data['cita']);
            if ( location_agenda == '1' ) {
              let cita = cargarCita( data['cita'], tamano_cita );
              obj.push(cita);
              nuevos_cambios = 1;
            }
          //console.log('registro');
        }

        if(data['accion'] == 'actualizacion'){
          for(var i=0; i < citas_locales.length; i++){
            if(citas_locales[i]['id_cita'] == data['cita']['id_cita'] ){
              citas_locales.splice(i, 1, data['cita']);
              if ( location_agenda == '1' ) {
                $('#fullCalendar').fullCalendar('removeEvents', data['cita']['id_cita']);
                let cita = cargarCita(data['cita'], tamano_cita);
                obj.push(cita);
                nuevos_cambios = 1;
              }
              //console.log('actualizado')
            }
          }
        }

        if(data['accion'] == 'eliminacion'){
          for(var i=0; i < citas_locales.length; i++){
            if(citas_locales[i]['id_cita'] == data['cita'] ){
              citas_locales.splice(i, 1);
              if ( location_agenda == '1' ) {
                $('#fullCalendar').fullCalendar('removeEvents', data['cita']);
                nuevos_cambios = 1;
              }
              //console.log('eliminado')
            }
          }
        }

        if( nuevos_cambios ){
          nuevos_cambios = 0;
          var calendar = $('#fullCalendar');
          calendar.each(function(i, cal){
            calendar.fullCalendar('addEventSource', obj);
          });
        }

        localStorage.setItem("citas", JSON.stringify(citas_locales));
      }
    });

  }

  cerrarSubCitas( canal ){
    this.pusher.unsubscribe( canal );
  }

  cargarCita( cita, tamano_cita ){

    var descripcion = cita['motivo'];
    var color = 'color' + cita['color'];
    var tamano_cita = tamano_cita;
    var eventDatos;

    if(cita['status'] == "Cancelada"){
      var color = 'colorR';
      descripcion = "(CANCELADA) " + descripcion;
    }

    if( cita['status'] == "Inasistencia" ){
      var color = 'colorA';
      descripcion = "(INASISTENCIA) " + descripcion;
    }

    if( cita['color'] != '0' ){

      var ffinal = cita['fecha_programada_final'];
      if( cita['fecha_programada_final'] == null ){
        ffinal = cita['fecha_programada'] +' '+ moment( moment( cita['hora_programada'],'HH:mm' ) ).add( tamano_cita, 'minutes' ).format( 'HH:mm' );
      }
      eventDatos = {
        id: cita['id_cita'],
        title: 'PX '+ cita['id_paciente']+': '+ cita['nombre'] + ' ' + cita['ape_pat'],
        start: cita['fecha_programada'] + ' ' + cita['hora_programada'],
        description: descripcion,
        end: ffinal,
        className: color,
        url: "/agenda/cita/" + cita['id_cita'],
      };
      return eventDatos;

    }else{

      eventDatos = {
        id: cita['id_cita'],
        title: cita['motivo'],
        start: cita['fecha_programada_inicial'],
        description: cita['motivo'],
        end: cita['fecha_programada_final'],
        className: color,
        url: "/agenda/cita/" + cita['id_cita'],
      };
      return eventDatos;

    }

  }

  async triggerEvent( channelName: String, event: String, data: Object ){
    this.token = this._usuarioService.getToken();
    this.config = this._configuracionesService.getConfig( );
    let dat = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "channel_name": channelName,
      "event_name": event,
      "data": data
    });

    let params = 'data='+dat;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "agenda/obtenercita", params, {headers: headers}).toPromise();

    return respuesta;

  }

  subscribirChat( canal ) {

    var channel = this.pusher.subscribe( canal );

    let msg$ = this.mensaje$;
    channel.bind( 'chat', function(data) {
      msg$.next(data['msg']);
    });

  }

  subscribirCerrarsesion( canal ) {

    var channel = this.pusher.subscribe( canal );
    let _uService = this._usuarioService;
    channel.bind( 'cerrarsesion', function(data) {
      if( data['estado'] ){
        _uService.cerrarSesion();
      };
    });

  }

}
