<div class="row">
  <div class="col-12 col-sm-6">
    <h1>Configuración</h1>
  </div>
</div>

<form [formGroup]="formConfig" (ngSubmit)="registrarConfig(formConfig.value)">

  <div class="container">
      <input type="hidden" formControlName="id_configuracion">
      <div class="mb-3 p-3" style='    border: 3px solid #1d65b091 !important; border-radius: 10px;'>
          <div class="row justify-content-center">
            <div class="col-12 col-sm-7 col-md-5 col-lg-3 col-md">
              <div class="form-group">
                <label class="control-label" for="nombre">NOMBRE DE LA CLINICA</label>
                <input
                  type="text"
                  id="nombre"
                  class="form-control"
                  formControlName="nombre">
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="control-label">HORA DE INICIO</label>
                  <div class="input-group">
                    <span class="input-group-prepend" style="display: block;">
                      <div class="input-group-text" >
                        <i class="glyphicon glyphicon-time"></i>
                      </div>
                    </span>
                    <input
                      class="form-control"
                      placeholder="Hora de Inicio"
                      aria-label="Hora de Inicio"
                      [ngxTimepicker]="horaInicial"
                      formControlName="hora_inicial">
                    <ngx-material-timepicker
                      #horaInicial
                      [enableKeyboardInput]="true"
                      [hoursOnly]="true"
                    ></ngx-material-timepicker>
                  </div>

                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                  <label class="control-label">HORA DE CIERRE</label>
                  <div class="input-group">
                    <span class="input-group-prepend" style="display: block;">
                      <div class="input-group-text" >
                        <i class="glyphicon glyphicon-time"></i>
                      </div>
                    </span>
                    <input
                      class="form-control"
                      placeholder="Hora de Cierre"
                      aria-label="Hora de Cierre"
                      [ngxTimepicker]="horaFinal"
                      formControlName="hora_final">
                    <ngx-material-timepicker
                      #horaFinal
                      [enableKeyboardInput]="true"
                      [hoursOnly]="true"
                    ></ngx-material-timepicker>
                  </div>

                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="control-label" for="duracion_espacios">ESPACIOS DE CITAS</label>
                    <select
                      formControlName="duracion_espacios"
                      id="duracion_espacios"
                      class="form-control"
                      title="Duracion Espacios Agenda"
                      data-style="btn btn-info btn-round">

                      <option value="05">5 Minutos</option>
                      <option value="10">10 Minutos</option>
                      <option value="15">15 Minutos</option>
                      <option value="20">20 Minutos</option>
                      <option value="30">30 Minutos</option>
                      <option value="60">60 Minutos</option>

                    </select>
                    <div class="help-block"></div>
                </div>
              </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-sm-7">
              <div class="form-group">
                <button
                  [disabled]="formConfig.invalid"
                  type="submit"
                  class="btn btn-success btn-block">
                  Registrar
                </button>
              </div>
            </div>
          </div>
      </div>
  </div>

</form>

<div class="mb-3 p-3" style='border: 3px solid #1d65b091 !important; border-radius: 10px;'>
  <div class="col-sm">
      <div class="row justify-content-center"><h5>LOGOS</h5></div>
      <div class="row justify-content-center text-center">
          <div class="col-12 col-sm-6 col-md-5">
            <label class="control-label" for="logo">LOGO PRINCIPAL</label><br>
            <div class="fileinput fileinput-new text-center" data-provides="fileinput">
              <div class="fileinput-new thumbnail img-raised logos_upload">
                <img src="{{urlImg}}logos/{{datos_config.logo}}{{timg}}">
              </div>
              <div class="fileinput-preview fileinput-exists thumbnail img-raised logos_upload"></div>
              <div>
                <button class="btn btn-success btn-round fileinput-exists" (click)="subirLogo('logo')" *ngIf="archivo_logo != null" >
                  <i class="fas fa-cloud-upload-alt"></i> Subir Imagen
                </button>
                <span class="btn btn-raised btn-round btn-info btn-file">
                    <span class="fileinput-new"><i class="fas fa-file-image mr-1"></i> Seleccionar Imagen</span>
                    <span class="fileinput-exists"><i class="fas fa-sync-alt mr-1"></i> Cambiar</span>
                    <input type="file" (change)="cambioLogo($event, 'logo')"/>
                </span>
                <a href="#" class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput">
                  <i class="fa fa-times"></i> Remover
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-5">
            <label class="control-label" for="logo_small">LOGO PEQUEÑO</label><br>
            <div class="fileinput fileinput-new text-center" data-provides="fileinput">
              <div class="fileinput-new thumbnail img-raised logos_upload">
                <img src="{{urlImg}}logos/{{datos_config.logo_small }}{{timg}}">
              </div>
              <div class="fileinput-preview fileinput-exists thumbnail img-raised logos_upload"></div>
              <div>
                <button class="btn btn-success btn-round fileinput-exists" (click)="subirLogo('logo_small')" *ngIf="archivo_logo_small != null">
                  <i class="fas fa-cloud-upload-alt"></i> Subir Imagen
                </button>
                <span class="btn btn-raised btn-round btn-info btn-file">
                    <span class="fileinput-new"><i class="fas fa-file-image mr-1"></i> Seleccionar Imagen</span>
                    <span class="fileinput-exists"><i class="fas fa-sync-alt mr-1"></i> Cambiar</span>
                    <input type="file" (change)="cambioLogo($event, 'logo_small')"/>
                </span>
                <a href="#" class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput">
                  <i class="fa fa-times"></i> Remover
                </a>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
