<div class="row">
  <div class="col-sm-5 mr-auto">
      <h1>Pagos/Salidas</h1>
  </div>
  <div class="col-sm-5 mr-auto">
    <div class="pull-right">
        <a [routerLink]="['/caja/medicos']" class="btn btn-primary">
          <i class="fas fa-money-bill-wave "></i> PAGO A DOCTORES
        </a>
    </div>
</div>
</div>

<div class="dropdown-divider mb-5" style='border-top: 3px solid #919a91a6;'></div>

<div class="row justify-content-center text-center">
  <div class="col-sm-8 col-md-6">
    <h6>CONCEPTO</h6>
    <div class="form-group">
      <input
        [(ngModel)]="concepto"
        type="text"
        id="concepto"
        class="form-control">
    </div>
  </div>
  <div class="col-sm-4 col-md-3">
    <h6>MONTO</h6>
    <div class="form-group">
      <input id="monto-input" class="form-control" currencyMask [(ngModel)]="monto" />
    </div>
  </div>
</div>

<div class="row justify-content-center mt-5">
  <div class="col-12 col-sm-8 col-md-5">
      <div class="form-group">
          <button
            [disabled]="concepto=='' || monto==''"
            (click)="registrarSalida()"
            class="btn btn-success btn-block">
            Registrar Movimiento
          </button>
      </div>
  </div>
</div>
