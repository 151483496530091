import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { CajaService } from 'src/app/services/caja.service';
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';

@Component({
  selector: 'create-corte',
  templateUrl: './create-corte.component.html',
})

export class CreatecorteComponent {

  public movimientos = null;
  public movimientos_p = null;
  public ultimo_corte = null;
  public detalle = {
    fecha_inicio: "",
    fecha_fin: "",
    total: "",
    total_entradas: "",
    total_salidas: "",
    total_pendiente: "",
    comentarios: ""
  };
  public saldo_medicos = 0;
  public dias_ultimo_semanal = 0;
  public dias_ultimo_mensual = 0;
  public tipo_corte = null;
  public iniciado = 0;
  public estado_apertura = 0;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService,
  ){

  }

  ngOnInit(){
    this.obtenerDatoscortes( );
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje,
      order:[[0, 'desc']]
    };
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  cambioFiltro( tipo ){
    this.obtenerDetalles(tipo);
  }

  obtenerDatoscortes( ){
    this.spinner.show('spnr-s');
    let respuesta = this._cajaService.obtenerDatoscortes( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.saldo_medicos = val.saldo_medicos;
          this.dias_ultimo_semanal = val.dias_ultimo_semanal;
          this.dias_ultimo_mensual = val.dias_ultimo_mensual;
          this.estado_apertura = val.apertura_caja;
          this.spinner.hide('spnr-s');
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
      }
    );
  }

  obtenerDetalles( tipo ){
    this.spinner.show('spnr-s');
    let respuesta = this._cajaService.filtroCorte( tipo );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.movimientos = val.movimientos;
          this.ultimo_corte = val.ultimo_corte;
          this.detalle.fecha_inicio = val.fechas.fecha_inicial;
          this.detalle.fecha_fin = val.fechas.fecha_final;
          this.detalle.total = val.totales.total;
          this.detalle.total_entradas = val.totales.entradas;
          this.detalle.total_salidas = val.totales.salidas;
          this.detalle.total_pendiente = val.totales.pendientes;
          this.movimientos_p = val.movimientos_p;

          if(this.iniciado == 0){
            this.iniciado = 1;
            this.dtTrigger.next();
          }else{
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          }
          this.spinner.hide('spnr-s');
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
      }
    );
  }

  registrarCorte(){

    Swal.fire({
      title: "¿Está seguro de realizar el corte de caja?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let respuesta = this._cajaService.registrarCorte( this.tipo_corte, this.detalle.comentarios );
        respuesta.then(
          val =>{
            if( val.status == "success" ){

              this.spinner.hide('spnr-s');
              Swal.fire({
                title: "Corte Realizado",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });

              let id_corte = val.id_corte;

              this._router.navigate(['/caja/corte', id_corte]);

            }else{

              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'No se ha registrado.',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });

            }

          },
          error =>{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'No se ha registrado.',
              text: 'Ha ocurrido un problema, por favor vuelve a intenarlo.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        );
      }
    })

  }

}
