export var Global = {

  url: "https://odontomeds.api.adclinik.com/api/",
  urlImg: "https://odontomeds.api.adclinik.com/img/",
  dominio : 'odontomeds',
  id_controlservicio: "1",
  id_contrato: "1",

  // url: "https://ortodental.api.adclinik.com/api/",
  // urlImg: "https://ortodental.api.adclinik.com/img/",
  // dominio : 'ortodental',
  // id_controlservicio: "4",
  // id_contrato: "4",

  // url: "http://localhost:8888/repositorios/api.adclinik.com/api/",
  // urlImg: "http://localhost:8888/repositorios/api.adclinik.com/img/",
  // dominio : 'odontomeds',
  // id_controlservicio: "1",
  // id_contrato: "1",

  lenguaje: {
    processing: "Procesando...",
    search: "Buscar:",
    lengthMenu: "Mostrar _MENU_ registros",
    info: "",
    infoEmpty: "Mostrando ningún elemento.",
    infoFiltered: "(filtrado _MAX_ elementos total)",
    infoPostFix: "",
    loadingRecords: "Cargando registros...",
    zeroRecords: "No se encontraron registros",
    emptyTable: "No hay datos disponibles en la tabla",
    paginate: {
      first: "",
      previous: "Anterior",
      next: "Siguiente",
      last: ""
    },
    aria: {
      sortAscending: ": Activar para ordenar la tabla en orden ascendente",
      sortDescending: ": Activar para ordenar la tabla en orden descendente"
    }
  }

}
