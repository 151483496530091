import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { CajaService } from 'src/app/services/caja.service';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";

@Component({
  selector: 'caja-salidas',
  templateUrl: './caja-salidas.component.html',
})

export class CajasalidasComponent {

  public concepto = "";
  public monto = "";
  public safari = 0;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService,
  ){

  }

  ngOnInit(){
    this.spinner.show('spnr-s');
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      this.safari = 1;
    }

    let respuesta = this._cajaService.validarApertura( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          if(val.apertura == 0){
            this._router.navigate(['/caja/apertura']);
          }
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        this._router.navigate(['/error']);
      }
    );
  }

  registrarSalida(){

    Swal.fire({
      title: "Se registrará un movimiento de Salida por: $" + this.monto,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let datos = {
          monto: this.monto,
          concepto: this.concepto
        };

        let respuesta = this._cajaService.registrarSalida( datos );
        respuesta.then(
          val =>{
            if( val.status == "success" ){

              this.spinner.hide('spnr-s');
              Swal.fire({
                title: "Registrado",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });

              let estado_ticket = val.ticket.ticket;
              let ticket_id = val.ticket.ticket_id;
              let tipo = val.ticket.tipo;
              this.imprimirTicket(ticket_id);

            }else{

              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'No se ha registrado.',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });

            }

          },
          error => {
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'No se ha registrado.',
              text: 'Ha ocurrido un problema, por favor vuelve a intentarlo.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
            this._router.navigate(['/caja']);
          }
        );

      }
    })

  }

  imprimirTicket(id_ticket){
    var ancho = 8 / 2.54 * 72
    let respuesta = this._cajaService.obtenerTicket( id_ticket );
    respuesta.then(
      val =>{
        if( val.status == "success" ){

          var html = htmlToPdfmake(val.ticket, {tableAutoSize:true});
          const documentDefinition = {
            pageSize: {
              width: ancho,
              height: 'auto'
            },
            pageMargins: [12, 12, 12, 12 ],
            content: html
          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }
          this._router.navigate(['/caja/movimientos']);

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Ticket.',
            text: 'Por favor intentalo desde el listado de movimientos.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this._router.navigate(['/caja/movimientos']);
        }

      },
      error =>{
        this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Ticket.',
            text: 'Por favor intentalo desde el listado de movimientos.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this._router.navigate(['/caja/movimientos']);
      }
    );
  }

}
