import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";
import { ConfiguracionesService } from "./configuraciones.service";

@Injectable()

export class CajaService{
  public url: string;
  public token = null;
  public config = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.url = Global.url;
    this.token = this._usuarioService.getToken();
    this.config = this._configuracionesService.getConfig( );
  }

  async validarApertura( ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "caja/validarapertura/"+this.config.id_clinica, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarApertura( datos ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "datos": datos
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/registrarapertura", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerMovimientos( tipo ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      'tipo': tipo
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/listado", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerSaldos( ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "caja/saldos/"+this.config.id_clinica, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarEntrada( datos ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "datos": datos
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/registrarentrada", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarSalida( datos ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "datos": datos
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/registrarsalida", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async cancelarMovimiento( id_movimiento, motivo ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "im": id_movimiento,
      "motivo": motivo,
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/cancelarmovimiento", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async retiroBancario( id_movimiento ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "im": id_movimiento,
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/retirobancario", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerSaldomedico( id_medico ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "caja/saldomedico/"+id_medico, {headers: headers}).toPromise();

    return respuesta;

  }

  async retiroMedico( datos ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "datos": datos
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/retiromedico", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerTicket( id_ticket , tipo = null ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "it": id_ticket,
      "tipo": ( tipo != null )? tipo : "normal"
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/ticket", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerCortes( ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "caja/cortes/"+this.config.id_clinica, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerCorte( id_corte ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "ict": id_corte
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/corte", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerDatoscortes( ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "caja/datoscortes/"+this.config.id_clinica, {headers: headers}).toPromise();

    return respuesta;

  }

  async filtroCorte( tipo ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "tipo": tipo
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/filtrocorte", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarCorte( tipo, comentarios ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "tipo": tipo,
      "comentarios": comentarios
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/cortecaja", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerAdeudos( ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "caja/adeudos/"+this.config.id_clinica, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerAdeudo( id_paciente ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "ip": id_paciente,
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/adeudo", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerReportecorte( id_ticket ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "ict": id_ticket,
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/reportecorte", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerReportemovimientos( fi, ff, tipo, filtro ){

    let data = JSON.stringify({
      "ic": this.config.id_clinica,
      "fi": fi,
      "ff": ff,
      "tipo": tipo,
      "filtro": filtro,
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "caja/reportemovimientos", params, {headers: headers}).toPromise();

    return respuesta;

  }

}
