<ul class="nav nav-pills nav-pills-success nav-pills-icons justify-content-center" role="tablist">
  <li class="nav-item">
      <a class="nav-link active" href="#afacial" role="tab" data-toggle="tab">
        Analisis Facial
      </a>
  </li>
  <li class="nav-item">
      <a class="nav-link" href="#afuncional" role="tab" data-toggle="tab">
        Analisis Funcional
      </a>
  </li>
</ul>

<form [formGroup]="formaAnalisis" (ngSubmit)="registrarAnalisis(formaAnalisis.value)">

  <div class="tab-content tab-space">
      <div class="tab-pane active" id="afacial">

        <div class="container">
            <div class="row justify-content-center">
              <h3>ANALISIS FACIAL</h3>
            </div>
            <div class="row mb-3">
                <div class="col-sm ">
                  <div class="form-group">
                    <label class="control-label">Patrón Facial</label>
                      <mat-select formControlName="patron"  class="form-control">
                        <mat-option [value]="'Mesofacial'">Mesofacial</mat-option>
                        <mat-option [value]="'Dólico Facial'">Dólico Facial</mat-option>
                        <mat-option [value]="'Branquifacial'">Branquifacial</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Perfil</label>
                      <mat-select formControlName="perfil"  class="form-control">
                        <mat-option [value]="'Recto'">Recto</mat-option>
                        <mat-option [value]="'Cóncavo'">Cóncavo</mat-option>
                        <mat-option [value]="'Convexo'">Convexo</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Asimetría</label>
                      <mat-select formControlName="asimetria"  class="form-control">
                        <mat-option [value]="'Mand. Derecha'">Mand. Derecha</mat-option>
                        <mat-option [value]="'Mand. Izquierda'">Mand. Izquierda</mat-option>
                        <mat-option [value]="'Otras'">Otras</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Altura Facial</label>
                      <mat-select formControlName="altura"  class="form-control">
                        <mat-option [value]="'Equilibrada'">Equilibrada</mat-option>
                        <mat-option [value]="'Larga'">Larga</mat-option>
                        <mat-option [value]="'Corta'">Corta</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Ancho Facial</label>
                      <mat-select formControlName="ancho"  class="form-control">
                        <mat-option [value]="'Equilibrada'">Equilibrada</mat-option>
                        <mat-option [value]="'Estrecho'">Estrecho</mat-option>
                        <mat-option [value]="'Amplio'">Amplio</mat-option>
                      </mat-select>
                  </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Perfil Maxilar</label>
                      <mat-select formControlName="maxilar"  class="form-control">
                        <mat-option [value]="'Ortognático'">Ortognático</mat-option>
                        <mat-option [value]="'Prognático'">Prognático</mat-option>
                        <mat-option [value]="'Retrognático'">Retrognático</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Perfil Mandibular</label>
                      <mat-select formControlName="mandibular"  class="form-control">
                        <mat-option [value]="'Ortognático'">Ortognático</mat-option>
                        <mat-option [value]="'Prognático'">Prognático</mat-option>
                        <mat-option [value]="'Retrognático'">Retrognático</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Surco Labio-Mentón</label>
                      <mat-select formControlName="surco"  class="form-control">
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Marcado'">Marcado</mat-option>
                        <mat-option [value]="'Borrado'">Borrado</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Labios en Reposo</label>
                      <mat-select formControlName="labios"  class="form-control">
                        <mat-option [value]="'Componentes'">Componentes</mat-option>
                        <mat-option [value]="'Incompetentes'">Incompetentes</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Perfil Labial</label>
                      <mat-select formControlName="labial"  class="form-control">
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Protrusivo Sup'">Protrusivo Sup</mat-option>
                        <mat-option [value]="'Protrusivo Inf'">Protrusivo Inf</mat-option>
                        <mat-option [value]="'Retrusivos Sup'">Retrusivos Sup</mat-option>
                        <mat-option [value]="'Retrusivos Inf'">Retrusivos Inf</mat-option>
                      </mat-select>
                  </div>
                </div>
            </div>
        </div>

      </div>
      <div class="tab-pane" id="afuncional">

        <div class="container">
            <div class="row justify-content-center">
              <h3>ANALISIS FUNCIONAL</h3>
            </div>

            <div class="row mb-3">
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Respiración</label>
                      <mat-select formControlName="respiracion"  class="form-control">
                        <mat-option [value]="'Bucal'">Bucal</mat-option>
                        <mat-option [value]="'Nasal'">Nasal</mat-option>
                        <mat-option [value]="'Mixta'">Mixta</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Deglución</label>
                      <mat-select formControlName="deglucion"  class="form-control">
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Atípica'">Atípica</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Actividad Comisural</label>
                      <mat-select formControlName="comisural"  class="form-control">
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Contracción'">Contracción</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Actividad Lingual</label>
                      <mat-select formControlName="lingual"  class="form-control">
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Interp. Anterior'">Interp. Anterior</mat-option>
                        <mat-option [value]="'Interp. Lateral'">Interp. Lateral</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Labio Superior</label>
                      <mat-select formControlName="superior"  class="form-control">
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Hipoactivo'">Hipoactivo</mat-option>
                        <mat-option [value]="'Hiperactivo'">Hiperactivo</mat-option>
                      </mat-select>
                  </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Labio Inferior</label>
                      <mat-select formControlName="inferior"  class="form-control">
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Hipoactivo'">Hipoactivo</mat-option>
                        <mat-option [value]="'Hiperactivo'">Hiperactivo</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Masetero</label>
                      <mat-select formControlName="masetero"  class="form-control">
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Hipoactivo'">Hipoactivo</mat-option>
                        <mat-option [value]="'Hiperactivo'">Hiperactivo</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Mentoniano</label>
                      <mat-select formControlName="mentoniano"  class="form-control">
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Hipoactivo'">Hipoactivo</mat-option>
                        <mat-option [value]="'Hiperactivo'">Hiperactivo</mat-option>
                      </mat-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label class="control-label">Habitos de Succión</label>
                    <input
                      placeholder="Dedos, Lengua, Labios, Onicofagia"
                      type="text"
                      class="form-control"
                      formControlName="succion">
                  </div>
                </div>
            </div>
        </div>

      </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-sm-8 col-md-6 ">
        <div class="form-group">
            <button class="btn btn-success btn-block">
              Guardar Analisis
            </button>
        </div>
    </div>
  </div>

</form>
