<div class="row justify-content-center" id="notificaciones" *ngIf="notificaciones != null">
  <div class="col-sm-12 col-md-6 col-lg-6">
      <div class="card">
          <div class="card-header text-center">
              <h4 class="card-subtitle mb-2 text-muted" id="titulo-anuncio">Notificaciones del Servicio</h4>
          </div>
          <div class="card-body text-center" id="cuerpo-anuncio">

            <div *ngFor="let notifiacion of notificaciones, let i = index"
              class="alert alert-{{notifiacion.tipo}}"
              role="alert">
                {{notifiacion.notificacion}}
            </div>

          </div>
      </div>
  </div>
</div>
