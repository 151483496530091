export class Usuario{

  constructor(
    public id_usuario : number,
    public id_persona : number,
    public id_clinica : number,
    public telefono : string,
    public correo : string,
    public login : string,
    public pass : string,
    public tipo : string,
    public estado : number,
    public sesion : number,
    public sesion_pasada : string,
    public id_usuario_app : number,
  ){

  }

}
