import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { Global } from 'src/app/services/global';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'citas_paciente',
  templateUrl: './citas_paciente.component.html',
})

export class CitaspacienteComponent {

  public id_paciente = null;
  public subscribe = null;
  public citas = null;
  public iniciado = 0;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
    private _pacienteService: PacientesService,
    private _expedienteService: ExpedienteService,
  ){

  }

  ngOnInit(){
    this.iniciado = 0;
    this._route.params.forEach((params:Params) => {
      this.id_paciente =  params['id'];
    });
    if( this.id_paciente == null ){
      this.id_paciente = this._route.parent.snapshot.paramMap.get('id');
    }
    this.subscribe = this._pacienteService.id_paciente$.subscribe( id_paciente =>{
      this.id_paciente = id_paciente;
    });

    this.obtenerCitas();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje,
      order:[[0, 'desc']]
    };
  }

  ngOnDestroy(){
    this.subscribe.unsubscribe();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  obtenerCitas(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerCitas( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            this.citas = val.citas;

            if(this.iniciado == 0){
              this.iniciado = 1;
              this.dtTrigger.next();
            }else{
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
              });
            }

          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  cambiarEstado(id_cita){
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.cambiarEstadocita( id_cita );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Registro Exitoso",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this.ngOnInit();
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al registrar los datos, por favor intentalo nuevamente.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }
}
