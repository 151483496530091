<h1>Documentos Clínicos</h1>

<br>
<div class="dropdown-divider"></div>
<br>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-sm-4">
            <button
              (click)="historiaConfig(contentModal)"
              class="btn btn-info btn-sq-lg"
              title="Historia Clínica">
              <p>
                <i class="fas fa-file-medical-alt fa-5x"></i>
              </p>
              <p>HISTORIA CLINICA</p>
            </button>
          </div>
        <div class="col-sm-4">
            <button
              (click)="consentimiento()"
              class="btn btn-primary btn-sq-lg">
              <p>
                <i class="fas fa-file-signature fa-5x"></i>
              </p>
              <p>CONSENTIMIENTO INF.</p>
            </button>
          </div>
    </div>
</div>

<ng-template #contentModal let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h5 class="modal-title">Detalles de Historia Clínica</h5>
  </div>
  <div class="modal-body">
    <div class="container">

        <div class="row pb-4">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.todo" color="primary" (change)="configHistoria(1)">
                SELECCIONAR TODO
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.generales" color="primary" (change)="configHistoria(0)">
                Generales
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.heredofamiliares" color="primary" (change)="configHistoria(0)">
                Antecedentes Heredofamiliares y Personales Patológicos
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.nopatologicos" color="primary" (change)="configHistoria(0)">
                Antecedentes Personales No Patológicos
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.gineco" color="primary" (change)="configHistoria(0)">
                Antecedentes Gineco-Obstetricos
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.personalizado" color="primary" (change)="configHistoria(0)">
                Cuestionario Personalizado
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.exploracion" color="primary" (change)="configHistoria(0)">
                Exploración Física
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.odontogramas" color="primary" (change)="configHistoria(0)">
                Odontogramas
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.notas" color="primary" (change)="configHistoria(0)">
                Notas de Evolución
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle [(ngModel)]="config.presupuesto" color="primary" (change)="configHistoria(0)">
                Presupuesto de Tratamiento
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row pt-5">
            <div class="col-sm-12">
                <div class="form-group">
                  <button class="btn btn-success btn-block" (click)="generarHistoria()">
                    Generar Documento
                  </button>
                </div>
            </div>
        </div>

    </div>
  </div>

</ng-template>
