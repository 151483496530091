<div class="row">
  <div class="col-sm-8">
      <h1>Historial de Movimientos</h1>
  </div>
  <div class="col-sm-4">
      <div class="pull-right">
          <p>
              <button class="btn btn-success" (click)="consultarSaldo(contentModal)">
                <i class="fas fa-wallet"></i> Consultar Saldo
              </button>
          </p>
      </div>
  </div>
</div>

<div class="row justify-content-center text-center">
  <div class="col-sm-12 col-md-3">
    <label class="control-label">Filtro de Movimientos</label>

    <div class="form-group">
      <mat-select
        class="form-control"
        [(ngModel)]="tipo_filtro"
        placeholder="Selecciona un Filtro"
        (selectionChange)="cambioFiltro($event.value)">
        <mat-option [value]="'D'">Ultimo Corte Diario</mat-option>
        <mat-option [value]="'S'">Ultimo Corte Semanal</mat-option>
        <mat-option [value]="'M'">Ultimo Corte Mensual</mat-option>
        <mat-option [value]="'A'">Todos los Movimientos</mat-option>
      </mat-select>
    </div>
  </div>
</div>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc" style="width: 8%;">Folio</th>
      <th class="disabled-sorting sorting_desc">Concepto</th>
      <th class="disabled-sorting sorting_desc" style="width: 8%;">Monto</th>
      <th class="disabled-sorting sorting_desc">PX</th>
      <th class="disabled-sorting sorting_desc" style="width: 8%;">Tipo</th>
      <th class="disabled-sorting sorting_desc">Reg</th>
      <th class="disabled-sorting sorting_desc">Fecha</th>
      <th class="disabled-sorting sorting_desc text-center" style="width: 8%;" data-priority="1">Acciones</th>
      <th class="disabled-sorting sorting_desc text-center" style="width: 8%;" data-priority="2">Cancelar</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let movimiento of movimientos"
      [ngStyle]="{'background-color': (movimiento.estado_tarjeta == '0') ? '#d6ff002e' : (movimiento.estado == '1') ? '#ff00002e' : (movimiento.tipo == 'Apertura') ? '#00ff0c2e' : null }"
      >
      <td style="width: 8%;">{{ movimiento.id_mov_caja }}</td>
      <td>{{ movimiento.concepto }}</td>
      <td style="width: 8%;">${{ movimiento.monto }}</td>
      <td>{{ movimiento.paciente }}</td>
      <td style="width: 8%;">{{ movimiento.tipo }}</td>
      <td>{{ movimiento.registro }}</td>
      <td>{{ movimiento.fecha }}</td>
      <td style="width: 8%;" class="text-center">
        <button *ngIf="movimiento.tarjeta == 1 && movimiento.estado_tarjeta == 0 && permisos.u" class="btn btn-success btn-icon btn-sm mr-1"  (click)="ejecutarRetiro(movimiento.id_mov_caja)" title="Retiro Bancario" aria-label="Retiro Bancario" data-pjax="0">
          <i class="fa fa-check"></i>
        </button>
        <button *ngIf="movimiento.tipo != 'Apertura' && movimiento.estado != '1' && movimiento.tecnico == '0'" class="btn btn-info btn-icon btn-sm"  (click)="imprimirTicket(movimiento.id_ticket)" title="Imprimir Ticket" aria-label="Imprimir Ticket" data-pjax="0">
          <i class="fas fa-ticket-alt"></i>
        </button>
      </td>
      <td style="width: 8%;" class="text-center">
        <button *ngIf="(movimiento.cancelarE == 1 || movimiento.cancelarS == 1) && permisos.d && movimiento.tecnico == '0'" class="btn btn-danger btn-icon btn-sm"  (click)="cancelarMovimiento(movimiento.id_mov_caja)" title="Cancelar" aria-label="Cancelar" data-pjax="0">
          <i class="fa fa-times"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #contentModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title" id="modal-primary-title">Consulta de Saldo</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body centered formulario" *ngIf="saldos != null">
    <div class="dropdown-divider"></div>
    <div class="container ">
        <div class="row justify-content-center">
            <div class="col-sm text-center">
                <h3>Balance General</h3>
                <h4>$ {{saldos.total}} MXN</h4>
            </div>
        </div>
        <div class="dropdown-divider"></div>
        <div class="row justify-content-center">
            <div class="col-sm text-center">
                <h3>Saldo Clinica</h3>
                <h4>$ {{saldos.saldoClinica}} MXN</h4>

            </div>
            <div class="col-sm text-center">
                <h3>Saldo de Médicos</h3>
                <h4>$ {{saldos.totalMedicos}} MXN</h4>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-3 text-center">
                <p>
                    <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        INFORMACION DETALLADA
                    </button>
                </p>
            </div>
        </div>
    </div>
    <br>
    <div class="dropdown-divider"></div>
    <br>
    <div class="collapse" id="collapseExample">
        <div class="card card-body ">
            <div class="text-center"><h3>Detalle de Saldo</h3></div>
            <div class="container">
                <div class="row justify-content-center mb-3">
                    <div class="col-sm"><h6>Balance General</h6> ${{saldos.total}} <br> </div>
                    <div class="col-sm"><h6>Caja General </h6> ${{saldos.saldoCajaGlobal}} <br> </div>
                    <div class="col-sm"><h6>Tarjeta General </h6> ${{saldos.saldoTarjetaGlobal}} <br> </div>
                </div>
                <div class="row justify-content-center mb-3">
                    <div class="col-sm"><h6>Total Clinica </h6> ${{saldos.saldoClinica}} <br> </div>
                    <div class="col-sm"><h6>Saldo Caja </h6> ${{saldos.saldoCaja}} <br> </div>
                    <div class="col-sm"><h6>Saldo Tarjeta </h6> ${{saldos.saldoTarjetaClinica}} <br> </div>
                </div>
                <div class="row justify-content-center mb-3">
                    <div class="col-sm"><h6>Total Médicos </h6> ${{saldos.totalMedicos}} <br> </div>
                    <div class="col-sm"><h6>Saldo Caja Médicos </h6> ${{saldos.saldoCajaMedicos}} <br> </div>
                    <div class="col-sm"><h6>Saldo Tarjeta Médicos </h6> ${{saldos.saldoTarjetaMedicos}} <br> </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</ng-template>
