import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { CajaService } from 'src/app/services/caja.service';
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";

@Component({
  selector: 'caja-cortes',
  templateUrl: './caja-cortes.component.html',
})

export class CajacortesComponent {

  public cortes = null;
  public iniciado = 0;
  public safari = 0;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService,
    private modalService: NgbModal,
  ){

  }

  ngOnInit(){

    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      this.safari = 1;
    }

    this.obtenerCortes();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje,
      order:[[0, 'desc']]
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  obtenerCortes( filtro = null ){
    this.spinner.show('spnr-s');
    let respuesta = this._cajaService.obtenerCortes( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.cortes = val.cortes;
          if(this.iniciado == 0){
            this.iniciado = 1;
            this.dtTrigger.next();
          }else{
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          }
          this.spinner.hide('spnr-s');
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
      }
    );
  }

  verCorte(id_corte){
    this._router.navigate(['/caja/corte', id_corte]);
  }

  crearCorte(){
    this._router.navigate(['/caja/corte']);
  }

  obtenerFecha(){

    var d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let hours = d.getHours();
    let minutes = d.getMinutes();

    let min = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + min ;

    return [day, month, year].join('-') + " "+strTime;

  }

  imprimirCorte(id_corte){
    let respuesta = this._cajaService.obtenerReportecorte( id_corte );
    respuesta.then(
      val =>{
        if( val.status == "success" ){

          var html = htmlToPdfmake(val.reporte, {tableAutoSize:true});
          const documentDefinition = {
            pageSize: 'A4',
            header:{
              columns:[
                {
                  text: "Documento Generado: "+this.obtenerFecha(),
                  alignment: 'right',
                  color: '#4f4c4c',
                }
              ],
              margin: [40,20]
            },
            content: html,
            styles:{
              'table-bordered':{
                border: '1px solid #dee2e6',
              }
            }
          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Reporte.',
            text: 'Por favor intentalo nuevamente.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }

      },
      error =>{
        this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Reporte.',
            text: 'Por favor intentalo nuevamente.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
      }
    );
  }

}
