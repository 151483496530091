<form [formGroup]="formPermisos" (ngSubmit)="registrarPermisos(formPermisos.value)">

  <div class="row">
    <div class="col-md-6 ml-auto">
        <h1>Modificar Permisos</h1>
    </div>
    <div class="col-md-5 mr-auto" >
        <div class="pull-right">
          <button
            [disabled]="formPermisos.invalid"
            type="submit"
            class="btn btn-success float-md-right">
            <i class="far fa-save icono"></i>
            Guardar Permisos
          </button>
        </div>
    </div>
  </div>

  <input type="hidden" formControlName="id_permiso" id="id_permiso">
  <input type="hidden" formControlName="agenda" id="agenda">
  <input type="hidden" formControlName="pacientes" id="pacientes">
  <input type="hidden" formControlName="caja" id="caja">
  <input type="hidden" formControlName="clinicas" id="clinicas">
  <input type="hidden" formControlName="usuarios" id="usuarios">
  <input type="hidden" formControlName="tecnicos" id="tecnicos">
  <input type="hidden" formControlName="inventarios" id="inventarios">
  <input type="hidden" formControlName="configuracion" id="configuracion">

</form>

<div class="container p-3 m-2"  style='border: 3px solid #1d65b091 !important; border-radius: 10px !important;'>
  <div class="row">
      <table class="table table-striped text-center" >
          <thead>
              <tr>
                  <th scope="col">#</th>
                  <th scope="col">REGISTRAR</th>
                  <th scope="col">VISUALIZAR</th>
                  <th scope="col">ACTUALIZAR</th>
                  <th scope="col">ELIMINAR</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <th scope="row">AGENDA</th>
                  <td><mat-slide-toggle id='agenda_c' [checked]="permisos.agenda.c" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='agenda_r' [checked]="permisos.agenda.r" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='agenda_u' [checked]="permisos.agenda.u" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='agenda_d' [checked]="permisos.agenda.d" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
              </tr>
              <tr>
                  <th scope="row">PACIENTES</th>
                  <td><mat-slide-toggle id='pacientes_c' [checked]="permisos.pacientes.c" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='pacientes_r' [checked]="permisos.pacientes.r" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='pacientes_u' [checked]="permisos.pacientes.u" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='pacientes_d' [checked]="permisos.pacientes.d" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
              </tr>
              <tr>
                  <th scope="row">CAJA</th>
                  <td><mat-slide-toggle id='caja_c' [checked]="permisos.caja.c" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='caja_r' [checked]="permisos.caja.r" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='caja_u' [checked]="permisos.caja.u" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='caja_d' [checked]="permisos.caja.d" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
              </tr>
              <tr>
                  <th scope="row">CLINICAS</th>
                  <td><mat-slide-toggle id='clinicas_c' [checked]="permisos.clinicas.c" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='clinicas_r' [checked]="permisos.clinicas.r" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='clinicas_u' [checked]="permisos.clinicas.u" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='clinicas_d' [checked]="permisos.clinicas.d" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
              </tr>
              <tr>
                  <th scope="row">USUARIOS</th>
                  <td><mat-slide-toggle id='usuarios_c' [checked]="permisos.usuarios.c" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='usuarios_r' [checked]="permisos.usuarios.r" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='usuarios_u' [checked]="permisos.usuarios.u" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td></td>
              </tr>
              <tr>
                  <th scope="row">TECNICOS</th>
                  <td><mat-slide-toggle id='tecnicos_c' [checked]="permisos.tecnicos.c" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='tecnicos_r' [checked]="permisos.tecnicos.r" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='tecnicos_u' [checked]="permisos.tecnicos.u" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='tecnicos_d' [checked]="permisos.tecnicos.d" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
              </tr>
              <tr>
                  <th scope="row">INVENTARIOS</th>
                  <td><mat-slide-toggle id='inventarios_c' [checked]="permisos.inventarios.c" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='inventarios_r' [checked]="permisos.inventarios.r" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='inventarios_u' [checked]="permisos.inventarios.u" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='inventarios_d' [checked]="permisos.inventarios.d" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
              </tr>
              <tr>
                  <th scope="row">CONFIGURACION</th>
                  <td><mat-slide-toggle id='configuracion_c' [checked]="permisos.configuracion.c" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='configuracion_r' [checked]="permisos.configuracion.r" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='configuracion_u' [checked]="permisos.configuracion.u" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
                  <td><mat-slide-toggle id='configuracion_d' [checked]="permisos.configuracion.d" color="primary" (change)="cambioEstado($event)" ></mat-slide-toggle> </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
