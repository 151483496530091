import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'usuario-registro',
  templateUrl: './usuario-registro.component.html',
})

export class UsuarioregistroComponent {

  formUsuario: FormGroup;
  @Input() cModal:boolean;
  @Input() public activeModal;
  @Input() public id_usuario;
  @Input() public nuevoUsuario;

  autoTicks = false;
  showTicks = false;
  tickInterval = 10;

  public clinicas = null;
  public estado_medico = 0;
  public identity = null;

  public datos_usuario = {
    "persona": {
      "id_persona": "",
      "nombre": "",
      "ape_pat": "",
      "ape_mat": "",
      "tel": "",
      "direccion": "",
      "estado_civil": "",
      "tipo_sangre": "",
      "correo": ""
    },
    "estudio": {
      "nombre": "",
      "tipo_estudio": "",
      "cedula": ""
    },
    "doctor": {
      "comision": 50,
      "estado_comision": 0
    },
    "usuario": {
      "tipo": "",
      "medico": 0,
      "login": "",
      "pass": "",
      "id_clinica": ""
    }
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _usuariosService: UsuariosService,
    private _configuracionesService: ConfiguracionesService,
    private _usuarioService: UsuarioService,
  ){
    this.colocarValores( );
  }

  ngOnInit(){
    this.identity = this._usuarioService.getIdentity();
    this.obtenerClinicas();
    this._route.params.forEach((params:Params) => {
      this.id_usuario = params['id'];
      this.id_usuario = ( isNaN( parseInt( this.id_usuario ) ) ) ? null : parseInt( this.id_usuario );
      this.obtenerUsuario();
    });
  }

  colocarValores( usuario = null ){

    let datos = null;

    if( usuario != null ){
      datos = usuario;
    }else{
      datos = this.datos_usuario;
    }
    this.datos_usuario = datos;
    datos.usuario.medico = (datos.usuario.medico == "1" || datos.usuario.medico == true || datos.usuario.medico == 1) ? true : false;
    datos.doctor.estado_comision = (datos.doctor.estado_comision == "1" || datos.doctor.estado_comision == true || datos.doctor.estado_comision == 1) ? true : false;
    this.estado_medico = datos.usuario.medico;

    this.formUsuario = this._bilder.group({
      id_persona : [ datos.persona.id_persona ],
      nombre: [ datos.persona.nombre, Validators.compose([ Validators.maxLength(45), Validators.required ]) ],
      ape_pat: [ datos.persona.ape_pat, Validators.compose([ Validators.maxLength(45), Validators.required ]) ],
      ape_mat: [ datos.persona.ape_mat, Validators.compose([ Validators.maxLength(45), Validators.required ]) ],
      tel: [ datos.persona.tel , Validators.compose([ Validators.maxLength(20), Validators.required ])],
      direccion: [ datos.persona.direccion , Validators.compose([ Validators.maxLength(100), Validators.required ]) ],
      estado_civil: [ datos.persona.estado_civil, Validators.required ],
      tipo_sangre: [ datos.persona.tipo_sangre, Validators.compose([ Validators.required ]) ],
      correo: [ datos.persona.correo , Validators.maxLength(100) ],
      nombre_estudio: [ datos.estudio.nombre , Validators.maxLength(45)],
      tipo_estudio: [ datos.estudio.tipo_estudio , Validators.maxLength(45)],
      cedula: [ datos.estudio.cedula , Validators.maxLength(45)],
      comision: [ datos.doctor.comision ],
      estado_comision: [ datos.doctor.estado_comision ],
      tipo: [ datos.usuario.tipo,  Validators.required ],
      login: [ datos.usuario.login, Validators.compose([ Validators.maxLength(45), Validators.required ]) ],
      medico: [ datos.usuario.medico ],
      pass: [ "", Validators.compose([ Validators.maxLength(20), Validators.minLength(6), Validators.required ])],
      id_clinica: [ datos.usuario.id_clinica, Validators.required ],
    });

    if( datos.persona.id_persona != "" ){
      this.formUsuario.get('pass').clearValidators();
      this.formUsuario.get('pass').setValidators([ Validators.compose([ Validators.maxLength(20), Validators.minLength(6) ]) ]);
      this.formUsuario.get('pass').updateValueAndValidity();
      this.formUsuario.get('tipo').clearValidators();
      this.formUsuario.get('tipo').updateValueAndValidity();
      this.formUsuario.get('id_clinica').clearValidators();
      this.formUsuario.get('tipo').updateValueAndValidity();
    }

  }

  obtenerUsuario(){
    if( this.id_usuario != null){
      let respuesta = this._usuariosService.obtenerUsuario( this.id_usuario );
      respuesta.then( val =>{
        if( val.status == "success" ){
          if(val.usuario != null ){
            this.colocarValores( val.usuario );
          }
        }
      });
    }
  }

  obtenerClinicas(){
    let respuesta = this._configuracionesService.obtenerClinicas( );
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.cantidad >= 1){
          this.clinicas = val.clinicas;
        }
      }
    });
  }

  obtenerFecha( fecha = null, formato = null ){

    if( fecha != null){
      var d = new Date( fecha );
    }else{
      var d = new Date();
    }

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if( formato != null && formato == "en" ){
      return [year, month, day].join('-') ;
    }else{
      return [day, month, year].join('-') ;
    }

  }

  registrarUsuario( datos ){

    this.spinner.show('spnr-s');
    let respuesta = null;
    let titulo = "";

    datos.medico = ( datos.medico == true ) ? '1' : '0';
    datos.estado_comision = ( datos.estado_comision == true ) ? '1' : '0';
    datos.id_persona = ( datos.id_persona == "" )? "null" : datos.id_persona;

    datos = {
      "persona": {
        "id_persona": datos.id_persona,
        "nombre": datos.nombre,
        "ape_pat": datos.ape_pat,
        "ape_mat": datos.ape_mat,
        "tel": datos.tel,
        "direccion": datos.direccion,
        "estado_civil": datos.estado_civil,
        "tipo_sangre": datos.tipo_sangre,
        "correo": datos.correo
      },
      "estudio": {
        "nombre_estudio": datos.nombre_estudio,
        "tipo_estudio": datos.tipo_estudio,
        "cedula": datos.cedula
      },
      "doctor": {
        "comision": datos.comision,
        "estado_comision": datos.estado_comision
      },
      "usuario": {
        "tipo": datos.tipo,
        "medico": datos.medico,
        "login": datos.login,
        "pass": datos.pass,
        "id_clinica": datos.id_clinica
      }
    };

    if( datos.persona.id_persona == "null" ){
      respuesta = this._usuariosService.registrarUsuario( datos );
      titulo = 'Usuario Registrado Exitosamente';
    }else{
      respuesta = this._usuariosService.actualizarUsuario( datos );
      titulo = 'Usuario Actualizado Exitosamente';
    }

    respuesta.then( val =>{
      if( val.status == "success" ){

        this.spinner.hide('spnr-s');
        Swal.fire({
          title: titulo,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });

        if( datos.persona.id_persona != "null" ){
          if( val.mu == "1" || val.mu == 1 ){

            this.spinner.show('spnr-s');
            let respuesta = this._usuarioService.cerrarS( );
            respuesta.then(
              val =>{
                if( val.status == "success" ){
                  this.spinner.hide('spnr-s');

                  this._usuarioService.cerrarSesion();
                  this._router.navigate(['login']);

                }else{
                  Swal.fire({
                    title: 'Oopss...',
                    text: "Ha ocurrido un problema al realizar esta acción. Por favor vuelve a intentarlo.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#1beb11',
                  });
                }
              },
              error => {
                this.spinner.hide('spnr-s');
                Swal.fire({
                  title: 'Oopss...',
                  text: "Ha ocurrido un problema al realizar esta acción. Por favor vuelve a intentarlo.",
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#1beb11',
                });
              }
            );

          }
        }else{
          this._router.navigate(['/usuarios']);
        }

      }else{

        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: val.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });

      }
    });

  }

  estadoMedico( event:any ){
    if (event.checked === true) {
      //this.estado_medico = 1;
      $( "#datosBasEstudios" ).slideDown( "slow" );
    } else {
      //this.estado_medico = 0;
      $( "#datosBasEstudios" ).slideUp( "slow" );
    }
  }

  onInputChange( event ) {
    this.formUsuario.controls['comision'].setValue(event.value);
  }

}
