import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { Global } from 'src/app/services/global';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'notas_evolucion',
  templateUrl: './notas_evolucion.component.html',
})

export class NotasevolucionComponent {

  @Input() public id_tratamiento = null;
  public notas = null;
  formNota: FormGroup;
  public activeModal = null;

  public iniciado = 0;
  public nota = {
    'id_notas_evolucion': '',
    'descripcion': '',
    'id_tratamiento': '',
  };
  public nota_view = null;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _bilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _expedienteService: ExpedienteService,
    private modalService: NgbModal,
  ){

  }

  ngOnInit(){
    this.obtenerNotas();
    this.colocarValores();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje,
      order:[[0, 'desc']]
    };

  }

  ngOnChanges() {
    this.ngOnInit();
  }

  obtenerNotas(){
    this.spinner.show('spnr-s');
    if( this.id_tratamiento != null){
      let respuesta = this._expedienteService.obtenerNotas( this.id_tratamiento );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            this.notas = val.notas;

            if(this.iniciado == 0){
              this.iniciado = 1;
              this.dtTrigger.next();
            }else{
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
              });
            }

          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  colocarValores( nota = null ){
    let datos = null;
    if( nota != null ){
      datos = nota;
    }else{
      datos = this.nota;
    }

    this.formNota = this._bilder.group({
      id_nota : [datos.id_notas_evolucion],
      nota: [ datos.descripcion, Validators.maxLength(255) ],
      id_tratamiento: [ this.id_tratamiento ]
    });
  }

  registroNota(contentModal){
    this.colocarValores();
    this.activeModal = this.modalService.open(contentModal);
  }

  verNota(id_nota, viewModal){
    this.nota_view = null;
    this.activeModal = null;
    this.notas.forEach( element => {
        if(element.id_notas_evolucion == id_nota){
          this.nota_view = element;
          this.activeModal = this.modalService.open(viewModal);
        }
    });

  }

  editarNota(id_nota, contentModal){
    this.notas.forEach( element => {
      if(element.id_notas_evolucion == id_nota){
        this.colocarValores(element);
        this.activeModal = this.modalService.open(contentModal);
      }
  });
  }

  eliminarNota(id_nota){

    Swal.fire({
      title: '¿Está seguro de eliminar este Elemento? ',
      text: 'Esta acción no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show('spnr-s');
        let respuesta = this._expedienteService.eliminarNota( id_nota );
        respuesta.then(
          val =>{
            if( val.status == "success" ){
              this.activeModal.close();
              this.spinner.hide('spnr-s');
              Swal.fire({
                title: "Nota Eliminada Exitosamente",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
              this.obtenerNotas();
            }else{
              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'Oopss...',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
            }
          },
          error =>{
            this.spinner.hide('spnr-s');
            Swal.fire({
                title: 'Oopss...',
                text: 'Ha ocurrido un problema al eliminar este elemento.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
          }
        );

      }
    })

  }

  registrarNota(datos){
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.registrarNota( datos );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.activeModal.close();
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Nota Registrada Exitosamente",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this.obtenerNotas();
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al registar los datos.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
      }
    );
  }

}
