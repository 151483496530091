import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgendaService } from 'src/app/services/agenda.service';
import { AnotacioncitaComponent } from '../anotacioncita/anotacioncita.component';
import { CitascreateComponent } from '../create/citas-create.component';
import Swal from 'sweetalert2';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';

@Component({
  selector: 'vercita',
  templateUrl: './vercita.component.html',
})

export class VercitaComponent {

  public id_cita = null;
  public cita = null;
  public cita_pasada = 0;
  public permisos = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _agendaService: AgendaService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.permisos = this._configuracionesService.getPermisos('agenda');
  }

  ngOnInit(){

    this._route.params.forEach((params:Params) => {
      if( params['id'] != null ){
        this.id_cita = params['id'];
        this.obtenerCita( this.id_cita );
      }else{
        this._router.navigate([ 'agenda' ]);
      }
    });

  }

  obtenerCita( id_cita ){
    this.spinner.show('spnr-s');

    let respuesta = this._agendaService.obtenerCita( id_cita );

    respuesta.then( val =>{
      if( val.status == "success" && val.cita != 0 ){
        this.cita = val.cita;
        this.spinner.hide('spnr-s');
        let actual = new Date();
        let f_cita = new Date( this.cita.fecha_programada_inicial );
        if( f_cita <= actual ){
            this.cita_pasada = 1;
        }
      }else{
        this.spinner.hide('spnr-s');
        this._router.navigate([ 'agenda' ]);
      }
    });

  }

  anotacion(id_cita ){
    const modalRef = this.modalService.open(AnotacioncitaComponent, { size: 'lg' });
    modalRef.componentInstance.id_cita = id_cita;
  }

  editarCita(id_cita){
    const modalRef = this.modalService.open(CitascreateComponent, { size: 'lg' });
    modalRef.componentInstance.id_cita = id_cita;
  }

  cancelarCita(id_cita){

    Swal.fire({
      title: '¿Está seguro de cancelar la Cita? ',
      text: "Menciona el Motivo",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      inputAttributes: {
        autocapitalize: 'off'
      },
      input: 'text',
      preConfirm: (concepto) => {

        if(concepto == null || concepto == undefined){
          concepto = "Sin Concepto";
        }
        let respuesta = this._agendaService.cancelarCita( id_cita, concepto );
        respuesta.then( val =>{
          if( val.status == "success" ){

            Swal.fire({
              title:'¡Registrado!',
              icon: "success",
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Aceptar'
            });
            this._router.navigate(['agenda']);
            // window.location.reload();
            // this._router.navigate(['/agenda/cita', id_cita]);

          }else{
            Swal.fire({
              title:'Oopss...',
              text: val.message,
              icon: "warning",
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Aceptar'
            });
          }
        });

        return "ffffff";

      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }

  inasistenciaCita(id_cita){

    Swal.fire({
      title: 'Menciona el motivo de la Inasistencia',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      inputAttributes: {
        autocapitalize: 'off'
      },
      input: 'text',
      preConfirm: (concepto) => {

        if(concepto == null || concepto == undefined){
          concepto = "Sin Concepto";
        }
        let respuesta = this._agendaService.inasistenciaCita( id_cita, concepto );
        respuesta.then( val =>{
          if( val.status == "success" ){

            Swal.fire({
              title:'¡Registrado!',
              icon: "success",
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Aceptar'
            });

            this._router.navigate(['/agenda/cita', id_cita]);

          }else{
            Swal.fire({
              title:'Oopss...',
              text: val.message,
              icon: "warning",
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Aceptar'
            });
          }
        });

        return "ffffff";

      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }

  eliminarDeshabilitado(id_cita){

    Swal.fire({
      title: '¿Está seguro de eliminar el bloque dehabilitado? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        let respuesta = this._agendaService.eliminarDeshabilitada( id_cita );
        respuesta.then( val =>{
          if( val.status == "success" ){

            Swal.fire({
              title:'¡Registrado!',
              icon: "success",
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Aceptar'
            });

            this._router.navigate(['agenda']);

          }else{

            Swal.fire({
              title:'Oopss...',
              text: val.message,
              icon: "warning",
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Aceptar'
            });

          }

        });

      }
    })

  }


}
