import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { UsuarioService } from "./services/usuario.service";

import { NoencontradaComponent } from "./components/layouts/noencontrada/noencontrada.component";
import { LoginComponent } from "./components/login/login.component";

import { AgendaComponent } from "./components/sistema/agenda/agenda/agenda.component";
import { VercitaComponent } from "./components/sistema/agenda/vercita/vercita.component";

import { ClinicasComponent } from "./components/sistema/administracion/clinicas/clinicas/clinicas.component";
import { ClinicaComponent } from "./components/sistema/administracion/clinicas/clinica/clinica.component";
import { CreatecitasComponent } from "./components/sistema/agenda/createcitas/createcitas.component";

import { CajaComponent } from "./components/sistema/caja/caja/caja.component";
import { CajaaperturaComponent } from "./components/sistema/caja/apertura/caja-apertura.component";
import { CajaentradasComponent } from "./components/sistema/caja/entradas/caja-entradas.component";
import { CajasalidasComponent } from "./components/sistema/caja/salidas/caja-salidas.component";
import { CajamovimientosComponent } from "./components/sistema/caja/movimientos/caja-movimientos.component";
import { CajacortesComponent } from "./components/sistema/caja/cortes/caja-cortes.component";
import { CajacorteComponent } from "./components/sistema/caja/cortes/corte/caja-corte.component";
import { CreatecorteComponent } from "./components/sistema/caja/cortes/create-corte/create-corte.component";
import { CajamedicosComponent } from "./components/sistema/caja/medicos/caja-medicos.component";
import { CajamedicoComponent } from "./components/sistema/caja/medicos/medico/caja-medico.component";
import { CajaadeudosComponent } from "./components/sistema/caja/adeudos/caja-adeudos.component";
import { CajaadeudoComponent } from "./components/sistema/caja/adeudos/adeudo/caja-adeudo.component";
import { CajareportesComponent } from "./components/sistema/caja/reportes/caja-reportes.component";

import { PacientesComponent } from "./components/sistema/pacientes/pacientes/pacientes.component";
import { PacienteregistroComponent } from "./components/sistema/pacientes/parciente-registro/paciente-registro.component";
import { PacienteactualizarComponent } from "./components/sistema/pacientes/paciente-actualizar/paciente-actualizar.component";
import { ExpedienteComponent } from "./components/sistema/pacientes/expediente/expediente/expediente.component";
import { HistoriaComponent } from "./components/sistema/pacientes/expediente/historia/historia/historia.component";
import { PacienteComponent } from "./components/sistema/pacientes/expediente/paciente/paciente.component";
import { CitaspacienteComponent } from "./components/sistema/pacientes/expediente/citas/citas_paciente.component";
import { PagospacienteComponent } from "./components/sistema/pacientes/expediente/pagos/pagos_paciente.component";
import { TratamientospacienteComponent } from "./components/sistema/pacientes/expediente/tratamientos/tratamientos_paciente.component";
import { VertratamientopacienteComponent } from "./components/sistema/pacientes/expediente/tratamientos/vertratamiento_paciente/vertratamiento_paciente.component";
import { TratamientoregistroComponent } from "./components/sistema/pacientes/expediente/tratamientos/tratamiento_registro/tratamiento_registro.component";
import { OdontogramaComponent } from "./components/sistema/pacientes/expediente/odontograma/odontograma.component";
import { ArchivosComponent } from "./components/sistema/pacientes/expediente/archivos/archivos.component";
import { DocumentosComponent } from "./components/sistema/pacientes/expediente/documentos/documentos.component";

import { UsuariosComponent } from "./components/sistema/administracion/usuarios/usuarios/usuarios.component";
import { UsuarioComponent } from "./components/sistema/administracion/usuarios/usuario/usuario.component";
import { UsuarioregistroComponent } from "./components/sistema/administracion/usuarios/usuario-registro/usuario-registro.component";
import { PerfilComponent } from "./components/sistema/administracion/usuarios/perfil/perfil.component";
import { PermisosComponent } from "./components/sistema/administracion/usuarios/permisos/permisos.component";

import { TecnicosComponent } from "./components/sistema/administracion/tecnicos/tecnicos/tecnicos.component";
import { ConfiguracionComponent } from "./components/sistema/administracion/configuracion/configuracion/configuracion.component";
import { ConsumoComponent } from "./components/sistema/administracion/consumo/consumo/consumo.component";
import { TratamientosComponent } from "./components/sistema/administracion/tratamientos/tratamientos/tratamientos.component";
import { TratamientoComponent } from "./components/sistema/administracion/tratamientos/tratamiento/tratamiento.component";
import { CuestionariosComponent } from "./components/sistema/administracion/cuestionarios/cuestionarios/cuestionarios.component";
import { ImprimirdocumentosComponent } from "./components/sistema/pacientes/documentos/imprimir-documentos.component";
import { NotaspacienteComponent } from "./components/sistema/pacientes/expediente/tratamientos/notas_paciente/notas_paciente.component";

const appRoutes: Routes = [

  // agenda, pacientes, caja, clinicas, usuarios, tecnicos, inventarios, configuracion

  {path: "", component:AgendaComponent, data: {title: 'ADClinik'}, canActivate:[UsuarioService] },//por defecto
  {path: "inicio", redirectTo: "agenda"},
  {path: "agenda", component:AgendaComponent, data: {title: 'Agenda', role: 'agenda-R'}, canActivate:[UsuarioService] },
  {path: "agenda/:fecha", component:AgendaComponent, data: {title: 'Agenda', role: 'agenda-R'}, canActivate:[UsuarioService] },
  {path: "agenda/citas/crearcita", component:CreatecitasComponent, data: {title: 'Crear Cita', role: 'agenda-C'}, canActivate:[UsuarioService] },
  {path: "agenda/cita/:id", component:VercitaComponent, data: {title: 'Detalle de Cita', role: 'agenda-R'}, canActivate:[UsuarioService] },
  {path: "agenda/cita", redirectTo: "agenda" },

  {path: "caja", component:CajaComponent, data: {title: 'Caja'}, canActivate:[UsuarioService] },
  {path: "caja/apertura", component:CajaaperturaComponent, data: {title: 'Apertura de Caja', role: 'caja-C'}, canActivate:[UsuarioService] },
  {path: "caja/entrada", component:CajaentradasComponent, data: {title: 'Entradas/Cobros', role: 'caja-C'}, canActivate:[UsuarioService] },
  {path: "caja/salida", component:CajasalidasComponent, data: {title: 'Salidas/Pagos', role: 'caja-C'}, canActivate:[UsuarioService] },
  {path: "caja/movimientos", component:CajamovimientosComponent, data: {title: 'Movimientos de Caja', role: 'caja-R'}, canActivate:[UsuarioService] },
  {path: "caja/cortes", component:CajacortesComponent, data: {title: 'Cortes de Caja', role: 'caja-R'}, canActivate:[UsuarioService] },
  {path: "caja/corte", component:CreatecorteComponent, data: {title: 'Realizar Corte de Caja', role: 'caja-C'}, canActivate:[UsuarioService] },
  {path: "caja/corte/:id", component:CajacorteComponent, data: {title: 'Detalle de Corte', role: 'caja-R'}, canActivate:[UsuarioService] },
  {path: "caja/medicos", component:CajamedicosComponent, data: {title: 'Pago Médicos', role: 'caja-R'}, canActivate:[UsuarioService] },
  {path: "caja/medico/:id", component:CajamedicoComponent, data: {title: 'Saldo Médico', role: 'caja-R'}, canActivate:[UsuarioService] },
  {path: "caja/adeudos", component:CajaadeudosComponent, data: {title: 'Adeudos', role: 'caja-R'}, canActivate:[UsuarioService] },
  {path: "caja/adeudo/:id", component:CajaadeudoComponent, data: {title: 'Detalle de Adeudo', role: 'caja-R'}, canActivate:[UsuarioService] },
  {path: "caja/reportes", component:CajareportesComponent, data: {title: 'Reportes de Caja', role: 'caja-R'}, canActivate:[UsuarioService] },

  {path: "pacientes", component:PacientesComponent, data: {title: 'Pacientes', role: 'pacientes-R'}, canActivate:[UsuarioService] },
  {path: "pacientes/registro", component:PacienteregistroComponent, data: {title: 'Registro de Paciente', role: 'pacientes-C'}, canActivate:[UsuarioService] },
  {path: "paciente/editar/:id", component:PacienteactualizarComponent, data: {title: 'Actualizar Paciente', role: 'pacientes-U'}, canActivate:[UsuarioService] },
  {path: "pacientes/documentos", component:ImprimirdocumentosComponent, data: {title: 'Imprimir Documentos', role: 'pacientes-R'}, canActivate:[UsuarioService] },
  //{path: "paciente/expediente/:id", component:ExpedienteComponent, data: {title: 'Expediente', role: 'expediente'}, canActivate:[UsuarioService] },
  {
    path: "paciente/expediente/:id", component:ExpedienteComponent,
    children: [
      {path: "", component:HistoriaComponent, data: {title: 'Historia Clínica', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "historia", component:HistoriaComponent, data: {title: 'Historia Clínica', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "personales", component:PacienteComponent, data: {title: 'Datos Personales', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "citas", component:CitaspacienteComponent, data: {title: 'Historial de Citas', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "pagos", component:PagospacienteComponent, data: {title: 'Pagos Paciente', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "tratamientos", component:TratamientospacienteComponent, data: {title: 'Tratamientos Paciente', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "tratamiento/:idt", component:VertratamientopacienteComponent, data: {title: 'Ver Tratamiento', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "notasevolucion", component:NotaspacienteComponent, data: {title: 'Notas Evolucion', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "nuevotratamiento", component:TratamientoregistroComponent, data: {title: 'Nuevo Tratamiento', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "editartratamiento/:idt", component:TratamientoregistroComponent, data: {title: 'Editar Tratamiento', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "odontograma/:tipo", component:OdontogramaComponent, data: {title: 'Odontograma', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "archivos", component:ArchivosComponent, data: {title: 'Docs y Archivos', role: 'expediente'}, canActivate:[UsuarioService] },
      {path: "documentos", component:DocumentosComponent, data: {title: 'Documentos', role: 'expediente'}, canActivate:[UsuarioService] },
    ],
    data: {title: 'Expediente Clínico', role: 'expediente'}, canActivate:[UsuarioService]
  },

  {path: "usuarios", component:UsuariosComponent, data: {title: 'Usuarios', role: 'usuarios-R'}, canActivate:[UsuarioService] },
  {path: "usuarios/registro", component:UsuarioregistroComponent, data: {title: 'Registro de Usuario', role: 'usuarios-C'}, canActivate:[UsuarioService] },
  {path: "usuario/actualizar/:id", component:UsuarioregistroComponent, data: {title: 'Actualizar Usuario'}, canActivate:[UsuarioService] },
  {path: "usuario/:id", component:UsuarioComponent, data: {title: 'Detalle de Usuario', role: 'usuarios-R'}, canActivate:[UsuarioService] },
  {path: "usuario/permisos/:id", component:PermisosComponent, data: {title: 'Permisos de Usuario', role: 'usuarios-U'}, canActivate:[UsuarioService] },
  {path: "miperfil", component:PerfilComponent, data: {title: 'Mi Perfil'}, canActivate:[UsuarioService] },

  {path: "tecnicos", component:TecnicosComponent, data: {title: 'Técnicos', role: 'tecnicos-R'}, canActivate:[UsuarioService] },
  {path: "configuracion", component:ConfiguracionComponent, data: {title: 'Configuración', role: 'configuracion-C'}, canActivate:[UsuarioService] },
  {path: "consumos", component:ConsumoComponent, data: {title: 'Consumos', role: 'configuracion-R'}, canActivate:[UsuarioService] },
  {path: "tratamientos", component:TratamientosComponent, data: {title: 'Tratamientos', role: 'configuracion-U'}, canActivate:[UsuarioService] },
  {path: "tratamiento/:id", component:TratamientoComponent, data: {title: 'Detalle de Tratamiento', role: 'configuracion-U'}, canActivate:[UsuarioService] },
  {path: "cuestionarios", component:CuestionariosComponent, data: {title: 'Cuestionario Personalizado', role: 'configuracion-U'}, canActivate:[UsuarioService] },

  {path: "login", component:LoginComponent, data: {title: 'ADClinik'} },
  {path: "salir/:sure", component:LoginComponent, data: {title: 'Iniciar Sesion'}, canActivate:[UsuarioService] },

  {path: "clinicas", component:ClinicasComponent, data: {title: 'Clínicas', role: 'clinicas-R'}, canActivate:[UsuarioService] },
  {path: "clinicas/:id", component:ClinicaComponent, data: {title: 'Info Clínica', role: 'clinicas-R'}, canActivate:[UsuarioService] },
  {path: "clinicas/actualizar/:id", component:ClinicaComponent, data: {title: 'Actualizar Clínica', role: 'clinicas-U'}, canActivate:[UsuarioService] },

  {path: "error", component:NoencontradaComponent, data: {title: 'Pagina No Encontrada'}, canActivate:[UsuarioService] },
  {path: "**", component:NoencontradaComponent, data: {title: 'Pagina No Encontrada'}, canActivate:[UsuarioService] },//error
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
