import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'usuario',
  templateUrl: './usuario.component.html',
})

export class UsuarioComponent {

  public id_usuario = null;
  public usuario = null;
  public identity = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _usuariosService: UsuariosService,
    private _usuarioService: UsuarioService,
  ){

  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_usuario = params['id'];
      this.id_usuario = ( isNaN( parseInt( this.id_usuario ) ) ) ? null : parseInt( this.id_usuario );
      this.obtenerUsuario();
    });
  }

  obtenerUsuario(){
    if( this.id_usuario != null){
      this.spinner.show('spnr-s');
      let respuesta = this._usuariosService.obtenerUsuario( this.id_usuario );
      respuesta.then( val =>{
        this.spinner.hide('spnr-s');
        if( val.status == "success" ){
          if(val.usuario != null ){
            this.usuario = val.usuario;
            this.identity = this._usuarioService.getIdentity();
          }else{
            this._router.navigate(['/usuarios']);
          }
        }else{
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this._router.navigate(['/usuarios']);
        }
      },
      error =>{
        Swal.fire({
          title: 'Oopss...',
          text: error,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
        this.spinner.hide('spnr-s');
      });
    }else{
      this.spinner.hide('spnr-s');
      this._router.navigate(['/usuarios']);
    }
  }

}
