import { Injectable, EventEmitter } from '@angular/core';

@Injectable()

export class HeaderService{

  public titulo;
  public subtitulo;
  public imagen;

  titulo$ = new EventEmitter<Array<string>>();
  constructor(){}


}
