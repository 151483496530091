<div class="row">
  <div class="col-12 col-sm-6">
    <h1>Pacientes</h1>
  </div>
  <div class="col-12 col-md-6">
    <div class="pull-right">
      <button type="button" class="btn btn-success" (click)="registrarPaciente()" *ngIf=" cuenta_pacientes < limite_pacientes && permisos.c ">
        <span class="glyphicon glyphicon-copy icono"></span>
        Registrar Paciente
      </button>
      <div class="alert alert-warning" role="alert" *ngIf=" cuenta_pacientes >= limite_pacientes ">
        Ha registrado el número máximo de pacientes.
      </div>
    </div>
  </div>
</div>


<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc" style="width: 5%;">F. Pac</th>
      <th class="disabled-sorting sorting_desc">Nombre  </th>
      <th class="disabled-sorting sorting_desc">Teléfono</th>
      <th class="disabled-sorting sorting_desc">Dirección</th>
      <th class="disabled-sorting sorting_desc text-center" data-priority="1">Acciones</th>
    </tr>
  </thead>
  <tbody *ngIf="pacientes?.length != 0">
    <tr *ngFor="let paciente of pacientes">
      <td>{{ paciente.id_paciente }}</td>
      <td>{{ paciente.nombre }}</td>
      <td>{{ paciente.tel }}</td>
      <td>{{ paciente.direccion }}</td>

      <td class="text-center">
        <a class="btn btn-info btn-icon btn-sm mr-1" *ngIf="permisos.c" [routerLink]="['/paciente/expediente', paciente.id_paciente ]" title="Doc. Clinicos" aria-label="Doc. Clinicos" data-pjax="0">
          <i class="fas fa-file-medical-alt"></i>
        </a>
        <a class="btn btn-warning btn-icon btn-sm mr-1" *ngIf="permisos.u" [routerLink]="['/paciente/editar/', paciente.id_paciente ]" title="Actualizar" aria-label="Actualizar" data-pjax="0">
          <i class="far fa-edit"></i>
        </a>
        <button
          *ngIf="permisos.d"
          class="btn btn-danger btn-icon btn-sm"
          (click)="eliminarPaciente(paciente.id_paciente)"
          title="Eliminar"
          aria-label="Eliminar">
          <i class="far fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="pacientes?.length == 0">
    <tr>
      <td colspan="3" class="no-data-available">No se encontraron pacientes</td>
    </tr>
  </tbody>
</table>
