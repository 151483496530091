import { Component } from '@angular/core';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';

@Component({
  selector: 'notificaciones',
  templateUrl: './notificaciones.component.html',
})

export class NotificacionesComponent {
  public notificaciones = null;

  constructor(
    private _configuracionService: ConfiguracionesService,
  ){
    this.obtenerNotificaciones();
  }

  ngOnInit() {
  }

  obtenerNotificaciones(){
    let respuesta = this._configuracionService.obtenerNotificaciones();

    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.notificaciones = val.notificaciones;
        }
      },
      error => {
        this.notificaciones = null;
      }
    );

  }


}
