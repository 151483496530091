import { Component } from '@angular/core';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'noencontrada',
  templateUrl: './noencontrada.component.html',
})

export class NoencontradaComponent {

  constructor(
    private _headerService: HeaderService
  ){

  }

  ngOnInit() {
    this._headerService.titulo$.emit(['', '', 'error.svg']);
  }

}
