<div class="wrapper wrapper-full-page ">
  <div class="full-page login-page section-image"
  filter-color="black"
  [ngStyle]="{ 'background-image': 'url(' + urlImg + 'backgrounds/adclinik-bg.jpg)'}">

    <div class="content">
      <div class="container">

        <notificaciones></notificaciones>

        <vencimiento [validacion]="validacion"></vencimiento>

        <div class="row justify-content-center" *ngIf="validacion != null">
          <div class="col-8 col-sm-8 col-md-7 col-lg-4" *ngIf="validacion.estado == 1">
            <div class="card card-login card-plain">

              <div class="card-header ">
                <div>
                  <img src="{{urlImg}}logos/logo.png"  alt="ADClinik-Logo">
                </div>
              </div>

              <div class="card-body ">
                <div class="text-center">
                  <p style="color: white">Por favor complete los siguientes campos para iniciar
                    sesión:</p>
                </div>

                <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">

                  <div class="input-group no-border form-control-lg field-loginform-login required">
                    <span class="input-group-prepend" style="display: block;">
                      <div class="input-group-text">
                        <i class="now-ui-icons users_circle-08"></i>
                      </div>
                    </span>
                    <input
                      class="form-control"
                      type="text"
                      name="login"
                      #login="ngModel"
                      [(ngModel)]="modelUsuario.login"
                      placeholder="Usuario..."
                      required
                    />
                  </div>
                  <div *ngIf="!login.valid && login.touched">
                    <p class="help-block help-block-error" style="color: white">El Usuario no es válido.</p>
                  </div>

                  <div class="input-group no-border form-control-lg field-loginform-pass required">
                    <div class="input-group-prepend" style="display: block;">
                      <div class="input-group-text">
                        <i class="now-ui-icons ui-1_lock-circle-open"></i>
                      </div>
                    </div>
                    <input
                      class="form-control"
                      type="password"
                      name="pass"
                      id = "pass"
                      #pass="ngModel"
                      [(ngModel)]="modelUsuario.pass"
                      placeholder="Contraseña..."
                      required
                    />
                  </div>
                  <div *ngIf="!pass.valid && pass.touched" >
                    <p class="help-block help-block-error" style="color: white">Contraseña no puede estar vacío.</p>
                  </div>

                  <div class="form-group pb-3"  *ngIf="clinicas != null">
                    <mat-select (selectionChange)="selectClinica($event.value)" class="form-control" style="background-color: white;" placeholder="Clinica a Ingresar">
                      <mat-option *ngFor="let clinica of clinicas" [value]="clinica.id">{{clinica.texto}}</mat-option>
                    </mat-select>
                  </div>

                  <div class="form-group">
                    <input type="submit" value="Acceder" class="btn btn-info btn-round btn-block mb-3" [disabled]="loginForm.invalid">
                  </div>
                </form>

              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
