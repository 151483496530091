import { Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { MedicosService } from 'src/app/services/medicos.service';
import { PacientesService } from 'src/app/services/pacientes.service';
import { TratamientosService } from 'src/app/services/tratamientos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'tratamiento_registro',
  templateUrl: './tratamiento_registro.component.html',
})

export class TratamientoregistroComponent {

  formTratamiento: FormGroup;
  detalle_tratamiento: FormArray;
  @Input() public id_tratamiento = "";
  @Input() public tipo;

  public doctores = null;
  public total = 0;
  public id_paciente = null;
  public id_medico = null;
  public subscribe = null;
  public tratamientos = null;

  public datos_tratamiento = {
    "tratamiento" : {
      "id_bas_tratamiento" : "",
      "descripcion" : "",
      "costo" : "",
      "tratamiento" : "",
      "tecnico" : "",
      "tipo" : "",
      "id_medico": "",
    },
    "relacion_tratamiento":[],
    "detalle" : [
      {
        "id_bas_dettratamientos" : "",
        "descripcion" : "",
        "monto" : "",
        "tecnico" : ""
      }
    ]
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _bilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _tratamientosService: TratamientosService,
    private _medicosService: MedicosService,
    private _expedienteService: ExpedienteService,
    private _pacienteService: PacientesService,
  ){

  }

  ngOnInit(){

    this._route.params.forEach((params:Params) => {
      this.id_tratamiento =  params['idt'];
      if( this.id_tratamiento != null ){
        this.obtenerTratamientopaciente( this.id_tratamiento );
      }
    });
    if( this.id_paciente == null ){
      this.id_paciente = this._route.parent.snapshot.paramMap.get('id');
    }
    this.subscribe = this._pacienteService.id_paciente$.subscribe( id_paciente =>{
      this.id_paciente = id_paciente;
    });

    this.obtenerMedicos();
    this.colocarValores();

  }

  colocarValores( tratamiento = null ){
    let datos = null;
    if( tratamiento != null ){
      datos = tratamiento;
    }else{
      datos = this.datos_tratamiento;
    }

    this.formTratamiento = this._bilder.group({
      id_bas_tratamiento : [ datos.tratamiento.id_bas_tratamiento ],
      descripcion: [datos.tratamiento.descripcion, Validators.compose([ Validators.maxLength(45), Validators.required ])],
      costo: [ datos.tratamiento.costo, Validators.maxLength(45)  ],
      detalle_tratamiento: this._bilder.array([  ])
    });
    let sum = 0;
    if( tratamiento != null ){
      let detalle = tratamiento.detalle;
      for(let i=0; i < detalle.length; i++ ){
        sum += parseFloat( detalle[i].monto );
        this.addItem(detalle[i]);
      }
    }else{
      this.addItem();
    }
    this.total = sum;
  }

  createDetalle(datos = null): FormGroup {

    let id_bas_dettratamientos = "";
    let descripcion = "";
    let monto = "";
    let tecnico = false;

    if(datos != null){
      id_bas_dettratamientos = datos.id_bas_dettratamientos;
      descripcion = datos.descripcion;
      monto = datos.monto;
      tecnico = (datos.tecnico == '1' || datos.tecnico == 1)? true: false;
    }

    return this._bilder.group({
      id_bas_dettratamientos: [ id_bas_dettratamientos ],
      descripcion: [ descripcion, Validators.required ],
      monto: [ monto, Validators.required ],
      tecnico: [ tecnico ]
    });
  }

  addItem(datos = null): void {
    this.detalle_tratamiento = this.formTratamiento.get('detalle_tratamiento') as FormArray;
    this.detalle_tratamiento.push(this.createDetalle(datos));
  }

  deleteItem(item){
    if(this.detalle_tratamiento != undefined ){
      if(this.detalle_tratamiento.length > 0){
        this.detalle_tratamiento.removeAt(item);
      }
    }
  }

  obtenerMedicos(){
    let respuesta = this._medicosService.obtenerMedicos();
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          if(val.meds != null ){
            this.doctores = val.meds;
          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        }
      },
      error => {
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al cargar los datos.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

  cambioDoctor( id_doctor ){
    this.spinner.show('spnr-s');
    this.tratamientos = null;
    this.id_medico = id_doctor;
    let respuesta = this._expedienteService.obtenerTratamientosmedico( id_doctor );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          if(val.tratamientos != null ){
            this.tratamientos = val.tratamientos;
            this.spinner.hide('spnr-s');
          }
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error => {
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al cargar los tratamientos.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

  cambioTratamiento(id_tratamiento){
    this.obtenerTratamiento( id_tratamiento );
  }

  sumar(){
    var items = $(".monto");
    var sum:any = 0;
    items.each(function (index, elem) {
        var val:any =  $(elem).val();
        var priceValue:any =  Number(val.replace(/[^0-9\.]+/g,""));
        sum = parseInt(sum) + parseInt(priceValue);
    });

    this.total = sum;
  }

  obtenerTratamiento( id_tratamiento ){
    this.spinner.show('spnr-s');
    let respuesta = this._tratamientosService.obtenerTratamiento( id_tratamiento );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          if(val.tratamiento != null ){
            this.colocarValores( val.tratamiento );
            this.spinner.hide('spnr-s');
          }
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error => {
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al cargar los datos.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

  obtenerTratamientopaciente( id_tratamiento ){
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.obtenerTratamientopaciente( id_tratamiento );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          if(val.tratamiento != null ){
            this.colocarValores( val.tratamiento );
            this.spinner.hide('spnr-s');
          }
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error => {
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al cargar los datos.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

  displayFn(elemento): string {
    return elemento ? elemento.texto : elemento;
  }

  registrarTratamiento( datos ){
    this.spinner.show('spnr-s');
    let id_medico = "";
    let respuesta = null;

    if( this.id_tratamiento == null ){
      id_medico = this.id_medico;
      respuesta = this._expedienteService.registrarTratamiento( datos, id_medico, this.id_paciente );
    }else{
      datos.id_bas_tratamiento = this.id_tratamiento
      respuesta = this._expedienteService.actualizarTratamiento( datos, this.id_tratamiento );
    }

    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Registro Exitoso",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this._router.navigate(['/paciente/expediente/'+this.id_paciente+'/tratamiento', val.id ]);
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error => {
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al registrar los datos.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

}
