import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'vertratamiento_paciente',
  templateUrl: './vertratamiento_paciente.component.html',
})

export class VertratamientopacienteComponent {

  public id_paciente = null;
  public id_tratamiento = null;
  public subscribe = null;
  public tratamiento = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
    private _pacienteService: PacientesService,
    private _expedienteService: ExpedienteService,
  ){

  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_tratamiento =  params['idt'];
    });
    if( this.id_paciente == null ){
      this.id_paciente = this._route.parent.snapshot.paramMap.get('id');
    }
    this.subscribe = this._pacienteService.id_paciente$.subscribe( id_paciente =>{
      this.id_paciente = id_paciente;
    });

    this.obtenerTratamiento();
  }

  ngOnDestroy(){
    this.subscribe.unsubscribe();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  obtenerTratamiento(){
    this.spinner.show('spnr-s');
    if( this.id_tratamiento != null){
      let respuesta = this._expedienteService.obtenerTratamiento( this.id_tratamiento );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            this.tratamiento = val.tratamiento;
          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  actualizar(){
    this._router.navigate(['/paciente/expediente/'+this.id_paciente+'/editartratamiento', this.id_tratamiento]);
  }

}
