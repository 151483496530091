import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";
import { CookieService } from "ngx-cookie-service";


@Injectable()

export class ConfiguracionesService{

  public url: string;
  public token = null;
  public configuracion: any = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
    private _cookies: CookieService,
  ){
    this.url = Global.url;
    this.token = this._usuarioService.getToken();
  }

  getPermisos( permiso ){

    let resultado = null;
    let configuracion = null;

    configuracion = this._cookies.get( "confADClinik" );

    if( configuracion ){
      configuracion = JSON.parse( configuracion );
      if( configuracion && configuracion != 'undefined' ){
        let permisos = configuracion;
        permisos = permisos[permiso];
        permisos = permisos.split("");
        resultado = {
          c: ( permisos[0] == '0' )? false : true,
          r: ( permisos[1] == '0' )? false : true,
          u: ( permisos[2] == '0' )? false : true,
          d: ( permisos[3] == '0' )? false : true,
        };
      }

    }
    return resultado;

  }

  setConfig( configuracion ){
    this._cookies.set( "confADClinik", JSON.stringify( configuracion ), { path: "/" } );
    this.configuracion = configuracion;

  }

  getConfig(){

    let configuracion = this._cookies.get( "confADClinik" );

    if( configuracion ){
      configuracion = JSON.parse( configuracion );
      if( configuracion && configuracion != 'undefined' ){
        this.configuracion = configuracion;
      }else{
        this.configuracion = null;
      }
    }else{
      this.configuracion = null;
    }
    return this.configuracion;
  }

  async obtenerClinicas( ){

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const respuesta:any = await this._http.get(this.url + "configuracion/clinicas", {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerLimites( ){

    this.token = this._usuarioService.getToken();
    this.getConfig( );

    let data = JSON.stringify( {
      "clinica": this.configuracion.id_clinica,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    const respuesta:any = await this._http.post(this.url + "configuracion/limites", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerConfiguracion( token, id_clinica ){

    this.token = token;

    let data = JSON.stringify( {
      "clinica": id_clinica,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    const respuesta:any = await this._http.post(this.url + "configuracion/configuracion", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerEstados( ){

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const respuesta:any = await this._http.get(this.url + "configuracion/estados", {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerMunicipios( id_estado ){

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const respuesta:any = await this._http.get(this.url + "configuracion/municipios/"+id_estado, {headers: headers}).toPromise();

    return respuesta;

  }

  async validarServicio( ){

    let data = JSON.stringify({
      "dominio": Global.dominio,
      "id_controlservicio": Global.id_controlservicio,
      "id_contrato": Global.id_contrato,
    });
    let params = 'data='+data;
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const respuesta:any = await this._http.post(this.url + "control/validarservicio", params, {headers: headers}).toPromise();
    //console.log(respuesta);
    return respuesta;
  }

  async obtenerNotificaciones( ){

    let data = JSON.stringify( {
      "dominio":"odontomeds",
      "id_controlservicio":"1",
      "id_contrato":"1"
    } );
    let params = 'data='+data;
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const respuesta:any = await this._http.post(this.url + "control/notificaciones", params, {headers: headers}).toPromise();
    //console.log(respuesta);
    return respuesta;

  }

  async actualizarConfiguracion( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "configuracion/actualizar/"+datos.id_configuracion, params, {headers: headers}).toPromise();

    return respuesta;

  }

  subirArchivos(files, id_configuracion, tipo):Promise<any>{

    return new Promise( (resolve, reject) => {
      var formData: any =  new FormData();
      var xhr = new XMLHttpRequest();

      for( var i = 0; i < files.length; i++){
        formData.append('uploads[]', files[i], files[i].name);
        formData.append('tipo', tipo);
      }

      xhr.onreadystatechange =  function(){
        if(xhr.readyState == 4){
          if(xhr.status == 200){
            resolve(JSON.parse(xhr.response));
          }else{
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', this.url + "configuracion/subirlogo/"+id_configuracion, true);
      xhr.setRequestHeader("enctype", "multipart/form-data");
      xhr.setRequestHeader('Authorization', this.token);
      xhr.send(formData);
    });

  }

  async obtenerConsumos( ){
    let config = this.getConfig();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "configuracion/consumo/"+config.id_clinica, {headers: headers}).toPromise();

    return respuesta;
  }

}
