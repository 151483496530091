import { Component, EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { CuestionariosService } from 'src/app/services/cuestionarios.service';
import { MedicosService } from 'src/app/services/medicos.service';
import { TratamientosService } from 'src/app/services/tratamientos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'cuestionarios-registro',
  templateUrl: './cuestionarios-registro.component.html',
})

export class CuestionariosregistroComponent {

  formCuestionario: FormGroup;
  @Input() public id_cuestionario = "";
  @Input() public tipo;
  public event: EventEmitter<any> = new EventEmitter();

  public clinicas = null;
  public datos_cuestionario = {
    "id_preguntas" : "",
    "pregunta" : "",
    "estado" : false,
    "id_clinica" : ""
  };

  constructor(
    public activeModal: NgbActiveModal,
    private _bilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _cuestionariosService: CuestionariosService,
    private _configuracionesService: ConfiguracionesService,
  ){

  }

  ngOnInit(){
    this.obtenerClinicas();
    this.colocarValores();
    if( this.id_cuestionario != "" ){
      this.obtenerCuestionario();
    }
  }

  obtenerClinicas(){
    let respuesta = this._configuracionesService.obtenerClinicas( );
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.cantidad >= 1){
          this.clinicas = val.clinicas;
        }
      }
    });
  }

  colocarValores( cuestionario = null ){
    let datos = null;
    if( cuestionario != null ){
      datos = cuestionario;
    }else{
      datos = this.datos_cuestionario;
    }
    this.formCuestionario = this._bilder.group({
      id_preguntas : [ datos.id_preguntas ],
      pregunta: [datos.pregunta, Validators.compose([ Validators.maxLength(255), Validators.required ])],
      estado: [ datos.estado ],
      id_clinica: [ datos.id_clinica, Validators.required ],
    });
  }

  obtenerCuestionario(){
    this.spinner.show('spnr-s');
    let respuesta = this._cuestionariosService.obtenerCuestionario( this.id_cuestionario );
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.pregunta != null ){
          this.colocarValores( val.pregunta );
          this.spinner.hide('spnr-s');
        }
      }
    });
  }

  registrarCuestionario( datos ){
    this.spinner.show('spnr-s');
    let respuesta = null;
    let mensaje = null;

    if( this.id_cuestionario == "" ){
      respuesta = this._cuestionariosService.registrarCuestionario( datos );
      mensaje = "Pregunta Registrada Exitosamente";
    }else{
      respuesta = this._cuestionariosService.actualizarCuestionario( datos );
      mensaje = "Pregunta Actualizada Exitosamente";
    }

    respuesta.then( val =>{
      if( val.status == "success" ){
        this.activeModal.close();
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: mensaje,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
        this.event.emit(1);
      }else{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: val.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    });

  }

}
