import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpedienteService } from 'src/app/services/expediente.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'nopatologicos',
  templateUrl: './nopatologicos.component.html',
})

export class NopatologicosComponent {

  formNopatologicos: FormGroup;

  @Input() public id_paciente = null;

  public datos_nopatologicos = {
    "id_paciente" : "",
    "higiene" : "",
    "alcoholismo" : "",
    "tabaquismo" : "",
    "drogas" : "",
    "enfprevias" : "",
    "enfactual" : "",
    "medicamento" : ""
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _expedienteService: ExpedienteService,
  ){

  }

  ngOnInit(){
    this.colocarValores();
    this.obtenerNopatologicos();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  colocarValores( nopatologicos = null ){
    let datos = null;
    if( nopatologicos != null ){
      datos = nopatologicos;
    }else{
      datos = this.datos_nopatologicos;
      datos.id_paciente = this.id_paciente;
    }

    this.formNopatologicos = this._bilder.group({
      id_paciente : [datos.id_paciente],
      higiene : [ datos.higiene ],
      alcoholismo : [ datos.alcoholismo ],
      tabaquismo : [ datos.tabaquismo ],
      drogas : [ datos.drogas ],
      enfprevias : [ datos.enfprevias ],
      enfactual : [ datos.enfactual ],
      medicamento : [ datos.medicamento ]
    });
  }

  obtenerNopatologicos(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerNopatologicos( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            if( val.nopatologicos != null ){
              this.colocarValores( val.nopatologicos );
            }
          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  registrarNopatologicos( datos ){
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.registrarNopatologicos( datos );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Registro Exitoso",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al registrar los datos, por favor intentalo nuevamente.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

}
