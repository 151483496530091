import { Component, Input } from '@angular/core';

@Component({
  selector: 'vencimiento',
  templateUrl: './vencimiento.component.html',
})

export class VencimientoComponent {
  @Input() public validacion = null;

  constructor(){

  }

  ngOnInit() {

  }


}
