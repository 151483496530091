<div *ngIf="tratamiento != null">
  <div class="pull-right">
    <p>
        <button class="btn btn-primary" *ngIf="tratamiento.estado == 1" (click)="actualizar()">
          <i class="fas fa-edit"></i> Actualizar
        </button>
    </p>
  </div>

  <h1>{{tratamiento.descripcion}}</h1>

  <div class="container">
    <div class="row justify-content-center">
        <div class="col-sm-12">
            <table class="table text-center">
                <thead>
                    <tr>
                        <th scope="row">Fecha Inicio</th>
                        <th scope="row">Descripción</th>
                        <th scope="row">Costo</th>
                        <th scope="row">Adeudo</th>
                        <th scope="row">Médico</th>
                        <th scope="row">Registró</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{tratamiento.fechainicio}}</td>
                        <td>{{tratamiento.descripcion}}</td>
                        <td>${{tratamiento.costo}}</td>
                        <td>${{tratamiento.adeudo}}</td>
                        <td>{{tratamiento.medico}}</td>
                        <td>{{tratamiento.usuario}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-sm-6">
            <notas_evolucion [id_tratamiento]="id_tratamiento"></notas_evolucion>
        </div>

        <div class="col-sm-6">
          <archivos_tratamiento [id_tratamiento]="id_tratamiento"></archivos_tratamiento>
        </div>
    </div>

  </div>
</div>

