import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicaregistroComponent } from '../clinica-registro/clinica-registro.component';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { ClinicasService } from 'src/app/services/clinicas.service';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';


@Component({
  selector: 'clinicas',
  templateUrl: './clinicas.component.html',
})

export class ClinicasComponent{

  closeResult: string;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  public clinicas = null;
  public cuenta_clinicas = null;
  public limite_clinicas = 3;
  public permisos = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    private _clinicasService: ClinicasService,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.permisos = this._configuracionesService.getPermisos('clinicas');
  }

  ngOnInit(){
    this.spinner.show('spnr-s');
    //this.identity = this._usuarioService.getIdentity();
    this.limiteClinicas();
    this.obtenerClinicas();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje
    };
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  limiteClinicas(){
    let respuesta = this._configuracionesService.obtenerLimites();
    respuesta.then( val =>{
      if( val.status == "success" ){
        this.limite_clinicas = val.limites.clinicas.limite;
        this.cuenta_clinicas = val.limites.clinicas.cuenta;
      }
    });
  }

  obtenerClinicas(){
    this._clinicasService.obtenerClinicas().subscribe(
      response => {
        //console.log(response);
        this.clinicas = response.clinicas;
        this.dtTrigger.next();
        this.spinner.hide('spnr-s');
      },
    );
  }

  recargarClinicas() {
    this.spinner.show('spnr-s');
    this._clinicasService.obtenerClinicas().subscribe(
      response => {
        this.clinicas = response.clinicas;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
        this.spinner.hide('spnr-s');
      },
    );

  }

  registroModal() {
    const modalRef = this.modalService.open(ClinicaregistroComponent);
    modalRef.componentInstance.event.subscribe( respuesta => {
      if( respuesta ){
        this.recargarClinicas();
      }
    });
  }

  actualizarModal(id_clinica) {
    const modalRef = this.modalService.open(ClinicaregistroComponent);
    modalRef.componentInstance.id_clinica = id_clinica;
    modalRef.componentInstance.event.subscribe( respuesta => {
      if( respuesta ){
        this.recargarClinicas();
      }
    });
  }

  eliminarClinica( id_clinica ){

    Swal.fire({
      title: '¿Está seguro de eliminar esta Clínica? ',
      text: 'Esta acción no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let respuesta = this._clinicasService.eliminarClinica( id_clinica );
        respuesta.then( val =>{
          if( val.status == "success" ){

            this.spinner.hide('spnr-s');

            Swal.fire({
              title: "Clinica eliminada exitosamente",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

            this.recargarClinicas();

          }else{

            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

          }
        });

      }
    })

  }

}
