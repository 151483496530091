import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import { ActivatedRoute, Params } from '@angular/router';
import { PacientesService } from 'src/app/services/pacientes.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";

@Component({
  selector: 'documentos',
  templateUrl: './documentos.component.html',
})

export class DocumentosComponent {

  public id_paciente = null;
  public subscribe = null;
  public safari = 0;

  public config = {
    todo : 0,
    generales : 0,
    heredofamiliares : 0,
    nopatologicos : 0,
    gineco : 0,
    personalizado : 0,
    exploracion : 0,
    odontogramas : 0,
    notas : 0,
    presupuesto : 0,
  };

  constructor(
    private _route: ActivatedRoute,
    private _bilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _expedienteService: ExpedienteService,
    private modalService: NgbModal,
    private _pacienteService: PacientesService,
  ){
  }

  ngOnInit(){

    this._route.params.forEach((params:Params) => {
      this.id_paciente =  params['id'];
    });

    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      this.safari = 1;
    }

    if( this.id_paciente == null ){
      this.id_paciente = this._route.parent.snapshot.paramMap.get('id');
    }
    this.subscribe = this._pacienteService.id_paciente$.subscribe( id_paciente =>{
      this.id_paciente = id_paciente;
    });

  }

  ngOnChanges() {
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  configHistoria(e){
    if( e == 1 ){
      let estado = this.config.todo
      this.config = {
        todo : estado,
        generales : estado,
        heredofamiliares : estado,
        nopatologicos : estado,
        gineco : estado,
        personalizado : estado,
        exploracion : estado,
        odontogramas : estado,
        notas : estado,
        presupuesto : estado,
      };
    }else{
      this.config.todo = 0;
    }

  }

  historiaConfig( contentModal ){
    this.modalService.open( contentModal );
  }

  generarHistoria(){
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.imprimirHistoria( this.id_paciente, this.config );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');

          var html = htmlToPdfmake(val.historia,
            {
              tableAutoSize:true
            }
            );
          const documentDefinition = {
            pageSize: 'A4',
            header:{
              columns:[
                {
                  text: "Folio Paciente: "+this.id_paciente,
                  alignment: 'right',
                  color: '#4f4c4c',
                }
              ],
              margin: [40,20]
            },
            content: html,

          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

  consentimiento(){

    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.imprimirConcentimiento( this.id_paciente );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');

          var html = htmlToPdfmake(val.concentimiento,
            {
              tableAutoSize:true
            }
            );
          const documentDefinition = {
            pageSize: 'A4',
            header:{
              columns:[
                {
                  text: "Folio Paciente: "+this.id_paciente,
                  alignment: 'right',
                  color: '#4f4c4c',
                }
              ],
              margin: [40,20]
            },
            content: html,

          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }



}
