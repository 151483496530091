import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";
import { ConfiguracionesService } from "./configuraciones.service";

@Injectable()

export class UsuariosService{

  public url: string;
  public token = null;
  public config = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.url = Global.url;
    this.token = this._usuarioService.getToken();
    this.config = this._configuracionesService.getConfig( );
  }

  obtenerUsuarios(  ):Observable<any>{

    let data = JSON.stringify( {
      "ic": this.config.id_clinica,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = this._http.post(this.url + "usuarios/listado", params, {headers: headers});

    return respuesta;

  }

  async obtenerUsuario( id_usuario ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "usuarios/obtenerusuario/"+id_usuario, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarUsuario( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "usuarios/registrar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async actualizarUsuario( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "usuarios/actualizar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  eliminarUsuarios( id ):Observable<any>{

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = this._http.delete(this.url + "usuarios/eliminar/" + id, {headers: headers});

    return respuesta;

  }

  async obtenerPermisos( id_usuario ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "usuarios/obtenerpermisos/"+id_usuario, {headers: headers}).toPromise();

    return respuesta;

  }

  async guardarPermisos( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "usuarios/registrarpermisos", params, {headers: headers}).toPromise();

    return respuesta;

  }

}
