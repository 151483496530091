import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";

@Injectable()

export class ClinicasService{
  public url: string;
  public token = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
  ){
    this.url = Global.url;
    this.token = this._usuarioService.getToken();
  }

  obtenerClinicas(  ):Observable<any>{

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = this._http.get(this.url + "clinicas/listado", {headers: headers});

    return respuesta;

  }

  async obtenerClinica( id_clinica ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "clinicas/obtenerclinica/"+id_clinica, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarClinica( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "clinicas/registrar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async actualizarClinica( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "clinicas/actualizar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async eliminarClinica( id_clinica ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.delete(this.url + "clinicas/eliminar/"+id_clinica, {headers: headers}).toPromise();

    return respuesta;

  }

}
