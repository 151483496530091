<div class="row">
  <div class="col-md-5 ml-auto">
      <h1>Cortes de Caja</h1>
  </div>
  <div class="col-md-5 mr-auto">
      <div class="pull-right">
        <button class="btn btn-success" (click)="crearCorte()">
          <span class="glyphicon glyphicon-book icono"></span>  Realizar Corte
        </button>
      </div>
  </div>
</div>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc" style="width: 8%;">Folio</th>
      <th class="disabled-sorting sorting_desc">Fecha</th>
      <th class="disabled-sorting sorting_desc" style="width: 10%;">Total</th>
      <th class="disabled-sorting sorting_desc" style="width: 10%;">Total E</th>
      <th class="disabled-sorting sorting_desc" style="width: 10%;">Total S</th>
      <th class="disabled-sorting sorting_desc" style="width: 10%;">Tipo</th>
      <th class="disabled-sorting sorting_desc text-center" style="width: 15%;" data-priority="1">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let corte of cortes" >
      <td style="width: 8%;">{{ corte.id_corte }}</td>
      <td>{{ corte.fecha }}</td>
      <td style="width: 15%;">${{ corte.total }}</td>
      <td style="width: 15%;">${{ corte.total_entradas }}</td>
      <td style="width: 15%;">${{ corte.total_salidas }}</td>
      <td style="width: 15%;">{{ corte.tipo }}</td>
      <td style="width: 15%;" class="text-center">
        <button (click)="verCorte(corte.id_corte)" class="btn btn-info btn-icon btn-sm mr-1" title="Ver" aria-label="Ver" data-pjax="0">
          <i class="fa fa-eye" ></i>
        </button>
        <button (click)="imprimirCorte(corte.id_corte)" class="btn btn-purple btn-icon btn-sm" title="Reporte" aria-label="Reporte" data-pjax="0">
          <i class="fas fa-print"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
