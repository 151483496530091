import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpedienteService } from 'src/app/services/expediente.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'facial',
  templateUrl: './facial.component.html',
})

export class FacialComponent {

  formaAnalisis: FormGroup;

  @Input() public id_paciente = null;

  public datos_analisis = {
    "id_paciente" : "",
    "patron" : "",
    "perfil" : "",
    "asimetria" : "",
    "altura" : "",
    "ancho" : "",
    "maxilar" : "",
    "mandibular" : "",
    "surco" : "",
    "labios" : "",
    "labial" : "",
    "respiracion" : "",
    "deglucion" : "",
    "comisural" : "",
    "lingual" : "",
    "superior" : "",
    "inferior" : "",
    "masetero" : "",
    "mentoniano" : "",
    "succion" : ""
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _expedienteService: ExpedienteService,
  ){

  }

  ngOnInit(){
    this.colocarValores();
    this.obtenerAnalisis();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  colocarValores( analisis = null ){
    let datos = null;
    if( analisis != null ){
      datos = analisis;
    }else{
      datos = this.datos_analisis;
      datos.id_paciente = this.id_paciente;
    }

    this.formaAnalisis = this._bilder.group({
      id_paciente : [datos.id_paciente],
      patron: [ datos.patron ],
      perfil: [ datos.perfil ],
      asimetria: [ datos.asimetria ],
      altura: [ datos.altura ],
      ancho: [ datos.ancho ],
      maxilar: [ datos.maxilar ],
      mandibular: [ datos.mandibular ],
      surco: [ datos.surco ],
      labios: [ datos.labios ],
      labial: [ datos.labial ],
      respiracion: [ datos.respiracion ],
      deglucion: [ datos.deglucion ],
      comisural: [ datos.comisural ],
      lingual: [ datos.lingual ],
      superior: [ datos.superior ],
      inferior: [ datos.inferior ],
      masetero: [ datos.masetero ],
      mentoniano: [ datos.mentoniano ],
      succion: [ datos.succion ]
    });
  }

  obtenerAnalisis(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerAnalisis( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            if( val.analisis != null ){
              this.colocarValores( val.analisis );
            }
          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  registrarAnalisis( datos ){
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.registrarAnalisis( datos );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Registro Exitoso",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al registrar los datos, por favor intentalo nuevamente.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

}
