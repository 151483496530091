import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute  } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import Swal from 'sweetalert2';
import { Global } from 'src/app/services/global';

@Component({
  selector: 'configuracion',
  templateUrl: './configuracion.component.html',
})

export class ConfiguracionComponent{

  formConfig: FormGroup;
  public logo_url = null;
  public logo_small_url = null;
  public archivo_logo = null;
  public archivo_logo_small = null;
  public resultadoSubir = null;
  public urlImg = null;
  public timg = "?rev="+ new Date().getTime();

  public datos_config = {
    "id_configuracion" : "",
    "logo":"",
    "logo_small":"",
    "nombre" : "",
    "hora_inicial" : "",
    "hora_final" : "",
    "duracion_espacios" : ""
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.urlImg = Global.urlImg;
  }

  ngOnInit(){
    //this.spinner.show('spnr-s');
    this.colocarValores();
  }

  colocarValores( ){
    this.datos_config = this._configuracionesService.getConfig( );
    let datos = this.datos_config;
    datos.hora_inicial = this.convertirHora(datos.hora_inicial);
    datos.hora_final = this.convertirHora(datos.hora_final);

    this.formConfig = this._bilder.group({
      id_configuracion : [ datos.id_configuracion ],
      nombre: [ datos.nombre, Validators.maxLength(45) ],
      hora_inicial: [ datos.hora_inicial ],
      hora_final: [ datos.hora_final ],
      duracion_espacios: [ datos.duracion_espacios ],
    });
  }

  obtenerHora( fecha = null ){

    if( fecha != null){
      var d = new Date( fecha );
    }else{
      var d = new Date();
    }

    let hours = d.getHours();
    let minutes = d.getMinutes();

    let min = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + min ;

    return strTime;

  }

  convertirHora( hora, tipo = null ){

    if(tipo == null){
      let hora_int = parseInt(hora);
      let hora_texto = null;
      let ampm = hora_int >= 12 ? 'pm' : 'am';
      hora_int = hora_int % 12;
      hora_int = hora_int ? hora_int : 12;
      if( hora_int < 10 ){
        hora_texto = "0" + hora_int + ":00 " + ampm;
      }else{
        hora_texto = hora_int + ":00 " + ampm;
      }

      return hora_texto;
    }else{
      hora = hora.split(" ");
      let ampm = hora[1];
      hora = hora[0].split(":");
      hora = parseInt( hora[0] );
      if( ampm == "pm" ){
        if( hora != 12 ){
          hora = (hora >= 1)? hora + 12 : hora;
        }
      }else{
        hora = (hora == 12)? 0 : hora;
      }
      if( hora < 10){
        hora = "0"+hora;
      }
      return hora.toString();
    }

  }

  cambioLogo(fileInput, tipo) {
    this.spinner.show('spnr-s');
      if(tipo == "logo"){
        this.archivo_logo = <Array<File>>fileInput.target.files;
      }else{
        this.archivo_logo_small = <Array<File>>fileInput.target.files;
      }
    this.spinner.hide('spnr-s');
  }

  subirLogo(tipo){
    this.spinner.show('spnr-s');
    let archivo = null;
    if(tipo == "logo"){
      archivo = this.archivo_logo
    }else{
      archivo = this.archivo_logo_small;
    }
    this._configuracionesService.subirArchivos( archivo, this.datos_config.id_configuracion, tipo).then(
      (result) =>{
        if( result.status == "success" ){
          this._configuracionesService.setConfig( result.config );
          this.spinner.hide('spnr-s');

          Swal.fire({
            title: "Imagen cargada exitosamente",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

          if(tipo == "logo"){
            this.archivo_logo = null;
          }else{
            this.archivo_logo_small = null;
          }
          window.location.reload();

        }else{

          this.spinner.hide('spnr-s');

          Swal.fire({
            title: 'Oopss...',
            text: result.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      (error) =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar este elemento.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

  registrarConfig( datos ){

    this.spinner.show('spnr-s');

    datos.hora_inicial = this.convertirHora(datos.hora_inicial, "24h");
    datos.hora_final = this.convertirHora(datos.hora_final, "24h");

    let respuesta = this._configuracionesService.actualizarConfiguracion( datos );

    respuesta.then( val =>{
      if( val.status == "success" ){
        this._configuracionesService.setConfig( val.config );
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Configuración Actualizada Exitosamente',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
        window.location.reload();

      }else{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: val.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    });
  }

}
