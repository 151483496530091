import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Global } from './global';
import { CookieService } from "ngx-cookie-service";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

@Injectable()

export class UsuarioService implements CanActivate{
  public url: string;
  public identity: string = null;
  public token: string = null;
  public expediente: string = null;

  identity$ = new EventEmitter<any>();
  expediente$ = new EventEmitter<any>();

  constructor(
    public _http: HttpClient,
    private _cookies: CookieService,
    private _router: Router,
  ){
    this.url = Global.url;
  }

  ingresar( usuario, gettoken = null ):Observable<any>{

    if( gettoken != null ){
      usuario.gettoken = true;
    }

    let data = JSON.stringify( {"user":usuario.login, "password":usuario.pass, "gettoken":gettoken} );
    let params = 'data='+data;
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.post(this.url + "usuario/login", params, {headers: headers});

  }

  registrar( usuario ):Observable<any>{

    let data = JSON.stringify(usuario);
    let params = 'data='+data;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.post(this.url + "clientes/registrar", params, {headers: headers});

  }

  async cerrarS(){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "usuario/cerrarsesion", {headers: headers}).toPromise();

    return respuesta;

  }

  cerrarSesion(){
    this.identity = null;
    this.token = null;
    localStorage.clear();
    this._cookies.delete("tokenADClinik", "/" );
    this._cookies.delete("identityADClinik", "/");
    this._cookies.delete("confADClinik", "/");
    this._cookies.delete("expedienteADClinik", "/");
    this.identity$.emit(this.identity);
    location.reload();
  }

  setIdentity(identity){

    this._cookies.set( "identityADClinik", JSON.stringify( identity ) , { path: "/" } );
    this.identity = identity;
    this.identity$.emit(this.identity);
  }

  setToken(token){
    this._cookies.set("tokenADClinik", token, { path: "/" });
    this.token = token;
  }

  getIdentity(){

    let identity = this._cookies.get( "identityADClinik" );

    if( identity ){
      identity = JSON.parse( identity );
      if( identity && identity != 'undefined' ){
        this.identity = identity;
        this.identity$.emit(this.identity);
      }else{
        this.identity = null;
      }
    }else{
      this.identity = null;
    }

    return this.identity;
  }

  getToken(){

    let token = this._cookies.get( 'tokenADClinik' );

    if( token ){

      this.token = token;

    }else{
      this.token = null;
    }

    return this.token;
  }

  setExpediente(estado){
    this._cookies.set("expedienteADClinik", estado, { path: "/" });
    this.expediente = estado;
    this.expediente$.emit(this.expediente);
  }

  getExpediente(){
    let expediente = this._cookies.get( 'expedienteADClinik' );

    if( expediente ){

      this.expediente = expediente;

    }else{
      this.expediente = null;
    }

    return this.expediente;
  }

  canActivate(route: ActivatedRouteSnapshot) {
    let role = route.data.role;
    role = (role == undefined)? "sn" : role;
    let validacion = false;

    if ( this.identity != null ) {
      validacion = true;

      if( role != 'sn' ){
        validacion = this.validarPermiso( role );
      }

      if(role == 'expediente'){
        this.setExpediente('1');
      }else{
        this.setExpediente('0');
      }

      if(validacion == false){
        this._router.navigate(['error']);
      }

    }

    return validacion;

  }

  validarPermiso( role ){

    if( role == 'expediente' ){
      role = 'pacientes-C';
    }

    let rol = role.split("-");
    let tipo = rol[0];
    let accion = rol[1];
    let resultado = false;
    let configuracion = null;

    configuracion = this._cookies.get( "confADClinik" );

    if( configuracion ){
      configuracion = JSON.parse( configuracion );
      if( configuracion && configuracion != 'undefined' ){
        let permisos = configuracion;

        permisos = permisos[tipo];
        permisos = permisos.split("");
        let tipo_permiso = 0;
        // agenda, pacientes, caja, clinicas, usuarios, tecnicos, inventarios, configuracion
        switch ( accion ){
          case 'C':
              tipo_permiso = 0;
          break;
          case 'R':
              tipo_permiso = 1;
          break;
          case 'U':
              tipo_permiso = 2;
          break;
          case 'D':
              tipo_permiso = 3;
          break;
        }

        if( permisos[tipo_permiso] == '0' ){
          resultado = false;
        }else{
          resultado = true;
        }

      }

    }
    return resultado;

  }

}
