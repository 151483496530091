import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'usuarios',
  templateUrl: './usuarios.component.html',
})

export class UsuariosComponent{

  closeResult: string;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  public usuarios = null;
  public limite_usuarios = 9999;
  public cuenta_usuarios = 0;
  public identity = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    private _usuariosService: UsuariosService,
    private _usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
  ){

  }

  ngOnInit(){
    this.spinner.show('spnr-s');
    this.identity = this._usuarioService.getIdentity();
    this.limiteUsuarios();
    this.obtenerUsuarios();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  limiteUsuarios(){
    let respuesta = this._configuracionesService.obtenerLimites();
    respuesta.then( val =>{
      if( val.status == "success" ){
        this.limite_usuarios = val.limites.usuarios.limite;
        this.cuenta_usuarios = val.limites.usuarios.cuenta;
      }
    });
  }

  obtenerUsuarios(){
    this._usuariosService.obtenerUsuarios().subscribe(
      response => {
        this.usuarios = response.usuarios;
        this.dtTrigger.next();
        this.spinner.hide('spnr-s');
      },
    );
  }

  eliminarUsuario(id){
    Swal.fire({
      title: '¿Está seguro de eliminar este Usuario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show('spnr-s');
        this.ejecutarEliminacion(id);
      }
    })
  }

  ejecutarEliminacion(id){
    this._usuariosService.eliminarUsuarios(id).subscribe(
      response => {

        if(response.status == 'success'){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Usuario eliminado exitosamente',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
          });
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          this.obtenerUsuarios();
        }else{
          this.spinner.hide('spnr-s');
          this.mensajeError(response.message);
        }

      },
      error => {
        let text = "Ha ocurrido un problema al eliminar este elemento. Vuelve a intenatarlo.";
        this.spinner.hide('spnr-s');
        this.mensajeError(text);
      }
    );
  }

  mensajeError(texto){
    Swal.fire({
      title: 'Oops..',
      text: texto,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    });
  }

  registrarUsuario(){
    this._router.navigate(['/usuarios/registro']);
  }


}
