import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { Global } from 'src/app/services/global';
import { MedicosService } from 'src/app/services/medicos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'caja-medicos',
  templateUrl: './caja-medicos.component.html',
})

export class CajamedicosComponent {

  public medicos = null;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _medicosService: MedicosService
  ){

  }

  ngOnInit(){
    this.obtenerMedicos( );
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  obtenerMedicos( ){
    this.spinner.show('spnr-s');
    let respuesta = this._medicosService.obtenerMedicosall( );
    respuesta.then( val =>{
      if( val.status == "success" ){
        this.medicos = val.meds;
        this.dtTrigger.next();
        this.spinner.hide('spnr-s');
      }
    });
  }

}
