<div class="container" >

  <h1>Detalle de Corte</h1>

  <div class="row justify-content-center" *ngIf="corte != null">
      <div class="col-sm-12 col-md-9 col-lg-7">
        <table class="table table-striped table-bordered table-sm">
          <tbody>
            <tr>
              <th>Folio</th>
              <td>{{corte.id_corte}}</td>
            </tr>
            <tr>
              <th>Fecha Inicial</th>
              <td>{{corte.fecha_inicio}}</td>
            </tr>
            <tr>
              <th>Fecha Final</th>
              <td>{{corte.fecha_fin}}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td>${{corte.total}}</td>
            </tr>
            <tr>
              <th>Total Entradas</th>
              <td>${{corte.total_entradas}}</td>
            </tr>
            <tr>
              <th>Total Salidas</th>
              <td>${{corte.total_salidas}}</td>
            </tr>
            <tr>
              <th>Total C/Cobrar</th>
              <td>${{corte.total_pendiente}}</td>
            </tr>
            <tr>
              <th>Comentarios</th>
              <td>{{corte.comentarios}}</td>
            </tr>
            <tr>
              <th>Tipo</th>
              <td>{{corte.tipo  }}</td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>

  <div class="text-center pt-5"><h3>Movimientos</h3></div>
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
    <thead>
      <tr>
        <th class="disabled-sorting sorting_desc" style="width: 8%;">Folio</th>
        <th class="disabled-sorting sorting_desc">Concepto</th>
        <th class="disabled-sorting sorting_desc" style="width: 8%;">Monto</th>
        <th class="disabled-sorting sorting_desc" >Registró</th>
        <th class="disabled-sorting sorting_desc" style="width: 8%;">Tipo</th>
        <th class="disabled-sorting sorting_desc" style="width: 18%;">Fecha</th>
        <th class="disabled-sorting sorting_desc" style="width: 8%;">Tarjeta</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let movimiento of movimientos"
        [ngStyle]="{'background-color': (movimiento.estado_tarjeta == '0') ? '#d6ff002e' : (movimiento.estado == '1') ? '#ff00002e' : (movimiento.tipo == 'Apertura') ? '#00ff0c2e' : null }"
        >
        <td style="width: 8%;">{{ movimiento.id_mov_caja }}</td>
        <td>{{ movimiento.concepto }}</td>
        <td style="width: 8%;">${{ movimiento.monto }}</td>
        <td>{{ movimiento.registro }}</td>
        <td style="width: 8%;">{{ movimiento.tipo }}</td>
        <td style="width: 18%;">{{ movimiento.fecha }}</td>
        <td style="width: 8%;">{{ movimiento.tarjeta }}</td>
      </tr>
    </tbody>
  </table>
</div>


