<div class="row" *ngIf="tratamiento">
  <div class="col-md-12">
      <h1>{{tratamiento.tratamiento.descripcion}}</h1>
  </div>
</div>

<div class="row justify-content-center" *ngIf="tratamiento">
  <div class="container">
      <div class="row text-center justify-content-center">
          <div class="col-sm-12 col-md-10">
              <table class="table text-center table-sm">
                  <thead class="thead-light">
                      <tr>
                          <th scope="col">PRECIO</th>
                          <th scope="col">SERV. MEDICO</th>
                          <th scope="col">SERV. TECNICO</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>${{tratamiento.tratamiento.costo}} MXN</td>
                          <td>${{tratamiento.tratamiento.tratamiento}} MXN</td>
                          <td>${{tratamiento.tratamiento.tecnico}} MXN</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>

  <div class='text-center'>
      <h4>Detalle de Tratamiento</h4>
  </div>

  <div class="container">
      <div class="row justify-content-center">
          <div class="col-sm-12 col-md-10 col-lg-8">
              <table class="table text-center table-sm">
                  <thead class="thead-light">
                      <tr>
                          <th scope="col">DESCRIPCION</th>
                          <th scope="col">MONTO</th>
                          <th scope="col">TIPO SERVICIO</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let detalle of tratamiento.detalle">
                        <td>{{detalle.descripcion}}</td>
                        <td>${{detalle.monto}}</td>
                        <td *ngIf="detalle.tecnico == 1">Técnico</td>
                        <td *ngIf="detalle.tecnico == 0">Médico</td>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>
