<div *ngIf="medico != null">

  <div class="row justify-content-center">
    <div class="col-12 col-sm-12 col-md-6 col-lg-8">
      <h2>Saldo Doctor: {{medico}}</h2><br>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4">
      <button class="btn btn-info btn-block" (click)="retiroMedico(contentModal)">
        <i class="fas fa-file-invoice-dollar mr-1"></i> Retirar Dinero
      </button>
    </div>
  </div>

  <div class="dropdown-divider"></div>
  <div class="container ">
      <div class="row justify-content-center">
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 text-center">
              <h4>Balance General</h4>
              <h5>${{saldo.total}} MXN</h5>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
            <h4>Disponible en Caja</h4>
            <h5>${{saldo.saldo}} MXN</h5>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
            <h4>Por retirar de Tarjeta</h4>
            <h5>${{saldo.tarjeta}} MXN</h5>
        </div>
      </div>
  </div>
</div>

  <br>
  <div class="dropdown-divider"></div>
  <br>

  <blockquote class="blockquote mt-3 ">
    <p class="mb-0">* El monto del tipo "Entrada" corresponde al porcentaje del monto de acuerdo a la comisión de la fecha de cobro.</p>
  </blockquote>

  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
    <thead>
      <tr>
        <th class="disabled-sorting sorting_desc" style="width: 8%;">Folio</th>
        <th class="disabled-sorting sorting_desc">Concepto</th>
        <th class="disabled-sorting sorting_desc" style="width: 8%;">Monto</th>
        <th class="disabled-sorting sorting_desc" style="width: 8%;">Tipo</th>
        <th class="disabled-sorting sorting_desc" style="width: 18%;">Fecha</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let movimiento of movimientos" [ngStyle]="{'background-color': (movimiento.estado == '1') ? '#ff00002e' : '' }">
        <td style="width: 8%;">{{ movimiento.id_mov_caja }}</td>
        <td>{{ movimiento.concepto }}</td>
        <td style="width: 8%;">${{ movimiento.monto }}</td>
        <td style="width: 8%;">{{ movimiento.tipo }}</td>
        <td style="width: 18%;">{{ movimiento.fecha }}</td>
      </tr>
    </tbody>
  </table>

  <ng-template #contentModal let-c="close" let-d="dismiss">

    <div class="modal-header">
      <h5 class="modal-title" id="modal-primary-title">Retiro de Dinero</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body centered formulario">
      <div class="row justify-content-center text-center">
        <div class="col-sm-8 col-md-6">
          <h6>CONCEPTO</h6>
          <div class="form-group">
            <input
              [(ngModel)]="concepto"
              type="text"
              id="concepto"
              class="form-control">
          </div>
        </div>
        <div class="col-sm-4 col-md-3">
          <h6>MONTO</h6>
          <div class="form-group">
            <input id="monto-input" class="form-control" currencyMask [(ngModel)]="monto" />
          </div>
        </div>
      </div>
      <div class="alert alert-warning" role="alert" *ngIf=" monto > saldo_disp ">
        No cuenta con saldo suficiente para este retiro.
      </div>
      <div class="row justify-content-center mt-5">
        <div class="col-12 col-sm-8 col-md-5">
            <div class="form-group">
                <button
                  [disabled]="concepto=='' || monto=='' || monto > saldo_disp"
                  (click)="registrarSalida()"
                  class="btn btn-success btn-block">
                  Registrar Movimiento
                </button>
            </div>
        </div>
      </div>

    </div>
  </ng-template>
