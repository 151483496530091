
<h1>Saldo Médicos</h1>
<br>
<div class="dropdown-divider"></div>
<br>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8">

          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
            <thead>
              <tr>
                <th class="disabled-sorting sorting_desc text-center">Doctor</th>
                <th class="disabled-sorting sorting_desc text-center" data-priority="1">Pagar a Doctor</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let medico of medicos" >
                <td class="text-center">{{ medico.texto }}</td>
                <td class="text-center">
                  <button class="btn btn-success btn-icon btn-sm" [routerLink]="['/caja/medico', medico.id]" title="Pagar a Doctor" aria-label="Pagar a Doctor" data-pjax="0">
                    <i class="fas fa-credit-card"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
    </div>
</div>
