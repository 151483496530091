import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UsuarioService } from '../../services/usuario.service';
import { HeaderService } from "../../services/header.service";
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { NotifierService } from "angular-notifier";
import Swal from 'sweetalert2';
import { Global } from 'src/app/services/global';
import { Usuario } from 'src/app/models/usuario';
import { PusherService } from 'src/app/services/pusher.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {

  public modelUsuario:Usuario;
  public estado: string;
  public token;
  public identity;
  public urlImg;
  private readonly notifier: NotifierService;
  public validacion = null;
  public clinicas = null;
  public clinica_seleccionada = 0;

  constructor(
    private _headerService: HeaderService,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
    private _router: Router,
    private _route: ActivatedRoute,
    _notifierService: NotifierService,
    private spinner: NgxSpinnerService,

  ){
    if( this._usuarioService.getIdentity() ){
      this._router.navigate(['inicio']);
    }
    this.modelUsuario = new Usuario(1,1,1,"","","","","",1,1,"",1);
    this.notifier = _notifierService;
    this.urlImg = Global.urlImg;
    this.validarServicio();
  }

  ngOnInit() {

  }

  validarServicio(){
    this.spinner.show('spnr-l');
    let respuesta = this._configuracionesService.validarServicio();
    respuesta.then(
      val =>{
        if( val.status == "success" && val.validacion != null ){
          this.validacion = val.validacion;
          this.obtenerClinicas();
        }else{
          this.spinner.hide('spnr-l');
          this.mensajeError( 'No se han validado los datos de tu servicio.' );
        }
      },
      error => {
        this.spinner.hide('spnr-l');
        this.validacion = null;
        this.mensajeError( 'No se han validado los datos de tu servicio.' );
      }
    );
  }

  obtenerClinicas(){
    let respuesta = this._configuracionesService.obtenerClinicas( );
    respuesta.then(
      val => {
        if( val.status == "success" ){
          if(val.cantidad > 1){
            this.clinicas = val.clinicas;
          }
          this.spinner.hide('spnr-l');
        }else{
          this.spinner.hide('spnr-l');
          this.mensajeError( 'No se han validado los datos de tu servicio.' );
        }
      },
      error => {
        this.spinner.hide('spnr-l');
        this.mensajeError( 'No se han validado los datos de tu servicio.' );
      }
     );
  }

  onSubmit( form ){

    this.spinner.show('spnr-l');

    this._usuarioService.ingresar(this.modelUsuario).subscribe(
      response => {
        //console.log(response);
        if( response.status != 'error' ){
          this.estado = 'success';
          this.token = response.signup;

          this._usuarioService.ingresar(this.modelUsuario, true).subscribe(
            response => {

              if( this.clinica_seleccionada == 0 ){
                this.clinica_seleccionada = response.signup.id_clinica;
              }

              let respuesta = this._configuracionesService.obtenerConfiguracion( this.token, this.clinica_seleccionada );
              respuesta.then( val =>{

                this.spinner.hide('spnr-l');

                if( val.status == "success" ){

                  this.identity = response.signup;
                  this._usuarioService.setToken( this.token );
                  this._usuarioService.setIdentity( this.identity );
                  this._configuracionesService.setConfig( val.configuracion );

                  this._router.navigate(['agenda']);
                  this.notifier.notify("success", "Bienvenido!");

                }else{

                  this.estado = 'error';
                  this.mensajeError( val.message );

                }

              });

            },
            error => {
              this.spinner.hide('spnr-l');
              this.estado = 'error';
              //console.log(<any>error);
              this.mensajeError( response.message );
            }
          );

        }else{
          this.spinner.hide('spnr-l');
          this.estado = 'error';
          this.mensajeError( response.message );
        }
      },
      error => {
        this.spinner.hide('spnr-l');
        this.estado = 'error';
        //console.log(<any>error);
        this.mensajeError( "Ha ocurrido un problema al iniciar sesión. Por favor vuelve a intentarlo." );
      }
    );
  }

  selectClinica( valor ){
    this.clinica_seleccionada = valor;
  }

  mostrar(){
    let x:any  = document.getElementById("contrasena");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  mensajeError( mensaje ){
    Swal.fire({
      title: 'Oops..',
      text: mensaje,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
    });
  }

}
