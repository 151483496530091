import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'paciente-create',
  templateUrl: './paciente-create.component.html',
})

export class PacientecreateComponent {

  @Input() public id_paciente;
  @Input() public nuevoPaciente;

  constructor(
    public activeModal: NgbActiveModal
  ){

  }

  ngOnInit(){
  }

}
