import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { CajaService } from 'src/app/services/caja.service';
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';

@Component({
  selector: 'caja-adeudos',
  templateUrl: './caja-adeudos.component.html',
})

export class CajaadeudosComponent {

  public adeudos = null;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService,
  ){

  }

  ngOnInit(){
    this.obtenerAdeudos();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje,
      order:[[1, 'desc']]
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  obtenerAdeudos( filtro = null ){
    this.spinner.show('spnr-s');
    let tipo = (filtro != null)? filtro : 'D';
    let respuesta = this._cajaService.obtenerAdeudos( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.adeudos = val.adeudos;
          this.dtTrigger.next();
          this.spinner.hide('spnr-s');
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Oops...",
            text: "Ha ocurrido un problema al cargar los datos. Vuelve a recargar la pagina por favor.",
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        this._router.navigate(['/error']);
      }
    );
  }

  verAdeudo( id_paciente ){
    this._router.navigate(['/caja/adeudo', id_paciente]);
  }

}
