<div class="container" *ngIf="cModal != true">
  <div class="row">
    <div class="col-6">
      <h1>Registro de Citas</h1>
    </div>
    <div class="col-6">
      <div class="pull-right">
        <p>
            <button
              type="button"
              class="btn btn-success"
              (click)="registroPaciente( )"
              >
              <span class="glyphicon glyphicon-user icono"></span>
              ¿Nuevo Paciente?
            </button>
        </p>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="nuevacitaFrom" (ngSubmit)="registrarCita(nuevacitaFrom.value)">

  <div class="container">

    <div class="form-group">

      <input type="hidden" formControlName="fecha_programada_inicial">
      <input type="hidden" formControlName="id_cita">

    </div>

    <div class="row justify-content-center">

      <div class="col-12 col-sm-5 col-md-4">
        <div class="form-group">
          <label class="control-label" for="fecha_programada">FECHA DE CITA</label>
          <div class="input-group">
            <span class="input-group-prepend" style="display: block;">
              <div class="input-group-text" >
                <i class="glyphicon glyphicon-calendar"></i>
              </div>
            </span>
            <input

              placeholder="Fecha de Cita"
              class="form-control"
              matInput
              [matDatepicker]="picker"
              [min]="minDate"
              formControlName="fecha_programada"
              (click)="picker.open(); $event.stopPropagation();">
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-5 col-md-4">
        <div class="form-group">
          <label class="control-label" for="fecha_programada">HORA DE CITA</label>
          <div class="input-group">
            <span class="input-group-prepend" style="display: block;">
              <div class="input-group-text" >
                <i class="glyphicon glyphicon-time"></i>
              </div>
            </span>
            <input

              class="form-control"
              placeholder="Hora de Cita"
              aria-label="Hora de Cita"
              [ngxTimepicker]="horaCita"
              min="{{minTime}}"
              max="{{maxTime}}"
              formControlName="hora_programada"
              >
            <ngx-material-timepicker
              #horaCita
              [enableKeyboardInput]="true"
              [minutesGap]="stepMinute"
              [timeSet]="cambioHora()"
            ></ngx-material-timepicker>
          </div>

        </div>
      </div>

    </div>

    <div class="row justify-content-center" *ngIf="cModal == true  && id_cita != null">
      <div class="col-12 col-sm-5 col-md-4">
        <div class="form-group">
          <label class="control-label" for="hora_programada_final">HORA DE FINALIZACION</label>
          <div class="input-group">
            <span class="input-group-prepend" style="display: block;">
              <div class="input-group-text" >
                <i class="glyphicon glyphicon-time"></i>
              </div>
            </span>
            <input

              class="form-control"
              placeholder="Hora de Cita"
              aria-label="Hora de Cita"
              [ngxTimepicker]="horaCitaf"
              min="{{minTimef}}"
              max="{{maxTime}}"
              formControlName="hora_programada_final"
              >
            <ngx-material-timepicker
              #horaCitaf
              [enableKeyboardInput]="true"
              [minutesGap]="stepMinute"
            ></ngx-material-timepicker>
          </div>

        </div>
      </div>

    </div>

    <div class="row justify-content-center" *ngIf="cModal == true && id_cita == null">

      <div class="col-12 col-sm-4">
        <div class="form-group">
          <label class="control-label" for="fecha_programada_final">HORA DE FINALIZACION</label>
          <input
            type="text"
            id="fecha_programada_final"
            class="form-control"
            formControlName="fecha_programada_final"
            readonly>

          <div class="help-block"></div>
        </div>
      </div>

      <div class="col-12 col-sm-4 text-center">
        <label class="control-label pb-2">DESHABILITAR HORARIO</label>
        <div class="form-group">
          <mat-slide-toggle (change)="cambiaEstado($event)" formControlName="deshabilitar" color="primary"></mat-slide-toggle>
          <div class="help-block"></div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-12 col-lg-8">
        <div class="form-group">
          <label class="control-label" for="motivo">MOTIVO</label>
          <input
            type="text"
            id="motivo"
            class="form-control"
            formControlName="motivo"
            aria-required="true">

          <div class="help-block"></div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" *ngIf="citaDeshabilitar == false">
      <div class="col-sm-12 col-md-12 col-lg-5">
        <div class="form-group field-paccitas-id_paciente required">
          <label class="control-label" for="paccitas-id_paciente">NOMBRE DEL PACIENTE</label>
          <input
            type="text"
            placeholder="Seleccionar Paciente"
            aria-label="Number"
            class="form-control"
            formControlName="id_paciente"
            matInput
            (keyup)="obtenerPacientes($event)"
            [matAutocomplete]="autoPacientes"
            [attr.readOnly]="id_cita"
            >
          <mat-autocomplete #autoPacientes="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let paciente of pacientes" [value]="paciente">
              {{ paciente.texto }}
            </mat-option>
          </mat-autocomplete>
          <div class="help-block" *ngIf="contCPac == 0">Se requieren 3 o más caracteres</div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-5">
        <div class="form-group field-paccitas-id_usuario required">
          <label class="control-label" for="paccitas-id_usuario">MEDICO</label>
          <input
            type="text"
            placeholder="Seleccionar Médico"
            aria-label="Number"
            class="form-control"
            formControlName="id_medico"
            matInput
            [matAutocomplete]="autoMedicos"
            >
          <mat-autocomplete #autoMedicos="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let medico of medicos" [value]="medico">
              {{ medico.texto }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="form-group">
          <button
            [disabled]="nuevacitaFrom.invalid"
            type="submit"
            class="btn btn-success btn-block">
            Reservar
          </button>
        </div>
      </div>
    </div>

  </div>

</form>

<ng-template #contentModal let-c="close" let-d="dismiss"></ng-template>

