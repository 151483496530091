import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { TratamientosService } from 'src/app/services/tratamientos.service';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { TratamientosregistroComponent } from '../tratamientos-registro/tratamientos-registro.component';


@Component({
  selector: 'tratamientos',
  templateUrl: './tratamientos.component.html',
})

export class TratamientosComponent{

  closeResult: string;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  public tratamientos = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    private _tratamientosService: TratamientosService,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
  ){

  }

  ngOnInit(){
    this.spinner.show('spnr-s');
    this.obtenerTratamientos();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje
    };
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  obtenerTratamientos(){
    this._tratamientosService.obtenerTratamientos().subscribe(
      response => {
        //console.log(response);
        this.tratamientos = response.tratamientos;
        this.dtTrigger.next();
        this.spinner.hide('spnr-s');
      },
    );
  }

  recargarTratamientos() {
    this.spinner.show('spnr-s');
    this._tratamientosService.obtenerTratamientos().subscribe(
      response => {
        this.tratamientos = response.tratamientos;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
        this.spinner.hide('spnr-s');
      },
    );

  }

  registroModal() {
    const modalRef = this.modalService.open(TratamientosregistroComponent, { size: 'lg' });
    modalRef.componentInstance.event.subscribe( respuesta => {
      if( respuesta ){
        this.recargarTratamientos();
      }
    });
  }

  actualizarModal(id_tratamiento) {
    const modalRef = this.modalService.open(TratamientosregistroComponent, { size: 'lg' });
    modalRef.componentInstance.id_tratamiento = id_tratamiento;
    modalRef.componentInstance.event.subscribe( respuesta => {
      if( respuesta ){
        this.recargarTratamientos();
      }
    });
  }

  eliminarTratamiento( id_tratamiento ){

    Swal.fire({
      title: '¿Está seguro de eliminar este Tratamiento? ',
      text: 'Esta acción no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let respuesta = this._tratamientosService.eliminarTratamiento( id_tratamiento );
        respuesta.then( val =>{
          if( val.status == "success" ){

            this.spinner.hide('spnr-s');

            Swal.fire({
              title: "Tratamiento eliminado exitosamente",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

            this.recargarTratamientos();

          }else{

            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

          }
        });

      }
    })

  }

}
