import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { Global } from 'src/app/services/global';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'generales',
  templateUrl: './generales.component.html',
  styleUrls: ['./generales.component.css'],
})

export class GeneralesComponent {

  formGenerales: FormGroup;

  @Input() public id_paciente = null;

  public datos_gen = {
    "id_paciente" : "",
    "asa" : "",
    "peso" : "",
    "talla" : "",
    "f_cardiaca" : "",
    "f_respiratoria" : "",
    "temperatura" : "",
    "presion" : "",
    "glucemia" : "",
    "imc" : "",
    "anestesico" : "",
    "dosis_ponderal" : "",
    "cartuchos" : "",
    "pulsioximetro" : "",
    "sangre" : "",
    'general': "",
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _expedienteService: ExpedienteService,
    private _pacienteService: PacientesService,
    private modalService: NgbModal,
  ){

  }

  ngOnInit(){
    this.colocarValores();
    this.obtenerGenerales();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  colocarValores( generales = null ){
    let datos = null;
    if( generales != null ){
      datos = generales;
    }else{
      datos = this.datos_gen;
      datos.id_paciente = this.id_paciente;
    }

    this.formGenerales = this._bilder.group({
      id_paciente : [datos.id_paciente],
      asa: [datos.asa],
      peso: [ datos.peso, Validators.maxLength(10) ],
      talla: [ datos.talla, Validators.maxLength(10) ],
      f_cardiaca: [ datos.f_cardiaca, Validators.maxLength(10) ],
      f_respiratoria: [ datos.f_respiratoria , Validators.maxLength(10) ],
      temperatura: [ datos.temperatura, Validators.maxLength(10)],
      presion: [ datos.presion , Validators.maxLength(10) ],
      glucemia: [ datos.glucemia , Validators.maxLength(10) ],
      imc: [ datos.imc , Validators.maxLength(50) ],
      anestesico: [ datos.anestesico, Validators.maxLength(35) ],
      dosis_ponderal: [ datos.dosis_ponderal , Validators.maxLength(10)],
      cartuchos: [ datos.cartuchos, Validators.maxLength(10) ],
      pulsioximetro: [ datos.pulsioximetro, Validators.maxLength(45) ],
      sangre: [ datos.sangre, Validators.maxLength(45) ],
      general: [ datos.general ]
    });
  }

  obtenerGenerales(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerGenerales( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            if( val.generales != null ){
              this.colocarValores( val.generales );
            }
          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  mostrarAsa(contentModal){
    this.modalService.open(contentModal);
  }

  calculoAnestesico(){
    let anestesico = this.formGenerales.get('anestesico').value;
    let peso =  this.formGenerales.get('peso').value;
    if( peso == 0 || anestesico == "" ){ return; }

    let dosisP = 0;
    let cartuchos = 0;
    let anestesicoMg = 0;
    let anestesicoCartucho = 0;

    if ( anestesico == 'LIDOCAINA' || anestesico == 'MEPIVACAINA' ){
      anestesicoMg = 4.4;
      if ( anestesico == 'MEPIVACAINA' ){
        anestesicoCartucho = 54;
      }else{
        anestesicoCartucho = 36;
      }
    }
    if ( anestesico == 'LIDOCAINA+EPINEFRINA' || anestesico == 'ARTICAINA+EPINEFRINA' ){
      anestesicoMg = 7;
      if ( anestesico == 'LIDOCAINA+EPINEFRINA' ){
        anestesicoCartucho = 36;
      }else{
        anestesicoCartucho = 72;
      }
    }

    dosisP = ( peso * anestesicoMg );
    cartuchos = ( dosisP / anestesicoCartucho );

    this.formGenerales.controls['dosis_ponderal'].setValue( dosisP.toFixed(2) );
    this.formGenerales.controls['cartuchos'].setValue( cartuchos.toFixed(2) );

  }

  calcularIMC(){

    let total = 0;
    let peso =  this.formGenerales.get('peso').value;
    let talla:any = this.formGenerales.get('talla').value
    peso = (peso != "" )? parseFloat(peso) : 0;
    talla = (talla != "" )? parseFloat(talla) : 0;

    if( talla == 0 || peso == 0){ return; }

    total = (peso / Math.pow(talla, 2));

    let clasificacion;
    switch ( true ) {
      // BAJO DE PESO
      case total < 16.00:
        clasificacion='BAJO PESO (Delgadez Severa)';
        break;

      case total >= 16.00 && total <= 16.99:
        clasificacion='BAJO PESO (Delgadez Moderada)';
        break;

      case total >= 17.00 && total <= 18.49:
        clasificacion='BAJO PESO (Delgadez Leve)';
        break;

      // PESO NORMAL
      case total >= 18.50 && total <= 24.99:
        clasificacion='NORMAL';
        break;

      // SOBREPESO
      case total >= 25.00 && total <= 29.99:
        clasificacion='SOBREPESO (Preobesidad)';
        break;

      // OBESIDAD
      case total >= 30.00 && total <= 34.99:
        clasificacion='OBESIDAD (Obesidad Leve)';
        break;

      case total >= 35.00 && total <= 39.99:
        clasificacion='OBESIDAD (Obesidad Media)';
        break;

      case total >= 40.00:
        clasificacion='OBESIDAD (Obesidad Mórvida)';
        break;

    }

    this.formGenerales.controls['imc'].setValue(total.toFixed(2) +' '+ clasificacion);

    this.calculoAnestesico();

  }

  registrarGenerales( datos ){
    datos.sangre = datos.sangre.toString();
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.registrarGenerales( datos );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Registro Exitoso",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al registrar los datos, por favor intentalo nuevamente.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

}
