import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'caja',
  templateUrl: './caja.component.html',
})

export class CajaComponent {

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
  ){

  }

  ngOnInit(){

  }

}
