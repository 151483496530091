import { Component, OnInit } from '@angular/core';
import { HeaderService } from "../../../services/header.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import { PacientesService } from 'src/app/services/pacientes.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'header-tag',
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit{

  titulo: string = "Titulo";
  subtitulo: string = "Subtitulo";
  imagen: string = "MidMoonStudioLarge.png";
  public urlImg;
  public identity;
  public token;
  public pacientes = null;
  public f_paciente = null;

  constructor(
    public _usuarioService: UsuarioService,
    public headerService: HeaderService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _bilder: FormBuilder,
    private _pacientesService: PacientesService,
    private spinner: NgxSpinnerService,
  ){
    this.identity = _usuarioService.getIdentity();
    this.titulo = "Titulo";
    this.subtitulo = "Subtitulo";
    this.imagen = "Imagen";
    this.urlImg = Global.urlImg;
  }

  ngOnInit() {
    this._usuarioService.identity$.subscribe( identity =>{
      this.identity = identity;
    });
    this.headerService.titulo$.subscribe( data =>{
      this.titulo = data[0];
      this.subtitulo = data[1];
      this.imagen = data[2];
    });
  }

  salir(){
    Swal.fire({
      title: 'Cerrar Sesión',
      text: "¿Estas seguro que deseas salir?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cerrar Sesion',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');
        let respuesta = this._usuarioService.cerrarS( );
        respuesta.then(
          val =>{
            if( val.status == "success" ){
              this.spinner.hide('spnr-s');

              this.identity = null;
              this.token = null;
              this._usuarioService.cerrarSesion();
              this._router.navigate(['login']);

            }else{
              Swal.fire({
                title: 'Oopss...',
                text: "Ha ocurrido un problema al realizar esta acción. Por favor vuelve a intentarlo.",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
            }
          },
          error => {
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: "Ha ocurrido un problema al realizar esta acción. Por favor vuelve a intentarlo.",
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        );

      }
    })

  }

  obtenerPacientes(event: any) {
    let texto = '';
    texto += event.target.value;

    if ( texto.length >= 3 ) {

      let respuesta = this._pacientesService.obtenerPacientes(texto);
      respuesta.then( val =>{
        if( val.status == "success" ){
          if(val.pacientes != null ){
            this.pacientes = val.pacientes;
          }
        }
      });

    }else{
      this.pacientes = null;
    }
  }

  displayFn(elemento): string {
    return elemento ? elemento.texto : elemento;
  }

  navToProd( elemento ){
    let id_paciente = elemento.option.value.id;
    this.f_paciente = null;
    this._router.navigate(['paciente/expediente/'+ id_paciente]);
  }

}
