import { Component, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClinicasService } from 'src/app/services/clinicas.service';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'clinicas-registro',
  templateUrl: './clinica-registro.component.html',
})

export class ClinicaregistroComponent {

  formClinica: FormGroup;
  @Input() public id_clinica = "";
  @Input() public tipo;
  public event: EventEmitter<any> = new EventEmitter();

  public estados = null;
  public municipios = null;
  public datos_clinica = {
    "id_clinica" : "",
    "razon_social" : "",
    "rfc" : "",
    "direccion" : "",
    "id_estado" : "",
    "id_municipio" : "",
    "telefono" : ""
  };

  constructor(
    public activeModal: NgbActiveModal,
    private _bilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _clinicasService: ClinicasService,
    private _configuracionesService: ConfiguracionesService,
  ){

  }

  ngOnInit(){
    this.obtenerEstados();
    this.colocarValores();
    if( this.id_clinica != "" ){
      this.obtenerClinica();
    }
  }

  obtenerEstados(){
    this.spinner.show('spnr-s');
    let respuesta = this._configuracionesService.obtenerEstados();
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.estados != null ){
          this.estados = val.estados;
          this.spinner.hide('spnr-s');
        }
      }
    });
  }

  obtenerMunicipios( id_estado ){
    this.spinner.show('spnr-s');
    let respuesta = this._configuracionesService.obtenerMunicipios( id_estado );
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.municipios != null ){
          this.municipios = val.municipios;
          this.spinner.hide('spnr-s');
        }
      }
    });
  }

  colocarValores( clinica = null ){
    let datos = null;
    if( clinica != null ){
      datos = clinica;
    }else{
      datos = this.datos_clinica;
    }

    this.formClinica = this._bilder.group({
      id_clinica : [datos.id_clinica],
      razon_social: [datos.razon_social, Validators.compose([ Validators.maxLength(45), Validators.required ])],
      rfc: [ datos.rfc, Validators.maxLength(45)  ],
      direccion: [ datos.direccion, Validators.maxLength(70)  ],
      id_estado: [ datos.id_estado, Validators.required  ],
      id_municipio: [ datos.id_municipio, Validators.required ],
      telefono: [ datos.telefono, Validators.maxLength(10)  ],
    });
  }

  obtenerClinica(){
    this.spinner.show('spnr-s');
    let respuesta = this._clinicasService.obtenerClinica( this.id_clinica );
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.clinica != null ){
          this.obtenerMunicipios(val.clinica.id_estado);
          this.colocarValores( val.clinica );
          this.spinner.hide('spnr-s');
        }
      }
    });
  }

  displayFn(elemento): string {
    return elemento ? elemento.texto : elemento;
  }

  cambioEstado(id_estado){
    this.obtenerMunicipios(id_estado);
  }

  registrarClinica( datos ){
    this.spinner.show('spnr-s');
    let respuesta = null;
    let mensaje = null;

    if( this.id_clinica == "" ){
      respuesta = this._clinicasService.registrarClinica( datos );
      mensaje = "Clínica Registrada Exitosamente";
    }else{
      respuesta = this._clinicasService.actualizarClinica( datos );
      mensaje = "Clínica Actualizada Exitosamente";
    }

    respuesta.then( val =>{
      if( val.status == "success" ){
        this.activeModal.close();
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: mensaje,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
        this.event.emit(1);
      }else{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: val.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    });

  }

}
