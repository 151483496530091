import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';
declare var moment: any;

@Component({
  selector: 'paciente',
  templateUrl: './paciente.component.html',
})

export class PacienteComponent {

  public id_paciente = null;
  public subscribe = null;

  formPaciente: FormGroup;
  public clinicas = null;

  public datos_pac = {
    "id_paciente" : "",
    "nombre" : "",
    "ape_pat" : "",
    "ape_mat" : "",
    "fecha_nac" : "",
    "edad" : "",
    "sexo" : "Femenino",
    "tel" : "",
    "direccion" : "",
    "correo" : "",
    "mensajes" : false,
    "rfc" : "",
    "ocupacion" : "",
    "escoloridad" : "",
    "grado" : "",
  };


  constructor(
    private _route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _pacienteService: PacientesService,
  ){
    this.colocarValores( );
  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_paciente =  params['id'];
    });
    if( this.id_paciente == null ){
      this.id_paciente = this._route.parent.snapshot.paramMap.get('id');
    }
    this.subscribe = this._pacienteService.id_paciente$.subscribe( id_paciente =>{
      this.id_paciente = id_paciente;
    });

    this.obtenerPaciente();
  }

  ngOnDestroy(){
    this.subscribe.unsubscribe();
  }

  ngOnChanges() {
    this.ngOnInit();
    this.obtenerPaciente();
  }

  colocarValores( paciente = null ){
    let datos = null;
    if( paciente != null ){
      datos = paciente;
    }else{
      datos = this.datos_pac;
    }
    datos.mensajes = (datos.mensajes == "1" || datos.mensajes == true) ? true : false;
    datos.fecha_nac = (datos.fecha_nac != "" && datos.fecha_nac != null) ?  new Date( datos.fecha_nac + " "): "";

    this.formPaciente = this._bilder.group({
      id_paciente : [datos.id_paciente],
      nombre: [datos.nombre, Validators.compose([ Validators.maxLength(150), Validators.required ])],
      ape_pat: [ { value : datos.ape_pat, disabled: false }, [ Validators.maxLength(45) ] ],
      ape_mat: [ { value : datos.ape_mat, disabled: false }, [ Validators.maxLength(45) ] ],
      fecha_nac: [ datos.fecha_nac ],
      edad: [ datos.edad , Validators.maxLength(10) ],
      sexo: [ datos.sexo, Validators.required],
      tel: [ datos.tel , Validators.maxLength(20) ],
      direccion: [ datos.direccion , Validators.maxLength(100) ],
      correo: [ datos.correo , Validators.maxLength(100) ],
      mensajes: [ datos.mensajes ],
      rfc: [ datos.rfc , Validators.maxLength(20)],
      ocupacion: [ datos.ocupacion ],
      escoloridad: [ datos.escoloridad ],
      grado: [ datos.grado ]
    });
  }

  cambioFecha( fecha, type ){

    var edad = null;
    if(fecha){
      var cumpleanos = new Date(fecha);
      if( type == 'input' ){
        let dFormat = moment(fecha, 'DD-MM-YYYY').format('MM-DD-YYYY');
        let date = new Date(dFormat);
        cumpleanos = date;
        this.formPaciente.controls['fecha_nac'].setValue( date );
      }

      var hoy = new Date();
      edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();
      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
          edad--;
      }
      edad = edad.toString();
    }

    this.formPaciente.controls['edad'].setValue( edad );
  }

  obtenerPaciente(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._pacienteService.obtenerPaciente( this.id_paciente );
      respuesta.then( val =>{
        if( val.status == "success" ){
          if(val.paciente != null ){
            this.colocarValores( val.paciente );
            this.spinner.hide('spnr-s');
          }
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      });
    }
  }

  obtenerFecha( fecha = null, formato = null ){

    if( fecha != null){
      var d = new Date( fecha );
    }else{
      var d = new Date();
    }

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if( formato != null && formato == "en" ){
      return [year, month, day].join('-') ;
    }else{
      return [day, month, year].join('-') ;
    }

  }

  registrarPaciente( datos ){

    this.spinner.show('spnr-s');
    datos.fecha_nac = this.obtenerFecha( datos.fecha_nac, 'en' );
    if( datos.fecha_nac == 'NaN-NaN-NaN'){
      datos.fecha_nac = null;
    }
    datos.mensajes = ( datos.mensajes == true ) ? '1' : '0';

    let respuesta = this._pacienteService.actualizarPaciente( datos );
    respuesta.then(
      val =>{
        if( val.status == "success" ){

          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Registro Exitoso",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      error =>{
        this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al registrar los cambios, por favor vuelve a intentarlo.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
      }
    );

  }

}
