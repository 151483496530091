<form [formGroup]="formGineco" (ngSubmit)="registrarGineco(formGineco.value)">

  <div class="container">
      <div class="row">
        <div class="col-sm-6 text-center">
          <h5>CONCEPTO</h5>
        </div>
        <div class="col-sm text-center">
          <h5>OBSERVACIONES</h5>
        </div>
      </div>
      <div class="row">
          <div class="col-sm-6">
            <b>Fecha de tu ultima menstruación</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="menstruacion">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-6">
            <b>Eres regular</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="regular">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-6">
            <b>Vida sexual activa</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="sexual">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-6">
            <b>Usas algún método anticonceptivo (cúal)</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="anticonceptivo">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-6">
            <b>Embarazo</b> (semanas de gestación)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="embarazo">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-6">
            <b>Tienes hijos (cuantos)</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="hijos">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-6">
            <b>Estas lactando</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="lactando">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-6">
            <b>Complicaciones durante el embarazo (cuales)</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="complicaciones">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-6">
            <b>Abortos</b>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="abortos">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row justify-content-center">
        <div class="col-sm-8 col-md-6 ">
            <div class="form-group">
                <button class="btn btn-success btn-block">
                  Guardar Gineco-Obstetras
                </button>
            </div>
        </div>
      </div>

  </div>

</form>
