import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { CajaService } from 'src/app/services/caja.service';
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";

@Component({
  selector: 'caja-medico',
  templateUrl: './caja-medico.component.html',
})

export class CajamedicoComponent {

  public movimientos = null;
  public saldo_disp = null;
  public saldo = null;
  public medico = null;
  public id_medico = null;
  public concepto = "";
  public monto = "";
  public modalRef = null;
  formRetiro: FormGroup;
  public estado_tabla = 0;
  public safari = 0;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService,
    private modalService: NgbModal,
  ){

  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_medico = params['id'];
    });

    this.spinner.show('spnr-s');

    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      this.safari = 1;
    }

    let respuesta = this._cajaService.validarApertura( );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          if(val.apertura == 0){
            this._router.navigate(['/caja/apertura']);
          }else{
            this.obtenerSaldomedico( );
            this.dtOptions = {
              pagingType: 'full_numbers',
              responsive: true,
              language: Global.lenguaje,
              order:[[0, 'desc']]
            };
          }
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        this._router.navigate(['/error']);
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  obtenerSaldomedico( ){
    this.spinner.show('spnr-s');
    let respuesta = this._cajaService.obtenerSaldomedico( this.id_medico );
    respuesta.then( val =>{
      if( val.status == "success" ){
        this.movimientos = val.movimientos;
        this.saldo = val.saldo;
        this.saldo_disp = this.saldo.saldo;
        this.medico = val.medico;
        if( this.estado_tabla == 0 ){
          this.estado_tabla = 1;
          this.dtTrigger.next();
        }else{
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        }

        this.spinner.hide('spnr-s');
      }
    });
  }

  retiroMedico(contentModal){
    this.modalRef = this.modalService.open(contentModal, {size: 'lg'});
  }

  registrarSalida(){
    Swal.fire({
      title: "Se realizará un Retiro por: $" + this.monto,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let datos = {
          monto: this.monto,
          concepto: this.concepto,
          im: this.id_medico
        };

        let respuesta = this._cajaService.retiroMedico( datos );
        respuesta.then(
          val =>{
            if( val.status == "success" ){

              this.spinner.hide('spnr-s');
              Swal.fire({
                title: "Registrado",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });

              this.obtenerSaldomedico( );
              this.modalRef.close();
              this.concepto = '';
              this.monto = '';

              let estado_ticket = val.ticket.ticket;
              let ticket_id = val.ticket.ticket_id;
              let tipo = val.ticket.tipo;
              this.imprimirTicket(ticket_id);

            }else{

              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'No se ha registrado.',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });

            }

          },
          error =>{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Ha ocurrido un problema al realizar este movimiento.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        );

      }
    })
  }

  imprimirTicket(id_ticket){
    var ancho = 8 / 2.54 * 72
    let respuesta = this._cajaService.obtenerTicket( id_ticket, 'me' );
    respuesta.then(
      val =>{
        if( val.status == "success" ){

          var html = htmlToPdfmake(val.ticket, {tableAutoSize:true});
          const documentDefinition = {
            pageSize: {
              width: ancho,
              height: 'auto'
            },
            pageMargins: [12, 12, 12, 12 ],
            content: html
          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Ticket.',
            text: 'Por favor intentalo desde el listado de movimientos.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this._router.navigate(['/caja/movimientos']);
        }

      },
      error =>{
        this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Ticket.',
            text: 'Por favor intentalo desde el listado de movimientos.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this._router.navigate(['/caja/movimientos']);
      }
    );
  }

}
