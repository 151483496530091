import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { CuestionariosService } from 'src/app/services/cuestionarios.service';
import { CuestionariosregistroComponent } from '../cuestionarios-registro/cuestionarios-registro.component';

@Component({
  selector: 'cuestionarios',
  templateUrl: './cuestionarios.component.html',
})

export class CuestionariosComponent{

  closeResult: string;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  public cuestionarios = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    private _cuestionariosService: CuestionariosService,
    private spinner: NgxSpinnerService,
  ){

  }

  ngOnInit(){
    this.spinner.show('spnr-s');
    this.obtenerCuestionarios();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje
    };
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  obtenerCuestionarios(){
    this._cuestionariosService.obtenerCuestionarios().subscribe(
      response => {
        //console.log(response);
        this.cuestionarios = response.cuestionarios;
        this.dtTrigger.next();
        this.spinner.hide('spnr-s');
      },
    );
  }

  recargarCuestionarios() {
    this.spinner.show('spnr-s');
    this._cuestionariosService.obtenerCuestionarios().subscribe(
      response => {
        this.cuestionarios = response.cuestionarios;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
        this.spinner.hide('spnr-s');
      },
    );

  }

  registroModal() {
    const modalRef = this.modalService.open(CuestionariosregistroComponent, { size: 'lg' });
    modalRef.componentInstance.event.subscribe( respuesta => {
      if( respuesta ){
        this.recargarCuestionarios();
      }
    });
  }

  actualizarModal(id_cuestionario) {
    const modalRef = this.modalService.open(CuestionariosregistroComponent, { size: 'lg' });
    modalRef.componentInstance.id_cuestionario = id_cuestionario;
    modalRef.componentInstance.event.subscribe( respuesta => {
      if( respuesta ){
        this.recargarCuestionarios();
      }
    });
  }

  deshabilitarPregunta( id_cuestionario ){

    Swal.fire({
      title: '¿Está seguro de habilitar/deshabilitar esta Pregunta? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let respuesta = this._cuestionariosService.deshabiitarCuestionario( id_cuestionario );
        respuesta.then( val =>{
          if( val.status == "success" ){

            this.spinner.hide('spnr-s');

            Swal.fire({
              title: "Pregunta habilitada/deshabilitada exitosamente",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

            this.recargarCuestionarios();

          }else{

            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

          }
        });

      }
    })

  }

  eliminarPregunta( id_cuestionario ){

    Swal.fire({
      title: '¿Está seguro de eliminar esta Pregunta? ',
      text: 'Esta acción no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');

        let respuesta = this._cuestionariosService.eliminarCuestionario( id_cuestionario );
        respuesta.then( val =>{
          if( val.status == "success" ){

            this.spinner.hide('spnr-s');

            Swal.fire({
              title: "Pregunta eliminada exitosamente",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

            this.recargarCuestionarios();

          }else{

            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });

          }
        });

      }
    })

  }

}
