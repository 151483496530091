import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";
import { ConfiguracionesService } from "./configuraciones.service";

@Injectable()

export class MedicosService{
  public url: string;
  public token = null;
  public config = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.url = Global.url;
    this.config = this._configuracionesService.getConfig( );
    this.token = this._usuarioService.getToken();
  }

  async obtenerMedicos( ){

    let data = JSON.stringify( {
      "clinica":this.config.id_clinica,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "medicos/obtenermedicos", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerMedicosall( ){

    let data = JSON.stringify( {
      "clinica":this.config.id_clinica,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "medicos/obtenermedicosall", params, {headers: headers}).toPromise();

    return respuesta;

  }

}
