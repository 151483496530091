import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import { Lightbox } from 'ngx-lightbox';
import { LightboxConfig } from 'ngx-lightbox';
import { ActivatedRoute, Params } from '@angular/router';
import { PacientesService } from 'src/app/services/pacientes.service';

@Component({
  selector: 'archivos',
  templateUrl: './archivos.component.html',
})

export class ArchivosComponent {

  public id_paciente = null;
  public id_tratamiento = null;
  public archivos = null;
  formArchivo: FormGroup;
  public activeModal = null;
  public imagen = null;
  public imagenes = null;
  public urlImg = "";
  public subscribe = null;
  public tratamientos = null;

  public iniciado = 0;
  public archivo = {
    'id_archivo': '',
    'tipo': 'Fotografia',
    'fecha': '',
    'archivo': '',
    'descripcion': '',
    'id_paciente': '',
    'id_tratamiento': '',
  };
  public archivo_view = null;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _bilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _expedienteService: ExpedienteService,
    private modalService: NgbModal,
    private _lightbox: Lightbox,
    private _lightboxConfig: LightboxConfig,
    private _pacienteService: PacientesService,
  ){
    _lightboxConfig.showZoom = true;
    this.urlImg = Global.urlImg;
    this._lightbox
  }

  ngOnInit(){

    this._route.params.forEach((params:Params) => {
      this.id_paciente =  params['id'];
    });
    if( this.id_paciente == null ){
      this.id_paciente = this._route.parent.snapshot.paramMap.get('id');
    }
    this.subscribe = this._pacienteService.id_paciente$.subscribe( id_paciente =>{
      this.id_paciente = id_paciente;
    });

    this.obtenerTratamientos();
    this.obtenerArchivos();
    this.colocarValores();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje,
      order:[[0, 'desc']]
    };

  }

  ngOnChanges() {
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  obtenerTratamientos(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerListatratamientos( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            this.tratamientos = val.tratamientos;
          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  obtenerArchivos(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerArchivospaciente( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            this.archivos = val.archivos;

            if(this.iniciado == 0){
              this.iniciado = 1;
              this.dtTrigger.next();
            }else{
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
              });
            }

          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  colocarValores( archivo = null ){
    let datos = null;
    if( archivo != null ){
      datos = archivo;
    }else{
      datos = this.archivo;
    }

    this.formArchivo = this._bilder.group({
      id_archivo: [ datos.id_archivo ],
      tipo: [ datos.tipo ],
      descripcion: [ datos.descripcion, Validators.maxLength(100) ],
      id_tratamiento: [ datos.id_tratamiento ],
    });
  }

  registroArchivo(contentModal){
    this.colocarValores();
    this.activeModal = this.modalService.open(contentModal);
  }

  verArchivo(id_archivo, viewModal){
    this.imagenes = [];
    this.archivo_view = null;
    this.activeModal = null;
    this.archivos.forEach( element => {
        if(element.id_archivo == id_archivo){
          this.archivo_view = element;

          const src = this.urlImg + "documentos_clinicos/" + element.archivo;
          const caption = element.descripcion;
          const thumb = this.urlImg + "documentos_clinicos/" + element.archivo;
          const album = {
              src: src,
              caption: caption,
              thumb: thumb
          };

          this.imagenes.push(album);

          this.activeModal = this.modalService.open(viewModal);

        }
    });

  }

  open(i): void {
    this._lightbox.open(this.imagenes, i);
  }

  close(): void {
    this._lightbox.close();
  }

  eliminarArchivo(id_archivo){

    Swal.fire({
      title: '¿Está seguro de eliminar este Elemento? ',
      text: 'Esta acción no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show('spnr-s');
        let respuesta = this._expedienteService.eliminarArchivo( id_archivo );
        respuesta.then(
          val =>{
            if( val.status == "success" ){
              this.activeModal.close();
              this.spinner.hide('spnr-s');
              Swal.fire({
                title: "Archivo Eliminado Exitosamente",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
              this.obtenerArchivos();
            }else{
              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'Oopss...',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
            }
          },
          error =>{
            this.spinner.hide('spnr-s');
            Swal.fire({
                title: 'Oopss...',
                text: 'Ha ocurrido un problema al eliminar este elemento.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
          }
        );

      }
    })

  }

  cambioArchivo(fileInput) {
    this.spinner.show('spnr-s');
    this.imagen = <Array<File>>fileInput.target.files;
    this.spinner.hide('spnr-s');
  }

  registrarArchivo( data ){
    this.spinner.show('spnr-s');
    let archivo = this.imagen;
    this._expedienteService.subirArchivospaciente( archivo, data, this.id_paciente ).then(
      (result) =>{
        if( result.status == "success" ){

          this.activeModal.close();
          this.obtenerArchivos();
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Archivo Cargado Exitosamente",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }else{

          this.spinner.hide('spnr-s');

          Swal.fire({
            title: 'Oopss...',
            text: result.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      (error) =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha ocurrido un problema al cargar este elemento.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );
  }

}
