<div class="container odontocontent" (window:resize)="onResize()">
    <div class="panel panel-primary">
        <div class="panel-body">
            <div class="row justify-content-center">
                <div class="col-md">
                    <div id="controls" class="panel panel-default container">
                        <div class="panel-body row">
                            <div class="col-sm-12">
                                <div class="grupobotones  btn-group-toggle justify-content-center" data-toggle="buttons">
                                    <label id="carie" class="btn btn-claro active">
                                        <i style="color: red;" class="fa fa-circle fa-fw iconocirculo"></i>
                                        <input type="radio" name="options" id="option1" autocomplete="off" checked> Caries
                                    </label>
                                    <label id="carieamplia" class="btn btn-claro">
                                        <i style="color: red;" class="fa fa-square-full fa-fw "></i>
                                        <input type="radio" name="options" id="option2" autocomplete="off"> Caries Amplia
                                    </label>
                                    <label id="obturacion" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-square-full fa-fw "></i>
                                        <input type="radio" name="options" id="option3" autocomplete="off"> Obturación
                                    </label>
                                    <label id="noerupcionado" class="btn btn-claro">
                                        <i style="color: #747F7D;" class="fa fa-square-full fa-fw "></i>
                                        <input type="radio" name="options" id="option4" autocomplete="off"> No Erupcionado
                                    </label>
                                    <label id="extracciono" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-times fa-fw"></i>
                                        <input type="radio" name="options" id="option5" autocomplete="off"> Extraccion Ortodoncia
                                    </label>
                                    <label id="extraccionp" class="btn btn-claro">
                                        <i style="color: red;" class="fa fa-times fa-fw"></i>
                                        <input type="radio" name="options" id="option6" autocomplete="off"> Extraccion Periodontal
                                    </label>
                                    <label id="puente" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-minus fa-fw"></i>
                                        <input type="radio" name="options" id="option7" autocomplete="off"> Puente
                                    </label>
                                    <label id="puentem" class="btn btn-claro">
                                        <i style="color: red;" class="fa fa-minus fa-fw"></i>
                                        <input type="radio" name="options" id="option8" autocomplete="off"> Puente Mal Adaptado
                                    </label>
                                    <label id="fractura" class="btn btn-claro">
                                        <i style="color: red;" class="fa fa-slash fa-fw fa-flip-horizontal"></i>
                                        <input type="radio" name="options" id="option9" autocomplete="off"> Fractura
                                    </label>
                                    <label id="semierupcionado" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-slash fa-fw fa-flip-horizontal"></i>
                                        <input type="radio" name="options" id="option10" autocomplete="off"> Semierupcionado
                                    </label>
                                    <label id="sano" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-check fa-fw"></i>
                                        <input type="radio" name="options" id="option11" autocomplete="off"> Sano
                                    </label>
                                    <label id="restoradicular" class="btn btn-claro">
                                        <i style="color: blue;" class="fa  fa-RR-icono fa-fw"></i>
                                        <input type="radio" name="options" id="option12" autocomplete="off"> Resto Radicular
                                    </label>
                                    <label id="ppr" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-minus fa-fw"></i>
                                        <input type="radio" name="options" id="option13" autocomplete="off"> PPR
                                    </label>
                                    <label id="obturacionmal" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-circle fa-fw iconocirculo"></i>
                                        <input type="radio" name="options" id="option14" autocomplete="off"> Obturacion Mal Adaptada
                                    </label>
                                    <label id="corona" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-crown fa-fw "></i>
                                        <input type="radio" name="options" id="option15" autocomplete="off"> Corona
                                    </label>
                                    <label id="movil" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-MOVIL-icono fa-fw "></i>
                                        <input type="radio" name="options" id="option16" autocomplete="off"> Movilidad
                                    </label>
                                    <label id="desgaste" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-DESGASTE-icono fa-fw "></i>
                                        <input type="radio" name="options" id="option17" autocomplete="off"> Desgaste
                                    </label>
                                    <label id="endo" class="btn btn-claro">
                                        <i style="color: green;" class="fa fa-ENDO-icono fa-fw "></i>
                                        <input type="radio" name="options" id="option18" autocomplete="off"> ENDODONCIA
                                    </label>
                                    <label id="implante" class="btn btn-claro">
                                        <i style="color: green;" class="fa fa-IMPLANTE-icono fa-fw "></i>
                                        <input type="radio" name="options" id="option19" autocomplete="off"> IMPLANTE
                                    </label>
                                    <label id="borrar" class="btn btn-claro">
                                        <i style="color: blue;" class="fa fa-eraser fa-fw "></i>
                                        <input type="radio" name="options" id="option20" autocomplete="off"> Borrar
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="dientes" id="dientes" style="background-color: white;">

            </div>
        </div>
    </div>
</div>
<div id="img-out"></div>

<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-8">
        <div class="form-group">
          <label class="control-label" >Pronostico</label>
          <textarea class="form-control" [(ngModel)]="pronostico">
          </textarea>
        </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-8">
        <div class="form-group">
          <label class="control-label" >Diagnostico</label>
          <textarea class="form-control" [(ngModel)]="diagnostico">
          </textarea>
        </div>
    </div>
  </div>

  <div class="row justify-content-center">
      <div class="col-sm-12 col-md-8">
          <div class="form-group">
            <button
              type="button"
              class="btn btn-success btn-block"
              (click)="registrarOdontograma();">
              Guardar Odontograma
            </button>
          </div>
      </div>
  </div>

</div>
