import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { CajaService } from 'src/app/services/caja.service';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";

@Component({
  selector: 'caja-reportes',
  templateUrl: './caja-reportes.component.html',
})

export class CajareportesComponent {

  public tipo = null;
  public f_inicial = null;
  public f_final = null;
  public minDateI = new Date();
  public maxDateI = new Date();
  public minDateF = new Date();
  public maxDateF = new Date();
  public safari = 0;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService
  ){

  }

  ngOnInit(){
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      this.safari = 1;
    }
  }

  cambioFinicio(){
    this.minDateF = new Date(this.f_inicial);
  }

  cambioFfinal(){
    this.maxDateI = new Date(this.f_final);
  }

  obtenerFecha( fecha = null, formato = null ){

    if( fecha != null){
      var d = new Date( fecha );
    }else{
      var d = new Date();
    }

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if( formato != null && formato == "en" ){
      return [year, month, day].join('/') ;
    }else{
      return [day, month, year].join('/') ;
    }

  }

  reportePersonalizado(){
    this.f_inicial = this.obtenerFecha( this.f_inicial, 'en');
    this.f_final = this.obtenerFecha( this.f_final, 'en');
    this.imprimirReporte('F');
  }

  reporteCompleto(){
    this.imprimirReporte('A');
  }

  imprimirReporte( tipo = null ){
    this.f_final = (this.f_final != null)? this.f_final: "sn";
    this.f_inicial = (this.f_inicial != null)? this.f_inicial: "sn";
    this.tipo = (this.tipo != null)? this.tipo: "sn";
    let respuesta = this._cajaService.obtenerReportemovimientos( this.f_inicial, this.f_final, tipo, this.tipo );
    respuesta.then(
      val =>{
        if( val.status == "success" ){

          var html = htmlToPdfmake(val.reporte, {tableAutoSize:true});
          const documentDefinition = {
            pageSize: 'A4',
            header:{
              columns:[
                {
                  text: "Documento Generado: "+this.obtenerFecha(),
                  alignment: 'right',
                  color: '#4f4c4c',
                }
              ],
              margin: [40,20]
            },
            content: html,
          };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          if(this.safari){
            pdfMake.createPdf(documentDefinition).download();
          }else{
            pdfMake.createPdf(documentDefinition).open();
          }

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Reporte.',
            text: 'Por favor intentalo nuevamente.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }

      },
      error =>{
        this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Ha ocurrido un problema al intentar generar el Reporte.',
            text: 'Por favor intentalo nuevamente.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
      }
    );
  }

}
