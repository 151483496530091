<form [formGroup]="formPatologicos" (ngSubmit)="registrarPatologicos(formPatologicos.value)">

  <div class="container ">
      <div class="row ">
          <div class="col-sm-12 col-md-6 text-center">
            <h5>CONCEPTO</h5>
          </div>
          <div class="col-sm text-center">
            <h5>OBSERVACIONES</h5>
          </div>
      </div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Enf. Infecciosas</b> (tuberculosis, tifoidea, brucelosis, ETS, SIDA, microsis, virales, hepatitis B, etc.)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="infeccionas">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Enf. Infancia</b> (varicela, rubeola, sarampión)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="infancia">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Traumatológicas</b> (articulares, esguinces, luxaciones. Fracturas óseas de craneo, cara o columna)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="traumatologicas">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Enf. Crónica Degenerativa</b> (obesidad, diabetes mellitus, hipertensión arterial)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="endocrinas">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Enf. Hematológicas</b> (anemias, leucemias, linfomas)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="ematologicas">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Enf. Neurológicas</b> (EVC, Epilepsia, Parkinson, Alzheimer, Migraña)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="neurologicas">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Enf. Mentales</b> (Psicosis: perdida de la noción de la realidad como esquizofrenia. Neurosis: búsqueda de adaptación y soporte a la realidad por esto padecen trastornos obsesivo compulsivos.)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="mentales">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Neoplasias</b> (tumores, cáncer)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="neoplasias">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b> Alergías </b> (medicamentos, anestésicos, alimentos, etc.)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="alergias">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b> Intervenciones Quirúrgicas </b> (tipo de operación, fecha, presencia o no de complicaciones)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="quirurgicas">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b> Transfusiones </b> (fecha, tipo de sange, cantidad, motivo, reacción adversa)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="transfusiones">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b> Enf. Cardiovasculares</b> (infarto agudo al miocardio, trombosis venosa profunda, angina de pecho, marcapasos, trombosis coronarias y arterioesclerosis)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="cardiovasculares">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b> Reumatológicas </b> (artritis reumatoide)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="reumatologicas">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row">
          <div class="col-sm-12 col-md-6">
            <b> Enf. Respiratorias </b> (asma, neumonia, bronquitis)
          </div>
          <div class="col-sm">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="respiratorias">
              </textarea>
            </div>
          </div>
      </div>
      <div class="dropdown-divider"></div>

      <div class="row justify-content-center">
        <div class="col-sm-8 col-md-6 ">
            <div class="form-group">
                <button class="btn btn-success btn-block">
                  Guardar Heredofamiliares
                </button>
            </div>
        </div>
      </div>

  </div>

</form>
