<div class="row">
  <div class="col-md-12 ml-auto">
      <h1>Realizar Corte de Caja</h1>
  </div>
</div>

<div class="container">

    <div class="alert alert-danger" role="alert" *ngIf="saldo_medicos != 0">
        <b>Se cuenta con: </b> <b style='color:black;'>${{saldo_medicos}} </b> <b>correspondiente a médicos (comisión), es necesario retirar/pagar a medicos antes de realizar un corte de caja.</b>
        <b> <a style='color:black;' [routerLink]="['/caja/medicos']"> IR A PAGOS</a> </b>
    </div>

    <div class="alert alert-warning" role="alert" *ngIf="dias_ultimo_semanal >= 7">
        <div>
          <b>Hace </b> <b style='color:black;'>{{dias_ultimo_semanal}} Días </b> <b>que no se realiza un corte Semanal.</b>
        </div>
    </div>

    <div class="alert alert-warning" role="alert" *ngIf="dias_ultimo_mensual >= 28">
      <div>
        <b>Hace </b> <b style='color:black;'>{{dias_ultimo_mensual}} Días </b> <b>que no se realiza un corte Mensual.</b>
      </div>
  </div>

    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-4 col-lg-4">
          <label class="control-label">Tipo de Corte</label>
          <div class="form-group">
            <mat-select
              class="form-control"
              [(ngModel)]="tipo_corte"
              placeholder="Tipo de Corte"
              (selectionChange)="cambioFiltro($event.value)">
              <mat-option [value]="'Diario'" *ngIf="estado_apertura != 0">Diario</mat-option>
              <mat-option [value]="'Semanal'" *ngIf="estado_apertura != 0">Semanal</mat-option>
              <mat-option [value]="'Mensual'" *ngIf="estado_apertura == 0">Mensual</mat-option>
            </mat-select>
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4">
          <div class="form-group">
            <label class="control-label">Fecha Inicial</label>
            <div class="input-group date">
                <span class="input-group-prepend" style="display: block;">
                    <div class="input-group-text">
                        <i class="glyphicon glyphicon-calendar"></i>
                    </div>
                </span>
                <input type="text" class="form-control" readonly="" [(ngModel)]="detalle.fecha_inicio">
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4">
          <div class="form-group">
            <label class="control-label">Fecha Finak</label>
            <div class="input-group date">
                <span class="input-group-prepend" style="display: block;">
                    <div class="input-group-text">
                        <i class="glyphicon glyphicon-calendar"></i>
                    </div>
                </span>
                <input type="text" class="form-control" readonly="" [(ngModel)]="detalle.fecha_fin">
            </div>
          </div>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-3 col-lg-3">
          <div class="form-group">
            <label class="control-label">Total</label>
            <input class="form-control" currencyMask readonly="" [(ngModel)]="detalle.total"/>
          </div>
        </div>

        <div class="col-sm-12 col-md-3 col-lg-3">
          <div class="form-group">
            <label class="control-label">Total Entradas</label>
            <input class="form-control" currencyMask readonly="" [(ngModel)]="detalle.total_entradas"/>
          </div>
        </div>

        <div class="col-sm-12 col-md-3 col-lg-3">
          <div class="form-group">
            <label class="control-label">Total Salidas</label>
            <input class="form-control" currencyMask readonly="" [(ngModel)]="detalle.total_salidas"/>
          </div>
        </div>

        <div class="col-sm-12 col-md-3 col-lg-3">
          <div class="form-group">
            <label class="control-label">Total C/Cobrar</label>
            <input class="form-control" currencyMask readonly="" [(ngModel)]="detalle.total_pendiente"/>
          </div>
        </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-sm-12">
          <div class="rounded" style='border: .5px solid #1d35b038 !important;'>

              <h3 class="text-center">Movimientos</h3>

              <div class="alert alert-info" role="alert" *ngIf="ultimo_corte != null">
                <b>Ultimo corte realizado:</b> <b style='color:black;'>{{ultimo_corte.fecha}}</b>  <b style='margin-left:3em;'>Folio:</b> <b style='color:black;'>{{ultimo_corte.id_corte}}</b>  <b style='margin-left:3em;'>Saldo de Corte Anterior:</b> <b style='color:black;'>${{ultimo_corte.total}}</b>
              </div>

              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
                <thead>
                  <tr>
                    <th class="disabled-sorting sorting_desc" style="width: 8%;">Folio</th>
                    <th class="disabled-sorting sorting_desc">Concepto</th>
                    <th class="disabled-sorting sorting_desc" style="width: 8%;">Monto</th>
                    <th class="disabled-sorting sorting_desc" >Registró</th>
                    <th class="disabled-sorting sorting_desc" style="width: 8%;">Tipo</th>
                    <th class="disabled-sorting sorting_desc" style="width: 18%;">Fecha</th>
                    <th class="disabled-sorting sorting_desc" style="width: 8%;">Tarjeta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let movimiento of movimientos"
                    [ngStyle]="{'background-color': (movimiento.estado_tarjeta == '0') ? '#d6ff002e' : (movimiento.estado == '1') ? '#ff00002e' : (movimiento.tipo == 'Apertura') ? '#00ff0c2e' : null }"
                    >
                    <td style="width: 8%;">{{ movimiento.id_mov_caja }}</td>
                    <td>{{ movimiento.concepto }}</td>
                    <td style="width: 8%;">${{ movimiento.monto }}</td>
                    <td>{{ movimiento.registro }}</td>
                    <td style="width: 8%;">{{ movimiento.tipo }}</td>
                    <td style="width: 18%;">{{ movimiento.fecha }}</td>
                    <td style="width: 8%;">{{ movimiento.tarjeta }}</td>
                  </tr>
                </tbody>
              </table>

          </div>
      </div>
  </div>

  <div class="row justify-content-center">
      <div class="col-sm-12 col-md-10 col-lg-8">
        <div class="form-group">
          <label class="control-label">Comentarios</label>
          <textarea class="form-control" rows="6" aria-invalid="false" [(ngModel)]="detalle.comentarios">

          </textarea>
        </div>
      </div>
  </div>

  <div class="row justify-content-center">
      <div class="col-sm-12 col-md-8 col-lg-6">
          <div class="form-group">
            <button (click)="registrarCorte()"class="btn btn-success btn-block" *ngIf="saldo_medicos == 0 && tipo_corte != null">
              REGISTRAR
            </button>
          </div>
      </div>
  </div>

</div>

