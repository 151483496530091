<h1>Adeudos Tratamientos</h1>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc" style="width: 15%;">F Paciente</th>
      <th class="disabled-sorting sorting_desc">Nombre Paciente</th>
      <th class="disabled-sorting sorting_desc text-center" style="width: 15%;" data-priority="1">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let adeudo of adeudos">
      <td style="width: 15%;">{{ adeudo.id }}</td>
      <td>{{ adeudo.texto }}</td>
      <td style="width: 15%;" class="text-center">
        <button class="btn btn-info btn-icon btn-sm" (click)="verAdeudo(adeudo.id)" title="Ver Detalles" aria-label="Ver Detalles" data-pjax="0">
          <i class="far fa-eye"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
