import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'permisos',
  templateUrl: './permisos.component.html',
})

export class PermisosComponent {

  formPermisos: FormGroup;
  public id_usuario = null;
  public identity = null;
  public permisos_datos = {
    "id_permiso": "",
    "agenda": "0000",
    "pacientes": "0000",
    "caja": "0000",
    "clinicas": "0000",
    "usuarios": "0000",
    "tecnicos": "0000",
    "inventarios": "0000",
    "configuracion": "0000"
  };

  public permisos = {
    "agenda": {
      "c": false,
      "r": false,
      "u": false,
      "d": false
    },
    "pacientes": {
      "c": false,
      "r": false,
      "u": false,
      "d": false
    },
    "caja": {
      "c": false,
      "r": false,
      "u": false,
      "d": false
    },
    "clinicas": {
      "c": false,
      "r": false,
      "u": false,
      "d": false
    },
    "usuarios": {
      "c": false,
      "r": false,
      "u": false,
      "d": false
    },
    "tecnicos": {
      "c": false,
      "r": false,
      "u": false,
      "d": false
    },
    "inventarios": {
      "c": false,
      "r": false,
      "u": false,
      "d": false
    },
    "configuracion": {
      "c": false,
      "r": false,
      "u": false,
      "d": false
    },
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _usuarioService: UsuarioService,
    private _usuariosService: UsuariosService,
    private _bilder: FormBuilder,
  ){

  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_usuario = params['id'];
    });
    this.obtenerPermisos();
    this.colocarValores();
  }

  colocarValores( datos = null ){

    if( datos != null ){

      for( let tipo of Object.keys(datos) ){

        if(tipo != "id_permiso"){
          let valores = datos[tipo];
          let valor = valores.split('');
          let contador = 0;
          valor.forEach( estado => {
            let permiso = (estado == "1" || estado == 1 ) ? true : false;
            switch (contador){
                case 0:
                    this.permisos[ tipo ]['c'] = permiso;
                    break;
                case 1:
                    this.permisos[ tipo ]['r'] = permiso;
                    break;
                case 2:
                    this.permisos[ tipo ]['u'] = permiso;
                    break;
                case 3 :
                    this.permisos[ tipo ]['d'] = permiso;
                    break;
            }
            contador++;
          });
        }

      };

    }

    this.formPermisos = this._bilder.group({
      id_permiso: [ this.permisos_datos.id_permiso , Validators.required ],
      agenda: [ this.permisos_datos.agenda, Validators.required ],
      pacientes: [ this.permisos_datos.pacientes, Validators.required ],
      caja: [ this.permisos_datos.caja, Validators.required ],
      clinicas: [ this.permisos_datos.clinicas, Validators.required ],
      usuarios: [ this.permisos_datos.usuarios, Validators.required ],
      tecnicos: [ this.permisos_datos.tecnicos, Validators.required ],
      inventarios: [ this.permisos_datos.inventarios, Validators.required ],
      configuracion: [ this.permisos_datos.configuracion, Validators.required ],
    });
  }

  obtenerPermisos(){

    if( this.id_usuario != null){

      this.spinner.show('spnr-s');

      let respuesta = this._usuariosService.obtenerPermisos( this.id_usuario );

      respuesta.then( val =>{
        this.spinner.hide('spnr-s');
        if( val.status == "success" ){
          this.permisos_datos = val.permisos;
          this.colocarValores(this.permisos_datos);
        }else{
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
          this._router.navigate(['/usuario', this.id_usuario]);
        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: error,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
        this._router.navigate(['/usuario', this.id_usuario]);
      });

    }else{
      this.spinner.hide('spnr-s');
      this._router.navigate(['/usuario', this.id_usuario]);
    }

  }

  cambioEstado( event:any ){

    let tipo = event.source.id;
    tipo = tipo.split('_');
    let actual:any = $( '#' + tipo[0] ).val();
    actual = actual.split('');
    let valor = 0;
    if (event.checked === true) { valor = 1; }

    switch ( tipo[1] ){
        case 'c':
            actual[0] = valor;
            break;
        case 'r':
            actual[1] = valor;
            break;
        case 'u':
            actual[2] = valor;
            break;
        case 'd':
            actual[3] = valor;
            break;
    }

    let nuevo = actual[0] + "" + actual[1] + "" + actual[2] + "" + actual[3];
    this.formPermisos.get( tipo[0] ).setValue( nuevo, { onlySelf: true });
    this.formPermisos.get(tipo[0]).updateValueAndValidity();
  }

  registrarPermisos( datos ){
    this.spinner.show('spnr-s');
    let respuesta = this._usuariosService.guardarPermisos( datos );
    respuesta.then( val =>{
      if( val.status == "success" ){

        this.spinner.hide('spnr-s');
        Swal.fire({
          title: "Permisos Registrados Exitosamente",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });

      }else{

        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: val.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });

      }
    });
  }

}
