<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="modal-title" id="modal-primary-title">Creación/Edición de Cita</h3>
</div>

<div class="modal-body centered formulario">
  <createcitas [cModal]=true [id_cita]="id_cita" [datos_cita]="datos_cita" [nuevaCita]="nuevaCita" [activeModal]="activeModal"></createcitas>
</div>
