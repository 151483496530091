import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import PerfectScrollbar from 'perfect-scrollbar';
import { AgendaService } from 'src/app/services/agenda.service';
import Swal from 'sweetalert2';
import { CitascreateComponent } from '../create/citas-create.component';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';

declare var jQuery:any;
declare var moment: any;
declare var $:any;

@Component({
  selector: 'agenda',
  templateUrl: './agenda.component.html',
})

export class AgendaComponent {

  public fecha = null;
  public fecha_registrada;
  public id_ultima_cita;
  public cantCitas;
  public config = null;
  public token = null;
  public buscando = 0;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _agendaService: AgendaService,
    private _configuracionesService: ConfiguracionesService,
    private modalService: NgbModal,
  ){
    //console.log('contrsuctor');
    localStorage.setItem( "ubicacion", '1' );
    this.config = this._configuracionesService.getConfig();
  }

  ngOnInit(){
    //console.log('init');
    this._route.params.forEach((params:Params) => {
      this.fecha = params['fecha'];
    });
    this.spinner.show('spnr-s');
    this.cargarConfiguracion();

  }

  redirigir(fruta){
    this._router.navigate(['/fruta', fruta]);
  }

  ngOnDestroy(){
    localStorage.setItem( "ubicacion", "0");
    document.getElementById("contenedor-medicos").innerHTML = "";
  }

  iniciarCalendario(){

    this.spinner.show('spnr-s');
    //console.log("iniciaSpinner");

    const buscarCitas = this.buscarCitas;
    const spnr = this.spinner;
    const agendaSrv = this._agendaService;
    const cCita = this.cargarCita;
    const modalReg = this.modalRegistro;
    const mdlServ = this.modalService;
    const cEstado = this.cambioEstado;
    var that = this;

    var calendar = $('#fullCalendar');
    var eventDatos;
    var arregloFechas = null;
    var obj = [];
    var citas_locales = [];

    var hora_inicio = this.config.hora_inicial;
    var hora_fin = this.config.hora_final;
    var tamano_cita = this.config.duracion_espacios;

    var external_date = this.fecha;
    var today, y, m, d;

    if( external_date != null && external_date != ""){
      today = new Date( external_date );
      today.setDate(today.getDate() + 1);
    }else{
      today = new Date();
      y = today.getFullYear();
      m = today.getMonth();
      d = today.getDate();
    }

    calendar.fullCalendar({

      defaultView: 'agendaDay',
      longPressDelay : '600',
      //selectLongPressDelay : '1000',
      minTime: hora_inicio+':00:00',
      maxTime: hora_fin+':00:00',
      firstDay: '0',
      height: 600,
      nowIndicator: true,
      slotDuration: "00:"+tamano_cita+":00",
      slotEventOverlap: false,
      displayEventTime : false,
      defaultDate: today,
      selectable: true,
      selectHelper: true,
      editable: false,
      eventLimit: true, // allow "more" link when too many events
      header: {
          lang: 'es',
          left: 'title',
          center: 'month,agendaWeek,agendaDay',
          right: 'prev,next,today'
      },
      views: {
          month: { // name of view
              titleFormat: 'MMMM YYYY',
          },
          week: {
              titleFormat: " MMMM D YYYY",
              slotLabelFormat:"h:mm"
          },
          day: {
              titleFormat: 'D MMM, YYYY',
              slotLabelFormat:"h:mm"
          }
      },

      viewRender: function(view, element) { //cargara las citas faltantes

          var f_inicio = moment(new Date(view.start)).format('YYYY-MM-DD');
          var f_final = moment(new Date(view.end)).format('YYYY-MM-DD');

          var fecha = new Date();
          var dd = String(fecha.getDate()).padStart(2, '0');
          var mm = String(fecha.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = fecha.getFullYear();
          var fecha_texto = yyyy + '-' + mm + '-' + dd;

          if( fecha_texto > f_inicio || fecha_texto < f_inicio ){
            buscarCitas(f_inicio, f_final, spnr, agendaSrv, cCita, that);
            //console.log('sssss');
          }else{
            //console.log('11111');
            spnr.hide('spnr-s');
          }

          if (view.name != 'month'){
              const ps = new PerfectScrollbar( ".fc-scroller" );
          }
      },

      eventMouseover: function(calEvent, jsEvent) {
          var tooltip = '<div class="tooltipevent etiqueta" >' + calEvent.description + '</div>';
          $("body").append(tooltip);
          $(this).mouseover(function(e) {
              $(this).css('z-index', 10000);
              $('.tooltipevent').fadeIn(2000);
              $('.tooltipevent').fadeTo('10', 1.9);
          }).mousemove(function(e) {
              $('.tooltipevent').css('top', e.pageY + 10);
              $('.tooltipevent').css('left', e.pageX + 20);
          });
      },

      eventMouseout: function(calEvent, jsEvent) {
          $(this).css('z-index', 8);
          $('.tooltipevent').remove();
      },

      eventRender: function(event, element){

          if( event.className[0] != "color0" ){
            let titulo = event.title;
            if( titulo != null && titulo != undefined ){
              let px = titulo.split(":");
              let segundo = px[1];
              let id = px[0].split("PX ");
              id = id[1];
              if( id != undefined ){
                let cambio = "<span class='colorID'>PX "+id+"</span>:"+segundo;
                element.find('.fc-title').html(cambio);
              }
            }
          }


      },

      dayClick: function(date, view ) {
        var check = moment(date).format('YYYY-MM-DD');
        var today = moment(new Date()).format('YYYY-MM-DD');
        if (check >= today) {
          if(calendar.fullCalendar('getView').type == 'month') {
            calendar.fullCalendar('changeView', 'agendaDay');
            calendar.fullCalendar('gotoDate', date);
          }
        }else {// si no, mostramos una alerta de error
          Swal.fire({
            icon: 'warning',
            title: 'Oops..',
            text: 'No se pueden crear reservaciones en el pasado',
            showCancelButton: false,
            confirmButtonColor: '#18ce0f',
            confirmButtonText: 'Aceptar',
          });
        }
      },

      select: function(start, end) {

        if(calendar.fullCalendar('getView').type != 'month') {
          // leemos las fechas de inicio de evento y hoy
          var check = moment(start).format('YYYY-MM-DD');
          var today = moment(new Date()).format('YYYY-MM-DD');

          var fecha_inicio = moment(start).format('YYYY-MM-DD')
          var hora_inicio = moment(start).format('HH:mm')
          var fecha_fin= moment(end).format('YYYY-MM-DD')
          var hora_fin = moment(end).format('HH:mm')

          // si el inicio de evento ocurre hoy o en el futuro mostramos el modal
          if (check >= today) {
          // éste es el código que tenías originalmente en el select
            if(calendar.fullCalendar('getView').type != 'month') {
                  var fechaI = start.format('YYYY-MM-DD');
                  var horaI = start.format('HH:mm');
                  modalReg( fecha_inicio, hora_inicio, fecha_fin, hora_fin, mdlServ );
                }
          }
          else { // si no, mostramos una alerta de error
            Swal.fire({
              icon: 'warning',
              title: 'Oops..',
              text: 'No se pueden crear reservaciones en el pasado',
              showCancelButton: false,
              confirmButtonColor: '#18ce0f',
              confirmButtonText: 'Aceptar',
            });
          }
        }
      },

    });

    var id_ultima_cita = this.id_ultima_cita;
    var id_ultima_cita_local = localStorage.getItem('id_ultima_cita_local');
    var cant_citas = this.cantCitas;
    var cant_citas_local = localStorage.getItem('cant_citas_local');
    //console.log(id_ultima_cita + ' ' + id_ultima_cita_local);
    if( id_ultima_cita_local == null ){ //nuevo inicio de sesion (no hay datos)
      //console.log('servidor');
      this.spinner.show('spnr-s');
      this._agendaService.obtenerCitas().subscribe(
        response => {
          let data = response.citas;
          localStorage.setItem( "citas", JSON.stringify(data) );
          localStorage.setItem( "id_ultima_cita_local", id_ultima_cita );
          localStorage.setItem( "cant_citas_local", cant_citas );
          obj = [];
          for(var i=0; i < data.length; i++){
            let cita = this.cargarCita( data[i], tamano_cita  );
            obj.push(cita);
          }
          calendar.each(function(i, cal){
            calendar.fullCalendar( 'addEventSource', obj );
          });
          this.cambioEstado(0);
          this.spinner.hide('spnr-s');
          //console.log("cargaVacia");
        },
        error =>{
          this.cambioEstado(0);
          this.spinner.hide('spnr-s');
        }
      );

    }else{
      if( id_ultima_cita != id_ultima_cita_local ){ //carga las citas que falten
        //console.log('faltantes');

        // this._agendaService.obtenerCitasFiltradas(id_ultima_cita_local).subscribe(
        //   response => {
        //     let data = response.citas;
        //     let obj_calendar = [];
        //     if( data.length > 0 ){
        //       citas_locales = JSON.parse( localStorage.getItem("citas") );
        //       //console.log(citas_locales.length);
        //       for( var i=0; i < data.length; i++ ){ //agrega las nuevas citas al local
        //         citas_locales.push( data[i] );
        //       }
        //       for( var i=0; i < citas_locales.length; i++ ){ //agrega las nuevas citas al local
        //         let cita = this.cargarCita(citas_locales[i], tamano_cita );
        //         obj.push( cita );
        //       }
        //       localStorage.setItem( "citas", JSON.stringify(citas_locales) );
        //       localStorage.setItem( "id_ultima_cita_local", id_ultima_cita );
        //       calendar.each(function(i, cal){
        //         calendar.fullCalendar( 'addEventSource', obj );
        //       });
        //     }
        //     //this.spinner.hide('spnr-s');
        //     //console.log("cargaFlyantes");

        //   },
        // );

      }else{ //carga las citas locales
        citas_locales = JSON.parse( localStorage.getItem("citas") );
        if( citas_locales.length > 0 ){
          obj = [];
          for (var i=0; i < citas_locales.length; i++ ){
            let cita = this.cargarCita( citas_locales[i], tamano_cita  );
            obj.push( cita );
          }
          calendar.each(function(i, cal){

            calendar.fullCalendar('addEventSource', obj);
          });
        }
        //this.spinner.hide('spnr-s');
        //console.log("cargaLocales");
      }
    }

    this.spinner.hide('spnr-s');
  }

  buscarCitas( f_inicial, f_final, spinner, _agendaService, cCita, that ) {

    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    //console.log('inicia tiempo: ' + time);
    //spinner.show('spnr-s'); //hacer validacion si esta mostrando si no no lo habre
    that.cambioEstado(1);
    //console.log("buscaCitas");

    var citas_locales = JSON.parse( localStorage.getItem("citas") );
    var contador_elementos_locales = 0;
    var citas_encontradas_locales = [];

    if(citas_locales){

      for( var i=0; i < citas_locales.length; i++ ){
        if( citas_locales[i]['fecha_programada'] >= f_inicial && citas_locales[i]['fecha_programada'] <= f_final ){
          citas_encontradas_locales.push(citas_locales[i]['id_cita']);
          contador_elementos_locales++;
        }
      }
      //console.log('cant local: ' +contador_elementos_locales);
      //console.log(citas_encontradas);

      let citas_encontradas = JSON.stringify(citas_encontradas_locales);

      _agendaService.obtenerCantFiltro(f_inicial, f_final, contador_elementos_locales, citas_encontradas).subscribe(
        response => {
          //console.log('respuesta');
          //console.log('cant server: ' + data['data']['cantidad']);
          //console.log('citas faltaban: ' + data['data']['citas_faltantes']);
          var obj = [];
          var calendar = $('#fullCalendar');
          var citas = response.citas_faltantes;

          if(citas.length > 0){
            for(var i=0; i < citas.length; i++){ //agrega las nuevas citas al local
              citas_locales.push( citas[i] );
              var cita = cCita( citas[i] );
              obj.push( cita );
            }
            localStorage.setItem( "citas", JSON.stringify(citas_locales) );
            calendar.each(function(i, cal){
              calendar.fullCalendar( 'addEventSource', obj );
            });
          }
          //spinner.hide('spnr-s');
          that.cambioEstado(0);
          //console.log("terminabuscaCitas");

        },
      );

    }

  }

  cargarCita( cita, tamano_cita ){

    var descripcion = cita['motivo'];
    var color = 'color' + cita['color'];
    var tamano_cita = tamano_cita;
    var eventDatos;

    if(cita['status'] == "Cancelada"){
      var color = 'colorR';
      descripcion = "(CANCELADA) " + descripcion;
    }

    if( cita['status'] == "Inasistencia" ){
      var color = 'colorA';
      descripcion = "(INASISTENCIA) " + descripcion;
    }

    if( cita['color'] != '0' ){

      var ffinal = cita['fecha_programada_final'];
      if( cita['fecha_programada_final'] == null ){
        ffinal = cita['fecha_programada'] +' '+ moment( moment( cita['hora_programada'],'HH:mm' ) ).add( tamano_cita, 'minutes' ).format( 'HH:mm' );
      }
      eventDatos = {
        id: cita['id_cita'],
        title: 'PX '+ cita['id_paciente']+': '+ cita['nombre'] + ' ' + cita['ape_pat'],
        start: cita['fecha_programada'] + ' ' + cita['hora_programada'],
        description: descripcion,
        end: ffinal,
        className: color,
        url: "/agenda/cita/" + cita['id_cita'],
      };
      return eventDatos;

    }else{

      eventDatos = {
        id: cita['id_cita'],
        title: cita['motivo'],
        start: cita['fecha_programada_inicial'],
        description: cita['motivo'],
        end: cita['fecha_programada_final'],
        className: color,
        url: "/agenda/cita/" + cita['id_cita'],
      };
      return eventDatos;

    }

  }

  cargarConfiguracion(){
    this._agendaService.obtenerAgenda().subscribe(
      response => {
        this.id_ultima_cita = response.ultima_cita;
        this.cantCitas = response.cant_citas;
        let medicos = response.medicos;
        let contenido_medicos =
        '<div class="border rounded mb-5 text-white">'+
          '<div class="text-center ">'+
            '<h6>Médicos</h6>'+
          '</div>'+
        '<table>'+
          '<thead class="text-center">'+
            '<tr>'+
              '<th>Identif.</th>'+
              '<th>Nombre</th>'+
            '</tr>'+
          '</thead>'+
          '<tbody>'+
            '<tr>'+
              '<td class="text-center "><div class="p-1 border colorA"></div></td>'+
              '<td class="text-left pl-2 ">Inasistencia</td>'+
            '</tr>'+
            '<tr>'+
              '<td class="text-center "><div class="p-1 border colorR"></div></td>'+
              '<td class="text-left pl-2 ">Cancelada</td>'+
            '</tr>';
            medicos.forEach(medico => {
              contenido_medicos += '<tr><td class="text-center"><div class="p-1 border color'+ medico.color +'"></div></td><td class="text-left pl-2 ">'+ medico.nombre +'</td></tr>';
            });

        contenido_medicos += '</tbody></table></div>';

        document.getElementById("contenedor-medicos").innerHTML = contenido_medicos;
        this.iniciarCalendario();
        //console.log('cargado');
      },
    );
  }

  modalRegistro( fecha_inicio, hora_inicio, fecha_fin, hora_fin, mdlServ ){

    const modalRef = mdlServ.open(CitascreateComponent, { size: 'lg' });

    let datos =
      {
        'fecha_inicio' : fecha_inicio,
        'hora_inicio' : hora_inicio,
        'fecha_fin': fecha_fin,
        'hora_fin' : hora_fin
      };

    modalRef.componentInstance.datos_cita = datos;
    modalRef.componentInstance.nuevaCita = true;
    modalRef.componentInstance.modalRef = modalRef;

  }

  cambioEstado( estado ){
    this.buscando = estado;
  }

  recargarAgenda(){
    console.log('recargar');
    localStorage.clear();
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([currentUrl]);
    });
  }
}
