import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";

@Injectable()

export class TratamientosService{
  public url: string;
  public token = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
  ){
    this.url = Global.url;
    this.token = this._usuarioService.getToken();
  }

  obtenerTratamientos(  ):Observable<any>{

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = this._http.get(this.url + "tratamientos/listado", {headers: headers});

    return respuesta;

  }

  async obtenerTratamiento( id_tratamiento ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "tratamientos/obtenertratamiento/"+id_tratamiento, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarTratamiento( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "tratamientos/registrar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async actualizarTratamiento( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "tratamientos/actualizar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async eliminarTratamiento( id_tratamiento ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.delete(this.url + "tratamientos/eliminar/"+id_tratamiento, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerTratamientospaciente( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "tratamientos/tratamientospaciente/"+id_paciente, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerProcedimientos( id_medico ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "tratamientos/procedimientos/"+id_medico, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerTratamientopaciente( id_tratamiento ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "tratamientos/tratamientopaciente/"+id_tratamiento, {headers: headers}).toPromise();

    return respuesta;

  }


}
