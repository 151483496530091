<form [formGroup]="formPersonalizado" (ngSubmit)="registrarPersonalizado(formPersonalizado.value)">

  <div class="container">
    <div class="row ">
      <div class="col-sm-6 text-center">
        <h5>CONCEPTO</h5>
      </div>
      <div class="col-sm text-center">
        <h5>OBSERVACIONES / RESPUESTA</h5>
      </div>
    </div>

    <div
      formArrayName="preguntas_respuestas"
      *ngFor="let item of formPersonalizado.get('preguntas_respuestas')['controls']; let i = index;">
      <div class="row" [formGroupName]="i">
          <div class="col-sm-12 col-md-6">
            <b>{{ formPersonalizado.controls.preguntas_respuestas.controls[i].controls.pregunta.value }}</b>
          </div>

          <div class="col-sm">
            <input type="hidden" formControlName="id_preguntas">
            <div class="form-group">
              <textarea
                class="form-control"
                formControlName="respuesta">
              </textarea>
            </div>
          </div>
      </div>

      <div class="dropdown-divider"></div>

    </div>
  </div>

  <div class="row justify-content-center" *ngIf="activar_boton == 1">
    <div class="col-sm-8 col-md-6 ">
        <div class="form-group">
            <button class="btn btn-success btn-block">
              Guardar
            </button>
        </div>
    </div>
  </div>

</form>
