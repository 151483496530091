<div class="row">
  <div class="col-sm-5 mr-auto">
      <h1>Cobros</h1>
  </div>
</div>

<div class="dropdown-divider mb-5" style='border-top: 3px solid #919a91a6;'></div>

<div class="row">
  <div class="col-sm-12 col-md-7">
      <div class="text-center">
          <div class="row justify-content-center mb-4">
            <div class="col-sm-8 col-md-6">
              <h6>CONCEPTO</h6>
              <div class="form-group">
                <input
                  [(ngModel)]="concepto"
                  type="text"
                  id="concepto"
                  class="form-control">
              </div>
            </div>
            <div class="col-sm-4 col-md-3">
              <h6>MONTO</h6>
              <div class="form-group">
                <input id="monto-input" class="form-control" currencyMask [(ngModel)]="monto" />
              </div>
            </div>
          </div>

          <div class="dropdown-divider mb-5" style='border-top: 2px solid #94b9e0;'></div>

          <div class="row justify-content-center mb-4">
              <div class="col-sm-12 col-md-6">
                  <h6>PACIENTE</h6>
                  <div class="form-group field-paccitas-id_paciente required">
                    <input
                      type="text"
                      placeholder="Seleccionar Paciente"
                      aria-label="Number"
                      class="form-control"
                      id="id_paciente"
                      matInput
                      (keyup)="obtenerPacientes($event)"
                      [matAutocomplete]="autoPacientes"
                      >
                    <mat-autocomplete #autoPacientes="matAutocomplete" [displayWith]="displayFn" (optionSelected)="seleccionPaciente($event)">
                      <mat-option *ngFor="let paciente of pacientes" [value]="paciente">
                        {{ paciente.texto }}
                      </mat-option>
                    </mat-autocomplete>
                    <div class="help-block" *ngIf="contCPac == 0">Se requieren 3 o más caracteres</div>
                  </div>
              </div>
          </div>

          <div class="row justify-content-center">

              <div class="col-sm-12 col-md-6" id="contratamiento">
                  <div id="con_tratamiento">
                      <h6>TRATAMIENTO</h6>
                      <div class="form-group">
                        <mat-select
                          [disabled]="id_paciente == null || id_doctor != null"
                          class="form-control"
                          [(ngModel)]="id_tratamiento"
                          placeholder="Selecciona un tratamiento"
                          (selectionChange)="cambioTratamiento($event.value)">
                          <mat-option [value]="0">VACIO</mat-option>
                          <mat-option *ngFor="let tratamiento of tratamientos" [value]="tratamiento.id">{{tratamiento.texto}}</mat-option>
                        </mat-select>
                      </div>

                      <div class="row justify-content-center " id="contenedor_tratamiento" style="display: none">
                          <div class="p-1 rounded" id="datos_tratamiento" style='border: 3px solid #1d35b0 !important;'>
                            <div class="container">
                              <p class="h4" style='margin-top: 0px;'>Saldo de Tratamiento</p>
                              <div class="row justify-content-center" *ngIf="tratamiento_paciente != null">
                                  <div class="col-sm-6">
                                      <h6>MONTO GENERAL</h6>
                                      $ {{tratamiento_paciente.monto_general}} (MXN)
                                  </div>
                                  <div class="col-sm-6">
                                      <h6>ADEUDO</h6>
                                      $ {{tratamiento_paciente.adeudo}} (MXN)
                                  </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="col-sm-12 col-md-6" id="sintratamiento">
                  <div id="sin_tratamiento">
                      <h6>MEDICO</h6>
                      <div class="form-group">
                          <mat-select class="form-control" [(ngModel)]="id_doctor" placeholder="Seleccionar Médico" (selectionChange)="cambioMedico($event.value)">
                            <mat-option [value]="0">VACIO</mat-option>
                            <mat-option *ngFor="let doctor of doctores" [value]="doctor.id">{{doctor.texto}}</mat-option>
                          </mat-select>
                      </div>

                      <h6>PROCEDIMIENTO</h6>
                      <div class="form-group">
                          <mat-select class="form-control" [(ngModel)]="id_procedimiento" placeholder="Seleccionar Procedimiento" (selectionChange)="cambioProcedimiento($event.value)">
                            <mat-option [value]="0">VACIO</mat-option>
                            <mat-option *ngFor="let procedimiento of procedimientos" [value]="procedimiento.id">{{procedimiento.texto}}</mat-option>
                          </mat-select>
                      </div>

                  </div>
              </div>
          </div>

          <div class="row justify-content-center mt-5 mb-5">
              <div class="col-sm-10 col-md-5">
                  <div class="form-group">
                      <button
                        (click)="agregarElemento()"
                        [disabled]="concepto == '' || monto == ''"
                        class="btn btn-info btn-block t-white" id="agregar" style='margin: 1px; color: #fff;'>
                        Agregar a Lista
                      </button>
                  </div>
              </div>
          </div>

      </div>

  </div>
  <div class="col-sm-12 col-md-5">
      <div class="h5 text-center">Listado de Elementos</div>
      <table class="table table-sm table-bordered tbl-caja" id="tabla-elementos" style="font-size: 9px!important; padding:7px 7px!important;">
          <thead>
              <tr>
                  <th scope="col">#</th>
                  <th scope="col">Medico</th>
                  <th scope="col">Concepto</th>
                  <th scope="col">P/U</th>
                  <th scope="col">Cant</th>
                  <th scope="col">SubT.</th>
                  <th scope="col">Eliminar</th>
              </tr>
          </thead>
          <tbody >
            <tr *ngFor="let fila of lista_tabla" id="fila_{{fila.fila}}">
              <th scope="row">{{fila.fila}}</th>
              <td>{{fila.medico}}</td>
              <td>{{fila.concepto}}</td>
              <td>${{fila.precio_unitario}}</td>
              <td class="text-center">
                <div id="cantidad">{{fila.elementos}}</div>
                {{fila.id_tratamiento}}
                <div class="btn-group" *ngIf="fila.tratamiento == null">
                  <button (click)="restarCant(fila.fila)" class="btn btn-info btn-sm delete" style="font-size: 6px!important; padding: 5px 10px!important; color: #fff;">
                    <i class="now-ui-icons ui-1_simple-delete"></i>
                  </button>
                  <button (click)="sumarCant(fila.fila)" class="btn btn-info btn-sm add" style="font-size: 6px!important; padding: 5px 10px!important; color: #fff;">
                    <i class="now-ui-icons ui-1_simple-add"></i>
                  </button>
                </div>
              </td>
              <td id="total_fila">${{fila.total_elementos}}</td>
              <td>
                <button class="btn btn-sm btn-danger eliminar" (click)="eliminarElemento(fila.fila)">
                  <i class="far fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
      </table>
      <div class="row justify-content-center ">
          <div class="col-sm-12">
              <div class="form-check mb-4">
                  <label class="form-check-label" style='color: #000000;'>
                      <input [(ngModel)]="tarjeta" class="form-check-input" type="checkbox" id='tarjeta'>
                      Pago con Tajerta de Credito
                      <span class="form-check-sign">
                          <span class="check"></span>
                      </span>
                  </label>
              </div>
          </div>
      </div>
      <div class="row justify-content-center text-center">
          <div class="col-sm-8">
              <div class="h4">Total</div>
             <div class="h2" id="total">{{total}}</div>
          </div>
      </div>
      <div class="row justify-content-center">
          <div class="col-sm-8">
              <div class="form-group">
                  <button (click)="registrarMovimientos()" class="btn btn-success btn-block" id="registrarMovimiento" style='margin: 1px; color: #fff;'>
                    Registrar Movimiento
                  </button>
              </div>
          </div>
      </div>
  </div>
</div>
