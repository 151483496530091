import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'citas-create',
  templateUrl: './citas-create.component.html',
})

export class CitascreateComponent {

  @Input() public id_cita;
  @Input() public datos_cita;
  @Input() public nuevaCita;

  constructor(
    public activeModal: NgbActiveModal
  ){

  }

  ngOnInit(){
  }

}
