import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { UsuarioService } from "./usuario.service";
import { ConfiguracionesService } from "./configuraciones.service";

@Injectable()

export class ExpedienteService{

  public url: string;
  public token = null;
  public config = null;

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.url = Global.url;
    this.config = this._configuracionesService.getConfig( );
    this.token = this._usuarioService.getToken();
  }

  async obtenerPacientes( texto ){

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "texto": texto
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "pacientes/obtenerpacientes", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerPaciente( id_paciente ){

    let data = JSON.stringify( {
      "ip": id_paciente
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "pacientes/obtenerpaciente", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarPaciente( datos ){

    let data = JSON.stringify( {
      "ic": this.config.id_clinica,
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "pacientes/registrar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async actualizarPaciente( datos ){

    let data = JSON.stringify( {
      "ic": this.config.id_clinica,
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "pacientes/actualizar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async eliminarPaciente( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.delete(this.url + "pacientes/eliminar/"+id_paciente, {headers: headers}).toPromise();

    return respuesta;

  }

  // PETICIONES PARA EXPEDIENTE CLINICO

  async obtenerGenerales( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/generales/"+id_paciente , {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarGenerales( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrargenerales", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerExp( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/exploracion/"+id_paciente , {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarExp( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrarexploracion", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerAnalisis( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/analisis/"+id_paciente , {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarAnalisis( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registraranalisis", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerPatologicos( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/patologicos/"+id_paciente , {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarPatologicos( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrarpatologicos", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerNopatologicos( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/nopatologicos/"+id_paciente , {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarNopatologicos( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrarnopatologicos", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerGineco( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/obstetricos/"+id_paciente , {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarGineco( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrarobstetricos", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerPersonalizado( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/personalizado/"+id_paciente , {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarPersonalizado( datos ){

    let data = JSON.stringify( {
      "ic" : this.config.id_clinica,
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrarpersonalizado", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerTriage( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/triage/"+id_paciente , {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarTriage( datos ){

    let data = JSON.stringify( {
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrartriage", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerCitas( id_paciente ){

    let data = JSON.stringify( {
      "ic" : this.config.id_clinica,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/citas/"+id_paciente, params, {headers: headers}).toPromise();

    return respuesta;

  }

  async cambiarEstadocita( id_cita ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/estadocita/"+id_cita , {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerPagos( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/pagos/"+id_paciente, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerTratamientos( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/tratamientos/"+id_paciente, {headers: headers}).toPromise();

    return respuesta;

  }

  async cancelarTratamiento( id_tratamiento, concepto ){

    let data = JSON.stringify( {
      "motivo": concepto
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/cancelartratamiento/"+id_tratamiento , params, {headers: headers}).toPromise();

    return respuesta;


  }

  async obtenerTratamiento( id_tratamiento ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/tratamiento/"+id_tratamiento, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerNotas( id_tratamiento ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/notasevolucion/"+id_tratamiento, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarNota( datos ){

    let data = JSON.stringify( {
      "nota": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrarnota" , params, {headers: headers}).toPromise();

    return respuesta;


  }

  async eliminarNota( id_nota ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.delete(this.url + "expediente/eliminarnota/"+id_nota, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerNotaspaciente( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/notaspaciente/"+id_paciente, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarNotapaciente( datos ){

    let data = JSON.stringify( {
      "nota": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrarnotapaciente" , params, {headers: headers}).toPromise();

    return respuesta;


  }

  async eliminarNotapaciente( id_nota ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.delete(this.url + "expediente/eliminarnotapaciente/"+id_nota, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerArchivos( id_tratamiento ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/archivos/"+id_tratamiento, {headers: headers}).toPromise();

    return respuesta;

  }

  subirArchivos(files, data):Promise<any>{

    return new Promise( (resolve, reject) => {
      var formData: any =  new FormData();
      var xhr = new XMLHttpRequest();

      for( var i = 0; i < files.length; i++){
        formData.append('uploads[]', files[i], files[i].name);
        formData.append('descripcion', data.descripcion);
        formData.append('id_tratamiento', data.id_tratamiento);
        formData.append('tipo', data.tipo);
      }

      xhr.onreadystatechange =  function(){
        if(xhr.readyState == 4){
          if(xhr.status == 200){
            resolve(JSON.parse(xhr.response));
          }else{
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', this.url + "expediente/registrararchivo", true);
      xhr.setRequestHeader("enctype", "multipart/form-data");
      xhr.setRequestHeader('Authorization', this.token);
      xhr.send(formData);
    });

  }

  async eliminarArchivo( id_archivo ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.delete(this.url + "expediente/eliminararchivo/"+id_archivo, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerTratamientosmedico( id_medico ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/tratamientosmedico/"+id_medico, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerTratamientopaciente( id_tratamiento ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/tratamientopaciente/"+id_tratamiento, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarTratamiento( datos, id_medico, id_paciente ){

    let data = JSON.stringify( {
      "datos": datos,
      "im": id_medico,
      "ip": id_paciente,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrartratamiento", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async actualizarTratamiento( datos, id_tratamiento ){

    let data = JSON.stringify( {
      "datos": datos,
      "it": id_tratamiento,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/actualizartratamiento", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerOdontograma( tipo, id_paciente ){

    let data = JSON.stringify( {
      "tipo": tipo,
      "ip": id_paciente
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/odontograma", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarOdontograma( datos ){

    let data = JSON.stringify( {
      "datos": datos,
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/registrarodontograma", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerArchivospaciente( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/archivospaciente/"+id_paciente, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerListatratamientos( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.get(this.url + "expediente/listatratamientos/"+id_paciente, {headers: headers}).toPromise();

    return respuesta;

  }

  subirArchivospaciente(files, data, ip):Promise<any>{

    return new Promise( (resolve, reject) => {
      var formData: any =  new FormData();
      var xhr = new XMLHttpRequest();

      for( var i = 0; i < files.length; i++){
        formData.append('uploads[]', files[i], files[i].name);
        formData.append('descripcion', data.descripcion);
        formData.append('id_tratamiento', data.id_tratamiento);
        formData.append('tipo', data.tipo);
        formData.append('ip', ip);
      }

      xhr.onreadystatechange =  function(){
        if(xhr.readyState == 4){
          if(xhr.status == 200){
            resolve(JSON.parse(xhr.response));
          }else{
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', this.url + "expediente/registrararchivopaciente", true);
      xhr.setRequestHeader("enctype", "multipart/form-data");
      xhr.setRequestHeader('Authorization', this.token);
      xhr.send(formData);
    });

  }

  async imprimirHistoria( id_paciente, config ){

    let data = JSON.stringify({
      "ip": id_paciente,
      "config": config,
      "ic" : this.config.id_clinica
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/imprimirhistoria", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async imprimirHistoriavacia( config ){

    let data = JSON.stringify({
      "config": config,
      "ic" : this.config.id_clinica
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/imprimirhistoriavacia", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async imprimirConcentimiento( id_paciente ){

    let data = JSON.stringify({
      "ip": id_paciente,
      "ic" : this.config.id_clinica
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/imprimirconcentimiento", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async imprimirTx( ){

    let data = JSON.stringify({
      "ic" : this.config.id_clinica
    });

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "expediente/imprimirtx", params, {headers: headers}).toPromise();

    return respuesta;

  }

  subirFoto(files, id_paciente):Promise<any>{

    return new Promise( (resolve, reject) => {
      var formData: any =  new FormData();
      var xhr = new XMLHttpRequest();

      for( var i = 0; i < files.length; i++){
        formData.append('uploads[]', files[i], files[i].name);
        formData.append('ip', id_paciente);
      }

      xhr.onreadystatechange =  function(){
        if(xhr.readyState == 4){
          if(xhr.status == 200){
            resolve(JSON.parse(xhr.response));
          }else{
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', this.url + "expediente/fotografia", true);
      xhr.setRequestHeader("enctype", "multipart/form-data");
      xhr.setRequestHeader('Authorization', this.token);
      xhr.send(formData);
    });

  }


}
