import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { Global } from 'src/app/services/global';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'historia',
  templateUrl: './historia.component.html',
})

export class HistoriaComponent {

  public id_paciente = null;
  public subscribe = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
    private _pacienteService: PacientesService,
  ){

  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_paciente =  params['id'];
    });
    if( this.id_paciente == null ){
      this.id_paciente = this._route.parent.snapshot.paramMap.get('id');
    }
    this.subscribe = this._pacienteService.id_paciente$.subscribe( id_paciente =>{
      this.id_paciente = id_paciente;
    });
  }

  ngOnDestroy(){
    this.subscribe.unsubscribe();
  }

}
