import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpedienteService } from 'src/app/services/expediente.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'personalizado',
  templateUrl: './personalizado.component.html',
})

export class PersonalizadoComponent {

  formPersonalizado: FormGroup;
  preguntas_respuestas: FormArray = null;

  @Input() public id_paciente = null;

  public preguntas = null;
  public activar_boton = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _expedienteService: ExpedienteService,
  ){

  }

  ngOnInit(){
    this.colocarValores();
    this.obtenerPersonalizado();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  colocarValores( preguntas = null, respuestas = null ){

    this.formPersonalizado = this._bilder.group({
      id_paciente : [ this.id_paciente ],
      preguntas_respuestas: this._bilder.array([  ])
    });

    this.preguntas_respuestas = this.formPersonalizado.get('preguntas_respuestas') as FormArray;
    if( preguntas != null ){
      for(let i=0; i < preguntas.length; i++ ){
        let id_preguntas = ( preguntas[i].id_preguntas );
        let pregunta = ( preguntas[i].pregunta );
        let respuesta = "";

        for(let r=0; r < respuestas.length; r++ ){
          let id_preg = respuestas[r].id_pregunta;
          if( id_preg == id_preguntas ){
            respuesta = respuestas[r].respuesta;
          }
        }

        let pregunta_resp = this._bilder.group({
          id_preguntas: [ id_preguntas ],
          pregunta: [ pregunta ],
          respuesta: [ respuesta ],
        });

        this.preguntas_respuestas.push( pregunta_resp  );
        this.activar_boton = 1;
      }
    }
  }

  obtenerPersonalizado(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerPersonalizado( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            if( val.preguntas != null ){
              this.preguntas = val.preguntas;
              this.colocarValores( val.preguntas, val.respuestas );
            }
          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  registrarPersonalizado( datos ){
    this.spinner.show('spnr-s');
    let respuesta = this._expedienteService.registrarPersonalizado( datos );
    respuesta.then(
      val =>{
        if( val.status == "success" ){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: "Registro Exitoso",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      error =>{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: "Ha ocurrido un problema al registrar los datos, por favor intentalo nuevamente.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

}
