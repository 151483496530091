<h1>Reportes de Caja</h1>

<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-6 border rounded mt-3">
      <div class="text-center">
        <h4>Reporte de Movimientos</h4>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-5">
              <label class="control-label">FECHA INICIAL</label>
              <div class="input-group">
                  <span class="input-group-prepend" style="display: block;">
                    <div class="input-group-text">
                      <i class="glyphicon glyphicon-calendar"></i>
                    </div>
                  </span>
                  <input
                    placeholder="Fecha Inicial"
                    class="form-control"
                    [(ngModel)]="f_inicial"
                    matInput
                    [matDatepicker]="pickerI"
                    [max]="maxDateI"
                    (click)="pickerI.open(); $event.stopPropagation();"
                    (dateChange)="cambioFinicio()">
                  <mat-datepicker #pickerI></mat-datepicker>
              </div>
          </div>

          <div class="col-sm-12 col-md-5">
            <label class="control-label">FECHA FINAL</label>
              <div class="input-group">
                  <span class="input-group-prepend" style="display: block;">
                    <div class="input-group-text">
                      <i class="glyphicon glyphicon-calendar"></i>
                    </div>
                  </span>
                  <input
                    placeholder="Fecha Final"
                    class="form-control"
                    [(ngModel)]="f_final"
                    matInput
                    [matDatepicker]="pickerF"
                    [min]="minDateF"
                    [max]="maxDateF"
                    (click)="pickerF.open(); $event.stopPropagation();"
                    (dateChange)="cambioFfinal()">
                  <mat-datepicker #pickerF></mat-datepicker>
              </div>
          </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10">
              <div class="row justify-content-center">
                <h6> MOVIMIENTOS </h6>
              </div>
              <div class="row justify-content-center">
                <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  class="example-radio-group"
                  [(ngModel)]="tipo">
                  <mat-radio-button class="mr-4" color="primary" [value]="'ENTRADA'">ENTRADAS</mat-radio-button>
                  <mat-radio-button class="mr-4" color="primary" [value]="'SALIDA'">SALIDAS</mat-radio-button>
                  <mat-radio-button class="mr-4" color="primary" [value]="'AMBAS'">AMBAS</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
        </div>

        <div class="row justify-content-center mt-3 ">
          <div class="col-sm-12 col-md-8">
            <button class="btn btn-info btn-block" (click)="reportePersonalizado()">
              <span class="glyphicon glyphicon-print icono"></span> IMPRIMIR REPORTE
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="col-sm-12 col-md-6 border rounded mt-3">
        <div class="container">
          <div class="row justify-content-center">

            <div class="text-center">
              <h4>Reporte de Caja</h4>
            </div>

          </div>
          <div class="row justify-content-center">
            <div class="col-sm-6">
              <button class="btn btn-info btn-sq-lg" (click)="reporteCompleto()">
                <p class="mt-3" ><i class="glyphicon glyphicon-print fa-5x"></i></p> <p>IMPRIMIR REPORTE</p>
              </button>
            </div>
          </div>
        </div>
    </div>

  </div>
</div>
