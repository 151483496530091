import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { Global } from 'src/app/services/global';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'barra-tag',
  templateUrl: './barra.component.html',
})

export class BarraComponent {
  public identity;
  public token;
  private readonly notifier: NotifierService;
  public urlImg;
  public config: PerfectScrollbarConfigInterface = {};
  public configApp = {
    "nombre": "ADClinik",
    "logo_small": "logo_small_default.png"
  };
  public timg = "?rev="+ new Date().getTime();

  constructor(
    public _usuarioService: UsuarioService,
    private _router: Router,
    private _route: ActivatedRoute,
    _notifierService: NotifierService,
    private _configuracionesService: ConfiguracionesService,
    private spinner: NgxSpinnerService,

  ){
    this.configApp = this._configuracionesService.getConfig();
    this.identity = _usuarioService.getIdentity();
    this.notifier = _notifierService;
    this.urlImg = Global.urlImg;
  }

  ngOnInit() {
    this._usuarioService.identity$.subscribe( identity =>{
      this.identity = identity;
    });
    // this.notifier.getConfig().behaviour.autoHide = false;
    // this.notifier.notify("info", "Bienvenido!");
  }

  salir(){
    Swal.fire({
      title: 'Cerrar Sesión',
      text: "¿Estas seguro que deseas salir?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cerrar Sesion',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.spinner.show('spnr-s');
        let respuesta = this._usuarioService.cerrarS( );
        respuesta.then(
          val =>{
            if( val.status == "success" ){
              this.spinner.hide('spnr-s');

              this.identity = null;
              this.token = null;
              this._usuarioService.cerrarSesion();
              this._router.navigate(['inicio']);

            }else{
              Swal.fire({
                title: 'Oopss...',
                text: "Ha ocurrido un problema al realizar esta acción. Por favor vuelve a intentarlo.",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
            }
          },
          error => {
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: "Ha ocurrido un problema al realizar esta acción. Por favor vuelve a intentarlo.",
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        );

      }
    })

  }



}
