import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Global } from 'src/app/services/global';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { TecnicosService } from 'src/app/services/tecnicos.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'tecnicos',
  templateUrl: './tecnicos.component.html',
})

export class TecnicosComponent{

  closeResult: string;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  formTecnico: FormGroup;
  private modalRef;

  public tecnicos = null;
  public limite_tecnicos = 9999;
  public cuenta_tecnicos = 0;
  public identity = null;
  public datos_tecnico = {
    'id_tecnico' : "",
    'nombre' : "",
    'direccion' : "",
    'telefono' : "",
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    private _bilder: FormBuilder,
    private _tecnicosService: TecnicosService,
    private _usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
  ){

  }

  ngOnInit(){
    this.spinner.show('spnr-s');
    this.identity = this._usuarioService.getIdentity();
    this.limiteTecnicos();
    this.obtenerTecnicos();
    this.colocarValores();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje
    };

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  limiteTecnicos(){
    let respuesta = this._configuracionesService.obtenerLimites();
    respuesta.then( val =>{
      if( val.status == "success" ){
        this.limite_tecnicos = val.limites.tecnicos.limite;
        this.cuenta_tecnicos = val.limites.tecnicos.cuenta;
      }
    });
  }

  obtenerTecnicos(){
    this._tecnicosService.obtenerTecnicos().subscribe(
      response => {
        this.tecnicos = response.tecnicos;
          this.dtTrigger.next();
        this.spinner.hide('spnr-s');
      },
    );
  }

  recargarTecnicos() {
    this.spinner.show('spnr-s');
    this._tecnicosService.obtenerTecnicos().subscribe(
      response => {
        this.tecnicos = response.tecnicos;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
        this.spinner.hide('spnr-s');
      },
    );

  }

  eliminarTecnico(id){
    Swal.fire({
      title: '¿Está seguro de eliminar este Tecnico?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show('spnr-s');
        this.ejecutarEliminacion(id);
      }
    })
  }

  ejecutarEliminacion(id){
    this._tecnicosService.eliminarTecnico(id).subscribe(
      response => {

        if(response.status == 'success'){
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Tecnico eliminado exitosamente',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
          });
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          this.obtenerTecnicos();
        }else{
          this.spinner.hide('spnr-s');
          this.mensajeError(response.message);
        }

      },
      error => {
        let text = "Ha ocurrido un problema al eliminar este elemento. Vuelve a intenatarlo.";
        this.spinner.hide('spnr-s');
        this.mensajeError(text);
      }
    );
  }

  mensajeError(texto){
    Swal.fire({
      title: 'Oops..',
      text: texto,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    });
  }

  registrarTecnico(contentModal){
    this.modalRef = this.modalService.open(contentModal);
  }

  actualizarTecnico(id_tecnico, contentModal){
    this.spinner.show('spnr-s');
    this.obtenerTecnico(id_tecnico, contentModal);
  }

  colocarValores( tecnico = null ){
    let datos = null;
    if( tecnico != null ){
      datos = tecnico;
    }else{
      datos = this.datos_tecnico;
    }

    this.formTecnico = this._bilder.group({
      id_tecnico : [datos.id_tecnico],
      nombre: [datos.nombre, Validators.compose([ Validators.maxLength(75), Validators.required ])],
      direccion: [datos.direccion, Validators.maxLength(75) ],
      telefono: [datos.telefono, Validators.compose([ Validators.maxLength(10), Validators.required ])],
    });

  }

  obtenerTecnico(id_tecnico, contentModal){
    if( id_tecnico != null && id_tecnico != ""){
      let respuesta = this._tecnicosService.obtenerTecnico( id_tecnico );
      respuesta.then( val =>{
        if( val.status == "success" ){
          if(val.tecnico != null ){
            this.spinner.hide('spnr-s');
            this.colocarValores( val.tecnico );
            this.modalRef = this.modalService.open( contentModal );
          }
        }else{
          this.spinner.hide('spnr-s');
        }
      });
    }
  }

  registrar( datos ){
    this.spinner.show('spnr-s');
    let respuesta = null;
    let titulo = "";

    if( datos.id_tecnico == "" ){
      respuesta = this._tecnicosService.registrarTecnico( datos );
      titulo = 'Técnico Registrado Exitosamente';
    }else{
      respuesta = this._tecnicosService.actualizarTecnico( datos );
      titulo = 'Técnico Actualizado Exitosamente';
    }

    respuesta.then( val =>{
      if( val.status == "success" ){

        this.modalRef.close();

        this.spinner.hide('spnr-s');
        Swal.fire({
          title: titulo,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
        this.colocarValores();
        this.recargarTecnicos();

      }else{
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: val.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });

      }
    });
  }


}
