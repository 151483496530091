<form [formGroup]="formExp" (ngSubmit)="registrarExp(formExp.value)">
  <div class="container">
      <div class="row">
          <div class="col-sm">

            <div class="form-group">
              <label class="control-label">GANGLIOS</label>
              <input
                type="text"
                class="form-control"
                formControlName="ganglios">
            </div>

            <div class="form-group">
              <label class="control-label">VESTÍBULO</label>
              <input
                type="text"
                class="form-control"
                formControlName="vestibulo">
            </div>

            <div class="form-group">
              <label class="control-label">ATM</label>
              <input
                type="text"
                class="form-control"
                formControlName="atm">
            </div>

            <div class="form-group">
              <label class="control-label">PALADAR</label>
              <input
                type="text"
                class="form-control"
                formControlName="paladar">
            </div>

            <div class="form-group">
              <label class="control-label">MÚSCULOS</label>
              <input
                type="text"
                class="form-control"
                formControlName="musculos">
            </div>

          </div>

          <div class="col-sm">
            <div class="form-group">
              <label class="control-label">LENGUA</label>
              <input
                type="text"
                class="form-control"
                formControlName="lengua">
            </div>

            <div class="form-group">
              <label class="control-label">OCLUSIÓN</label>
              <input
                type="text"
                class="form-control"
                formControlName="oclusion">
            </div>

            <div class="form-group">
              <label class="control-label">LABIOS</label>
              <input
                type="text"
                class="form-control"
                formControlName="labios">
            </div>

            <div class="form-group">
              <label class="control-label">CARRILLOS</label>
              <input
                type="text"
                class="form-control"
                formControlName="carrillos">
            </div>

            <div class="form-group">
              <label class="control-label">PISOBOCA</label>
              <input
                type="text"
                class="form-control"
                formControlName="pisoboca">
            </div>

          </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-8 col-md-6 ">
            <div class="form-group">
                <button class="btn btn-success btn-block">
                  Guardar Exploración
                </button>
            </div>
        </div>
    </div>

  </div>
</form>
