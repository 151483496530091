import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { PacientesService } from 'src/app/services/pacientes.service';
import { AgendaService } from 'src/app/services/agenda.service';
import { MedicosService } from 'src/app/services/medicos.service';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PacientecreateComponent } from '../../pacientes/create/paciente-create.component';

declare var calendario:any;

@Component({
  selector: 'createcitas',
  templateUrl: './createcitas.component.html',
})

export class CreatecitasComponent {

  nuevacitaFrom: FormGroup;
  @Input() cModal:boolean;
  @Input() public activeModal;
  @Input() public id_cita;
  @Input() public datos_cita;
  @Input() public nuevaCita;

  public config = null;
  public citaDeshabilitar = false;
  public pacientes = null;
  public medicos = null;
  public contCPac = 1;
  public contCMed = 1;
  public cita = null;

  public minDate = new Date();
  public minTime = "08:00 am";
  public maxTime = "08:00 pm";
  public minTimef = "08:00 am";

  public touchUi = true;
  public stepMinute = 15;


  constructor(
    private spinner: NgxSpinnerService,
    private _bilder: FormBuilder,
    private _router: Router,
    private _pacientesService: PacientesService,
    private _medicosService: MedicosService,
    private _agendaService: AgendaService,
    private _configuracionesService: ConfiguracionesService,
    private modalService: NgbModal,
  ){

    this.config = this._configuracionesService.getConfig();
    this.minTime = this.convertirHora( this.config.hora_inicial );
    this.maxTime = this.convertirHora( this.config.hora_final );
    this.stepMinute = this.config.duracion_espacios;

  }

  ngOnInit(){

    this.colocarValores( );
    this.obtenerMedicos();
    //this.minDate = moment().subtract(1, 'days');
  }

  ngAfterViewInit() {

  }

  convertirHora( hora ){

    let hora_int = parseInt(hora);
    let hora_texto = null;
    let ampm = hora_int >= 12 ? 'pm' : 'am';
    hora_int = hora_int % 12;
    hora_int = hora_int ? hora_int : 12;
    if( hora_int < 10 ){
      hora_texto = "0" + hora_int + ":00 " + ampm;
    }else{
      hora_texto = hora_int + ":00 " + ampm;
    }

    return hora_texto;
  }

  colocarValores( cita = null ){

    if( cita != null ){

      let fecha_programada = this.obtenerFecha( cita.fecha_programada_inicial, 'en' );
      let hora_programada = this.obtenerHora( cita.fecha_programada_inicial );
      let hora_programada_f = this.obtenerHora( cita.fecha_programada_final );
      let fecha_programada_inicial = fecha_programada + " " + hora_programada;
      this.minTimef = this.convertirHora( hora_programada );
      let id_cita = cita.id_cita;

      this.nuevacitaFrom = this._bilder.group({
        id_cita: [id_cita],
        fecha_programada: [ { value : new Date(fecha_programada_inicial), disabled: this.nuevaCita}, [ Validators.required]],
        hora_programada: [ { value : hora_programada, disabled: this.nuevaCita}, [ Validators.required]],
        hora_programada_final: [ { value :hora_programada_f, disabled: this.nuevaCita}, [ Validators.required]],
        fecha_programada_inicial: [ fecha_programada+" "+hora_programada, Validators.required ],
        fecha_programada_final: [ cita.fecha_programada_final, Validators.required],
        deshabilitar: [0],
        motivo: [ cita.motivo, Validators.compose([ Validators.maxLength(255), Validators.required ])],
        id_paciente: [ null, Validators.required],
        id_medico: [ null, Validators.required],
      });

      if( cita.id_paciente != 0 && cita.id_paciente != null ){
        let respuesta = this._pacientesService.obtenerPacientes(cita.paciente);
        respuesta.then( val =>{
          if( val.status == "success" ){
            if(val.pacientes != null ){
              this.pacientes = val.pacientes;
              const toSelect =  this.pacientes.find(c => c.id == cita.id_paciente);
              this.nuevacitaFrom.get('id_paciente').setValue(toSelect);
            }
          }
        });
      }

      if( cita.id_medico != 0 && cita.id_medico != null ){
          const toSelect =  this.medicos.find(c => c.id == cita.id_medico);
          this.nuevacitaFrom.get('id_medico').setValue(toSelect);
      }

      this.cita = cita;

    }else{

      let fecha_programada: any;
      let hora_programada: any;
      let fecha_programada_final: any;
      let hora_programada_final: any;
      let fecha_programada_inicial: any;

      if(  this.datos_cita != null ){

        fecha_programada = this.datos_cita.fecha_inicio;
        hora_programada = this.datos_cita.hora_inicio;
        fecha_programada_inicial = fecha_programada + " " + hora_programada;
        fecha_programada_final = this.datos_cita.fecha_fin;
        hora_programada_final = this.datos_cita.hora_fin;

      }else{

        fecha_programada = this.obtenerFecha(null, 'en');
        hora_programada = '01:00';
        fecha_programada_inicial = fecha_programada + " " + hora_programada;

        var h_actual = new Date(fecha_programada_inicial);
        var h_max = new Date(fecha_programada + " 20:00:00");

        if( h_actual.getTime() > h_max.getTime() ){
          h_actual.setDate(h_actual.getDate() + 1);
          fecha_programada = new Date(this.obtenerFecha(h_actual, 'en')+" "+this.minTime);
          hora_programada = this.obtenerHora( fecha_programada );
          fecha_programada_inicial = this.obtenerFecha(fecha_programada, 'en') + " " + hora_programada;
          this.minDate = fecha_programada;
        }

      }

      fecha_programada_final = fecha_programada_final + " " + hora_programada_final;

      this.nuevacitaFrom = this._bilder.group({
        id_cita: [null],
        fecha_programada: [ { value : new Date(fecha_programada_inicial), disabled: this.nuevaCita}, [ Validators.required]],
        hora_programada: [ { value : hora_programada, disabled: this.nuevaCita}, [ Validators.required]],
        hora_programada_final: [ { value : '', disabled: this.nuevaCita} ],
        fecha_programada_inicial: [ fecha_programada_inicial, Validators.required ],
        fecha_programada_final: [ fecha_programada_final ],
        deshabilitar: [false],
        motivo: [ '', Validators.compose([ Validators.maxLength(255), Validators.required ])],
        id_paciente: [ '', Validators.required],
        id_medico: [ '', Validators.required ],
      });

    }

  }

  obtenerFecha( fecha = null, formato = null ){

    if( fecha != null){
      var d = new Date( fecha );
    }else{
      var d = new Date();
    }

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if( formato != null && formato == "en" ){
      return [year, month, day].join('-') ;
    }else{
      return [day, month, year].join('-') ;
    }

  }

  obtenerHora( fecha = null ){

    if( fecha != null){
      var d = new Date( fecha );
    }else{
      var d = new Date();
    }

    let hours = d.getHours();
    let minutes = d.getMinutes();

    let min = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + min ;

    return strTime;

  }

  obtenerCita(){
    if( this.id_cita != null){
      this.spinner.show('spnr-s');
      let respuesta = this._agendaService.obtenerCita( this.id_cita );
      respuesta.then(
        val =>{
          this.spinner.hide('spnr-s');
          if( val.status == "success" ){
            if(val.cita != null ){
              this.colocarValores( val.cita );
            }
            this.aplicarSeleccionados();
          }
        },
        error => {
          this.spinner.hide('spnr-s');
        }
      );

    }

  }

  aplicarSeleccionados(){
    if(  this.datos_cita != null ){
      this.colocarValores();
    }
  }

  obtenerMedicos(){
    let respuesta = this._medicosService.obtenerMedicos();
    respuesta.then( val =>{
      if( val.status == "success" ){
        if(val.meds != null ){
          this.medicos = val.meds;
          this.obtenerCita();
        }
      }
    });
  }

  obtenerPacientes(event: any) {
    let texto = '';
    texto += event.target.value;

    if ( texto.length >= 3 ) {

      let respuesta = this._pacientesService.obtenerPacientes(texto);
      respuesta.then( val =>{
        if( val.status == "success" ){
          if(val.pacientes != null ){
            this.pacientes = val.pacientes;
          }
        }
      });

      this.contCPac = 1;

    }else{
      this.pacientes = null;
      this.contCPac = 0;
    }
  }

  displayFn(elemento): string {
      return elemento ? elemento.texto : elemento;
  }

  cambiaEstado( event:any ){
    if (event.checked === true) {
      this.citaDeshabilitar = true;
      this.nuevacitaFrom.controls['id_paciente'].clearValidators();
      this.nuevacitaFrom.controls['id_medico'].clearValidators();
      this.nuevacitaFrom.controls["id_paciente"].updateValueAndValidity();
      this.nuevacitaFrom.controls["id_medico"].updateValueAndValidity();

    } else {
      this.citaDeshabilitar = false;
      this.nuevacitaFrom.controls['id_paciente'].setValidators([Validators.required]);
      this.nuevacitaFrom.controls['id_medico'].setValidators([Validators.required]);
      this.nuevacitaFrom.controls["id_paciente"].updateValueAndValidity();
      this.nuevacitaFrom.controls["id_medico"].updateValueAndValidity();
    }
  }

  amto24( hora ){
    var time = hora;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = null;
    try {
      AMPM = time.match(/\s(.*)$/)[1];
    } catch (error) {
      return hora;
    }
    if(AMPM == "PM" && hours<12) hours = hours+12;
    if(AMPM == "AM" && hours==12) hours = hours-12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if(hours<10) sHours = "0" + sHours;
    if(minutes<10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes ;
  }

  cambioHora( ){
    let hora_seleccionada = this.nuevacitaFrom.controls.hora_programada.value;
    this.minTimef = hora_seleccionada;
  }

  registrarCita(nuevacitaFrom){

    this.spinner.show('spnr-s');
    let respuesta = null;

    if( this.citaDeshabilitar == false ){
      nuevacitaFrom.id_medico = nuevacitaFrom.id_medico.id;
      nuevacitaFrom.id_paciente = nuevacitaFrom.id_paciente.id;
    }else{
      nuevacitaFrom.id_medico = 0;
      nuevacitaFrom.id_paciente = 0;
    }



    if( this.nuevaCita || nuevacitaFrom.id_cita == null ){
      if( nuevacitaFrom.fecha_programada ){
        nuevacitaFrom.fecha_programada = this.obtenerFecha( nuevacitaFrom.fecha_programada, 'en' );
        nuevacitaFrom.hora_programada = this.amto24( nuevacitaFrom.hora_programada );
        nuevacitaFrom.fecha_programada_inicial = nuevacitaFrom.fecha_programada + ' ' + nuevacitaFrom.hora_programada;

      }else{
        nuevacitaFrom.fecha_programada = this.obtenerFecha( nuevacitaFrom.fecha_programada_inicial, 'en' );
        nuevacitaFrom.hora_programada = this.obtenerHora( nuevacitaFrom.fecha_programada_inicial );
      }
      respuesta = this._agendaService.registrarCita( nuevacitaFrom );
    }else{
      //nuevacitaFrom.fecha_programada_final = "undefined undefined";
      nuevacitaFrom.fecha_programada = this.obtenerFecha( nuevacitaFrom.fecha_programada, 'en' );
      nuevacitaFrom.hora_programada = this.amto24( nuevacitaFrom.hora_programada );
      nuevacitaFrom.fecha_programada_inicial = nuevacitaFrom.fecha_programada + ' ' + nuevacitaFrom.hora_programada;
      nuevacitaFrom.hora_programada_final = this.amto24( nuevacitaFrom.hora_programada_final );
      nuevacitaFrom.fecha_programada_final = this.obtenerFecha( nuevacitaFrom.fecha_programada_final, 'en' ) + ' ' + this.amto24( nuevacitaFrom.hora_programada_final );
      respuesta = this._agendaService.actualizarCita( nuevacitaFrom );
    }

    respuesta.then(
      val =>{
        if( val.status == "success" ){

          let id_cita = val.id_cita;
          let fecha_cita = val.fecha_cita;

          if( this.cModal ){ this.activeModal.close(); }
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Cita Registrada Exitosamente',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

          this._router.navigate(['/agenda', fecha_cita]);

        }else{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: val.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });

        }
      },
      error => {
        this.spinner.hide('spnr-s');
        Swal.fire({
          title: 'Oopss...',
          text: 'Ha Ocurrido un Problema al Realizar el Movimiento',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#1beb11',
        });
      }
    );

  }

  registroPaciente( ){
    const modalRef = this.modalService.open(PacientecreateComponent, { size: 'lg' });
    modalRef.componentInstance.nuevoPaciente = true;
  }

}
