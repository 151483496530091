<form [formGroup]="formTriage" (ngSubmit)="registrarTriage(formTriage.value)">

  <ul class="nav nav-pills nav-pills-primary nav-pills-icons justify-content-center" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" href="#paso1" role="tab" data-toggle="tab">
          Últimos 14 Días
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="#paso2" role="tab" data-toggle="tab">
          Definiciones Operacionales
        </a>
    </li>
  </ul>

  <div class="container">
    <div class="row justify-content-center mt-3">
      <div class="col-sm-12 col-md-7">
        <div class="form-group">
          <label class="control-label">Definición Operacional de Caso Sospechoso</label>
          <input
            [ngStyle]="{'background-color': color_resultado}"
            type="text"
            class="form-control"
            formControlName="resultado">
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <div class="form-group">
          <label class="control-label">Temperatura °C</label>
          <input
            type="text"
            class="form-control"
            formControlName="temperatura"
            (change)="validaciones()">
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3 pt-3">
        <button class="btn btn-success btn-block">
          Guardar
        </button>
      </div>
    </div>
  </div>

  <div class="tab-content tab-space">
      <div class="tab-pane active" id="paso1">

        <div class="container">
          <div class="row justify-content-center">
            <h4>EN LOS ULTIMOS 14 DIAS:</h4>
          </div>

          <div class="row justify-content-center pt-2" >
              <div class="col-sm-12 col-md-7">
                  <div class="row justify-content-center">
                      <div class="col-sm-7 pt-3">
                          <b>1.- ¿Ha presentado fiebre mayor a los 38°C?</b>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                            <mat-select formControlName="fiebre"  class="form-control" (selectionChange)="validaciones()">
                              <mat-option [value]="0">No</mat-option>
                              <mat-option [value]="1">Si</mat-option>
                            </mat-select>
                        </div>
                      </div>
                  </div>

                  <div class="row justify-content-center pt-2">
                      <div class="col-sm-7 pt-3">
                          <b>2.- ¿Ha presentado sintomas de enfermedad respiratoria?</b>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <mat-select formControlName="respiratoria"  class="form-control" (selectionChange)="validaciones()">
                            <mat-option [value]="0">No</mat-option>
                            <mat-option [value]="1">Si</mat-option>
                          </mat-select>
                        </div>
                      </div>
                  </div>

                  <div class="row justify-content-center pt-2">
                      <div class="col-sm-7 pt-3">
                          <b>3.- ¿Ha viajado a países de riesgo de contagio?</b>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <mat-select formControlName="viaje"  class="form-control" (selectionChange)="validaciones()">
                            <mat-option [value]="0">No</mat-option>
                            <mat-option [value]="1">Si</mat-option>
                          </mat-select>
                        </div>
                      </div>
                  </div>

                  <div class="row justify-content-center pt-2">
                      <div class="col-sm-7 pt-3">
                          <b>4.- ¿Ha estado en contacto con alguna persona con diagnóstico de Covid-19?</b>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <mat-select formControlName="contacto"  class="form-control" (selectionChange)="validaciones()">
                            <mat-option [value]="0">No</mat-option>
                            <mat-option [value]="1">Si</mat-option>
                          </mat-select>
                        </div>
                      </div>
                  </div>

                  <div class="row justify-content-center pt-2">
                      <div class="col-sm-7 pt-3">
                          <b>5.- ¿Ha estado en contacto estrecho con personas que presentaban síntomas de enfermedad respiratoria?</b>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <mat-select formControlName="estrecho"  class="form-control" (selectionChange)="validaciones()">
                            <mat-option [value]="0">No</mat-option>
                            <mat-option [value]="1">Si</mat-option>
                          </mat-select>
                        </div>
                      </div>
                  </div>
              </div>

              <div class="col-sm-12 col-md-5">
                <div class="text-center">
                  <h4>IMPORTANTE</h4>
                </div>
                <b>En caso de que se registre una temperatura mayor a 38°C, realizar las siguientes indicaciones:</b>
                <p>
                    *Explicarle a la persona que no puede ingresar al consultorio. <br>
                    *Recomendarle que acuda a su médico y a su domicilio para realizar el distanciamiento social.
                </p>
              </div>
          </div>

        </div>

      </div>
      <div class="tab-pane" id="paso2">
        <div class="container">
            <div class="row justify-content-center mt-3">
                <div class="col-sm-12 col-md-10">
                    <h5>DEFINICIONES OPERACIONALES</h5>
                    <b>CASO SOSPECHOSO:</b>
                    <p>
                        Persona de cualquier edad que en los últimos siete dias haya presentado al menos dos de los siguientes
                        signos y sintomas: tos, fiebre o cefalea (en menores de cinco años de edad, la irritabilidad puede
                        sustituir a la cefalea).
                    </p>

                    <div class="row justify-content-center mt-1 mb-4">

                        <div class="col-sm-6 col-md-3">
                          <div class="form-group">
                              <label class="control-label">Tos</label>
                              <mat-select formControlName="tos"  class="form-control" (selectionChange)="validaciones()">
                                <mat-option [value]="0">No</mat-option>
                                <mat-option [value]="1">Si</mat-option>
                              </mat-select>
                          </div>
                        </div>

                        <div class="col-sm-6 col-md-3">
                          <div class="form-group">
                              <label class="control-label">Fiebre</label>
                              <mat-select formControlName="fiebre2"  class="form-control" (selectionChange)="validaciones()">
                                <mat-option [value]="0">No</mat-option>
                                <mat-option [value]="1">Si</mat-option>
                              </mat-select>
                          </div>
                        </div>

                        <div class="col-sm-6 col-md-3">
                          <div class="form-group">
                              <label class="control-label">Cefalea</label>
                              <mat-select formControlName="cefalea"  class="form-control" (selectionChange)="validaciones()">
                                <mat-option [value]="0">No</mat-option>
                                <mat-option [value]="1">Si</mat-option>
                              </mat-select>
                          </div>
                        </div>

                    </div>

                    <b>Acompañadas de al menos uno de los siguientes signos o sintomas:</b>
                </div>
            </div>

            <div class="row justify-content-center mt-5">

                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                      <label class="control-label">Disnea(dificultad p/ respirar)</label>
                      <mat-select formControlName="disena"  class="form-control" (selectionChange)="validaciones()">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Si</mat-option>
                      </mat-select>
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                      <label class="control-label">Artralgias</label>
                      <mat-select formControlName="artralgias"  class="form-control" (selectionChange)="validaciones()">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Si</mat-option>
                      </mat-select>
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                      <label class="control-label">Mialgias</label>
                      <mat-select formControlName="mialgias"  class="form-control" (selectionChange)="validaciones()">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Si</mat-option>
                      </mat-select>
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                      <label class="control-label">Odinofagia / Ardor Faríngeo</label>
                      <mat-select formControlName="odinofagia"  class="form-control" (selectionChange)="validaciones()">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Si</mat-option>
                      </mat-select>
                  </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                      <label class="control-label">Rinorrea</label>
                      <mat-select formControlName="rinorrea"  class="form-control" (selectionChange)="validaciones()">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Si</mat-option>
                      </mat-select>
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                      <label class="control-label">Conjuntivitis</label>
                      <mat-select formControlName="conjuntivitis"  class="form-control" (selectionChange)="validaciones()">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Si</mat-option>
                      </mat-select>
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                      <label class="control-label">Dolor Torácico</label>
                      <mat-select formControlName="toraico"  class="form-control" (selectionChange)="validaciones()">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Si</mat-option>
                      </mat-select>
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                      <label class="control-label">Perdida de Olfato</label>
                      <mat-select formControlName="olfato"  class="form-control" (selectionChange)="validaciones()">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Si</mat-option>
                      </mat-select>
                  </div>
                </div>
            </div>
        </div>

      </div>
  </div>

</form>
