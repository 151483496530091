import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { CajaService } from 'src/app/services/caja.service';
import { Global } from 'src/app/services/global';

@Component({
  selector: 'caja-corte',
  templateUrl: './caja-corte.component.html',
})

export class CajacorteComponent {

  public movimientos = null;
  public corte = null;
  public id_corte = null;
  public estado_tabla = 0;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _cajaService: CajaService,
  ){

  }

  ngOnInit(){
    this._route.params.forEach((params:Params) => {
      this.id_corte = params['id'];
    });
    this.obtenerCorte( );
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje,
      order:[[0, 'desc']]
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  obtenerCorte( ){
    this.spinner.show('spnr-s');
    let respuesta = this._cajaService.obtenerCorte( this.id_corte );
    respuesta.then( val =>{
      if( val.status == "success" ){
        this.movimientos = val.movimientos;
        this.corte = val.corte;
        if( this.estado_tabla == 0 ){
          this.estado_tabla = 1;
          this.dtTrigger.next();
        }else{
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        }

        this.spinner.hide('spnr-s');
      }
    });
  }

}
