import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { Global } from 'src/app/services/global';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';
declare function createOdontogram():any;
declare function iniciarOdontograma(datosdientes, tipo):any;
declare function iniciarBotones(tipo):any;
declare function guardarOdontograma():any;
declare var domtoimage:any;

@Component({
  selector: 'odontograma',
  templateUrl: './odontograma.component.html',
  styleUrls: ['./odontograma.component.css'],
})

export class OdontogramaComponent {

  public tipo = null;
  public id_paciente = null;
  public subscribe = null;
  public odontograma = null;
  public iniciado = 0;
  public pronostico = "";
  public diagnostico = "";
  public img = "";
  public tipo_dispositivo = 0;
  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
    private _pacienteService: PacientesService,
    private _expedienteService: ExpedienteService,
  ){

  }

  ngOnInit(){

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.tipo_dispositivo = 1;
    }else{
      this.tipo_dispositivo = 0;
    }


    this.iniciado = 0;
    if( this.id_paciente == null ){
      this.id_paciente = this._route.parent.snapshot.paramMap.get('id');
    }
    this.subscribe = this._pacienteService.id_paciente$.subscribe( id_paciente =>{
      this.id_paciente = id_paciente;
    });
    this._route.params.forEach((params:Params) => {
      this.tipo =  params['tipo'];
      this.odontograma = null;
      this.pronostico = "";
      this.diagnostico = "";
      this.iniciarOdontogramavista();
      this.obtenerOdontograma();
    });

  }

  ngAfterViewInit() {
    this.iniciarOdontogramavista();
    this.onResize();
    //document.querySelector('meta[name="viewport"]').setAttribute("content", 'width=1920, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
  }

  onResize(){
    if( $(window).width() <= 1450 ){
      if( $('#codonto').hasClass('tamanodonto') == false ){
        $('#codonto').addClass('tamanodonto');
      }
    }else{
      if( $('#codonto').hasClass('tamanodonto') ){
        $('#codonto').removeClass('tamanodonto');
      }
    }
  }

  iniciarOdontogramavista(){
    let dientes = `<div class="row uno">
        <div id="tr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        </div>
        <div id="tl" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        </div>
        <div id="tlr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
        </div>
        <div id="tll" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        </div>
    </div>
    <div class="row">
        <div id="blr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
        </div>
        <div id="bll" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        </div>
        <div id="br" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        </div>
        <div id="bl" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        </div>
    </div>`;
    document.getElementById('dientes').innerHTML = dientes;
    createOdontogram();
    let tipo_dispositivo = this.tipo_dispositivo;
    this.cargarOdontograma( );
    iniciarBotones( tipo_dispositivo );

   // document.querySelector('meta[name="viewport"]').content = 'height=1080, width=1920, initial-scale=1';
  }

  cargarOdontograma( ){

    let tipo_dispositivo = this.tipo_dispositivo;
    let stringdientes = 'null';
    if(this.odontograma != null){
      stringdientes = this.odontograma.informacion;
    }
    if( stringdientes != 'null' ){
      let datosdientes = JSON.parse( stringdientes );
      iniciarOdontograma(datosdientes, tipo_dispositivo);
    }
  }

  ngOnDestroy(){
    if( $('#codonto').hasClass('tamanodonto') ){
      $('#codonto').removeClass('tamanodonto');
    }
    this.subscribe.unsubscribe();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  obtenerOdontograma(){
    this.spinner.show('spnr-s');

    this.odontograma = null;
    this.pronostico = "";
    this.diagnostico = "";

    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerOdontograma( this.tipo, this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            this.odontograma = val.odontograma;

            if(this.odontograma != null){
              this.pronostico = this.odontograma.pronostico;
              this.diagnostico = this.odontograma.diagnostico;
            }

            this.cargarOdontograma();
            if(this.iniciado == 0){
              this.iniciado = 1;
            }

          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  registrarOdontograma() {

    this.spinner.show('spnr-s');
    let spnr = this.spinner;
    let candena_dientes = guardarOdontograma();
    let node = document.getElementById('dientes');
    let tipo = this.tipo;
    let id_paciente = this.id_paciente;
    let pronostico = this.pronostico;
    let diagnostico = this.diagnostico;
    let expedienteS = this._expedienteService;

    domtoimage.toPng(node, { width: 1200, }).then(function (canvas) {

        let imgdata = canvas;

        let data = {
          candena_dientes: candena_dientes,
          tipo: tipo,
          imgdata: imgdata,
          ip: id_paciente,
          pronostico: pronostico,
          diagnostico: diagnostico,
        };

        let respuesta = expedienteS.registrarOdontograma( data );
        respuesta.then(
          val =>{
            if( val.status == "success" ){
              spnr.hide('spnr-s');
              Swal.fire({
                title: 'Registro Exitoso',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });

            }else{
              spnr.hide('spnr-s');
              Swal.fire({
                title: 'Oopss...',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
            }
          },
          error =>{
            spnr.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        );

    }).catch(function (error) {
      spnr.hide('spnr-s');
      console.log(error);
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    });

  }

}
