<div class="container pb-5">
    <div class="row">
        <div class="col-4 col-sm-4 align-left">
            <b>ADClinik</b>
        </div>
        <div class="col-4 col-sm-4 text-center">
            <b><a href="https://adclinik.com/contacto" target="_blank"> Contactar </a></b>
        </div>
        <div class="col-4 col-sm-4">
            <div class="pull-right hidden-xs">
                <b>Versión</b> 2.0.0
            </div>
        </div>
    </div>
</div>

