<div class="sidebar" data-color="blue">

  <div class="logo">
    <a href="#" class="simple-text logo-mini">
      <img src="{{urlImg}}logos/{{configApp.logo_small}}{{timg}}" alt="ADClinik">
    </a>
    <a href="#" class="simple-text logo-normal">
      {{configApp.nombre}}
    </a>

    <div class="navbar-minimize">
      <button id="minimizeSidebar" class="btn btn-simple btn-icon btn-neutral btn-round">
        <i class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
        <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>
      </button>
    </div>
  </div>


  <div class="sidebar-wrapper"  [perfectScrollbar]="config">


    <ul class="nav">

      <li>
        <a data-toggle="collapse" href="#Pacientes">
          <i class="now-ui-icons users_single-02"></i>
          <p>
            Pacientes<b class="caret"></b>
          </p>
        </a>
        <div class="collapse " id="Pacientes">
          <ul class="nav">
            <li>
              <a [routerLink]="['/pacientes']" class="mitm">
                <span class="sidebar-mini-icon"><i class="fas fa-user-friends"></i></span>
                <span class="sidebar-normal"> Pacientes </span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/pacientes/registro']" class="mitm">
                <span class="sidebar-mini-icon"><i class="fas fa-user-plus"></i></span>
                <span class="sidebar-normal"> Registro de Pacientes </span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/pacientes/documentos']" class="mitm">
                <span class="sidebar-mini-icon"><i class="fas fa-file-medical-alt"></i></span>
                <span class="sidebar-normal"> Imprimir Documentos </span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <!-- <li>
        <a data-toggle="collapse" href="#Recetario">
          <i class="fas fa-file-prescription"></i>
          <p>
            Recetario<b class="caret"></b>
          </p>
        </a>
        <div class="collapse " id="Recetario">
          <ul class="nav">
            <li>
              <a href='#'>
                <span class="sidebar-mini-icon"><i class="fas fa-file-prescription"></i></span>
                <span class="sidebar-normal"> Lista de Recetas </span>
              </a>
            </li>
            <li>
              <a href='#'>
                <span class="sidebar-mini-icon"><i class="fas fa-prescription"></i></span>
                <span class="sidebar-normal"> Crear Receta </span>
              </a>
            </li>
          </ul>
        </div>
      </li> -->

      <!-- MODULO DE FINANCIERO -->
      <li>
        <a [routerLink]="['caja']" class="mitm">
          <i class="now-ui-icons business_money-coins"></i>
          <p>
            Caja
          </p>
        </a>
      </li>

      <li>
        <a data-toggle="collapse" href="#formsExamples">
          <i class="now-ui-icons ui-2_settings-90"></i>
          <p>
            Administración <b class="caret"></b>
          </p>
        </a>

        <div class="collapse " id="formsExamples">
          <ul class="nav">
            <li>
              <a [routerLink]="['clinicas']" class="mitm">
                <span class="sidebar-mini-icon"><i class="fas fa-clinic-medical"></i></span>
                <span class="sidebar-normal"> Clínicas </span>
              </a>
            </li>

            <li>
              <a [routerLink]="['usuarios']" class="mitm">
                <span class="sidebar-mini-icon"><i class="fas fa-users"></i></span>
                <span class="sidebar-normal"> Usuarios </span>
              </a>
            </li>

            <!-- MODULO DE TECNICOS -->
            <li>
              <a [routerLink]="['tecnicos']" class="mitm">
                <span class="sidebar-mini-icon"><i class="fas fa-briefcase-medical"></i></span>
                <span class="sidebar-normal"> Técnicos </span>
              </a>
            </li>


            <li>
              <a [routerLink]="['tratamientos']" class="mitm">
                <span class="sidebar-mini-icon"><i class="fas fa-stethoscope"></i></span>
                <span class="sidebar-normal"> Tratamientos </span>
              </a>
            </li>

            <li>
              <a [routerLink]="['cuestionarios']" class="mitm">
                <span class="sidebar-mini-icon"><i class="fas fa-question-circle"></i></span>
                <span class="sidebar-normal"> Cuestionarios </span>
              </a>
            </li>

            <!-- MODULO DE INVENTARIOS -->
            <!-- <li>
              <a [routerLink]="['inventarios']" >
                <span class="sidebar-mini-icon"><i class="fas fa-clipboard-list"></i></span>
                <span class="sidebar-normal"> Inventarios </span>
              </a>
            </li> -->

            <!-- MODULO DE INVENTARIOS -->
            <!-- <li>
              <a [routerLink]="['consumos']" >
                <span class="sidebar-mini-icon"><i class="fas fa-clipboard-list"></i></span>
                <span class="sidebar-normal"> Consultar Consumo </span>
              </a>
            </li> -->

            <li>
              <a [routerLink]="['configuracion']" class="mitm">
                <span class="sidebar-mini-icon"><i class=" fas fa-cogs"></i></span>
                <span class="sidebar-normal"> Configuracion </span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <div id="contenedor-medicos" class='m-2 mt-5' style='font-size: .85em;'>

    </div>

  </div>



</div>
