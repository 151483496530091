<div class="row">
  <div class="col-md-8">
    <h1>Cuestionario Personalizado</h1>
  </div>
  <div class="col-md-4">
    <div class="pull-right">
      <button type="button" class="btn btn-success"
        (click)="registroModal()">
        <span class="glyphicon glyphicon-copy icono"></span>
        Registrar Pregunta
      </button>
    </div>
  </div>
</div>

<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered dataTable no-footer dtr-inline">
  <thead>
    <tr>
      <th class="disabled-sorting sorting_desc" style="width: 8%;">Folio</th>
      <th class="disabled-sorting sorting_desc">Pregunta</th>
      <th class="disabled-sorting sorting_desc" style="width: 8%;">Estado</th>
      <th class="disabled-sorting sorting_desc text-center" data-priority="1">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let cuestionarios of cuestionarios">
      <td>{{ cuestionarios.id_preguntas }}</td>
      <td>{{ cuestionarios.pregunta }}</td>
      <td *ngIf="cuestionarios.estado == 1">Activo</td>
      <td *ngIf="cuestionarios.estado == 2">Inactivo</td>
      <td class="text-center">
        <button class="btn btn-warning btn-icon btn-sm mr-1"  (click)="actualizarModal(cuestionarios.id_preguntas)" title="Actualizar" aria-label="Actualizar" data-pjax="0">
          <i class="far fa-edit"></i>
        </button>
        <button *ngIf="cuestionarios.estado == 2" class="btn btn-success btn-icon btn-sm mr-1"  (click)="deshabilitarPregunta(cuestionarios.id_preguntas)" title="Habilitar" aria-label="Habilitar">
          <i class="fas fa-check"></i>
        </button>
        <button *ngIf="cuestionarios.estado == 1" class="btn btn-purple btn-icon btn-sm mr-1"  (click)="deshabilitarPregunta(cuestionarios.id_preguntas)" title="Deshabilitar" aria-label="Deshabilitar">
          <i class="fa fa-times"></i>
        </button>
        <button class="btn btn-danger btn-icon btn-sm"  (click)="eliminarPregunta(cuestionarios.id_preguntas)" title="Eliminar" aria-label="Eliminar">
          <i class="far fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #contentModal let-c="close" let-d="dismiss">
</ng-template>
