<ul class="nav nav-pills nav-pills-info justify-content-center" role="tablist" >
  <li class="nav-item">
      <a class="nav-link active" data-toggle="tab" href="#traige" role="tablist" aria-expanded="false">
          Triage
      </a>
  </li>
  <li class="nav-item">
      <a class="nav-link" data-toggle="tab" href="#generales" role="tablist" aria-expanded="false">
          Generales
      </a>
  </li>
  <li class="nav-item">
      <a class="nav-link" data-toggle="tab" href="#antecedentes" role="tablist" aria-expanded="false">
          Antecedentes
      </a>
  </li>
  <li class="nav-item">
      <a class="nav-link" data-toggle="tab" href="#personalizado" role="tablist" aria-expanded="false">
          Personalizado
      </a>
  </li>
  <li class="nav-item">
      <a class="nav-link" data-toggle="tab" href="#exploracion" role="tablist" aria-expanded="false">
          Exploración Física
      </a>
  </li>
  <li class="nav-item">
      <a class="nav-link" data-toggle="tab" href="#facial" role="tablist" aria-expanded="false">
          Facial/Funcional
      </a>
  </li>
</ul>

<!-- ******************************************************************************************************************************************************************** -->

<div class="tab-content tab-space">
  <!--DIVISION DE SIGNOS VITALES-->
  <div class="tab-pane" id="generales" aria-expanded="false">
      <!--FORMULARIO DE SIGNOS VITALES-->
      <generales [id_paciente]="id_paciente" ></generales>
  </div>

  <!-- ******************************************************************************************************************************************************************** -->

  <div class="tab-pane" id="antecedentes" aria-expanded="false">

    <ul class="nav nav-pills nav-pills-success nav-pills-icons justify-content-center" role="tablist">
      <li class="nav-item">
          <a class="nav-link active" href="#patologicos" role="tab" data-toggle="tab">
              Heredofamiliares y<br>
              Personales Patológicos
          </a>
      </li>
      <li class="nav-item">
          <a class="nav-link" href="#nopatologicos" role="tab" data-toggle="tab">
              Personales<br>
              No Patológicos
          </a>
      </li>
      <li class="nav-item">
          <a class="nav-link" href="#ginecoobstetricos" role="tab" data-toggle="tab">
              Gineco <br>
              Obstetricos
          </a>
      </li>
    </ul>

    <div class="tab-content tab-space">
        <div class="tab-pane active" id="patologicos">
          <patologicos [id_paciente]="id_paciente" ></patologicos>
        </div>
        <div class="tab-pane" id="nopatologicos">
          <nopatologicos [id_paciente]="id_paciente" ></nopatologicos>
        </div>
        <div class="tab-pane" id="ginecoobstetricos">
          <gineco [id_paciente]="id_paciente" ></gineco>
        </div>
    </div>

  </div>

  <!-- ******************************************************************************************************************************************************************** -->

  <div class="tab-pane" id="personalizado" aria-expanded="false"> <!--FORMULARIO DE PERSONALIZADOS-->
    <personalizado [id_paciente]="id_paciente" ></personalizado>
  </div>

  <!-- ******************************************************************************************************************************************************************** -->

  <div class="tab-pane" id="exploracion" aria-expanded="false"> <!-- EXPLORACION FISICA -->
    <exploracion [id_paciente]="id_paciente" ></exploracion>
  </div>

  <!-- ******************************************************************************************************************************************************************** -->

  <div class="tab-pane" id="facial" aria-expanded="false"> <!-- ANALISIS FUNCIONAL/FACIAL -->
    <facial [id_paciente]="id_paciente" ></facial>
  </div>
  <!-- ******************************************************************************************************************************************************************** -->

  <div class="tab-pane active" id="traige" aria-expanded="false"><!--FORMULARIO DE TRIAGE-->
    <triage [id_paciente]="id_paciente" ></triage>
  </div>

</div>
