import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from './global';
import { Observable } from "rxjs";
import { UsuarioService } from "./usuario.service";
import { ConfiguracionesService } from "./configuraciones.service";

@Injectable()

export class PacientesService{

  public url: string;
  public token = null;
  public config = null;

  id_paciente$ = new EventEmitter<any>();

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService,
    private _configuracionesService: ConfiguracionesService,
  ){
    this.url = Global.url;
    this.config = this._configuracionesService.getConfig( );
    this.token = this._usuarioService.getToken();
  }

  setPaciente(id_paciente){
    this.id_paciente$.emit(id_paciente);
  }

  async obtenerPacientes( texto ){

    let data = JSON.stringify( {
      "clinica": this.config.id_clinica,
      "texto": texto
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "pacientes/obtenerpacientes", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async obtenerPaciente( id_paciente ){

    let data = JSON.stringify( {
      "ip": id_paciente
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "pacientes/obtenerpaciente", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async registrarPaciente( datos ){

    let data = JSON.stringify( {
      "ic": this.config.id_clinica,
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "pacientes/registrar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async actualizarPaciente( datos ){

    let data = JSON.stringify( {
      "ic": this.config.id_clinica,
      "datos": datos
    } );

    let params = 'data='+data;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.post(this.url + "pacientes/actualizar", params, {headers: headers}).toPromise();

    return respuesta;

  }

  async eliminarPaciente( id_paciente ){

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization',  this.token);

    var respuesta: any = await this._http.delete(this.url + "pacientes/eliminar/"+id_paciente, {headers: headers}).toPromise();

    return respuesta;

  }

}
