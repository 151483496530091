<div class="modal-header">
  <h5 class="modal-title" id="modal-primary-title">Registrar/Actualizar Pregunta</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body centered formulario">
  <form [formGroup]="formCuestionario" (ngSubmit)="registrarCuestionario(formCuestionario.value)">
    <div class="container">
      <input type="hidden" formControlName="id_preguntas">
        <div class="row justify-content-center">
          <div class="col-12 col-sm-8 col-md-9">
            <div class="form-group">
              <label class="control-label">PREGUNTA</label>
              <input
                type="text"
                id="descripcion"
                class="form-control"
                formControlName="pregunta">
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 col-sm-4 col-md-3 text-center">
            <label>ESTADO</label>
            <div class="form-group">
              <mat-slide-toggle
                formControlName="estado"
                color="primary">
              </mat-slide-toggle>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-7">
            <div class="form-group">
              <label class="control-label">CLINICA</label>
                <mat-select formControlName="id_clinica" class="form-control">
                  <mat-option *ngFor="let clinica of clinicas" [value]="clinica.id">{{clinica.texto}}</mat-option>
                </mat-select>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 col-sm-7">
            <div class="form-group">
              <button
                [disabled]="formCuestionario.invalid"
                type="submit"
                class="btn btn-success btn-block">
                Registrar
              </button>
            </div>
          </div>
        </div>
    </div>
  </form>
</div>
