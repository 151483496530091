import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ConfiguracionesService } from 'src/app/services/configuraciones.service';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { Global } from 'src/app/services/global';
import { PacientesService } from 'src/app/services/pacientes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'tratamientos_paciente',
  templateUrl: './tratamientos_paciente.component.html',
})

export class TratamientospacienteComponent {

  public id_paciente = null;
  public subscribe = null;
  public tratamientos = null;
  public iniciado = 0;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _configuracionesService: ConfiguracionesService,
    private _pacienteService: PacientesService,
    private _expedienteService: ExpedienteService,
  ){

  }

  ngOnInit(){
    this.iniciado = 0;
    this._route.params.forEach((params:Params) => {
      this.id_paciente =  params['id'];
    });
    if( this.id_paciente == null ){
      this.id_paciente = this._route.parent.snapshot.paramMap.get('id');
    }
    this.subscribe = this._pacienteService.id_paciente$.subscribe( id_paciente =>{
      this.id_paciente = id_paciente;
    });

    this.obtenerTratamientos();
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      language: Global.lenguaje,
      order:[[0, 'desc']]
    };
  }

  ngOnDestroy(){
    this.subscribe.unsubscribe();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  obtenerTratamientos(){
    this.spinner.show('spnr-s');
    if( this.id_paciente != null){
      let respuesta = this._expedienteService.obtenerTratamientos( this.id_paciente );
      respuesta.then(
        val =>{
          if( val.status == "success" ){
            this.spinner.hide('spnr-s');
            this.tratamientos = val.tratamientos;

            if(this.iniciado == 0){
              this.iniciado = 1;
              this.dtTrigger.next();
            }else{
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
              });
            }

          }else{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: val.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        },
        error =>{
          this.spinner.hide('spnr-s');
          Swal.fire({
            title: 'Oopss...',
            text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#1beb11',
          });
        }
      );
    }else{
      this.spinner.hide('spnr-s');
      Swal.fire({
        title: 'Oopss...',
        text: 'Ha ocurrido un problema al cargar los datos, se recomienda recargar la pagina.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1beb11',
      });
    }
  }

  cancelarTratamiento( id_tratamiento ){

    Swal.fire({
      title: 'Menciona el motivo de Cancelación',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      inputAttributes: {
        autocapitalize: 'off'
      },
      input: 'text',
      preConfirm: (concepto) => {
        this.spinner.show('spnr-s');
        let respuesta = this._expedienteService.cancelarTratamiento(id_tratamiento, concepto);
        respuesta.then(
          val =>{
            if( val.status == "success" ){
              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'Tratamiento Cancelado Exitosamente',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
              this.obtenerTratamientos();
            }else{
              this.spinner.hide('spnr-s');
              Swal.fire({
                title: 'Oopss...',
                text: val.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1beb11',
              });
            }
          },
          error =>{
            this.spinner.hide('spnr-s');
            Swal.fire({
              title: 'Oopss...',
              text: 'Ha ocurrido un problema al cargar los datos.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#1beb11',
            });
          }
        );

      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }

  verTratamiento( id_tratamiento ){
    this._router.navigate(['/paciente/expediente/'+this.id_paciente+'/tratamiento', id_tratamiento]);
  }

}
